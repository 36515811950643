import React, {Component, Fragment} from "react";
import "./center.css";
import SETTING_OK from "../../assets/icon/setting_ok.png";
import SETTING_FAIL from "../../assets/icon/setting_fail.png";
import {
    AliRoot,
    CenterRoot,
    filterArea,
    genProcessBack,
    getUserInfoCache, handleAliAuth, handleUrlState,
    InputTest,
    LoginRoot,
    regEmail,
    regPhone, storage,
    UserInfoStorage
} from "../../tool/Const";
import AccountMgrModal from "./AccountMgrModal";
import {connectionError, fail, failReturn, findMsgByError, success, successReturn} from "../../tool/http/ErrorMgr";
import {
    AliId,
    error, Header, LoginByWxUrl,
    loginOutErrOr,
    RefreshUserByIdUrl,
    sendGetBack,
    sendGetRequest,
    UpdateAliUrl,
    UpdateCareerUrl,
    UpdateNickUrl,
    UpdateWechatUrl
} from "../../tool/http/Request";
import LunchWin from "../../tool/Tool";
import {Checkbox} from "antd";
import WxPlugin from "../WxPlugin";

const accountUrl = CenterRoot + "?type=account";

class AccountMgr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: '',  // 用户信息缓存
            userInfoTmp: '',  // 用户信息缓存拷贝
            userNickModifyStatus: false,  // 用户昵称修改状态
            showModal: false,  // 修改设置弹窗开关
            modalTitle: '',  // 修改设置弹窗标题
            modalType: '',  // 修改设置弹窗类型
            modifyStatus: false,
            areaOptions: JSON.parse(JSON.stringify(filterArea)),  // 所属行业checkbox list
            areaOptionsTmp: [],
            targetOptions: JSON.parse(JSON.stringify(filterArea)),  // 感兴趣领域checkbox list
            targetOptionsTmp: [],
            checkBoxType: 0,  // 行业领域checkbox type，默认0，不显示；1为所属行业，最大选择值为1；2为感兴趣领域，最大选择值为3
            areaSelectNum: '',  // 所属行业select数量
            targetSelectNum: '',  // 感兴趣领域select数量
        }
    }

    // 账户安全设置
    loadCoreUserInfo = (userInfo, userInfoTmp, userNickModifyStatus, showModal) => {
        if (userInfo) {
            return (
                <Fragment>
                    <div className={"account-ct-user-setting-title"}>
                        <div className={"account-title-l"}>
                            {"账户安全设置"}
                        </div>
                        <div className={"account-title-r"}>
                            {"用户ID: "}{userInfo.userId}
                        </div>
                    </div>
                    <div className={"account-ct-user-setting-core"}>
                        {/* 实名认证 */}
                        <div className={"account-ct-user-setting-core-ele"}>
                            <div className={"account-ct-user-setting-core-l"}>
                                <img style={{marginRight: '10px'}} src={userInfo.userReal ? SETTING_OK : SETTING_FAIL} alt={""}/>
                                {"实名认证"}
                            </div>
                            <div className={"account-ct-user-setting-core-m"}>
                                {userInfo.userReal}
                            </div>
                            <div className={"account-ct-user-setting-core-r"}
                                 onClick={() => {
                                     window.location.href = "/center?type=certification"
                                 }}
                            >
                                {userInfo.userReal ? "" : "前往认证"}
                            </div>
                        </div>
                        {/* 用户昵称 */}
                        <div className={"account-ct-user-setting-core-ele"}>
                            <div className={"account-ct-user-setting-core-l"}>
                                <img style={{marginRight: '10px'}} src={SETTING_OK} alt={""}/>
                                {"设置昵称"}
                            </div>
                            <div className={"account-ct-user-setting-core-m"}>
                                {
                                    userNickModifyStatus ?
                                        <input className={"account-ct-user-setting-input"}
                                               style={{width: '80%'}}
                                               defaultValue={userInfoTmp.userNick}
                                               maxLength={10}
                                               onChange={(e) => {
                                                   this.changeUserNick(e, userInfoTmp)
                                               }}
                                        /> :
                                        userInfo.userNick
                                }
                            </div>
                            <div className={"account-ct-user-setting-core-r"}
                                 onClick={() => {this.setUserNickModify(userNickModifyStatus)}}
                            >
                                {
                                    userNickModifyStatus ?
                                        <div className={"account-ct-button-other-wrap"}>
                                            <button className={"center-ct-button save-button"}
                                                    onClick={() => {this.saveUserNickModify(userInfoTmp)}}
                                            >
                                                {"保存"}
                                            </button>
                                            <button className={"center-ct-button cancel-button"} style={{marginLeft: '10px'}}
                                                    onClick={() => {this.setUserNickModify(userNickModifyStatus)}}
                                            >
                                                {"取消"}
                                            </button>
                                        </div>
                                        :
                                        "修改昵称"
                                }
                            </div>
                        </div>
                        {/* 用户手机 */}
                        <div className={"account-ct-user-setting-core-ele"}>
                            <div className={"account-ct-user-setting-core-l"}>
                                <img style={{marginRight: '10px'}} src={SETTING_OK} alt={""}/>
                                {"绑定手机"}
                            </div>
                            <div className={"account-ct-user-setting-core-m"}>
                                {userInfo.userPhone.replace(regPhone, '$1****$2')}
                            </div>
                            <div className={"account-ct-user-setting-core-r"}
                                 onClick={() => {this.setModal(showModal, "修改手机", 1)}}
                            >
                                {"修改手机"}
                            </div>
                        </div>
                        {/* 用户邮箱 */}
                        <div className={"account-ct-user-setting-core-ele"}>
                            <div className={"account-ct-user-setting-core-l"}>
                                <img style={{marginRight: '10px'}} src={userInfo.userEmail ? SETTING_OK : SETTING_FAIL} alt={""}/>
                                {"绑定邮箱"}
                            </div>
                            <div className={"account-ct-user-setting-core-m"} style={{color: userInfo.userEmail ? '' : '#fb7299'}}>
                                {userInfo.userEmail ? userInfo.userEmail.replace(regEmail, '$1****$2') : "未绑定邮箱"}
                            </div>
                            <div className={"account-ct-user-setting-core-r"}
                                 onClick={() => {this.setModal(showModal, userInfo.userEmail ? "修改邮箱" : "绑定邮箱", 3)}}
                            >
                                {userInfo.userEmail ? "修改邮箱" : "绑定邮箱"}
                            </div>
                        </div>
                        {/* 用户密码 */}
                        <div className={"account-ct-user-setting-core-ele"}>
                            <div className={"account-ct-user-setting-core-l"}>
                                <img style={{marginRight: '10px'}} src={SETTING_OK} alt={""}/>
                                {"设置密码"}
                            </div>
                            <div className={"account-ct-user-setting-core-m"}>
                                {"已设置"}
                            </div>
                            <div className={"account-ct-user-setting-core-r"}
                                 onClick={() => {this.setModal(showModal, "修改密码", 4)}}
                            >
                                {"修改密码"}
                            </div>
                        </div>
                        {/* 微信绑定 */}
                        <div className={"account-ct-user-setting-core-ele"}>
                            <div className={"account-ct-user-setting-core-l"}>
                                <img style={{marginRight: '10px'}} src={userInfo.wechatId ? SETTING_OK : SETTING_FAIL} alt={""}/>
                                {"绑定微信"}
                            </div>
                            <div className={"account-ct-user-setting-core-m"}>
                                {userInfo.wechatId ? "已绑定" : "未绑定"}
                            </div>
                            <div className={"account-ct-user-setting-core-r"}
                                 onClick={() => {this.updateWechatPre(userInfo.userId, userInfo.wechatId ? 1 : 2)}}
                            >
                                {userInfo.wechatId ? "解除绑定" : "前往绑定"}
                            </div>
                        </div>
                        {/* 支付宝绑定 */}
                        <div className={"account-ct-user-setting-core-ele"}>
                            <div className={"account-ct-user-setting-core-l"}>
                                <img style={{marginRight: '10px'}} src={userInfo.aliId ? SETTING_OK : SETTING_FAIL} alt={""}/>
                                {"绑定支付宝"}
                            </div>
                            <div className={"account-ct-user-setting-core-m"}>
                                {userInfo.aliId ? "已绑定" : "未绑定"}
                            </div>
                            <div className={"account-ct-user-setting-core-r"}
                                 onClick={() => {this.updateAliPre(userInfo.userId, userInfo.aliId ? 1 : 2)}}
                            >
                                {userInfo.aliId ? "解除绑定" : "前往绑定"}
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    /**
     * 微信绑定
     */
    updateWechatPre = (userId, type) => {
        if (type === 1) {
            // 解绑
            this.updateWechat(userId, null, "微信账号已解除绑定");
        } else {
            // 绑定
            console.log("绑定");
            let id = "wrap";
            let winId = "wxBind";
            LunchWin(<WxPlugin index={2}/>, id, winId);
        }
    }

    updateWechat = (userId, wechatId, msg) => {
        let params = {
            userId: userId,
            wechatId: wechatId,
        };

        let _this = this;
        const updateWxBack: WinBack = {
            callBack() {
                _this.refreshUser(userId, msg);
            }
        }

        sendGetBack(UpdateWechatUrl, params, true, genProcessBack(updateWxBack, false));
    }

    /**
     * 微信绑定
     */
    updateAliPre = (userId, type) => {
        if (type === 1) {
            // 解绑
            this.updateAli(userId, null, "支付宝账号已解除绑定");
        } else {
            // 绑定
            handleAliAuth(type);
        }
    }

    updateAli = (userId, aliId, msg) => {
        let params = {
            userId: userId,
            aliId: aliId,
        };

        let _this = this;
        const updateAliBack: WinBack = {
            callBack() {
                _this.refreshUser(userId, msg);
            }
        }

        sendGetBack(UpdateAliUrl, params, true, genProcessBack(updateAliBack, false));
    }

    // 更新用户信息
    refreshUser = (userId, msg) => {
        let params = {
            userId: userId,
        };

        const refreshBack: WinBack = {
            callDataBack(data: any) {
                UserInfoStorage.set("userInfo", data);
                successReturn(msg, accountUrl);
            }
        }

        sendGetBack(RefreshUserByIdUrl, params, true, genProcessBack(refreshBack, true));
    }

    // 用户昵称修改状态
    setUserNickModify = (status) => {
        this.setState({
            userNickModifyStatus: !status,
        })
    }

    // 修改用户昵称
    changeUserNick = (e, userInfoTmp) => {
        let value = e.target.value;
        console.log("用户昵称修改为", value);
        if (InputTest["userNick"].test.test(value)) {
            userInfoTmp.userNick = value;
            this.setState({
                userInfoTmp: userInfoTmp,
            })
        } else {
            fail("昵称由4-10位英文字母、中文、下划线组成")
        }
    }

    // 保存用户昵称修改
    saveUserNickModify = (userInfoTmp) => {
        let params = {
            userId: userInfoTmp.userId,
            nick: userInfoTmp.userNick,
        }
        console.log("用户昵称修改params：", params);
        sendGetRequest(UpdateNickUrl, params, true).then(res => {
            console.log("用户昵称修改res：", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    success("用户昵称修改成功");
                    let userInfoUpdate =  JSON.parse(JSON.stringify(userInfoTmp));
                    UserInfoStorage.remove("userInfo");
                    UserInfoStorage.set("userInfo", userInfoUpdate);
                    this.setState({
                        userInfo: userInfoUpdate,
                    })
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // // 取消用户昵称修改
    // cancelUserNickModify = (userInfo) => {
    //     this.setState({
    //         userInfoTmp: userInfo,
    //         userNickModifyStatus: false,
    //     })
    // }

    // 弹窗开关
    setModal = (showModal, title, type) => {
        this.stopCtScroll(showModal);
        this.setState({
            showModal: !showModal,
            modalTitle: title,
            modalType: type,
        })
    }

    // 父类（组件未卸载）
    setUserInfo = (userInfo, type, newValue) => {
        if (type === 1) {
            userInfo.userPhone = newValue;
        } else if (type === 2) {
            userInfo.userEmail = newValue;
        } else {
            userInfo.userPwd = newValue;
        }
        let userInfoUpdate =  JSON.parse(JSON.stringify(userInfo));
        UserInfoStorage.remove("userInfo");
        UserInfoStorage.set("userInfo", userInfoUpdate);
        this.setState({
            userInfo: userInfoUpdate,
        },
            () => {
                this.setModal(true)
            })
    }

    // 切换弹窗type
    switchModalType = (type) => {
        document.getElementById("ver-code").value = "";
        this.setState({
            modalType: type,
        })
    }

    // 基本信息设置
    loadUserInfo = (userInfo, userInfoTmp, modifyStatus, checkBoxType, areaSelectNum, targetSelectNum) => {
        let areaOptions = this.state.areaOptions;
        let areaOptionsTmp = this.state.areaOptionsTmp;

        let targetOptions = this.state.targetOptions;
        let targetOptionsTmp = this.state.targetOptionsTmp;
        if (userInfo) {
            return (
                <Fragment>
                    <div className={"account-ct-user-setting-title"}>
                        {"基本信息设置"}
                    </div>
                    <div className={"account-ct-user-setting"}>
                        <div className={"account-ct-user-setting-ele"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"所在单位："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"userComp"}
                                   type={"text"}
                                   placeholder={"请填写您的单位名称"}
                                   maxLength={30}
                                   defaultValue={userInfoTmp.userComp}
                                   onChange={(e) => {this.changeUserInfo(e, userInfoTmp, "userComp")}}
                            />
                        </div>
                        <div className={"account-ct-user-setting-ele"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"所属行业："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"userArea"}
                                   type={"text"}
                                   placeholder={"请选择您的行业"}
                                   value={userInfoTmp.userArea || ''}
                                   onClick={() => {this.switchCheckBoxType(1)}}
                                   readOnly={"readonly"}
                            />
                        </div>
                        <div className={"account-ct-checkbox-wrap"}>
                            <div className={"account-ct-checkbox-ct"}>
                                {checkBoxType === 1 ?
                                    this.loadAreaCheckBox(areaOptionsTmp, 1, userInfoTmp, areaSelectNum) : ""
                                }
                            </div>
                        </div>
                        <div className={"account-ct-user-setting-ele"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"担任职务："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"userJob"}
                                   type={"text"}
                                   placeholder={"请填写您的职务"}
                                   maxLength={20}
                                   defaultValue={userInfoTmp.userJob}
                                   onChange={(e) => {this.changeUserInfo(e, userInfoTmp, "userJob")}}
                            />
                        </div>
                        <div className={"account-ct-user-setting-ele"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"感兴趣领域："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"userTarget"}
                                   type={"text"}
                                   placeholder={"请选择您感兴趣的行业领域"}
                                   value={userInfoTmp.userTarget || ''}
                                   onClick={() => {this.switchCheckBoxType(2)}}
                                   readOnly={"readonly"}
                            />
                        </div>
                        <div className={"account-ct-checkbox-wrap"}>
                            <div className={"account-ct-checkbox-ct"}>
                                {checkBoxType === 2 ?
                                    this.loadAreaCheckBox(targetOptionsTmp, 3, userInfoTmp, targetSelectNum) : ""
                                }
                            </div>
                        </div>
                        <div className={"account-ct-button-wrap"}>
                            <button className={modifyStatus ? "center-ct-button save-button" : "center-ct-button save-button-none"}
                                    onClick={() => {
                                        this.saveAccountModify(userInfoTmp, areaSelectNum, targetSelectNum)
                                    }}
                            >
                                {"保存"}
                            </button>
                            <button className={"center-ct-button cancel-button"} style={{marginLeft: '60px'}}
                                    onClick={() => {
                                        this.cancelAccountModify(userInfo, areaOptions, targetOptions)
                                    }}
                            >
                                {"取消"}
                            </button>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    // 保存基本信息修改
    saveAccountModify = (userInfoTmp, areaSelectNum, targetSelectNum) => {
        // console.log("==========保存开始==========");
        console.log("updateCareer数据: ", userInfoTmp)
        // if (areaSelectNum === 0 || targetSelectNum === 0) {
        //     fail("请至少选择一项行业")
        // } else {
            sendGetRequest(UpdateCareerUrl, userInfoTmp, true).then(res => {
                console.log("updateCareer结果: ", res)
                if (res === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    if (res && res.hasOwnProperty("success") && res.success) {
                        UserInfoStorage.remove("userInfo");
                        UserInfoStorage.set("userInfo", userInfoTmp);
                        this.setState({
                            checkBoxType: 0,
                        })
                        success("基本信息修改保存成功");
                    } else if (res[error] === loginOutErrOr) {
                        UserInfoStorage.remove("userInfo");
                        failReturn(findMsgByError(res[error]), LoginRoot)
                    } else {
                        fail(findMsgByError(res[error]));
                    }
                }
            })
        // }
        // console.log("==========保存结束==========");
    }

    // 取消基本信息修改
    cancelAccountModify = (userInfo, areaOptions, targetOptions) => {
        // console.log("==========取消开始==========");
        document.getElementById("userComp").value = userInfo.userComp;
        document.getElementById("userArea").value = userInfo.userArea;
        document.getElementById("userJob").value = userInfo.userJob;
        document.getElementById("userTarget").value = userInfo.userTarget;
        let arrArea = areaOptions.filter(it => it.checked)	// 筛选出 checked 为 false 的个数
        let arrTarget = targetOptions.filter(it => it.checked)	// 筛选出 checked 为 false 的个数
        this.setState({
            userInfoTmp: JSON.parse(JSON.stringify(userInfo)),
            modifyStatus: false,
            // areaOptions: areaOptions,
            areaOptionsTmp: JSON.parse(JSON.stringify(areaOptions)),
            areaSelectNum: arrArea.length,

            // targetOptions: this.state.targetOptionsTmp,
            targetOptionsTmp: JSON.parse(JSON.stringify(targetOptions)),
            checkBoxType: 0,
            targetSelectNum: arrTarget.length,
        },
            () => {
                // console.log("取消后的userInfo: ", this.state.userInfo);
                // console.log("取消后的userInfoTmp: ", this.state.userInfoTmp);
                // console.log("取消后的原本areaOptions： ", this.state.areaOptions);
                // console.log("取消后的拷贝areaOptionsTmp： ", this.state.areaOptionsTmp);
                // console.log("取消后的原本targetOptions： ", this.state.targetOptions);
                // console.log("取消后的拷贝targetOptionsTmp： ", this.state.targetOptionsTmp);
            })
        // console.log("==========取消结束==========");
    }

    // 切换对应的行业领域checkbox
    switchCheckBoxType= (type) => {
        this.setState({
            checkBoxType: type,
        })
    }

    // 加载行业领域checkbox以及对应最大选择值
    loadAreaCheckBox = (checkBoxOptions, maxNum, userInfoTmp, selectNum) => {
        let list = [];
        if (checkBoxOptions.length > 1) {
            checkBoxOptions.forEach((item, index) => {
                list.push(
                    <Checkbox
                        style={{ display: "flex", flexDirection: 'row' }}
                        key={item.value}
                        checked={item.checked}
                        disabled={selectNum < maxNum ? false : !item.disabled}
                        value={item.value}
                        onChange={() => {this.handleCheckBox(index, checkBoxOptions, maxNum, userInfoTmp)}}
                    >
                        {item.label}
                    </Checkbox>
                )
            })
        }
        return list;
    }

    // 处理checkbox选择 checked: 指定当前是否选中, true 选中 false 未选中;disabled: 失效状态, true 失效 false 未失效
    handleCheckBox = (index, checkBoxOptions, maxNum, userInfoTmp) => {
        if (maxNum === 1) {
            checkBoxOptions[index].checked = !checkBoxOptions[index].checked;  // 单击会改变 data 中 checked 的状态
            checkBoxOptions[index].disabled = !checkBoxOptions[index].disabled;  // 单击会改变 data 中 disabled 的状态
            let arr = checkBoxOptions.filter(it => it.checked);	// 筛选出 checked 为 true 的个数
            // console.log("arr: ", arr);
            userInfoTmp.userArea = arr.length > 0 ? arr[0].value : '';
            // console.log("update areaOptions: ", checkBoxOptions);

            // console.log("userInfoTmp: ", userInfoTmp);
            this.setState({
                areaSelectNum: arr.length,
                areaOptionsTmp: checkBoxOptions,
                userInfoTmp: userInfoTmp,
                modifyStatus: true,
            },
                () => {
                    // console.log("原本userInfo： ", this.state.userInfo);
                    // console.log("缓存userInfoTmp： ", this.state.userInfoTmp);
                    // console.log("原本areaOptions： ", this.state.areaOptions);
                    // console.log("缓存areaOptionsTmp： ", this.state.areaOptionsTmp);
                })
        } else {
            let selectList = [];
            checkBoxOptions[index].checked = !checkBoxOptions[index].checked;  // 单击会改变 data 中 checked 的状态
            checkBoxOptions[index].disabled = !checkBoxOptions[index].disabled;  // 单击会改变 data 中 disabled 的状态
            let arr = checkBoxOptions.filter(it => it.checked);	// 筛选出 checked 为 true 的个数
            if (arr) {
                for (let m in arr) {
                    selectList.push(arr[m].value)
                }
            }
            // console.log("arr: ", arr);
            // console.log("selectList: ", selectList);
            // console.log("update targetOptions: ", checkBoxOptions);
            // console.log("userInfoTmp: ", userInfoTmp);
            userInfoTmp.userTarget = selectList.toString().replace('[', '')
                .replace(']', '').replaceAll('\'', '');
            this.setState({
                targetSelectNum: arr.length,
                targetOptionsTmp: checkBoxOptions,
                userInfoTmp: userInfoTmp,
                modifyStatus: true,
            },
                () => {
                    // console.log("原本userInfo： ", this.state.userInfo);
                    // console.log("缓存userInfoTmp： ", this.state.userInfoTmp);
                    // console.log("原本targetOptions： ", this.state.targetOptions);
                    // console.log("缓存targetOptionsTmp： ", this.state.targetOptionsTmp);
                })
        }
    };

    // 基本信息编辑
    changeUserInfo = (e, userInfoTmp, type) => {
        let input = e.target.value;
        switch (type) {
            case "userComp": {
                userInfoTmp.userComp = input;
            break;
            }
            case "userArea": {
                userInfoTmp.userArea = input;
                break;
            }
            case "userJob": {
                userInfoTmp.userJob = input;
                break;
            }
            case "userTarget": {
                userInfoTmp.userTarget = input;
                break;
            }
            default: {
                userInfoTmp.userComp = input;
                break;
            }
        }
        this.setState({
            // userPojo: userPojo,
            modifyStatus: true,
        },
            () => {
                console.log("——————", "更新后的基本信息：",  userInfoTmp, "——————");
            });

    }

    // 禁止滚动条功能
    stopCtScroll = (isScroll) => {
        // 为元素添加事件监听
        let id = document.getElementById('account-ct');
        if (!isScroll) {
            id.style['overflow-y'] = 'hidden';
        } else {
            id.style['overflow-y'] = 'auto';
        }
    }

    componentDidMount() {
        // 缓存获取用户信息
        getUserInfoCache(this, true);
        // 初始化基本信息设置Options
        this.initOptions();
        this.handleRedirectUrl();
    }

    // 初始化基本信息设置Options
    initOptions = () => {
        let userInfoCache = UserInfoStorage.get("userInfo");
        let areaOptions = this.state.areaOptions;
        let targetOptions = this.state.targetOptions;
        if (userInfoCache) {
            // 所属行业Options
            if (areaOptions) {
                let userArea = userInfoCache.userArea;
                for (let i in areaOptions) {
                    if (areaOptions[i].value === userArea) {
                        areaOptions[i].checked = true;
                        areaOptions[i].disabled = true;
                    }
                }
                let tmpArr = JSON.parse(JSON.stringify(areaOptions));
                // console.log("init areaOptions: ", areaOptions);
                let arr = areaOptions.filter(it => it.checked)	// 筛选出 checked 为 false 的个数
                // console.log("check areaOptions num: ", arr.length)
                this.setState({
                    areaSelectNum: arr.length,
                    areaOptions: areaOptions,
                    areaOptionsTmp: tmpArr,
                })
            }
            // 感兴趣领域Options
            if (targetOptions) {
                let userTarget = userInfoCache.userTarget;
                if (userTarget && userTarget.indexOf(',') > -1) {
                    userTarget = userTarget.split(',');
                }
                // console.log("感兴趣领域:", userTarget);
                for (let i in targetOptions) {
                    for (let j in userTarget) {
                        if (targetOptions[i].value === userTarget[j]) {
                            targetOptions[i].checked = true;
                            targetOptions[i].disabled = true;
                        }
                    }
                }
                let tmpArrL = JSON.parse(JSON.stringify(targetOptions));
                // console.log("init targetOptions: ", targetOptions);
                let arrL = targetOptions.filter(it => it.checked)	// 筛选出 checked 为 false 的个数
                // console.log("check targetOptions num: ", arrL.length)
                this.setState({
                    targetSelectNum: arrL.length,
                    targetOptions: targetOptions,
                    targetOptionsTmp: tmpArrL,
                })
            }
        }
    }

    // 处理账号绑定重定向
    handleRedirectUrl = () => {
        let url = window.location.href;
        let params = url.split("?type=account&")[1];
        console.log(params);
        if (params) {
            let paramsList = params.split("&");
            console.log(paramsList);

            let wayPart = paramsList.filter(it => it.includes("way="));
            if (wayPart.length === 0) {
                failReturn("扫码失败", accountUrl);
            } else {
                let way = wayPart[0].split("way=")[1];
                if (way === "wx") {
                    this.handleWxRedirectUrl(paramsList);
                    return;
                }
                if (way === "ali") {
                    this.handleAliRedirectUrl();
                }
            }
        }
    }

    handleWxRedirectUrl = (paramsList) => {
        console.log("wx");
        let authCodePart = paramsList.filter(it => it.includes("code="));
        if (authCodePart.length === 0) {
            failReturn("微信扫码失败", accountUrl);
        } else {
            let authCode = authCodePart[0].split("code=")[1];
            let statePart = paramsList.filter(it => it.includes("state="));
            if (statePart) {
                let stateList = handleUrlState(statePart);
                let code = stateList.stateCode;
                let stateSession = storage.get("wxBindState");
                if (Number.parseInt(code) === Number.parseInt(stateSession)) {
                    let param = {
                        authCode: authCode,
                    };

                    let _this = this;
                    const wxBack: WinBack = {
                        callDataBack(data: any) {
                            let userInfo = UserInfoStorage.get("userInfo");
                            let userId = userInfo.userId;
                            let wechatId = data.wechatId;
                            console.log(userId, wechatId);
                            _this.updateWechat(userId, wechatId, "微信账号已绑定");
                        }
                    }

                    sendGetBack(LoginByWxUrl, param, true, genProcessBack(wxBack, true))
                } else {
                    // 触发CSRF攻击
                    // 不可证明客户端和当前第三方网站的登录认证状态存在关联
                    console.log("WX触发CSRF攻击[state校验不通过]");
                    window.location.href = accountUrl;
                }
            } else {
                // 触发CSRF攻击
                // 不可证明客户端和当前第三方网站的登录认证状态存在关联
                console.log("WX触发CSRF攻击[没有state]");
                window.location.href = accountUrl;
            }
        }
    }

    handleAliRedirectUrl = () => {
        console.log("ali");
        console.log(this.props.location);
        if (this.props.location != null) {
            let aliId = this.props.location.state.aliId;
            if (aliId) {
                let userInfo = UserInfoStorage.get("userInfo");
                this.updateAli(userInfo, aliId, "支付宝账号已绑定");
            }
        } else {

        }
    }

    render() {
        let userInfo = this.state.userInfo;
        let userInfoTmp = this.state.userInfoTmp;

        let userNickModifyStatus = this.state.userNickModifyStatus;

        let showModal = this.state.showModal;
        let modalTitle = this.state.modalTitle;
        let modalType = this.state.modalType;
        let modifyStatus = this.state.modifyStatus;


        let checkBoxType = this.state.checkBoxType;
        let areaSelectNum = this.state.areaSelectNum;
        let targetSelectNum = this.state.targetSelectNum;

        // console.log("基本信息设置：", userInfo);
        // console.log("基本信息设置深拷贝：", userInfoTmp);
        // console.log("areaOptions: ", this.state.areaOptions);
        // console.log("areaOptions copy: ", this.state.areaOptionsTmp);
        // console.log("targetOptions: ", this.state.targetOptions);
        // console.log("targetOptions copy: ", this.state.targetOptionsTmp);

        return (
            <div className={"account-ct"} id={"account-ct"}>
                {showModal && <AccountMgrModal parent={this} userInfo={userInfo} title={modalTitle} type={modalType}/>}
                <div className={"center-ct-header"}>
                    <div className={"center-ct-header-wrap"}>
                        <div className={"center-ct-header-icon"}/>
                        <div className={"center-ct-header-title"}>
                            {"账号管理"}
                        </div>
                        <div className={"center-ct-header-subtitle"}>
                            {"请完善以下信息，方便我们更好地为您服务"}
                        </div>
                    </div>
                </div>
                <div className={"account-ct-ct"}>
                    {this.loadCoreUserInfo(userInfo, userInfoTmp, userNickModifyStatus, showModal)}
                </div>
                <div className={"account-ct-ct"}>
                    {this.loadUserInfo(userInfo, userInfoTmp, modifyStatus, checkBoxType, areaSelectNum, targetSelectNum)}
                </div>
            </div>
        )
    }
}

export default AccountMgr;
