import React, {Component} from "react";
import "./center.css";
import {CloseOutlined} from "@ant-design/icons";
import {delWin} from "../../tool/Launcher";
import {genProcessBack, handlePrice, keySSS, UserInfoStorage} from "../../tool/Const";
import PayOperator from "./PayOperator";
import LunchWin from "../../tool/Tool";
import PayWin from "../../tool/PayWin";
import PayParent from "./PayParent";
import {success, successReturn} from "../../tool/http/ErrorMgr";
import np from "number-precision";

const ptcList = [6, 30, 100, 1000];

class PtcWin extends PayParent {
    constructor(props) {
        super(props);
        this.state = {
            type: 0,
            payStatus: {
                realPrice: 6,
                paiPrice: 0,
                rmbPrice: 6,
                disPrice: 0,
            },
            orderPojo: {
                orderId: '',
                userId: '',
                serviceNum: '',
                realPrice: '',
                paiPrice: '',
                rmbPrice: '',
                disPrice: '',
            },
        }
    }

    loadPtc = (payStatus, type) => {
        let ui = [];
        ptcList.forEach((item, index) => {
            ui.push(
                <div className={"ptc-pay-item-wrap"} key={item + index}>
                    <div className={type === index ? "ptc-pay-item item-selected" : "ptc-pay-item"}
                         onClick={() => {this.switchPtcType(index, item, payStatus)}}
                    >
                        <div className={"ptc-pay-item-num"}>
                            <div className={"ptc-pay-item-num-num"}>
                                {handlePrice(item * 100)}
                            </div>
                            <div className={"ptc-pay-item-num-txt"}>
                                {"湃通币"}
                            </div>
                        </div>
                        <div className={"ptc-pay-item-price"}>
                            {"¥ " + item + ".00"}
                        </div>
                    </div>
                </div>
            )
        })
        ui.push(
            <div className={"ptc-diy-item-wrap"} key={"diy"}>
                <div className={type === 10 ? "ptc-diy-item-selected" : "ptc-diy-item"}
                     onClick={() => {this.switchPtcType(10, 0, payStatus)}}
                >
                    {type === 10 ?
                        <div  className={"ptc-diy-ct"}>
                            <div className={"ptc-diy-input-wrap"}>
                                <input className={"ptc-diy-input"}
                                       key={"diy"}
                                       maxLength={8}
                                       onChange={(e) => {
                                           this.handleInputPrice(e, payStatus)
                                       }}
                                />
                                <div className={"ptc-diy-item-txt"} style={{marginTop: '5px'}}>
                                    {"请输入充值金额"}
                                </div>
                            </div>
                            <div className={"ptc-diy-input-price"}>
                                <div className={"ptc-diy-input-price-num"}>
                                    {handlePrice(np.times(payStatus.realPrice, 100))}
                                </div>
                                <div className={"ptc-diy-input-price-txt"}>
                                    {"湃通币"}
                                </div>
                            </div>
                        </div>
                        :
                        <div className={"ptc-diy-item-txt"}>
                            {"自定义充值数量（¥1 - ¥10,000）"}
                        </div>
                    }
                </div>
            </div>
        );
        return ui;
    }

    switchPtcType = (index, price, payStatus) => {
        payStatus.realPrice = Number.parseInt(price);
        payStatus.rmbPrice = Number.parseInt(price);
        this.setState({
            type: index,
            payStatus: payStatus,
        })
    }

    handleInputPrice = (e, payStatus) => {
        let val = e.target.value ? e.target.value : 0;
        console.log(val)
        payStatus.realPrice = Number.parseFloat(val);
        payStatus.rmbPrice = Number.parseFloat(val);
        this.setState({
            payStatus: payStatus,
        })
    }

    // 调用支付方式对应接口
    handlePay = () => {
        let orderPojo = this.state.orderPojo;

        let pojo = {
            orderPojo: orderPojo,
        }

        delWin("wrap", "ptc");
        let id = "wrap";
        let winId = "pay";
        LunchWin(<PayWin parentId={id} winId={winId} requestPojo={pojo} setPojo={this.setRequestPojo} fun={this.addPtcFun}/>, id, winId);
    }

    setRequestPojo = (requestPojo, id) => {
        requestPojo.orderPojo.orderId = id;
        this.setState({
            requestPojo: requestPojo,
        })
    }

    addPtcFun = (pojo, backData) => {
        let operator = new PayOperator();

        pojo.orderPojo.status = backData;

        let _this = this;
        const myBack: WinBack = {
            callDataBack(data: any) {
                console.log("充值湃通币res: ", data);
                let userInfo = UserInfoStorage.get("userInfo");
                _this.refreshUser(userInfo);
                // successReturn("湃通币充值成功", window.location.href);
                success("湃通币充值成功")
                // _this.setState({
                //     isFinish: true,
                //     order: data,
                // })
            }
        }

        operator.addPtc(pojo, genProcessBack(myBack, true));
    }

    render() {
        let parentId = this.props.parentId;
        let winId = this.props.winId;
        let userInfo = this.props.userInfo;

        let payStatus = this.state.payStatus;
        let type = this.state.type;

        console.log("payStatus: ", payStatus);

        return (
            <div className={"ptc-plugin"}>
                <div className={"ptc-plugin-header"}>
                    <span>{"请选择充值金额"}</span>
                </div>
                <div className={"ptc-plugin-ct-wrap"}>
                    <div className={"ptc-plugin-ct"}>
                        {this.loadPtc(payStatus, type)}
                    </div>
                </div>
                <div className={"pay-plugin-pay-wrap"}>
                    <div className={"pay-plugin-qr-num"}>
                        {"¥ " + handlePrice(payStatus.rmbPrice + ".00")}
                    </div>
                    <button className={"pay-plugin-pay-btn"}
                            onClick={() =>{
                                this.handlePayPre(4, 1, "充值")
                            }}
                    >
                        {"点击支付"}
                    </button>
                </div>
                <div className={"modal-close-wrap"}>
                    <CloseOutlined onClick={() => {delWin(parentId, winId)}}/>
                </div>
            </div>
        );
    }
}

export default PtcWin;