import React, {Component} from "react";
import "./expert.css";
import "../page.css";
import {
    advList,
    dataIndexE,
    getAdvData,
    getPageDataByIndex,
    getSubtraction,
    handleAdRightWinSizeChange, handleAdRightWinSizeInit, handleCopWay,
    handleExpertTitle,
    handlePageScrollMove,
    handleSrcType,
    LoadingPolicyCopAds,
    LoadingSolutionCopAds,
    LoginRoot,
    PolicyRoot,
    scrollTop,
    SolutionRoot,
    solutionTechPerList, toThousandsReg,
    UserInfoStorage,
    utcTimeFormat
} from "../../tool/Const";
import Header from "../Header";
import AdRight from "../../tool/AdRight";
import Contact from "../home/Contact";
import Footer from "../Footer";
import {Breadcrumb} from "antd";
import moment from "moment";
import {CalendarFilled, CloseOutlined, LoadingOutlined} from "@ant-design/icons";
import {
    CheckExpertAppointUrl,
    data,
    error,
    FindExpertScheduleInfoUrl,
    loginOutErrOr,
    sendGetRequest,
    SrcHeader
} from "../../tool/http/Request";
import {connectionError, fail, failReturn, findMsgByError} from "../../tool/http/ErrorMgr";
import LOCK_PART from "../../assets/img/lock-part.png";
import PayPanel from "../../tool/payPanel/PayPanel";
import Calendar from "../center/Calendar";
import AVATAR_BG from "../../assets/img/expert_avator_bg.png";
import ScriptParent from "../ScriptParent";
import ErrorPage from "../Error/ErrorPage";

const adClasses = {
    adOuter: "expert-ct-ad",
    adOne: "expert-ct-ad-one",
    adTwo: "expert-ct-ad-two",
}

class ExpertScript extends ScriptParent {
    constructor(props) {
        super(props);
        this.state = {
            pageData: '',  // 该页面数据
            isPageDataLoad: false,  // 该页面数据是否加载完

            fixed: true,  // 右侧广告位参数
            fixedAd: false,
            adLeft: "",
            adBt: false,
            showAd: true,
            gap: 0,
            cH: 0,
            adjustGap: 0,
            openSize: 0,  // 右侧广告位参数

            modalStatus: false,  // 弹窗开关
            calModalStatus: false,  // 预约表的弹窗层开关

            curDate: Object.assign(utcTimeFormat), // 当前日期和时间
            dateUpperLimit: '',  // 当前日期上限
            dateLowerLimit: '',  // 当前日期下限

            expertSchMap: new Map(),  // 专家开放预约日程的源数据
            isLoadExpSchMap: false,
            userSchMap: new Map(),  // 用户预约该专家的日程
            isLoadUserSchMap: false,
            schTimeMap: new Map(),  // 专家开放预约日程转换成对应开放日期的预约时间段
            openTimeSchMap: new Map(),  // 最终处理并检验过合理开放预约时间段

            advMap: new Map(),  // adv数据

            isMatch: false,
        }
    }

    // 加载Header
    loadHeader = (userInfo, data, modalStatus) => {
        if (data) {
            // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
            // let picDir = require.context("../pic/", true, /\.(png|jpg)$/);
            // let imgUrl = picDir("./" + data["expertBanner"]);
            let imgUrl = SrcHeader + data["expertId"] + "/" + data["expertBanner"];
            return (
                <div className={"script-header expert-header"}>
                    <div className={"script-header-ct"}>
                        <div className={"script-header-l-expert"}>
                            <div className={"script-expert-name"}>
                                {data["expertName"]}
                            </div>
                            <div className={"script-tag"}>
                                <span style={{color: '#d6d6d6'}}>
                                    {"荣誉称号："}
                                </span>
                                <span>
                                    {data["expertHor"] ? data["expertHor"].replaceAll(";", " / ") : "暂无"}
                                </span>
                            </div>
                            <div className={"script-tag"}>
                                <span style={{color: '#d6d6d6'}}>
                                    {"职称："}
                                </span>
                                <span>
                                    {handleExpertTitle(data["expertTitle"])}
                                </span>
                            </div>
                            <div className={"script-tag"}>
                                <span style={{color: '#d6d6d6'}}>
                                    {"担任职务："}
                                </span>
                                <span>
                                    {data["expertJob"].replaceAll(";", " / ")}
                                </span>
                            </div>
                            <div className={"contact-bt"}>
                                {/*<div className={"contact-bt-icon"}>*/}
                                {/*   */}
                                {/*    /!*<img className={"img-style contain"} src={CAL} alt={""}/>*!/*/}
                                {/*</div>*/}
                                <CalendarFilled className={"calendar-style"}/>
                                <div className={"contact-bt-text"}
                                     onClick={() => {
                                         this.setModalStatus(modalStatus, userInfo)
                                     }}
                                >
                                    {"点击立即预约"}
                                </div>
                            </div>
                        </div>
                        <div className={"script-header-avatar"}>
                            <div className={"expert-avatar"}>
                                <img className={"img-style cover"} src={imgUrl} alt={""}/>
                            </div>
                            <img className={"expert-avatar-bg"} src={AVATAR_BG} alt={""}/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 加载内容
    loadMain = (userInfo, data, modalStatus) => {
        if (data) {
            return (
                <div className={"script-main"}>
                    <div className={"script-main-header"}>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item style={{marginRight: '5px'}}>
                                {"您所在的位置: "}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a href="/">{"首页"}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item>
                                <a href="/expert">{"专家人才"}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item style={{color: '#0077ff'}}>
                                <strong>{"正文"}</strong>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className={"script-main-ct"}>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"专家基本情况"}
                            </div>
                            <div className={"script-item-info expert-info"}>
                                {this.loadInfoLayout("研究领域：", data["expertAreas"])}
                                {this.loadInfoLayout("专家来源：", handleSrcType(data["srcType"]))}
                                {this.loadInfoLayout("地理位置：", data["expertGeo"])}
                                {this.loadInfoLayout("所属机构：", data["expertRoot"])}
                                {this.loadInfoLayout("毕业院校：", data["expertSch"])}
                                {this.loadInfoLayout("研究方向：", data["expertTarget"])}
                                {this.loadInfoLayout("预约价格：", data["expertPrice"] + "元/次")}
                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"专家简介"}
                            </div>
                            <div className={"script-item-content"}>
                                {data["expertDes"]}
                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"专家详细介绍"}
                            </div>
                            <div className={"script-item-content"}>
                                {data["expertCt"]}
                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"专家开放预约时间段"}
                            </div>
                            {
                                userInfo ?
                                    <div className={"contact-plugin"}
                                         onClick={() => {
                                             this.setModalStatus(modalStatus, userInfo)
                                         }}
                                    >
                                        {/*<div className={"contact-bt-icon"}>*/}
                                        {/*    <img className={"img-style contain"} src={CAL} alt={""}/>*/}
                                        {/*</div>*/}
                                        <CalendarFilled className={"calendar-style"}/>
                                        <div className={"contact-bt-text"}>
                                            {"点击立即预约"}
                                        </div>
                                    </div>
                                    :
                                    <div className={"lock-part"}>
                                        <img className={"img-style cover"} src={LOCK_PART} alt={""}/>
                                        <PayPanel parent={this} type={1} classWrap={"login-panel"} title={"登陆后即可解锁专家开放预约时间段"}/>
                                    </div>
                            }
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"专家人才预约服务流程"}
                            </div>
                            <div className={"service-process"}>

                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"安全保障"}
                            </div>
                            <div className={"safety-guarantee"}>

                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 弹窗开关
    setModalStatus = (modalStatus, userInfo) => {
        if (userInfo) {
            // let dateLowerLimit = modalStatus ?  this.state.dateLowerLimit : utcTimeFormat;
            console.log("utcTimeFormat: ", utcTimeFormat);
            this.setState({
                modalStatus: !modalStatus,
                curDate: moment().utcOffset(8),
            })
        } else {
            fail("预约专家日程，请先登录账号")
        }
    }

    // 加载预约时间弹窗（日历）
    loadCal = (userInfo, data, curDate, dateUpperLimit, dateLowerLimit, expertSchMap, openTimeSchMap, modalStatus) => {
        let cW = document.getElementById('pageHeader').clientWidth;
        let cHp = document.getElementById('pageHeader').clientHeight;
        let cH = cHp + 150 + 322;
        if (modalStatus === true) {
            return (
                <div className={"modal-bg"} style={{width: cW, height: cH}}>
                    <div className={"expert-calendar-modal-ct"}>
                        <div className={"modal-header-wrap"}>
                            <div className={"modal-header-title"}>
                                {data["expertName"] + "（" + handleExpertTitle(data["expertTitle"]) + "）的开放预约日程表"}
                            </div>
                            <div className={"modal-header-txt"}>
                                {"注意：预约最早只能提前三天预约"}
                            </div>
                        </div>
                        <div className={"modal-cal-wrap"} id={"schedule-cal"}>
                            <div className={"modal-cal-ct"}
                                 // style={{transform: 'scale(0.95)'}}
                            >
                                <Calendar parent={this}
                                          userInfo={userInfo} expertData={data}
                                          type={"schCal"}
                                          curDate={curDate} dateUpperLimit={dateUpperLimit} dateLowerLimit={dateLowerLimit}
                                          expertSchMap={expertSchMap} openTimeSchMap={openTimeSchMap}
                                />
                            </div>
                        </div>
                        <div className={"modal-close-wrap"}>
                            <CloseOutlined onClick={() => {this.setModalStatus(modalStatus, userInfo)}}/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 禁止滚动条功能
    stopScrollM = (isScroll) => {
        // 为元素添加事件监听
        let id = document.getElementById('schedule-cal');
        if (!isScroll) {
            id.style['overflow'] = 'hidden';
        } else {
            id.style['overflow-y'] = 'auto';
        }
    }

    componentDidMount() {
        getPageDataByIndex(this).then(res =>{
            if (res) {
                this.handlePromise();
                getAdvData(advList.expert, this);
                scrollTop();  // 置顶
                handleAdRightWinSizeInit(this);
                window.addEventListener("resize", this.handleResize)
                window.onscroll = () => {
                    let cH = document.getElementById("script-outer").clientHeight;
                    let cHC = this.state.cH;
                    handlePageScrollMove(this, "", 1760, 510 + cH,
                        510 + cH,
                        "script-outer", 940)
                    if (cHC !== cH) {
                        this.setState({
                            cH: cH,
                        })
                    }
                };
            }
        });

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        handleAdRightWinSizeChange("script-outer", this);
    }

    // 获取专家开放预约日程
    // 命名为expertSchMap,结构为map
    getExpertSchMap = () => {
        // 获取专家日程设置
        let userInfoCache = UserInfoStorage.get("userInfo");
        let pageData = this.state.pageData;
        // console.log("userInfoCache: ", userInfoCache);
        // console.log("pageData: ", pageData);
        if (userInfoCache && pageData) {
            let params = {
                userId: userInfoCache.userId,
                expertId: pageData.refId,
            };
            sendGetRequest(FindExpertScheduleInfoUrl, params, true).then(res => {
                console.log("获取专家日程设置res: ", res);
                if (res === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    if (res && res.hasOwnProperty("success") && res.success) {
                        let map = new Map();
                        let expertSchMap = res[data].scheduleMap;
                        for (let i = 1; i <= 7; i++) {
                            // console.log(i, scheduleMap[i]);
                            map.set(i, expertSchMap[i] ? expertSchMap[i] : []);
                        }
                        this.setState({
                            expertSchMap: map,
                            isLoadExpSchMap: true,
                        });
                    } else if (res[error] === loginOutErrOr) {
                        UserInfoStorage.remove("userInfo");
                        failReturn(findMsgByError(res[error]), LoginRoot)
                    } else {
                        fail(findMsgByError(res[error]));
                    }
                }
            })
        }
    }

    // 获取专家的开放预约时间段
    getCalOpenTime = () => {
        let userInfoCache = UserInfoStorage.get("userInfo");
        let pageData = this.state.pageData;
        if (userInfoCache && pageData) {
            let curDate = this.state.curDate;
            let startDate = moment(curDate).add(4, 'days');
            let endDate = moment(curDate).add(17, 'days');
            // console.log("curDate: ", curDate.format("YYYY-MM-DD"));
            // console.log("startDate: ", startDate.format("YYYY-MM-DD"));
            // console.log("endDate: ", endDate.format("YYYY-MM-DD"));
            this.setState({
                    dateLowerLimit: startDate.format('YYYY-MM-DD'),  // 当前日期下限（开始）
                    dateUpperLimit: endDate.format('YYYY-MM-DD'),  // 当前日期上限（结束）
                },
                () => {
                    this.getUserSchMap(userInfoCache, pageData);
                })
        }

    }

    // 获取专家开放预约时间段的已被预约的日程
    getUserSchMap = (userInfoCache, pageData) => {
        let dateLowerLimit = this.state.dateLowerLimit;
        let dateUpperLimit = this.state.dateUpperLimit;
        let params = {
            userId: userInfoCache.userId,
            expertId: pageData.refId,
            start: dateLowerLimit,
            end: dateUpperLimit,
            needDes: false,  // 专家端需要用户详情参数
        }
        // console.log("预约日程params: ", params);
        sendGetRequest(CheckExpertAppointUrl, params, true).then(res => {
            // console.log("获取专家预约日程res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.handleAppointTime(res[data]);
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 处理已被预约的日程
    // 命名为userSchMap,结构为map
    handleAppointTime = (res) => {
        let map = new Map();
        res.forEach(item => {
            let date = item["appointDate"];
            let time = item["appointTime"];
            let timeList = time.split(";");
            let userSet = map.has(date) ? map.get(date) : new Set();
            timeList.forEach(time => {
                userSet.add(parseInt(time));
            })
            map.set(date, userSet);
        })
        let map2 = new Map();
        map.forEach((value, key) => {
            map2.set(key, Array.from(value).sort(function (a, b) {
                return a - b;
            }));
        })
        // console.log(map2);
        this.setState({
            userSchMap: map2,
            isLoadUserSchMap: true,
        });
    }

    handlePromise = () => {
        this.getExpertSchMap();
        this.getCalOpenTime();
        setTimeout(() => {
            let isLoadExpSchMap = this.state.isLoadExpSchMap;
            let isLoadUserSchMap = this.state.isLoadUserSchMap;
            let dateUpperLimit = this.state.dateUpperLimit;  // 当前日期上限
            let dateLowerLimit = this.state.dateLowerLimit;  // 当前日期下限
            if (isLoadExpSchMap && isLoadUserSchMap && dateUpperLimit && dateLowerLimit) {
                this.handleSchTimeMap(dateUpperLimit, dateLowerLimit);
            }
        }, 500)

    }

    // 处理可选择时间段内的日程
    handleSchTimeMap = (dateUpperLimit, dateLowerLimit) => {
        // console.log("结束时间：：", dateUpperLimit);
        // console.log("开始时间：：", dateLowerLimit);
        let expertSchMap = this.state.expertSchMap;
        let map = new Map();
        for (let i = 0; i < 14; i++) {
            let everyDay = moment(dateLowerLimit).add(i, 'days').format("YYYY-MM-DD");
            let weekOfEveryDay = moment(everyDay).format('E');
            // console.log(everyDay, weekOfEveryDay);
            let openTimeList = expertSchMap.get(parseInt(weekOfEveryDay));
            map.set(everyDay, openTimeList);
            // console.log(map);
            this.setState({
                schTimeMap: map,
            },
                () => {
                    this.handleOpenTimeSchMap();
                })
        }
    }

    // 处理专家开放预约日程与已被预约日程 取差集
    handleOpenTimeSchMap = () => {
        let schTimeMap = this.state.schTimeMap;
        let userSchMap = this.state.userSchMap;
        // console.log("schTimeMap：", schTimeMap);
        // console.log("userSchMap：", userSchMap);

        let map = new Map();
        schTimeMap.forEach((value, key) => {
            // let weekOfDay = moment(key).format('E');
            let list = userSchMap.get(key);
            // console.log(value);
            // console.log(list);
            let openTimeSch = getSubtraction(value, list);
            // console.log(openTimeSch);
            let finalSch = this.checkSchValid(openTimeSch);
            map.set(key, finalSch);
            this.setState({
                    openTimeSchMap: map,
            },
                () => {
                    // console.log("openTimeSchMap: ", map);
                })
        })
    }

    // 校验预约时间段合理性
    checkSchValid = (list) => {
        let validList = [];
        let len = list.length;
        for (let i = 0; i < len; i++) {
            let first = list[i];
            let second = first + 1;
            let third = first + 2;
            if (list.indexOf(second) !== -1 && list.indexOf(third) !== -1) {
                validList.push(first);
            }
        };
        // console.log("validList: ", validList);
        return validList;
    }

    render() {
        let userInfo = UserInfoStorage.get("userInfo");
        // console.log("userInfo：", userInfo);

        let pageData = this.state.pageData;
        let isPageDataLoad = this.state.isPageDataLoad;
        // console.log("data：", pageData);

        let adLeft = this.state.adLeft;
        let right = "calc((100% - " + adLeft + " ) / 2)";
        let totalGap = this.state.gap - this.state.adjustGap;
        let gapR = totalGap === 0 ? "0" : parseInt(totalGap + "") + "px";
        let wH = parseInt((this.state.cH - 30) + "") + "px";

        let modalStatus = this.state.modalStatus;

        let expertSchMap = this.state.expertSchMap;
        let userSchMap = this.state.userSchMap;
        let openTimeSchMap = this.state.openTimeSchMap;
        // console.log("expertSchMap：", expertSchMap);
        // console.log("userSchMap：", userSchMap);

        let curDate = this.state.curDate;
        let dateUpperLimit = this.state.dateUpperLimit;  // 当前日期上限
        let dateLowerLimit = this.state.dateLowerLimit;  // 当前日期下限

        let advMap = this.state.advMap;
        console.log("advMap: ", advMap);

        let isMatch = this.state.isMatch;

        return (
            isMatch ?
                <div className={"home-ct"}>
                    <Header/>
                    {modalStatus && this.loadCal(userInfo, pageData, curDate, dateUpperLimit, dateLowerLimit, expertSchMap, openTimeSchMap, modalStatus)}
                    <div className={"script-ct"} id={"pageHeader"}>
                        {this.loadHeader(userInfo, pageData, modalStatus)}
                        <div className={"script-page-content"} id={"script-outer"}>
                            <div className={"script-ct-data"}>
                                {
                                    isPageDataLoad ?
                                        this.loadMain(userInfo, pageData, modalStatus)
                                        :
                                        <div className={"load-plugin"}>
                                            <LoadingOutlined className={"load-icon"}/>
                                            <div className={"load-txt"}>
                                                {"页面加载中，请稍候..."}
                                            </div>
                                        </div>
                                }
                            </div>
                            {
                                advMap.size === advList.expert.length ?
                                    <AdRight fixedAd={this.state.fixedAd} adBt={this.state.adBt} gapR={gapR} right={right}
                                             wholeHgt={wH} adClasses={adClasses}
                                             adTitleOne={"科技人才创新"} adOneCt={LoadingSolutionCopAds(solutionTechPerList)} adOneUrl={SolutionRoot}
                                             adTitleTwo={"政策精要"} adTwoCt={LoadingPolicyCopAds(advMap.get(dataIndexE.policy))} adTwoUrl={PolicyRoot.path}
                                    />
                                    : null
                            }
                        </div>
                    </div>
                    <Contact fixed={this.state.fixed}/>
                    <div>
                        <Footer/>
                    </div>
                </div>
                : <ErrorPage/>
        )
    }
}

export default ExpertScript;