import React, {Component} from "react";
import "./center.css";
import {Checkbox, Switch} from "antd";
import {
    AreaMap,
    filterArea,
    filterAreaTech,
    filterCopWayBySub,
    filterSrcTypeBySub,
    filterStage,
    filterTitle,
    filterTypeBySub,
    handleCopWay,
    handleExpertTitle,
    handleOutcomeType,
    handleSrcType,
    LoginRoot,
    MouseHover,
    PolicyMap,
    UserInfoStorage
} from "../../tool/Const";
import {connectionError, fail, failReturn, findMsgByError, success} from "../../tool/http/ErrorMgr";
import {CloseOutlined, DeleteOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {
    data,
    DeleteSubscriptUrl,
    error,
    genPojo,
    GetSubscriptSummaryUrl,
    loginOutErrOr,
    ProcessSubscriptUrl,
    sendGetRequest,
    sendPostRequest,
    SwitchSubscriptUrl
} from "../../tool/http/Request";
import SubscriptionGeo from "./SubscriptionGeo";

const CheckboxGroup = Checkbox.Group;

// 科技成果 sub filter
const subOutcome = [
    {
        title: "地理位置",
        ct: [],
        geo: '',
        max: 1,
        ip: 'geo_0',
    },
    {
        title: "地理位置",
        ct: [],
        geo: '',
        max: 1,
        ip: 'geo_1',
    },
    {
        title: "类型",
        ct: filterTypeBySub,
        value: [],
        max: 1,
        ip: 'type',
    },
    {
        title: "技术来源",
        ct: filterSrcTypeBySub,
        value: [],
        max: 2,
        ip: 'from',
    },
    {
        title: "成熟度",
        ct: filterStage,
        value: [],
        max: 2,
        ip: 'level',
    },
    {
        title: "行业分类",
        ct: filterAreaTech,
        value: [],
        max: 2,
        ip: 'area',
    },
    {
        title: "合作方式",
        ct: filterCopWayBySub,
        value: [],
        max: 2,
        ip: 'method',
    },
]

// 技术需求 sub filter
const subDemand = [
    {
        title: "地理位置",
        ct: [],
        geo: '',
        max: 1,
        ip: 'geo_0',
    },
    {
        title: "地理位置",
        ct: [],
        geo: '',
        max: 1,
        ip: 'geo_1',
    },
    {
        title: "需求类型",
        ct: filterTypeBySub,
        value: [],
        max: 1,
        ip: 'type',
    },
    {
        title: "需求来源",
        ct: filterSrcTypeBySub,
        value: [],
        max: 2,
        ip: 'from',
    },
    {
        title: "行业分类",
        ct: filterAreaTech,
        value: [],
        max: 2,
        ip: 'area',
    },
    {
        title: "合作方式",
        ct: filterCopWayBySub,
        value: [],
        max: 2,
        ip: 'method',
    },
]

// 专家人才 sub filter
const subExpert = [
    {
        title: "地理位置",
        ct: [],
        geo: '',
        max: 1,
        ip: 'geo_0',
    },
    {
        title: "地理位置",
        ct: [],
        geo: '',
        max: 1,
        ip: 'geo_1',
    },
    {
        title: "研究领域",
        ct: filterArea,
        value: [],
        max: 2,
        ip: 'area',
    },
    {
        title: "专家来源",
        ct: filterSrcTypeBySub,
        value: [],
        max: 2,
        ip: 'from',
    },
    {
        title: "专家职称",
        ct: filterTitle,
        value: [],
        max: 2,
        ip: 'title',
    },
]

// 科技政策 sub filter
const subPolicy = [
    {
        title: "政策范围",
        ct: [],
        geo: '',
        max: 1,
        ip: 'geo_0',
    },
    {
        title: "政策范围",
        ct: [],
        geo: '',
        max: 1,
        ip: 'geo_1',
    },
]

const settingCt = {
    0: {title: "科技成果订阅", name: "outcomeScript", subFilter: subOutcome},
    1: {title: "技术需求订阅", name: "demandScript", subFilter: subDemand},
    2: {title: "专家人才订阅", name: "expertScript", subFilter: subExpert},
    3: {title: "科技政策订阅", name: "policyScript", subFilter: subPolicy},
}

class SubscriptionMgr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subOpen: false,  // 订阅开关
            subCt: [],  // 订阅参数
            modalStatus: false,  // 弹窗开关
            editItem: '',  // 编辑对象
            hoverI: -1,

            options: {
                outcomeScript: JSON.parse(JSON.stringify(subOutcome)),
                demandScript: JSON.parse(JSON.stringify(subDemand)),
                expertScript: JSON.parse(JSON.stringify(subExpert)),
                policyScript: JSON.parse(JSON.stringify(subExpert)),
            },
            optionsTmp: '',
        }
    }

    componentDidMount() {
        this.initData();
    }

    // 初始化
    initData = () => {
        let userInfo = UserInfoStorage.get("userInfo");
        let params = {
            userId: userInfo.userId,
        };
        sendGetRequest(GetSubscriptSummaryUrl, params, true).then(res => {
            console.log("获取订阅信息res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.handleSubCt(res[data]);
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 处理获取到的订阅信息
    handleSubCt = (subCt) => {
        let options = this.state.options;
        let subOutcomeTmp = JSON.parse(JSON.stringify(subOutcome));
        let subDemandTmp = JSON.parse(JSON.stringify(subDemand));
        let subExpertTmp = JSON.parse(JSON.stringify(subExpert));
        let subPolicyTmp = JSON.parse(JSON.stringify(subPolicy));

        let hasOutcome = subCt ? subCt.outcomeScript !== null : false;
        let hasDemand = subCt ? subCt.demandScript !== null : false;
        let hasExpert = subCt ? subCt.expertScript !== null : false;
        let hasPolicy = subCt ? subCt.policyScript !== null : false;

        subOutcomeTmp.map(item => {
            return (
                [
                    hasOutcome ? subCt["outcomeScript"]["geos"].map((geo, index) => {
                        return item.ip === "geo_" + index ? item.geo = geo : null;
                    }) : null,
                    item.ip === "type" ?
                        [item.value = (hasOutcome ? [handleOutcomeType(subCt["outcomeScript"]["type"])] : ""), this.handleInitCheckbox(item, item.value)] : null,
                    item.ip === "from" ?
                        [item.value = (hasOutcome ? this.handleRawData("srcList", subCt["outcomeScript"]["srcList"]) : []), this.handleInitCheckbox(item, item.value)] : null,
                    item.ip === "level" ?
                        [item.value = (hasOutcome ? subCt["outcomeScript"]["stages"] : []), this.handleInitCheckbox(item, item.value)] : null,
                    item.ip === "area" ?
                        [item.value = (hasOutcome ? subCt["outcomeScript"]["areas"] : []), this.handleInitCheckbox(item, item.value)] : null,
                    item.ip === "method" ?
                        [item.value = (hasOutcome ? this.handleRawData("cops", subCt["outcomeScript"]["cops"]) : []), this.handleInitCheckbox(item, item.value)] : null,
                ]
            );
        })
        let subOutcomeFinal = subOutcomeTmp.filter(item => item.geo !== '');

        subDemandTmp.map(item => {
            return (
                [
                    hasDemand ? subCt["demandScript"]["geos"].map((geo, index) => {
                        return item.ip === "geo_" + index ? item.geo = geo : null;
                    }) : null,
                    item.ip === "type" ?
                        [item.value = (hasDemand ? [handleOutcomeType(subCt["demandScript"]["type"])] : ""), this.handleInitCheckbox(item, item.value)] : null,
                    item.ip === "from" ?
                        [item.value = (hasDemand ? this.handleRawData("srcList", subCt["demandScript"]["srcList"]) : []), this.handleInitCheckbox(item, item.value)] : null,
                    item.ip === "area" ?
                        [item.value = (hasDemand ? subCt["demandScript"]["areas"] : []), this.handleInitCheckbox(item, item.value)] : null,
                    item.ip === "method" ?
                        [item.value = (hasDemand ? this.handleRawData("cops", subCt["demandScript"]["cops"]) : []), this.handleInitCheckbox(item, item.value)] : null,
                ]
            );
        })
        let subDemandFinal = subDemandTmp.filter(item => item.geo !== '');
        subExpertTmp.map(item => {
            return (
                [
                    hasExpert ? subCt["expertScript"]["geos"].map((geo, index) => {
                        return item.ip === "geo_" + index ? item.geo = geo : null;
                    }) : null,
                    item.ip === "from" ?
                        [item.value = (hasExpert ? this.handleRawData("srcList", subCt["expertScript"]["srcList"]) : []), this.handleInitCheckbox(item, item.value)] : null,
                    item.ip === "area" ?
                        [item.value = (hasExpert ? subCt["expertScript"]["areas"] : []), this.handleInitCheckbox(item, item.value)] : null,
                    item.ip === "title" ?
                        [item.value = (hasExpert ? this.handleRawData("titles", subCt["expertScript"]["titles"]) : []), this.handleInitCheckbox(item, item.value)] : null,
                ]
            );
        })
        let subExpertFinal = subExpertTmp.filter(item => item.geo !== '');
        subPolicyTmp.map(item => {
            return (
                hasPolicy ? subCt["policyScript"]["geos"].map((geo, index) => {
                    return item.ip === "geo_" + index ? item.geo = geo : null;
                }) : null
            )
        })
        let subPolicyFinal = subPolicyTmp.filter(item => item.geo !== '');
        options["outcomeScript"] = subOutcomeFinal;
        options["demandScript"] = subDemandFinal;
        options["expertScript"] = subExpertFinal;
        options["policyScript"] = subPolicyFinal;
        this.setState({
            options: options,
            optionsTmp: JSON.parse(JSON.stringify(options)),
            subCt: subCt,
            subOpen: subCt ? subCt["needSend"] : false,
        })
    }

    handleInitCheckbox = (item, value) => {
        let maxNum = item.max;
        if (maxNum === 1) {
            item.ct.filter(it => it.value === value[0] ? it.checked = true : it.disabled = true);
        } else {
            item.ct.filter(it => it.value === value[0] || it.value === value[1] ? it.checked = true : it.disabled = true);
        }
        if (value.length < maxNum) {
            item.ct.filter(it => it.disabled = false);
        }
        return item;
    }

    handleRawData = (type, data) => {
        let list = [];
        switch (type) {
            case "srcList": {
                data.map(item => {
                    list.push(handleSrcType(item));
                })
                return list;
            }
            case "cops": {
                data.map(item => {
                    list.push(handleCopWay(item));
                })
                return list;
            }
            case "titles": {
                data.map(item => {
                    list.push(handleExpertTitle(item));
                })
                return list;
            }
        }

    }

    // 修改订阅开关
    switchSub = (subCt, subOpen) => {
        if (subCt) {
            let value = subOpen ? 0 : 1;
            let userInfo = UserInfoStorage.get("userInfo");
            let params = {
                userId: userInfo.userId,
                value: value,
            };
            sendGetRequest(SwitchSubscriptUrl, params, true).then(res => {
                console.log("切换订阅开关res: ", res);
                if (res === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    if (res && res.hasOwnProperty("success") && res.success) {
                        let subOpenCur = !subOpen;
                        this.setState({
                            subOpen: subOpenCur,
                        }, () => success(subOpenCur ? "订阅已开启" : "订阅已关闭"))
                    } else if (res[error] === loginOutErrOr) {
                        UserInfoStorage.remove("userInfo");
                        failReturn(findMsgByError(res[error]), LoginRoot)
                    } else {
                        fail(findMsgByError(res[error]));
                    }
                }
            })
        } else {
            fail("至少设置一种订阅")
        }
    }

    // 加载弹窗
    loadModal = (modalStatus, index, options, optionsTmp, hoverI) => {
        if (modalStatus) {
            return (
                <div className={"modal-bg"}>
                    <div className={"sub-mgr-modal-ct-wrap"}>
                        <div className={"modal-header-wrap"} style={{height: '60px'}}>
                            <div className={"modal-header-title"}>
                                {settingCt[index].title}
                            </div>
                        </div>
                        <div className={"sub-mgr-modal-ct"}>
                            {this.loadCheckBox(settingCt[index].name, settingCt[index].title, optionsTmp[settingCt[index].name], hoverI)}
                        </div>
                        <div className={"modal-close-wrap"}>
                            <CloseOutlined onClick={() => {
                                this.closeModal()
                            }}/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 加载订阅板块
    loadSubSetting = (subCt, modalStatus) => {
        let ui = [];
        if (subCt) {
            for (let i = 0; i < 4; i++) {
                let ct = subCt[settingCt[i]["name"]];
                let title = settingCt[i]["title"];
                ui.push(
                    <div className={"subscription-mgr-ct-setting-item"} key={title}>
                        <div className={"subscription-mgr-ct-setting-item-title"}>
                            {title}
                            <div className={"sub-ct-info-fun"}>
                                <div className={"sub-ct-info-fun-modify"}
                                     onClick={() => {
                                         this.switchEdit(i)
                                     }}
                                >
                                    {"编辑"}
                                </div>
                                <div className={"sub-ct-info-fun-delete"}
                                     onClick={() => {
                                         this.switchDelete(i)
                                     }}>
                                    {"删除"}
                                </div>
                            </div>
                        </div>
                        <div className={"subscription-mgr-ct-setting-item-content"}>
                            {
                                ct ?
                                    <div className={"sub-ct-info"}>
                                        {this.loadSubSettingCt(ct)}
                                    </div>
                                        // <div className={"sub-ct-info-fun"}>
                                        //     <div className={"sub-ct-info-fun-modify"}
                                        //          onClick={() => {
                                        //              this.switchEdit(i)
                                        //          }}
                                        //     >
                                        //         {"编辑"}
                                        //     </div>
                                        //     <div className={"sub-ct-info-fun-delete"}
                                        //          onClick={() => {
                                        //              this.switchDelete(i)
                                        //          }}>
                                        //         {"删除"}
                                        //     </div>
                                        // </div>]
                                    :
                                    <div className={"subscription-mgr-ct-setting-item-empty"}>
                                        <button className={"subscription-mgr-ct-setting-item-empty-btn"}
                                                onClick={() => {
                                                    this.switchEdit(i)
                                                }}
                                        >
                                            {"新建订阅设置"}
                                        </button>
                                    </div>
                            }
                        </div>
                    </div>
                )
            }
        } else {
            for (let i = 0; i < 4; i++) {
                let title = settingCt[i]["title"];
                ui.push(
                    <div className={"subscription-mgr-ct-setting-item"} key={title}>
                        <div className={"subscription-mgr-ct-setting-item-title"}>
                            {title}
                        </div>
                        <div className={"subscription-mgr-ct-setting-item-content"}>
                            <div className={"subscription-mgr-ct-setting-item-empty"}>
                                <button className={"subscription-mgr-ct-setting-item-empty-btn"}
                                        onClick={() => {
                                            this.switchEdit(i)
                                        }}
                                >
                                    {"新建订阅设置"}
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return ui;
    }

    // 加载具体内容
    loadSubSettingCt = (ct) => {
        return (
            [
                ct['geos'] ?
                    <div className={"sub-ct-info-item"}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className={"sub-title-sign"}/>
                            <span className={"sub-ct-info-item-title"}>{"地理位置"}</span>
                        </div>
                        {
                            ct['geos'].map((item, index) => {
                                return (
                                    <div className={"sub-ct-info-item-text"}>
                                        <span className={"sub-ct-item-span"}>{item}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    : null,
                ct['type'] ?
                    <div className={"sub-ct-info-item"}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className={"sub-title-sign"}/>
                            <span className={"sub-ct-info-item-title"}>{"类型"}</span>
                        </div>
                        <div className={"sub-ct-info-item-text"}>
                            <span className={"sub-ct-item-span"}>{handleOutcomeType(ct['type'])}</span>
                        </div>
                    </div>
                    : null,
                ct['srcList'] ?
                    <div className={"sub-ct-info-item"}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className={"sub-title-sign"}/>
                            <span className={"sub-ct-info-item-title"}>{"来源"}</span>
                        </div>
                        {
                            ct['srcList'].map((item, index) => {
                                return (
                                    <div className={"sub-ct-info-item-text"}>
                                        <span className={"sub-ct-item-span"}>{handleSrcType(item)}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    : null,
                ct['stages'] ?
                    <div className={"sub-ct-info-item"}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className={"sub-title-sign"}/>
                            <span className={"sub-ct-info-item-title"}>{"成熟度"}</span>
                        </div>
                        {
                            ct['stages'].map((item, index) => {
                                return (
                                    <div className={"sub-ct-info-item-text"}>
                                        <span className={"sub-ct-item-span"}>{item}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    : null,
                ct['areas'] ?
                    <div className={"sub-ct-info-item"}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className={"sub-title-sign"}/>
                            <span className={"sub-ct-info-item-title"}>{"行业/领域"}</span>
                        </div>
                        {
                            ct['areas'].map((item, index) => {
                                return (
                                    <div className={"sub-ct-info-item-text"}>
                                        <span className={"sub-ct-item-span"}>{item}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    : null,
                ct['cops'] ?
                    <div className={"sub-ct-info-item"}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className={"sub-title-sign"}/>
                            <span className={"sub-ct-info-item-title"}>{"合作方式"}</span>
                        </div>
                        {
                            ct['cops'].map((item, index) => {
                                return (
                                    <div className={"sub-ct-info-item-text"}>
                                        <span className={"sub-ct-item-span"}>{handleCopWay(item)}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    : null,
                ct['titles'] ?
                    <div className={"sub-ct-info-item"}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div className={"sub-title-sign"}/>
                            <span className={"sub-ct-info-item-title"}>{"职称"}</span>
                        </div>
                        {
                            ct['titles'].map((item, index) => {
                                return (
                                    <div className={"sub-ct-info-item-text"}>
                                        <span className={"sub-ct-item-span"}>{handleExpertTitle(item)}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    : null,
            ]
        )
    }

    // 编辑指定订阅设置
    switchEdit = (index) => {
        let userInfo = UserInfoStorage.get("userInfo");
        console.log(userInfo)
        let email = userInfo.userEmail;
        if (email) {
            this.setState({
                editItem: index,
                modalStatus: true,
            })
        } else {
            fail("请先绑定邮箱");
        }

    }

    // 删除指定订阅设置
    switchDelete = (index) => {
        let subTarget = settingCt[index].name;
        let subTargetTitle = settingCt[index].title;
        let subIndex = this.handleIndexByScript(subTarget);
        let userInfo = UserInfoStorage.get("userInfo");
        let params = {
            userId: userInfo.userId,
            index: subIndex,
        }
        console.log("删除订阅设置params: ", params);
        sendGetRequest(DeleteSubscriptUrl, params, true).then(res => {
            console.log("订阅设置res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    success(subTargetTitle + "已删除");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    loadCheckBox = (type, title, data, hoverI) => {
        let ui = [];
        let list = [];
        let geos = [];
        console.log(data)
        if (data) {
            let geosData = data.filter(item => item.title === "地理位置");
            let policyData = data.filter(item => item.title === "政策范围");
            let checkData = data.filter(item => item.title !== "地理位置" && item.title !== "政策范围");
            console.log("geosData", geosData);
            console.log("policyData", policyData);

            geosData.forEach((geo, geo_index) => {
                geos.push(
                    <div className={"sub-geo-item-wrap"} key={geo_index}>
                        <SubscriptionGeo parent={this} options={geo} keys={geo_index + 's'} map={AreaMap}/>
                        <div className={"option-tag-delete"}
                             onClick={() => {
                                 this.deleteSubGeo(data, type, geo.ip)
                             }}
                        >
                            <span className={"option-tag-all"}>{"删除"}</span>
                            <DeleteOutlined
                                style={{fontSize: "18px", padding: "6px", background: "#89b6e5", color: "#f5f5f5"}}
                            />
                        </div>
                    </div>
                )
            })
            policyData.forEach((geo, geo_index) => {
                geos.push(
                    <div className={"sub-geo-item-wrap"} key={geo_index}>
                        <SubscriptionGeo parent={this} options={geo} keys={geo_index + 's'} map={PolicyMap}/>
                        <div className={"option-tag-delete"}
                             onClick={() => {
                                 this.deleteSubGeo(data, type, geo.ip)
                             }}
                        >
                            <span className={"option-tag-all"}>{"删除"}</span>
                            <DeleteOutlined
                                style={{fontSize: "18px", padding: "6px", background: "#89b6e5", color: "#f5f5f5"}}
                            />
                        </div>
                    </div>
                )
            })
            checkData.forEach((item, index) => {
                list.push(
                    <div>
                        <div className={"checkbox-title"}>
                            {item.title}
                        </div>
                        <CheckboxGroup options={item.ct} defaultValue={item.value} key={index}
                                       checked={item.checked}
                                       onChange={(e) => {
                                           this.onChangeCheckbox(e, item.title, type)
                                       }}/>
                    </div>
                )
            });
            ui.push(
                <div>
                    <div className={"checkbox-title"}>
                        {type === "policyScript" ? "政策范围(最多添加两个)" : "地理位置(最多添加两个)"}
                    </div>
                    <div className={"sub-geo"}>
                        <PlusSquareOutlined className={geosData.length === 2 ? "sub-geo-add disabled" : "sub-geo-add"}
                                            style={{color: geosData.length === 2 ? "#bbbbbb" : ""}}
                                            onMouseEnter={() => {
                                                MouseHover(1, hoverI, this)
                                            }}
                                            onMouseLeave={() => {
                                                MouseHover(-1, hoverI, this)
                                            }}
                                            onClick={() => {
                                                this.addSubGeo(type)
                                            }}
                        />
                        <div className={"sub-geo-add-tooltip"}
                             style={{display: hoverI === 1 && geosData.length === 2 ? "" : "none"}}
                        >
                            {"最多添加两个地理位置"}
                        </div>
                        <div className={"sub-geo-wrap"}>
                            {geos}
                        </div>
                    </div>
                    {list}
                    <div className={"sub-btn-wrap"}>
                        <button className={"center-ct-button cancel-button"}
                                onClick={() => {
                                    this.cancelSub()
                                }}
                        >
                            {"取消"}
                        </button>
                        <button className={"center-ct-button save-button"} style={{marginLeft: '20px'}}
                                onClick={() => {
                                    this.saveSub(data, type, title)
                                }}
                        >
                            {"保存"}
                        </button>
                    </div>
                </div>
            )
        }
        return ui;
    }

    // 添加geo
    addSubGeo = (type) => {
        let optionsTmp = this.state.optionsTmp;
        let targetSub = optionsTmp[type];
        let subGeo = targetSub.filter(item => item.title === "地理位置");
        let len = subGeo.length;
        let sample = {
            title: "地理位置",
            ct: [],
            geo: '',
            max: 1,
            ip: '',
        };
        if (len === 1) {
            let hasIp = subGeo[0].ip;
            sample.ip = hasIp === "geo_0" ? "geo_1" : "geo_0";
        } else {
            sample.ip = "geo_0";
        }
        ;
        targetSub.push(sample);
        this.setState({
            optionsTmp: optionsTmp,
        })
    }

    // 删除geo
    deleteSubGeo = (data, type, ip) => {
        console.log(data, ip);
        let optionsTmp = this.state.optionsTmp;
        optionsTmp[type] = optionsTmp[type].filter(item => item.ip !== ip);
        this.setState({
            optionsTmp: optionsTmp,
        })
    }

    // 更改checkbox
    onChangeCheckbox = (e, title, type) => {
        console.log("点击对象：", e)
        let optionsTmp = this.state.optionsTmp;
        let op = optionsTmp[type].filter(it => it.title === title);
        let maxNum = op[0].max;
        console.log("optionsTmp: ", optionsTmp);
        console.log("最大选择: ", maxNum);
        if (maxNum === 1) {
            op[0].ct.filter(it => it.value === e[0] ? it.checked = true : it.disabled = true);
        } else {
            op[0].ct.filter(it => it.value === e[0] || it.value === e[1] ? it.checked = true : it.disabled = true);
        }
        if (e.length < maxNum) {
            op[0].ct.filter(it => it.disabled = false);
        }
        optionsTmp[type].filter(it => it.title === title ? it.value = e : null);
        this.setState({
            optionsTmp: optionsTmp
        })
    };

    // 取消
    cancelSub = () => {
        let options = this.state.options;
        this.setState({
            optionsTmp: JSON.parse(JSON.stringify(options)),
            modalStatus: false,
        })
    }

    // 保存订阅设置
    saveSub = (data, type, title) => {
        console.log("保存：", type, data);
        let index = this.handleIndexByScript(type);
        let userInfo = UserInfoStorage.get("userInfo");
        let raw = {
            type: '',
            srcList: [],
            levels: [],
            areas: [],
            cops: [],
            geos: [],
            titles: [],
        };
        data.map(item => {
            return (
                [
                    item.ip === "geo_0" ? raw.geos.push(item.geo) : null,
                    item.ip === "geo_1" ? raw.geos.push(item.geo) : null,
                    item.ip === "type" ? raw.type = item.value[0] : null,
                    item.ip === "from" ? raw.srcList = item.value : null,
                    item.ip === "level" ? raw.levels = item.value : null,
                    item.ip === "area" ? raw.areas = item.value : null,
                    item.ip === "method" ? raw.cops = item.value : null,
                    item.ip === "title" ? raw.titles = item.value : null,
                ]
            );
        });
        let isLegal = this.checkScript(index, raw);
        console.log(isLegal)
        if (isLegal) {
            let pojo = {
                userId: userInfo.userId,
                index: index,
                subscriptRaw: raw,
            }
            console.log("保存订阅设置params: ", pojo);
            sendPostRequest(genPojo(ProcessSubscriptUrl, pojo), true).then(res => {
                console.log("订阅设置res: ", res);
                if (res === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    if (res && res.hasOwnProperty("success") && res.success) {
                        success(title + "已修改");
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    } else if (res[error] === loginOutErrOr) {
                        UserInfoStorage.remove("userInfo");
                        failReturn(findMsgByError(res[error]), LoginRoot)
                    } else {
                        fail(findMsgByError(res[error]));
                    }
                }
            })
        } else {
            fail("请正确选择订阅设置")
        }
    }

    // 校验合规性
    checkScript = (index, script) => {
        console.log(index, script);
        switch (index) {
            case "1344": {
                let typeLegal = script['type'] !== '';
                let srcLegal = script['srcList'].length > 0;
                let areasLegal = script['areas'].length > 0;
                let geosLegal = script['geos'].filter(it => it !== '').length > 0;
                let levelsLegal = script['levels'].length > 0;
                let copsLegal = script['cops'].length > 0;
                return typeLegal && srcLegal && areasLegal && geosLegal && levelsLegal && copsLegal;
            }
            case "9919": {
                let typeLegal = script['type'] !== '';
                let srcLegal = script['srcList'].length > 0;
                let areasLegal = script['areas'].length > 0;
                let geosLegal = script['geos'].filter(it => it !== '').length > 0;
                let copsLegal = script['cops'].length > 0;
                return typeLegal && srcLegal && areasLegal && geosLegal && copsLegal;
            }
            case "2577": {
                let srcLegal = script['srcList'].length > 0;
                let areasLegal = script['areas'].length > 0;
                let geosLegal = script['geos'].filter(it => it !== '').length > 0;
                let titlesLegal = script['titles'].length > 0;
                return srcLegal && areasLegal && geosLegal && titlesLegal;
            }
            case "3478": {
                return script['geos'].filter(it => it !== '').length > 0;
            }
        }
    }

    // 根据xxxScript提取对应index
    handleIndexByScript = (script) => {
        switch (script) {
            case "outcomeScript": {
                return "1344";
            }
            case "demandScript": {
                return "9919";
            }
            case "expertScript": {
                return "2577";
            }
            case "policyScript": {
                return "3478";
            }
        }
    }

    // 关闭弹窗
    closeModal = () => {
        this.setState({
            modalStatus: false,
        })
    }

    render() {
        let subOpen = this.state.subOpen;
        let subCt = this.state.subCt;
        let modalStatus = this.state.modalStatus;
        let editItem = this.state.editItem;
        let hoverI = this.state.hoverI;

        let options = this.state.options;
        let optionsTmp = this.state.optionsTmp;

        console.log("subCt: ", subCt);
        console.log("options: ", options);
        console.log("optionsTmp: ", optionsTmp);

        return (
            <div className={"subscription-mgr-ct"}>
                {modalStatus && this.loadModal(modalStatus, editItem, options, optionsTmp, hoverI)}
                <div className={"center-ct-header"}>
                    <div className={"center-ct-header-wrap"}>
                        <div className={"center-ct-header-icon"}/>
                        <div className={"center-ct-header-title"}>
                            {"订阅管理"}
                        </div>
                        <div className={"center-ct-header-fun"}>
                            <div className={"center-ct-header-fun-title"}>
                                {"开启订阅"}
                            </div>
                            <Switch className={subOpen ? "ant-switch-checked" : "ant-switch"}
                                    checked={subOpen} disabled={subCt === null}
                                    onClick={() => {
                                        this.switchSub(subCt, subOpen)
                                    }}
                            />
                        </div>
                    </div>
                </div>
                <div className={"subscription-mgr-ct-setting"}>
                    {this.loadSubSetting(subCt, modalStatus)}
                </div>
            </div>
        )
    }
}

export default SubscriptionMgr;
