import React from 'react';
import "./../page.css";
import {ReadOutlined, SearchOutlined, UserOutlined} from "@ant-design/icons";
import {DemandRoot, ExpertRoot} from "../../tool/Const";

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            label: 0,
            text: '',
        }
    }

    setText = (e) => {
        let val = e.target.value;
        console.log(val);
        this.setState({
            text: val,
        })
    }

    handleSearch = (label, text) => {
        if (text) {
            if (label === 0) {
                console.log("专家搜索");
                window.location.href = ExpertRoot.path + "?keyWords=" + text;
            } else {
                console.log("需求搜索");
                window.location.href = DemandRoot.path + "?keyWords=" + text;
            }
        }
    }

    render() {
        let label = this.state.label;
        let text = this.state.text;

        return <div className={"out-content"}>
            <div className={"search-ot"}>
                <div className={"page-ct"}>
                    <div className={"search-outer"}>
                        <div className={"search-title"}>
                            <span>{"携手铸造科技核心力量"}</span>
                        </div>
                        <div className={"search-ct"}>
                            <div className={"search-win"}>
                                <div className={"search-item"}
                                     style={label === 0 ? {
                                         background: "rgb(81,142,222)",
                                         color: "white",
                                         zIndex: "100",
                                         boxShadow: "0 0 4px rgba(0, 0, 0, 0.6)"
                                     } : {}}
                                     onClick={() => {
                                         this.setState({
                                             label: 0,
                                         })
                                     }}>
                                    <UserOutlined/>
                                    <span className={"search-item-txt"}>{"专家"}</span>
                                </div>
                                <div className={"search-item"}
                                     style={label === 1 ? {
                                         background: "rgb(81,142,222)",
                                         color: "white",
                                         zIndex: "100",
                                         boxShadow: "0 0 4px rgba(0, 0, 0, 0.6)"
                                     } : {}}
                                     onClick={() => {
                                         this.setState({
                                             label: 1,
                                         })
                                     }}>
                                    <ReadOutlined/>
                                    <span className={"search-item-txt"}>{"成果"}</span>
                                </div>
                                <div style={{height: "100%", width: "2px"}}/>
                                <div className={"search-input"}>
                                    <input className={"search-input-ct"} placeholder={label === 0 ? "请输入专家关键词" : "请输入技术关键词"}
                                           autoComplete={"off"}
                                           onChange={(e) => {
                                               this.setText(e)
                                           }}
                                    />
                                </div>
                                <div className={"search-bt"}
                                     onClick={() => {
                                         this.handleSearch(label, text)
                                     }}
                                >
                                    <SearchOutlined/>
                                    <span style={{fontSize: "18px", fontWeight: "bolder"}}>{"点击搜索"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Search;