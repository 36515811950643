import React, {Component} from "react";
import "./demand.css";
import "../page.css";
import {
    advList,
    dataIndexE,
    getAdvData,
    getPageDataByIndex,
    handleAdRightWinSizeChange, handleAdRightWinSizeInit,
    handleCopWay,
    handleOutcomeType,
    handlePageScrollMove,
    handleSrcType,
    LoadingPolicyCopAds,
    LoadingSolutionCopAds,
    PolicyRoot,
    scrollTop,
    SolutionRoot,
    solutionTechSpeList,
    toThousandsReg,
    UserInfoStorage
} from "../../tool/Const";
import Header from "../Header";
import {LoadingOutlined} from "@ant-design/icons";
import AdRight from "../../tool/AdRight";
import Contact from "../home/Contact";
import Footer from "../Footer";
import CONTACT from "../../assets/img/contact.png";
import {Breadcrumb, Image} from "antd";
import LOCK_PART from "../../assets/img/lock-part.png";
import PayPanel from "../../tool/payPanel/PayPanel";
import IMG_BG from "../../assets/img/img_bg.png";
import {SrcHeader} from "../../tool/http/Request";
import ScriptParent from "../ScriptParent";
import ErrorPage from "../Error/ErrorPage";
import SERVICE_PROCESS from "../../assets/service/service-process-demand.png";
import SAFETY_GUARANTEE from "../../assets/service/safety-guarantee.png"

const adClasses = {
    adOuter: "demand-ct-ad",
    adOne: "demand-ct-ad-one",
    adTwo: "demand-ct-ad-two",
}

class DemandScript extends ScriptParent {
    constructor() {
        super();
        this.state = {
            pageData: '',  // 该页面数据
            isPageDataLoad: false,  // 该页面数据是否加载完
            fixed: true,
            fixedAd: false,
            adLeft: "",
            adBt: false,
            showAd: true,
            gap: 0,
            cH: 0,
            adjustGap: 0,
            openSize: 0,
            advMap: new Map(),  // adv数据
            isMatch: false,
        }
    }

    // 加载Header
    loadHeader = (data) => {
        if (data) {
            // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
            // let picDir = require.context("../pic/", true, /\.(png|jpg)$/);
            // let imgUrl = picDir("./" + data["demandBanner"]);
            let imgUrl = SrcHeader + data["demandId"] + "/" + data["demandBanner"];
            return (
                <div className={"script-header demand-header"}>
                    <div className={"script-header-ct"}>
                        <div className={"script-header-l"}>
                            <div className={"script-name demand-name"}>
                                {data["demandName"]}
                            </div>
                            <div className={"script-tag"}>
                                <span style={{color: '#d6d6d6'}}>
                                    {"需求类型："}
                                </span>
                                <span>
                                    {handleOutcomeType(data["demandType"])}
                                </span>
                            </div>
                            <div className={"script-tag"}>
                                <span style={{color: '#d6d6d6'}}>
                                    {"行业："}
                                </span>
                                <span>
                                    {data["demandAreas"]}
                                </span>
                            </div>
                            <div className={"contact-bt"}>
                                <div className={"contact-bt-icon"}>
                                    <img className={"img-style contain"} src={CONTACT} alt={""}/>
                                </div>
                                <div className={"contact-bt-text"}>
                                    {"点击立即咨询"}
                                </div>
                            </div>
                        </div>
                        <div className={"script-header-r"}>
                            <img className={"script-img-bg"} src={IMG_BG} alt={""}/>
                            <div className={"script-img"}>
                                <img className={"img-style cover"} src={imgUrl} alt={""}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 加载内容
    loadMain = (userInfo, data) => {
        if (data) {
            return (
                <div className={"script-main"}>
                    <div className={"script-main-header"}>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item style={{marginRight: '5px'}}>
                                {"您所在的位置: "}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a href="/">{"首页"}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item>
                                <a href="/demand">{"技术需求"}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item style={{color: '#0077ff'}}>
                                <strong>{"正文"}</strong>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className={"script-main-ct"}>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"技术需求基本情况"}
                            </div>
                            <div className={"script-item-info expert-info"}>
                                {this.loadInfoLayout("需求来源：", handleSrcType(data["srcType"]))}
                                {this.loadInfoLayout("行业分类：", data["demandAreas"])}
                                {this.loadInfoLayout("合作方式：", handleCopWay(data["copWay"]))}
                                {this.loadInfoLayout("需求金额：", toThousandsReg(data["demandPrice"]) + "元")}
                                {this.loadInfoLayout("所属机构：", data["demandRoot"])}
                                {this.loadInfoLayout("发布日期：", data["relDate"])}
                                {this.loadInfoLayout("完成日期：", data["compDate"])}
                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"技术需求简介"}
                            </div>
                            <div className={"script-item-content"}>
                                {data["demandDes"]}
                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"技术需求详情"}
                            </div>
                            {
                                userInfo ?
                                    data["demandCt"] ?
                                        <div className={"script-item-content"}>
                                            {data["demandCt"]}
                                        </div>
                                        :
                                        <div className={"lock-part"}>
                                            <img className={"img-style cover"} src={LOCK_PART} alt={""}/>
                                            <PayPanel parent={this} type={2}
                                                      classWrap={"pay-panel"} title={"升级为会员后即可解锁技术需求详情"}
                                                      itemId={data["demandId"]}
                                            />
                                        </div>
                                    :
                                    <div className={"lock-part"}>
                                        <img className={"img-style cover"} src={LOCK_PART} alt={""}/>
                                        <PayPanel parent={this} type={1}
                                                  classWrap={"login-panel"} title={"会员登陆后即可解锁技术需求详情"}
                                        />
                                    </div>
                            }
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"技术需求拓展描述"}
                            </div>
                            {
                                userInfo ?
                                    data["extraInfo"] ?
                                        <div className={"script-item-content"}>
                                            {data["extraInfo"]}
                                        </div>
                                        :
                                        <div className={"lock-part"}>
                                            <img className={"img-style cover"} src={LOCK_PART} alt={""}/>
                                            <PayPanel parent={this} type={2}
                                                      classWrap={"pay-panel"} title={"升级为会员后即可解锁技术需求拓展描述"}
                                                      itemId={data["demandId"]}
                                            />
                                        </div>
                                    :
                                    <div className={"lock-part"}>
                                        <img className={"img-style cover"} src={LOCK_PART} alt={""}/>
                                        <PayPanel parent={this} type={1}
                                                  classWrap={"login-panel"} title={"会员登陆后即可解锁技术需求拓展描述"}
                                        />
                                    </div>
                            }
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"对接承接方要求描述"}
                            </div>
                            {
                                userInfo ?
                                    data["accepterRequest"] ?
                                        <div className={"script-item-content"}>
                                            {data["accepterRequest"]}
                                        </div>
                                        :
                                        <div className={"lock-part"}>
                                            <img className={"img-style cover"} src={LOCK_PART} alt={""}/>
                                            <PayPanel parent={this} type={2}
                                                      classWrap={"pay-panel"} title={"升级为会员后即可解锁技术需求拓展描述"}
                                                      itemId={data["demandId"]}
                                            />
                                        </div>
                                    :
                                    <div className={"lock-part"}>
                                        <img className={"img-style cover"} src={LOCK_PART} alt={""}/>
                                        <PayPanel parent={this} type={1}
                                                  classWrap={"login-panel"} title={"会员登陆后即可解锁对接承接方要求描述"}
                                        />
                                    </div>
                            }
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"联系咨询"}
                            </div>
                            <div className={"contact-plugin"}>
                                <div className={"contact-bt-icon"}>
                                    <img className={"img-style contain"} src={CONTACT} alt={""}/>
                                </div>
                                <div className={"contact-bt-text"}>
                                    {"点击立即咨询"}
                                </div>
                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"技术需求对接服务流程"}
                            </div>
                            <div className={"service-process"}>
                                <img className={"img-style contain"} src={SERVICE_PROCESS} alt={""}/>
                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"安全保障"}
                            </div>
                            <div className={"safety-guarantee"}>
                                <img className={"img-style contain"} src={SAFETY_GUARANTEE} alt={""}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    componentDidMount() {
        getPageDataByIndex(this).then(res => {
            if (res) {
                getAdvData(advList.demand, this);
                handleAdRightWinSizeInit(this);
                window.addEventListener("resize", this.handleResize);
                window.onscroll = () => {
                    let cH = document.getElementById("script-outer").clientHeight;
                    let cHC = this.state.cH;
                    handlePageScrollMove(this, "", 1760, 500 + cH, 500 + cH, "script-outer", 940)
                    if (cHC !== cH) {
                        this.setState({
                            cH: cH,
                        })
                    }
                };
                scrollTop();
            }
        });

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        handleAdRightWinSizeChange("script-outer", this);
    }

    render() {
        let userInfo = UserInfoStorage.get("userInfo");
        console.log("userInfo: ", userInfo);

        let pageData = this.state.pageData;
        let isPageDataLoad = this.state.isPageDataLoad;
        // console.log("data：", pageData);

        let adLeft = this.state.adLeft;
        let right = "calc((100% - " + adLeft + " ) / 2)";
        let totalGap = this.state.gap - this.state.adjustGap;
        let gapR = totalGap === 0 ? "0" : parseInt(totalGap + "") + "px";
        let wH = parseInt((this.state.cH - 30) + "") + "px";

        let advMap = this.state.advMap;
        console.log("advMap: ", advMap);

        let isMatch = this.state.isMatch;

        return (
            isMatch ?
                <div className={"home-ct"}>
                    <Header/>
                    <div className={"script-ct"} id={"pageHeader"}>
                        {this.loadHeader(pageData)}
                        <div className={"script-page-content"} id={"script-outer"}>
                            <div className={"script-ct-data"}>
                                {
                                    isPageDataLoad ?
                                        this.loadMain(userInfo, pageData)
                                        :
                                        <div className={"load-plugin"}>
                                            <LoadingOutlined className={"load-icon"}/>
                                            <div className={"load-txt"}>
                                                {"页面加载中，请稍候..."}
                                            </div>
                                        </div>
                                }
                            </div>
                            {
                                advMap.size === advList.demand.length ?
                                    <AdRight fixedAd={this.state.fixedAd} adBt={this.state.adBt} gapR={gapR} right={right}
                                             wholeHgt={wH} adClasses={adClasses}
                                             adTitleOne={"院校科研创新"} adOneCt={LoadingSolutionCopAds(solutionTechSpeList)} adOneUrl={SolutionRoot}
                                             adTitleTwo={"政策精要"} adTwoCt={LoadingPolicyCopAds(advMap.get(dataIndexE.policy))} adTwoUrl={PolicyRoot.path}
                                    />
                                    : null
                            }
                        </div>
                    </div>
                    <Contact fixed={this.state.fixed}/>
                    <div>
                        <Footer/>
                    </div>
                </div>
                : <ErrorPage/>
        );
    }
}

export default DemandScript;