import React, {Component} from 'react';
import "../tool.css";
import {AimOutlined, CaretRightOutlined} from "@ant-design/icons";

class GeoFilter extends Component {
    selectGeo = (province, city, options, isSub, parent) => {
        let geo = province;
        if (city !== null) {
            isSub ? geo += ";" + city : geo += ("[" + city + "]");
            // geo += ";" + city
        }
        options.geo = geo;
        parent.setState({
            openGeo: false,
            showCity: -1,
        })
        parent.setOptions(options)
    }

    controlCity = (num, parent) => {
        parent.setState({
            showCity: num
        })
    }

    controlGeo = (open, parent) => {
        parent.setState({
            openGeo: open
        })
    }

    loadGeoMenu = (options, parent) => {
        let areas = [];
        if (this.props.mainType) {
            areas.push(
                <span className={"geo-province"} key={"allArea"}
                      onClick={() => {
                          this.selectGeo(this.props.mainType, null, options, this.props.isSub, parent)
                      }}
                >
                <div style={{width: "99%"}}>
                    {this.props.mainType}
                </div>
            </span>
            )
        }
        this.props.map.forEach((area, index) => {
            let cities = [];
            let province = area.province;
            let cityList = area.cities;
            if (cityList.length > 1 && !this.props.isSub) {
                cities.push(
                    <span className={"city-tag"} key={"all-city"} onClick={() => {
                        this.selectGeo(province, null, options, this.props.isSub, parent)
                    }}>
                        {"全部"}
                    </span>
                )
            }
            cityList.forEach((city, num) => {
                cities.push(
                    <span className={"city-tag"} key={num + "city"} onClick={() => {
                        this.selectGeo(province, city, options, this.props.isSub, parent)
                    }}>
                        {city}
                    </span>
                )
            })
            areas.push(
                <span className={"geo-province"} key={index + "area"} onMouseEnter={() => {this.controlCity(index, parent)}}
                      onMouseLeave={() => {this.controlCity(-1, parent)}}>
                    <div style={{width: "99%"}}>{province}</div>
                    <CaretRightOutlined style={{fontSize: "12px"}}/>
                    <div className={"geo-cities"} style={{display: parent.state.showCity === index ? "" : "none"}}>
                        <div className={"city-ct"}>
                            {cities}
                        </div>
                    </div>
                </span>
            )
        })
        return (
            <div className={"geo-outer"}>
                <div className={"geo-ct"}>
                    {areas}
                </div>
            </div>
        )
    }

    render() {
        let parent = this.props.parent;
        let options = this.props.options;

        return (
            <div className={this.props.areaClass}>
                {this.props.isIcon ? <AimOutlined style={{fontSize: "18px", paddingRight: "4px", paddingLeft: "6px"}}/> : null}
                <span className={this.props.titleClass}>
                    {this.props.header}
                </span>
                <span className={"filter-area-geo"}
                      onMouseEnter={() => {
                          this.controlGeo(true, parent)
                      }}
                      onMouseLeave={() => {
                          this.controlGeo(false, parent)
                      }}
                >
                    {options.geo ? options.geo : "请选择地理位置"}
                    {parent.state.openGeo ? this.loadGeoMenu(options, parent) : null}
                </span>
            </div>
        )
    }
}

export default GeoFilter;