import React, {Component} from "react";
import "./news.css";
import "../page.css";
import Header from "../Header";
import Contact from "../home/Contact";
import Footer from "../Footer";
import {
    advList,
    dataIndexE,
    DemandRoot,
    getAdvData,
    getPageDataByIndex,
    handleAdRightWinSizeChange, handleAdRightWinSizeInit,
    handleBigData,
    handlePageScrollMove,
    LoadingDemandCopAds,
    LoadingPolicyCopAds,
    NewsRoot,
    PolicyRoot,
    scrollTop,
    UserInfoStorage
} from "../../tool/Const";
import {LoadingOutlined} from "@ant-design/icons";
import AdRight from "../../tool/AdRight";
import {Breadcrumb} from "antd";
import {SrcHeader} from "../../tool/http/Request";
import ErrorPage from "../Error/ErrorPage";

const adClasses = {
    adOuter: "news-ct-ad",
    adOne: "news-ct-ad-one",
    adTwo: "news-ct-ad-two",
}

class NewsScript extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageData: '',  // 该页面数据
            isPageDataLoad: false,  // 该页面数据是否加载完
            fixed: true,
            fixedAd: false,
            adLeft: "",
            adBt: false,
            showAd: true,
            gap: 0,
            cH: 0,
            adjustGap: 0,
            openSize: 0,
            advMap: new Map(),  // adv数据
            isMatch: false,
        }
    }

    // 加载Header
    loadHeader = (data) => {
        if (data) {
            // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
            // let picDir = require.context("../pic/", true, /\.(png|jpg)$/);
            // let imgUrl = picDir("./" + data["newsBanner"]);
            let imgUrl = SrcHeader + data["newsId"] + "/" + data["newsBanner"];
            return (
                <div className={"script-header outcome-header"}>
                    <div className={"script-header-ct"}>
                        <div className={"script-header-l"}>
                            <div className={"script-name outcome-name"}>
                                {data["newsName"]}
                            </div>
                            <div className={"script-tag"}>
                                <span style={{color: '#d6d6d6'}}>
                                    {"发布单位："}
                                </span>
                                <span>
                                    {data["newsRoot"]}
                                </span>
                            </div>
                        </div>
                        <div className={"script-header-r"}>
                            <div className={"script-img"}>
                                <img className={"img-style cover"} src={imgUrl} alt={""}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 加载内容
    loadMain = (data, userInfo) => {
        if (data) {
            return (
                <div className={"script-main"}>
                    <div className={"script-main-header"}>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item style={{marginRight: '5px'}}>
                                {"您所在的位置: "}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a href="/">{"首页"}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item>
                                <a href={NewsRoot.path}>{"科技资讯"}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item style={{color: '#0077ff'}}>
                                <strong>{"正文"}</strong>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className={"script-main-ct"}>
                        <h1>{data["newsName"]}</h1>
                        <div className="time">
                            {"发布单位： " + data["newsRoot"]}
                        </div>
                        <div className="time">
                            {"发布日期： " + data["newsDate"]}
                        </div>
                        <div className="article">
                            {handleBigData(data, "news", userInfo)}
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"研发情报指南服务流程"}
                            </div>
                            <div className={"service-process"}>

                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    componentDidMount() {
        getPageDataByIndex(this).then(res => {
            if (res) {
                getAdvData(advList.news, this);
                handleAdRightWinSizeInit(this);
                window.addEventListener("resize", this.handleResize);
                window.onscroll = () => {
                    let cH = document.getElementById("script-outer").clientHeight;
                    let cHC = this.state.cH;
                    handlePageScrollMove(this, "", 1320, 160 + cH,
                        160 + cH,
                        "script-outer", 1134)
                    if (cHC !== cH) {
                        this.setState({
                            cH: cH,
                        })
                    }
                };
                scrollTop();
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        handleAdRightWinSizeChange("script-outer", this);
    }

    render() {
        let userInfo = UserInfoStorage.get("userInfo");
        let pageData = this.state.pageData;
        let isPageDataLoad = this.state.isPageDataLoad;
        // console.log("data：", pageData);

        let adLeft = this.state.adLeft;
        let right = "calc((100% - " + adLeft + " ) / 2)";
        let totalGap = this.state.gap - this.state.adjustGap;
        let gapR = totalGap === 0 ? "0" : parseInt(totalGap + "") + "px";
        let wH = parseInt((this.state.cH - 30) + "") + "px";

        let advMap = this.state.advMap;
        console.log("advMap: ", advMap);

        let isMatch = this.state.isMatch;

        return (
            isMatch ?
                <div className={"home-ct"}>
                    <Header/>
                    <div className={"script-ct"} id={"pageHeader"}>
                        {/*{this.loadHeader(pageData)}*/}
                        <div className={"script-page-content"} id={"script-outer"}>
                            <div className={"script-ct-data"}>
                                {
                                    isPageDataLoad ?
                                        this.loadMain(pageData, userInfo)
                                        :
                                        <div className={"load-plugin"}>
                                            <LoadingOutlined className={"load-icon"}/>
                                            <div className={"load-txt"}>
                                                {"页面加载中，请稍候..."}
                                            </div>
                                        </div>
                                }
                            </div>
                            {
                                advMap.size === advList.news.length ?
                                    <AdRight fixedAd={this.state.fixedAd} adBt={this.state.adBt} gapR={gapR} right={right}
                                             wholeHgt={wH} adClasses={adClasses}
                                             adTitleOne={"热门政策解读"} adOneCt={LoadingPolicyCopAds(advMap.get(dataIndexE.policy))} adOneUrl={PolicyRoot.path}
                                             adTitleTwo={"推荐需求"} adTwoCt={LoadingDemandCopAds(advMap.get(dataIndexE.demand))} adTwoUrl={DemandRoot.path}
                                    />
                                    : null
                            }
                        </div>
                    </div>
                    <Contact fixed={this.state.fixed}/>
                    <div>
                        <Footer/>
                    </div>
                </div>
                : <ErrorPage/>
        )
    }
}

export default NewsScript;