import React, {Component} from 'react';
import "./demand.css";
import "../page.css";
import Header from "../Header";
import Footer from "../Footer";
import DemandFilter from "./DemandFilter";
import AdRight from "../../tool/AdRight";
import Contact from "../home/Contact";
import {
    advList,
    dataIndexE,
    DemandRoot,
    findFavorDataByType,
    getAdvData,
    handleAdRightWinSizeChange, handleAdRightWinSizeInit,
    handleClickMonitor,
    handleIdByIndex,
    handlePageScrollMove,
    LoadingPolicyCopAds,
    LoadingSolutionCopAds,
    PolicyRoot,
    SaveBt,
    SolutionRoot,
    solutionTechSpeList,
    toThousandsReg,
    UserInfoStorage
} from "../../tool/Const";
import Partitioner from "../../tool/Partitioner";
import {CalendarOutlined, EyeOutlined} from "@ant-design/icons";
import EMPTY from "../../assets/img/record_empty.svg";
import {CheckMonitorUrl, data, error, sendGetRequest} from "../../tool/http/Request";
import {connectionError, fail, findMsgByError} from "../../tool/http/ErrorMgr";

const demands = ["1", "1", "1", "1", "1", "1", "1", "1"]

const adClasses = {
    adOuter: "demand-ct-ad",
    adOne: "demand-ct-ad-one",
    adTwo: "demand-ct-ad-two",
}

const loadSetting = {
    loadingBatch: 20,
    classes: {
        outer: "outcome-page-partition-ct",
    }
}

const initType = "发明专利";

const initGeo = "全国所有省市";

const initTag = "全部";

class DemandPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fixed: true,
            fixedAd: false,
            adLeft: "",
            adBt: false,
            showAd: true,
            gap: 0,
            cH: 0,
            hoverI: -1,
            adjustGap: 0,
            openSize: 0,
            partitioner: {
                totalSize: 0,
                showData: [],
                loadComplete: false,
            },
            options: {
                index: '9919',
                geo: initGeo,
                type: initType,
                from: initTag,
                level: initTag,
                area: initTag,
                method: initTag,
                keywords: "",
            },
            favorList: [],
            idList: [],
            idDataList: [],
            isMonitorLoad: false,
            advMap: new Map(),  // adv数据
        };
        this.timeout = null;
        this.handleIdList = this.handleIdList.bind(this);
    }

    componentDidMount() {
        handleAdRightWinSizeInit(this);
        window.addEventListener("resize", this.handleResize)
        window.onscroll = () => {
            let cH = document.getElementById("demand-outer").clientHeight;
            let cHC = this.state.cH;
            // distanceAboveAd 页面往上移动多少，右边ad fixed (header + filter + padding + adHeight + contact - 60)
            // distanceBtAd (header + filter + padding + contact - 60)
            // distanceContact (header + filter + padding + contact - 60)
            let headerH = document.getElementById("header").clientHeight;
            let filterH = document.getElementById("filter").clientHeight;
            let paddingH = 0;
            let adH = document.getElementById("ad").clientHeight;
            let contactH = 42;
            let distanceAboveAd = headerH + filterH + paddingH + adH + contactH - 60; // 1790
            let distanceBtAd = headerH + filterH + paddingH + contactH - 60;  // 530
            let distanceContact = headerH + filterH + paddingH + contactH - 90;  // 500

            console.log("headerH: ", headerH);
            console.log("filterH: ", filterH);
            console.log("adH: ", adH);
            console.log("distanceAboveAd: ", distanceAboveAd);  // 1790
            console.log("distanceBtAd: ", distanceBtAd);  // 530
            console.log("distanceContact: ", distanceContact);  // 500

            handlePageScrollMove(this, "d-tag", distanceAboveAd, distanceBtAd + cH,
                distanceContact + cH, "demand-outer", 940)
            if (cHC !== cH) {
                this.setState({
                    cH: cH,
                })
            }
        };
        findFavorDataByType(this);
        getAdvData(advList.demand, this);
        this.handleMenuLink();
    }

    handleMenuLink = () => {
        let search = this.props.location.search;
        if (search) {
            let searchD = decodeURIComponent(search);
            let params = searchD.replace("?", "");
            let filterType = params.split("=")[0];
            let filterValue = params.split("=")[1];
            console.log(filterType, filterValue);
            let options = this.state.options;
            if (filterType === "keyWords") {
                options[filterType] = filterValue;
            }
            this.setState({
                options: Object.assign({}, options),
            })
        }
    }

    handleResize = () => {
        handleAdRightWinSizeChange("demand-outer", this);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize)
    }

    loadDemandData = () => {
        let data = []
        let showData = this.state.partitioner.showData;
        let hoverI = this.state.hoverI;
        let userInfo = UserInfoStorage.get("userInfo");
        let favorList = this.state.favorList;
        let options = this.state.options;
        let idDataList = this.state.idDataList;
        let isMonitorLoad = this.state.isMonitorLoad;

        if (showData && isMonitorLoad && idDataList.length > 0) {
            showData.forEach((item, index) => {
                let it = idDataList.filter(it => it["dataId"] === item["demandId"]);
                console.log("匹配数据：", it);

                data.push(
                    <div className={"demand-data-item"} key={index}>
                        <div className={"demand-item-ct"}>
                            <div className={"demand-item-title"}>
                                <div className={"demand-item-title-save"}>
                                    {SaveBt(item, index, hoverI, this, userInfo, favorList, options)}
                                    <div className={"save-demand"}
                                         style={{display: index === hoverI ? "" : "none"}}
                                    >
                                        {favorList.indexOf(item["demandId"]) > -1 ? "取消收藏" : "点击收藏"}
                                    </div>
                                </div>
                                <div className={"demand-item-title-ct ellipse"}
                                     onClick={() => {
                                         handleClickMonitor(userInfo, item["demandId"], 2, 1, DemandRoot.path + "/" + item["demandId"])
                                     }}
                                >
                                    {/*{"分散式污水处理技术的改变和延续改变延"}*/}
                                    {item["demandName"]}
                                </div>
                            </div>
                            <div className={"demand-item-info"}
                                 onClick={() => {
                                     handleClickMonitor(userInfo, item["demandId"], 2, 1, DemandRoot.path + "/" + item["demandId"])
                                 }}
                            >
                                <div className={"demand-item-info-line"}>
                                    <span className={"demand-item-info-title"}>
                                        {"所属行业："}
                                    </span>
                                    <span className={"demand-item-info-ct ellipse"}
                                          style={{maxWidth: 'calc(100% - 120px)', background: "rgba(76,206,67, 0.2)", color: "green"}}>
                                        {/*{"环境保护与资源综合利用技术"}*/}
                                        {item["demandAreas"]}
                                    </span>
                                </div>
                                <div className={"demand-item-info-line"}>
                                    <div className={"demand-item-info-line-half"}>
                                        <span className={"demand-item-info-title"}>
                                            {"所属区域："}
                                        </span>
                                        <span className={"demand-item-info-ct ellipse"}
                                              style={{maxWidth: 'calc(100% - 100px)', background: "rgba(151,217,255,0.3)", color: "rgb(60,131,213)"}}>
                                            {/*{"上海市"}*/}
                                            {item["demandGeo"]}
                                        </span>
                                    </div>
                                    <div className={"demand-item-info-line-half"}>
                                        <span className={"demand-item-info-title"}>
                                            {"预计金额："}
                                        </span>
                                        {
                                            item["demandPrice"] === 0 ?
                                              <span className={"demand-price"}>
                                                  {"面议"}
                                              </span>
                                              :
                                              <>
                                                   <span className={"demand-price"}>
                                                     {toThousandsReg(item["demandPrice"])}
                                                   </span>
                                                   <span style={{lineHeight: '18px', color: "red", paddingLeft: "4px"}}>
                                                     {"元"}
                                                   </span>
                                              </>
                                        }
                                    </div>
                                </div>
                                <div className={"demand-item-info-line"}>
                                    <div className={"demand-item-info-line-half"}>
                                        <span className={"demand-item-info-title"}
                                              style={{paddingRight: '10px'}}
                                        >
                                            <CalendarOutlined style={{fontSize: "18px", color: "rgb(75,75,75)"}}/>
                                        </span>
                                        <span style={{fontSize: '14px'}}>
                                            {/*{"2022-03-04"}*/}
                                            {item["compDate"]}
                                        </span>
                                    </div>
                                    <div className={"demand-item-info-line-half"}>
                                        <span className={"demand-item-info-title"}
                                              style={{paddingRight: '10px'}}
                                        >
                                            <EyeOutlined style={{fontSize: "18px", color: "rgb(75,75,75)"}}/>
                                        </span>
                                        <span style={{fontSize: '14px'}}>
                                            {/*{"500"}*/}
                                            {it.length > 0 ? toThousandsReg(it[0]["realClick"]) : 500}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            data.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }

        return data;
    }

    // 由资源ID获取资源收藏点击等数据pre
    handleIdList = (dataList, options) => {
        let index = options.index;
        let list = [];
        if (dataList) {
            dataList.forEach((item, item_index) => {
                list.push(item[handleIdByIndex(index)])
            })
        }
        this.setState({
                idList: list,
            },
            () => {
                this.handleCheckMonitor();
            })
    }

    // 由资源ID获取资源收藏点击等数据post
    handleCheckMonitor = () => {
        let ids = this.state.idList;
        // 当前页面有id list才调用查看资源ID获取资源收藏点击等数据api
        if (ids.length > 0) {
            let index = this.state.options.index;
            let params = {
                statisticsPojo: {
                    index: index,
                    idsStr: ids + '',
                },
            };
            console.log("由资源ID获取资源收藏点击等数据params: ", params);
            sendGetRequest(CheckMonitorUrl, params, false).then(res => {
                console.log("由资源ID获取资源收藏点击等数据res: ", res);
                if (res === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    if (res && res.hasOwnProperty("success") && res.success) {
                        this.setState({
                            idDataList: res[data],
                            isMonitorLoad: true,
                        })
                    } else {
                        fail(findMsgByError(res[error]))
                    }
                }
            })
        }
    }

    render() {
        let adLeft = this.state.adLeft;
        let right = "calc((100% - " + adLeft + " ) / 2)";
        let totalGap = this.state.gap - this.state.adjustGap;
        let gapR = totalGap === 0 ? "0" : parseInt(totalGap + "") + "px";
        let wH = parseInt((this.state.cH - 30) + "") + "px";
        let options = this.state.options;
        let advMap = this.state.advMap;

        console.log(options)
        return (
            <div className={"home-ct"}>
                <Header/>
                <div className={"demand-page"}>
                    <DemandFilter parent={this} total={this.state.partitioner.totalSize} options={options}
                                  initTag={initTag}/>
                    <div className={"demand-page-content"} id={"demand-outer"}>
                        <div className={"demand-ct-data"}>
                            {this.loadDemandData()}
                            <div className={"outcome-page-partition"}>
                                <Partitioner parent={this} loadSetting={loadSetting} options={options}
                                             handleCheckMonitor={this.handleIdList}
                                />
                            </div>
                        </div>
                        {
                            advMap.size === advList.demand.length ?
                                <AdRight fixedAd={this.state.fixedAd} adBt={this.state.adBt} gapR={gapR} right={right}
                                         wholeHgt={wH} adClasses={adClasses}
                                         adTitleOne={"院校科研创新"} adOneCt={LoadingSolutionCopAds(solutionTechSpeList)} adOneUrl={SolutionRoot}
                                         adTitleTwo={"政策精要"} adTwoCt={LoadingPolicyCopAds(advMap.get(dataIndexE.policy))} adTwoUrl={PolicyRoot.path}
                                />
                                : null
                        }
                    </div>
                </div>
                <Contact fixed={this.state.fixed}/>
                <div>
                    <Footer/>
                </div>
            </div>
        )
    }
}

export default DemandPage;
