import React, {Component} from 'react';
import "./tool.css";
import {DashOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {validNum} from "./Const";
import {data, error, FindResourceDataUrl, FindResourceSizeUrl, sendGetRequest} from "./http/Request";
import {connectionError, fail, findMsgByError} from "./http/ErrorMgr";

const getterTimes = 3;

const hiddenStyle = {
    background: "transparent",
    cursor: "default",
    color: "#3c80d4"
}

const selectStyle = {
    margin: "3px 4px 3px 4px",
    width: "44px",
    height: "44px",
    fontSize: "18px",
    background: "#1981ff",
    boxShadow: "0 0 4px rgba(0, 0, 0, 0.8)"
}

class Partitioner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataMap: new Map(),
            selectedPage: 1,
            totalPages: 0,
            inputPage: 0,
            options: this.props.options,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.options !== this.props.options) {
        // if (!arrayIdentical(prevProps.options, this.props.options)) {
        //     console.log("prevProps.options: ", prevProps.options);
        //     console.log("this.props.options: ", this.props.options);
            console.log("load data");
            this.state.dataMap.delete(1);
            this.loadData(1);
            this.setState({
                selectedPage: 1,
            },
                () => {

                })
        }
    }

    componentDidMount() {
        this.loadData(1);
    }

    setShowData = (data, totalData, parent, partitioner) => {
        document.documentElement.scrollTop = 0;
        partitioner.showData = data;
        partitioner.loadComplete = true;
        partitioner.totalSize = totalData;
        parent.setState({
            partitioner: partitioner,
        })
    }

    loadData = (page) => {
        // 获取资源数据数据量
        let options = Object.assign({}, this.props.options);
        console.log(options);
        options.geo = encodeURIComponent(options.geo);
        // console.log(options.geo);
        let params = {
            filter: options,
            // filter: {
            //     index: options.index,  // 科研成果index
            //     geo: options.geo,  // 地理位置
            //     type: options.type,  // 类型
            //     from: options.from,  // 技术来源
            //     level: options.level,  // 成熟度
            //     area: options.area,  // 行业分类
            //     method: options.method,  // 合作方式
            //     keyWords: options.keywords,  // 关键词
            // },
        };
        console.log("获取资源数据数据量params: ", params);
        sendGetRequest(FindResourceSizeUrl, params, false).then(res => {
            console.log("获取资源数据数据量res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    let totalData = res[data];
                    // let totalData = 21;
                    // let totalData = 3;
                    let parent = this.props.parent;
                    let partitioner = parent.state.partitioner;

                    console.log("partitioner：", partitioner);

                    let isDataSizeChange = false;
                    if (totalData !== partitioner.totalSize) {  // /findResourceSize
                        isDataSizeChange = true;
                    }
                    console.log("isDataSizeChange: ", isDataSizeChange);
                    let dataMap = !isDataSizeChange ? this.state.dataMap : new Map();
                    if (dataMap.has(page)) {
                        this.setShowData(dataMap.get(page), totalData, parent, partitioner);
                        console.log("find data from exist!")
                        return;
                    }
                    console.log("find data from new!")
                    let batchSize = this.props.loadSetting.loadingBatch;
                    let selectedPage = this.state.selectedPage;
                    let getterSize = batchSize * getterTimes;
                    this.findResourceData(page, selectedPage, batchSize, dataMap, totalData, partitioner, parent);
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 获取资源数据
    findResourceData = (page, selectedPage, batchSize, dataMap, totalData, partitioner, parent) => {
        let options = Object.assign({}, this.props.options);
        options.geo = encodeURIComponent(options.geo);
        let params = {
            dataPojo: {
                filter: options,
                // filter: {
                //     index: options.index,  // 科技成果index
                //     geo: options.geo,  // 地理位置
                //     type: options.type,  // 类型
                //     from: options.from,  // 技术来源
                //     level: options.level,  // 成熟度
                //     area: options.area,  // 行业分类
                //     title: options.title,
                //     method: options.method,  // 合作方式
                //     keyWords: options.keywords,  // 关键词
                // },
                pageLimit: batchSize,
                pageNo: page,
            }
        };
        sendGetRequest(FindResourceDataUrl, params, false).then(res => {
            console.log("获取分页数据res: ", page, res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    let dataList = res[data];  // /findResourceData
                    // let dataList = ["1", "2", "3"];

                    let pageData = [];
                    let pageNum = selectedPage;
                    dataList.forEach((data) => {
                        // console.log(data)
                        if (pageData.length === batchSize) {
                            dataMap.set(pageNum, pageData);
                            pageData = [];
                            pageNum += 1;
                        }
                        pageData.push(data);

                    })
                    this.props.handleCheckMonitor && this.props.handleCheckMonitor(dataList, options);
                    if (pageData.length > 0) {
                        dataMap.set(pageNum, pageData);
                    }
                    this.setState({
                        dataMap: dataMap,
                        totalPages: this.calTotalPageNum(totalData, batchSize),
                    })
                    this.setShowData(dataMap.get(page), totalData, parent, partitioner)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    calTotalPageNum = (totalData, batchSize) => {
        return totalData % batchSize === 0 ? totalData / batchSize : parseInt((totalData / batchSize) + "") + 1;
    }

    loadNumBtList = (totalPages) => {
        let selectedPage = this.state.selectedPage;
        return (
            <div className={"num-bt-outer"}>
                <div className={"check-move-bt"}
                     style={selectedPage === 1 ? {
                         background: "rgb(144,144,144)",
                         cursor: "not-allowed"
                     } : {background: "rgb(147,209,255)"}}
                     onClick={() => {
                         this.switchPage(selectedPage - 1, totalPages)
                     }}>
                    <LeftOutlined/>
                </div>
                {this.loadPageBt(selectedPage, totalPages)}
                <div className={"check-move-bt"}
                     style={selectedPage === totalPages ? {
                         background: "rgb(144,144,144)",
                         cursor: "not-allowed"
                     } : {background: "rgb(147,209,255)"}}
                     onClick={() => {
                         this.switchPage(selectedPage + 1, totalPages)
                     }}>
                    <RightOutlined/>
                </div>
            </div>
        )
    }

    switchPage = (toPage, totalPages) => {
        if (toPage < 1 || toPage > totalPages) {
            return;
        }
        this.handlePageSwitch(toPage)
    }

    handlePageSwitch = (i) => {
        this.loadData(i);
        this.setState({selectedPage: i});
    }

    loadPageBt = (selectedPage, totalPages) => {
        let before = [];
        let now = [];
        let after = [];
        for (let i = 1; i <= totalPages; i++) {
            if (i < selectedPage - 2) {
                if (before.length < 2) {
                    before.push(<div className={"check-move-bt"} style={selectedPage === i ? selectStyle : {}}
                                     onClick={() => {
                                         this.handlePageSwitch(i)
                                     }}>{i}</div>)
                } else {
                    before[1] = <div className={"check-move-bt"}
                                     style={hiddenStyle}>{
                        <DashOutlined/>}</div>
                }
            } else if (i > selectedPage + 2) {
                if (after.length < 2) {
                    after.push(<div className={"check-move-bt"} style={selectedPage === i ? selectStyle : {}}
                                    onClick={() => {
                                        this.handlePageSwitch(i)
                                    }}>{i}</div>)
                } else {
                    after[0] = <div className={"check-move-bt"}
                                    style={hiddenStyle}>{
                        <DashOutlined/>}</div>
                    after[1] = <div className={"check-move-bt"} style={selectedPage === totalPages ? selectStyle : {}}
                                    onClick={() => {
                                        this.handlePageSwitch(totalPages)
                                    }}>{i}</div>
                }
            } else {
                now.push(<div className={"check-move-bt"} style={selectedPage === i ? selectStyle : {}} onClick={() => {
                    this.handlePageSwitch(i)
                }}>{i}</div>)
            }
        }
        // console.log(before)
        // console.log(now)
        // console.log(after)
        return before.concat(now, after);
    }

    setInputPage = (e) => {
        let val = e.target.value;
        if (validNum.test(val)) {
            this.setState({
                inputPage: val,
            })
        }
    }

    toInputPage = (totalPages) => {
        let to = this.state.inputPage;
        if (to < 1 || to > totalPages) {
            return;
        }
        this.handlePageSwitch(parseInt(to))
    }

    loadInputPageBt = (totalPages) => {
        return (
            <div className={"num-input-bt-outer"}>
                <input placeholder={"页码"} className={"num-input-win"} maxLength={4} onChange={(e) => {
                    this.setInputPage(e)
                }}/>
                <button className={"num-input-win-go"} onClick={() => {
                    this.toInputPage(totalPages)
                }}>{"GO"}</button>
            </div>
        )
    }

    render() {
        let setting = this.props.loadSetting;
        let totalPages = this.state.totalPages;
        return (
            <div className={setting.classes.outer}>
                {this.loadNumBtList(totalPages)}
                {this.loadInputPageBt(totalPages)}
            </div>
        )
    }
}

export default Partitioner;