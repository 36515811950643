import React, {Component} from "react";
import "./center.css";
import GeoFilter from "../../tool/filter/GeoFilter";

class SubscriptionGeo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openGeo: false,
            showCity: -1,
        }
    }

    setOptions = (options) => {
        this.props.parent.setState({
            // options: Object.assign({}, options)
        })
    }

    render() {
        let options = this.props.options;
        let map = this.props.map;
        let keys = this.props.keys;

        return (
            <GeoFilter options={options} parent={this} key={keys}
                       areaClass={"filter-area-sub"} titleClass={"filter-area-geo-title-sub"}
                       map={map} isIcon={false} isSub={true}
                       mainType={null} header={null}/>
        );
    }
}

export default SubscriptionGeo;