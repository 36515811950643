import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./page/Home";
import OutcomePage from "./page/outcome/OutcomePage";
import {
    DemandRoot,
    ExpertRoot,
    HomeRoot,
    LoginRoot,
    NewsRoot,
    OutcomeRoot,
    PolicyRoot,
    RegisterRoot,
    AboutRoot,
    SolutionRoot,
    CenterRoot,
    Wechat, AliRoot
} from "./tool/Const";
import ExpertPage from "./page/expert/ExpertPage";
import DemandPage from "./page/demand/DemandPage";
import Login from "./page/Login";
import Register from "./page/Register";
import PolicyPage from "./page/policy/PolicyPage";
import NewsPage from "./page/news/NewsPage";
import AboutPage from "./page/about/AboutPage";
import SolutionPage from "./page/solution/SolutionPage";
import CenterPage from "./page/center/CenterPage";
import LoginWechat from "./page/LoginWechat";
import OutcomeScript from "./page/outcome/OutcomeScript";
import ExpertScript from "./page/expert/ExpertScript";
import DemandScript from "./page/demand/DemandScript";
import PolicyScript from "./page/policy/PolicyScript";
import NewsScript from "./page/news/NewsScript";
import AliPlugin from "./page/AliPlugin";
import ErrorPage from "./page/Error/ErrorPage";

class PageRoutes extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    {/*跳转时不重复显示主页exact*/}
                    <Route exact={true} path={HomeRoot} component={Home}/>
                    <Route exact={true} path={LoginRoot} component={Login}/>
                    <Route exact={true} path={RegisterRoot} component={Register}/>
                    <Route exact={true} path={OutcomeRoot.path} component={OutcomePage}/>
                    <Route exact={true} path={OutcomeRoot.path + OutcomeRoot.params} component={OutcomeScript}/>  {/*科研成果详情页面*/}
                    <Route exact={true} path={DemandRoot.path} component={DemandPage}/>
                    <Route exact={true} path={DemandRoot.path + DemandRoot.params} component={DemandScript}/>  {/*技术需求详情页面*/}
                    <Route exact={true} path={ExpertRoot.path} component={ExpertPage}/>
                    <Route exact={true} path={ExpertRoot.path + ExpertRoot.params} component={ExpertScript}/>  {/*专家人才详情页面*/}
                    <Route exact={true} path={SolutionRoot} component={SolutionPage}/>
                    <Route exact={true} path={PolicyRoot.path} component={PolicyPage}/>
                    <Route exact={true} path={PolicyRoot.path + PolicyRoot.params} component={PolicyScript}/>  {/*科技政策详情页面*/}
                    <Route exact={true} path={NewsRoot.path} component={NewsPage}/>
                    <Route exact={true} path={NewsRoot.path + NewsRoot.params} component={NewsScript}/>  {/*科技资讯详情页面*/}
                    <Route exact={true} path={AboutRoot} component={AboutPage}/>
                    <Route exact={true} path={CenterRoot} component={CenterPage}/>
                    <Route exact={true} path={Wechat} component={LoginWechat}/>
                    <Route exact={true} path={AliRoot} component={AliPlugin}/>
                    <Route path={"*"} component={ErrorPage}/>
                </Switch>
            </Router>
        );
    }
}

export default PageRoutes;
