import React, {Component} from 'react';
import "./solution.css";
import "../page.css";
import Header from "../Header";
import Footer from "../Footer";
import {
    advList,
    dataIndexE,
    ExpertRoot,
    getAdvData,
    LoadAds,
    LoadingExpertCopAds,
    LoadingOutcomeCopAds,
    LoadingPolicyCopAds,
    OutcomeRoot,
    PolicyRoot
} from "../../tool/Const";
import BK from "../pic/stbk.jpg"
import SLL from "../pic/SLL.png";
import SLW from "../pic/SLW.png";
import IMG1 from "../../assets/solution/icon/xiaoqichanxuehezuo.png";
import IMG2 from "../../assets/solution/icon/gongjianqiyejishu.png";
import IMG3 from "../../assets/solution/icon/gongjianlianheshiyanshi.png";
import IMG4 from "../../assets/solution/icon/zhuanjingtexinzixunshenbao.png";
import IMG5 from "../../assets/solution/icon/kejijiangxiangshenbao.png";
import IMG6 from "../../assets/solution/icon/yanfaqingbaozhinan.png";
import IMG7 from "../../assets/solution/icon/zhishichanquan.png";
import IMG8 from "../../assets/solution/icon/zhuanjiayunhuiyi.png";
import IMG9 from "../../assets/solution/icon/zhuanjiamianduimian.png";
import IMG10 from "../../assets/solution/icon/quyujishufuwupingtai.png";
import IMG11 from "../../assets/solution/icon/kechuangfeidiyunying.png";
import IMG12 from "../../assets/solution/icon/kechuangchuangxinhuodong.png";
import IMG13 from "../../assets/solution/icon/zhengce.png";
import IMG14 from "../../assets/solution/icon/chanyeyuanquyunying.png";
import IMG15 from "../../assets/solution/icon/kejifuhua.png";
import IMG16 from "../../assets/solution/icon/zhongchuanghua.png";
import IMG17 from "../../assets/solution/icon/jishuzhuanyi.png";
import IMG18 from "../../assets/solution/icon/xinxingyanfajigou.png";
import IMG19 from "../../assets/solution/icon/kejigongzuozhan.png";
import IMG20 from "../../assets/solution/icon/kejichengguopinggu.png";
import IMG21 from "../../assets/solution/icon/jianceyurenzhengfuwu.png";
import IMG22 from "../../assets/solution/icon/xiaowaishixunjidi.png";
import IMG23 from "../../assets/solution/icon/jishuzhinangtuan.png";
import IMG24 from "../../assets/solution/icon/keyanrencaizhaopin.png";
import IMG25 from "../../assets/solution/icon/chuangxinrencaigongzuozhan.png";
import IMG26 from "../../assets/solution/icon/jishujingjirenpaixun.png";
import IMG27 from "../../assets/solution/icon/qiyejishurenyuanpeixun.png";
import IMG28 from "../../assets/solution/icon/gaocengcirencaiyinjin.png";

const dataOne = [
    {
        title: '校企产学研合作',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG1,
    },
    {
        title: '共建企业技术中心',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG2,
    },
    {
        title: '共建联合实验室',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG3,
    },
    {
        title: '专精特新咨询申报',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG4,
    },
    {
        title: '科技奖项申报',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG5,
    },
    {
        title: '研发情报指南',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG6,
    },
    {
        title: '知识产权分析布局',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG7,
    },
    {
        title: '专家云会议',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG8,
    },
    {
        title: '专家面对面',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG9,
    },
];

const dataTwo = [
    {
        title: '区域技术服务平台',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG10,
    },
    {
        title: '科创飞地运营',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG11,
    },
    {
        title: '科技创新活动',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG12,
    },
    {
        title: '政策解读与培训',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG13,
    },
    {
        title: '产地园区运营',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG14,
    },
    {
        title: '科技孵化器运营',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG15,
    },
    {
        title: '众创空间运营',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG16,
    },
];

const dataThree = [
    {
        title: '技术转移分中心',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG17,
    },
    {
        title: '新型研发机构',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG18,
    },
    {
        title: '科技工作站',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG19,
    },
    {
        title: '科技成果评估',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG20,
    },
    {
        title: '检测与认证服务',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG21,
    },
    {
        title: '校外实训基地',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG22,
    },
];

const dataFour = [
    {
        title: '技术智囊团',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG23,
    },
    {
        title: '科研人才招聘',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG24,
    },
    {
        title: '创新人才工作站',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG25,
    },
    {
        title: '技术经纪人培训',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG26,
    },
    {
        title: '企业技术人员培训',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG27,
    },
    {
        title: '高层次人才引进',
        des: '企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。',
        img: IMG28,
    },
]

const dataAll = dataOne.concat(dataTwo.concat(dataThree.concat(dataFour)))

const adClasses = {
    adOuter: "solution-ct-ad",
    adOutcome: "outcome-ct-ad-one",
    adExpert: "solution-ct-ad-expert",
    adPolicy: "outcome-ct-ad-two",
}

class SolutionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHeightM: "",
            isHeightL: "",
            type: 0,
            list: dataAll,
            adList: [],
            advMap: new Map(),  // adv数据
            isLoadAdvMap: false,
        }
    }

    switchSolutions = (type, list) => {
        this.setState({
            type: type,
            list: list,
        })
        // switch (type) {
        //     case 0: {
        //         this.setState({
        //             type: type,
        //             list: dataAll,
        //         })
        //         return;
        //     }
        //     case 1: {
        //         this.setState({
        //             type: type,
        //             list: dataOne,
        //         })
        //         return;
        //     }
        //     case 2: {
        //         this.setState({
        //             type: type,
        //             list: dataTwo,
        //         })
        //         return;
        //     }
        //     case 3: {
        //         this.setState({
        //             type: type,
        //             list: dataThree,
        //         })
        //         return;
        //     }
        //     case 4: {
        //         this.setState({
        //             type: type,
        //             list: dataFour,
        //         })
        //         return;
        //     }
        //     default: {
        //         this.setState({
        //             type: type,
        //             list: dataAll,
        //         })
        //         return;
        //     }
        // }
    }

    loadingSolutions = (list) => {
        let solutions = [];
        list.forEach((item, index) => {
            solutions.push(
                <div className={"solution-data-item"} key={index}>
                    <div className={"solution-data-item-tag-layout"}>
                        <div className={"solution-data-item-tag-layout-img"}>
                            <img src={item.img} alt={""} className={"solution-data-item-pic"}/>
                        </div>
                    </div>
                    <div className={"solution-data-item-ct-layout"}>
                        <div className={"solution-data-item-ct"}>
                            <div className={"solution-data-item-title"}>
                                {item.title}
                            </div>
                            <div className={"solution-data-item-des"}>
                                {item.des}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return solutions;
    }

    calcLeftHeight = () => {
        let advMap = this.state.advMap
        // 判断解决方案高度
        let div = document.getElementById("solution-ct-layout");
        let leftHeight = div.clientHeight;
        console.log(leftHeight);
        if (leftHeight <= 1100) {
            this.setState({
                adList: [
                    {
                        adClass: adClasses.adOutcome,
                        adTitle: "精选科技成果",
                        adCt: LoadingOutcomeCopAds(advMap.get(dataIndexE.outcome)),
                        adUrl: OutcomeRoot.path
                    },
                    {
                        adClass: adClasses.adExpert,
                        adTitle: "推荐专家",
                        adCt: LoadingExpertCopAds(advMap.get(dataIndexE.expert)),
                        adUrl: ExpertRoot.path
                    },
                ]
            })
        } else {
            this.setState({
                adList: [
                    {
                        adClass: adClasses.adOutcome,
                        adTitle: "精选科技成果",
                        adCt: LoadingOutcomeCopAds(advMap.get(dataIndexE.outcome)),
                        adUrl: OutcomeRoot.path
                    },
                    {
                        adClass: adClasses.adExpert,
                        adTitle: "推荐专家",
                        adCt: LoadingExpertCopAds(advMap.get(dataIndexE.expert)),
                        adUrl: ExpertRoot.path
                    },
                    {
                        adClass: adClasses.adPolicy,
                        adTitle: "热门政策解读",
                        adCt: LoadingPolicyCopAds(advMap.get(dataIndexE.policy)),
                        adUrl: PolicyRoot.path
                    },
                ]
            })
        }
    }

    componentDidMount() {
        getAdvData(advList.solution, this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.type !== prevState.type || this.state.isLoadAdvMap !== prevState.isLoadAdvMap) {
            this.calcLeftHeight()
        }
    }

    render() {
        let type = this.state.type;
        let list = this.state.list;

        let isHeightM = this.state.isHeightM;
        let isHeightL = this.state.isHeightL;

        let adList = this.state.adList;

        let advMap = this.state.advMap;
        console.log("advMap: ", advMap);

        return (
            <div className={"home-ct"}>
                <Header/>
                <div className={"solution-banner-container"}>
                    <div className={"solution-banner-outer"}>
                        <img src={SLL} className={"solution-banner-img"} alt={""}/>
                        <img src={SLW} className={"solution-banner-img"} alt={""}/>
                    </div>
                </div>
                <div className={"solution-ct-outer"}>
                    <div className={"solution-ct-inner"}>
                        <div className={"solution-inner-left"}>
                            <div className={"solution-ct-menu"}>
                                <div className={type === 0 ? "solution-ct-menu-ele-selected" : "solution-ct-menu-ele"}
                                     onClick={() => {
                                         this.switchSolutions(0, dataAll)
                                     }}
                                >
                                    {"全部"}
                                </div>
                                <div className={type === 1 ? "solution-ct-menu-ele-selected" : "solution-ct-menu-ele"}
                                     onClick={() => {
                                         this.switchSolutions(1, dataOne)
                                     }}
                                >
                                    {"企业技术创新"}
                                </div>
                                <div className={type === 2 ? "solution-ct-menu-ele-selected" : "solution-ct-menu-ele"}
                                     onClick={() => {
                                         this.switchSolutions(2, dataTwo)
                                     }}
                                >
                                    {"政府协同创新"}
                                </div>
                                <div className={type === 3 ? "solution-ct-menu-ele-selected" : "solution-ct-menu-ele"}
                                     onClick={() => {
                                         this.switchSolutions(3, dataThree)
                                     }}
                                >
                                    {"院校科研创新"}
                                </div>
                                <div className={type === 4 ? "solution-ct-menu-ele-selected" : "solution-ct-menu-ele"}
                                     onClick={() => {
                                         this.switchSolutions(4, dataFour)
                                     }}
                                >
                                    {"科技人才创新"}
                                </div>
                            </div>
                            <div className={"solution-ct-layout"} id={"solution-ct-layout"}>
                                {this.loadingSolutions(list)}
                            </div>
                        </div>
                        <div className={"solution-inner-right"}>
                            <div className={"solution-inner-right-inner"}>
                                {
                                    type === 0 &&
                                    <div className={"solution-adv-img"}>
                                        <img src={BK} alt={""} className={"solution-adv-img-pic"}/>
                                    </div>
                                }
                                <div className={"solution-adv-ct"}>
                                    {LoadAds({}, "solution-ct-ad", adList)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default SolutionPage;
