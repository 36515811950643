import React, {Fragment} from "react";
import "./../page/page.css";
import Bk from "../page/pic/func.png";
import {
    AlipayCircleFilled,
    CaretRightOutlined,
    EyeOutlined,
    LogoutOutlined,
    MessageOutlined,
    MobileOutlined,
    StarFilled,
    StarOutlined,
    WechatFilled
} from "@ant-design/icons";
import {connectionError, fail, failReturn, findMsgByError, success} from "./http/ErrorMgr";
import {
    AliId, AliOAuthUrl,
    CheckUnlockItemByUserUrl,
    ClickMonitorUrl,
    CloseUserItemByIdUrl,
    data,
    error,
    FavorOpUrl,
    FindFavorDataByTypeUrl,
    FindItemDetailByIdUrl,
    FindUserAcceptItemsUrl,
    genPojo,
    GetAdvDataUrl,
    GetPushDataUrl, Header,
    InitOrderUrl,
    loginOutErrOr,
    LoginUrl,
    LogoutUrl,
    sendGetBack,
    sendGetRequest,
    SendPhoneValidUrl,
    sendPostRequest,
    SrcHeader,
    TempId
} from "./http/Request";
import moment from "moment";
import {Checkbox} from "antd";
import EXP_SRC_SAMPLE from "../assets/img/exp_src_sample.jpg";
import QR_CODE from "../assets/img/qr_code.png";
import MAIN from "../page/pic/maintenance.jpeg";
import LOCK_PART from "../assets/img/lock-part.png";
import PayPanel from "./payPanel/PayPanel";
import LOGO from "../assets/icon/logo-v.svg";
import IMG1 from "../assets/solution/icon/xiaoqichanxuehezuo.png";
import IMG2 from "../assets/solution/icon/gongjianqiyejishu.png";
import IMG3 from "../assets/solution/icon/gongjianlianheshiyanshi.png";
import IMG4 from "../assets/solution/icon/zhuanjingtexinzixunshenbao.png";
import IMG5 from "../assets/solution/icon/kejijiangxiangshenbao.png";
import IMG6 from "../assets/solution/icon/yanfaqingbaozhinan.png";
import IMG7 from "../assets/solution/icon/zhishichanquan.png";
import IMG8 from "../assets/solution/icon/zhuanjiayunhuiyi.png";
import IMG9 from "../assets/solution/icon/zhuanjiamianduimian.png";
import IMG10 from "../assets/solution/icon/quyujishufuwupingtai.png";
import IMG11 from "../assets/solution/icon/kechuangfeidiyunying.png";
import IMG12 from "../assets/solution/icon/kechuangchuangxinhuodong.png";
import IMG13 from "../assets/solution/icon/zhengce.png";
import IMG14 from "../assets/solution/icon/chanyeyuanquyunying.png";
import IMG15 from "../assets/solution/icon/kejifuhua.png";
import IMG16 from "../assets/solution/icon/zhongchuanghua.png";
import IMG17 from "../assets/solution/icon/jishuzhuanyi.png";
import IMG18 from "../assets/solution/icon/xinxingyanfajigou.png";
import IMG19 from "../assets/solution/icon/kejigongzuozhan.png";
import IMG20 from "../assets/solution/icon/kejichengguopinggu.png";
import IMG21 from "../assets/solution/icon/jianceyurenzhengfuwu.png";
import IMG22 from "../assets/solution/icon/xiaowaishixunjidi.png";
import IMG23 from "../assets/solution/icon/jishuzhinangtuan.png";
import IMG24 from "../assets/solution/icon/keyanrencaizhaopin.png";
import IMG25 from "../assets/solution/icon/chuangxinrencaigongzuozhan.png";
import IMG26 from "../assets/solution/icon/jishujingjirenpaixun.png";
import IMG27 from "../assets/solution/icon/qiyejishurenyuanpeixun.png";
import IMG28 from "../assets/solution/icon/gaocengcirencaiyinjin.png";

const SolutionRoot = "/solution";

const AboutRoot = "/about";

const OutcomeRoot = {
    path: "/outcome",
    params: '/:outcomeId',
};

const DemandRoot = {
    path: "/demand",
    params: '/:demandId',
};

const ExpertRoot = {
    path: "/expert",
    params: '/:expertId',
};

const PolicyRoot = {
    path: "/policy",
    params: '/:policyId',
};

const CenterRoot = "/center";

const RegisterRoot = "/register";

const LoginRoot = "/login";

const NewsRoot = {
    path: "/news",
    params: '/:newsId',
};

const HomeRoot = "/";

const Wechat = "/we";

const AliRoot = "/ali";

const MouseHover = (index, hoverI, parent) => {
    if (index === -1) {
        clearTimeout(parent.timeout);
        parent.timeout = null;
        if (index !== hoverI) {
            parent.setState({
                hoverI: index,
            })
        }
        return;
    }

    parent.timeout = setTimeout(() => {
        if (index !== hoverI) {
            parent.setState({
                hoverI: index,
            })
        }
    }, 200)
}

const AdCardTitle = (title, url) => {
    return (
        <div className={"ad-card-title"}>
            <div className={"ad-card-title-des"}>
                <div className={"ad-card-title-ct"}>{title}</div>
            </div>
            <div className={"ad-card-title-tag"}
                 onClick={() => {
                     window.open(url, "_blank")
                 }}
            >
                <LogoutOutlined/>
            </div>
        </div>
    )
}

const handleSendButton = (period, canSend, phone, reg, parent) => {
    if (canSend) {
        if (!phone || !InputTest.userPhone.test.test(phone)) {
            fail("请先输入正确手机号码！")
            return;
        }
        sendGetRequest(SendPhoneValidUrl, {phone: phone, tempId: TempId, reg: reg}, true).then(res => {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    handleCountDown(period, parent);
                    success("发送成功，请查收！")
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }
}

const LoadAds = (style, adOuter, adList) => {
    let ads = [];
    adList.forEach((item, index) => {
        ads.push(
            <div className={item.adClass} key={index}>
                {AdCardTitle(item.adTitle, item.adUrl)}
                {item.adCt}
            </div>
        )
    })
    return (
        <div className={adOuter} style={style} id={"ad"}>
            {ads}
        </div>
    )
}

const handleCountDown = (seconds, dom) => {
    let second = seconds;
    const countDown = () => {
        if (second > 0) {
            second--;
            dom.setState({
                secondI: second
            })
        }
        if (second === 0) {
            second = seconds;
            dom.setState({
                secondI: second
            })
            return;
        }
        setTimeout(countDown, 1000);
    };
    setTimeout(countDown, 1000);
};

const solutionTechSpeList = [
    {
        id: 1,
        title: "技术转移分中心",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG17,
    },
    {
        id: 2,
        title: "新型研发机构",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG18,
    },
    {
        id: 3,
        title: "科技工作站",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG19,
    },
    {
        id: 4,
        title: "科技成果评估",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG20,
    },
    {
        id: 5,
        title: "检测与认证服务",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG21,

    },
    // {
    //     id: 6,
    //     title: "校外实训基地",
    //     des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
    //     img: IMG22,
    // },
]

const solutionTechPerList = [
    {
        id: 1,
        title: "技术智囊团",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG23,
    },
    {
        id: 2,
        title: "科研人才招聘",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG24,
    },
    {
        id: 3,
        title: "创新人才工作站",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG25,
    },
    {
        id: 4,
        title: "技术经纪人培训",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG26,
    },
    {
        id: 5,
        title: "企业技术人员培训",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG27,
    },
    // {
    //     id: 6,
    //     title: "高层次人才引进",
    //     des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
    //     img: IMG28,
    // },
]

const solutionTechCopList = [
    {
        id: 1,
        title: "校企产学研合作",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG1,
    },
    {
        id: 2,
        title: "共建企业技术中心",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG2,
    },
    {
        id: 3,
        title: "共建联合实验室",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG3,
    },
    {
        id: 4,
        title: "专精特新咨询申报",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG4,
    },
    {
        id: 5,
        title: "科技奖项申报",
        des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
        img: IMG5,
    },
    // {
    //     id: 6,
    //     title: "研发情报指南",
    //     des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
    //     img: IMG6,
    // },
    // {
    //     id: 7,
    //     title: "知识产权分析布局",
    //     des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
    //     img: IMG7,
    // },
    // {
    //     id: 8,
    //     title: "专家云会议",
    //     des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
    //     img: IMG8,
    // },
    // {
    //     id: 9,
    //     title: "专家面对面",
    //     des: "企业在日常经营中遇到技术难题，自有技术知识力量无法解决解答。企业需要寻求专业的专家（团队）对该技术的可行性进行论证、技术预测、专题技术调查、分析评价时，可以通过本平台提交专家邀约申请。",
    //     img: IMG9,
    // },
]

const LoadingOutcomeCopAds = (dataList) => {
    return (
        <div className={"ad-ct"}>
            {outcomeAdLine(dataList)}
        </div>
    )
}

const outcomeAdLine = (dataList) => {
    let userInfo = UserInfoStorage.get("userInfo");
    let list = [];
    if (dataList.length > 0) {
        dataList.forEach((item, index) => {
            // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
            // let picDir = require.context("../page/pic/", true, /\.(png|jpg)$/);
            // let imgUrl = picDir("./" + item["outcomeBanner"]);
            let imgUrl = SrcHeader + item["outcomeId"] + "/" + item["outcomeBanner"];
            list.push(
                <div className={"outcome-ad-wrap"} id={index}>
                    <div className={"outcome-ad-line"}
                         onClick={() => {
                             handleClickMonitor(userInfo, item["outcomeId"], 2, 1, OutcomeRoot.path + "/" + item["outcomeId"])
                         }}
                    >
                        <div className={"outcome-ad-line-sign"}>
                            <img className={"img-style cover"} src={imgUrl} alt={""}/>
                        </div>
                        <div className={"outcome-ad-line-ct"}>
                            <div className={"outcome-ad-line-name"}>
                                {item["outcomeName"]}
                            </div>
                            <div className={"outcome-ad-line-com"}>
                                <div className={"outcome-ad-line-type"}>
                                    {handleOutcomeType(item["outcomeType"])}
                                </div>
                                <div className={"outcome-ad-line-stage"}>
                                    {item["outcomeStage"]}
                                </div>
                            </div>
                            <div className={"outcome-ad-line-areas"}>
                                {item["outcomeAreas"]}
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
    } else {
        list.push(
            <div className={"maintenance-page"}>
                <div>
                    <img className={"img-style contain"} src={MAIN} alt={""}/>
                </div>
                <div>
                    {"网络服务异常，页面走丢了，请稍后重试"}
                </div>
            </div>
        )
    }
    return list;
}

const LoadingExpertCopAds = (dataList) => {
    return (
        <div className={"ad-ct"}>
            {expertAdLine(dataList)}
        </div>
    )
}

const expertAdLine = (dataList) => {
    let userInfo = UserInfoStorage.get("userInfo");
    let list = [];
    if (dataList.length > 0) {
        dataList.forEach((item, index) => {
            // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
            // let picDir = require.context("../page/pic/", true, /\.(png|jpg)$/);
            // let imgUrl = picDir("./" + item["expertBanner"]);
            let imgUrl = SrcHeader + item["expertId"] + "/" + item["expertBanner"];
            list.push(
                <div className={"expert-ad-wrap"} id={index}>
                    <div className={"expert-ad-line"}
                         onClick={() => {
                             handleClickMonitor(userInfo, item["expertId"], 2, 1, ExpertRoot.path + "/" + item["expertId"])
                         }}
                    >
                        <div className={"expert-ad-line-sign"}>
                            <img className={"img-style cover"} src={imgUrl} alt={"avatar"}/>
                        </div>
                        <div className={"expert-ad-line-ct"}>
                            <div className={"expert-ad-line-top"}>
                                <div className={"expert-ad-line-name"}>
                                    {item["expertName"]}
                                </div>
                                <div className={"expert-ad-line-hor"}>
                                    {showSemData(item["expertHor"], 0)}
                                </div>
                            </div>
                            <div className={"expert-ad-line-target"}>
                                {item["expertAreas"]}
                            </div>
                            <div className={"expert-ad-line-des"}>
                                {item["expertTarget"]}
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
    } else {
        list.push(
            <div className={"maintenance-page"}>
                <div>
                    <img className={"img-style contain"} src={MAIN} alt={""}/>
                </div>
                <div>
                    {"网络服务异常，页面走丢了，请稍后重试"}
                </div>
            </div>
        )
    }
    return list;
}

const LoadingDemandCopAds = (dataList) => {
    return (
        <div className={"ad-ct"}>
            {demandAdLine(dataList)}
        </div>
    )
}

const demandAdLine = (dataList) => {
    let userInfo = UserInfoStorage.get("userInfo");
    let list = [];
    if (dataList.length > 0) {
        dataList.forEach((item, index) => {
            // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
            // let picDir = require.context("../page/pic/", true, /\.(png|jpg)$/);
            // let imgUrl = picDir("./" + item["demandBanner"]);
            let imgUrl = SrcHeader + item["demandId"] + "/" + item["demandBanner"];
            list.push(
                <div className={"demand-ad-wrap"} id={index}>
                    <div className={"demand-ad-line"}
                         onClick={() => {
                             handleClickMonitor(userInfo, item["demandId"], 2, 1, DemandRoot.path + "/" + item["demandId"])
                         }}
                    >
                        <div className={"demand-ad-line-sign"}>
                            <img className={"img-style cover"} src={imgUrl} alt={""}/>
                        </div>
                        <div className={"demand-ad-line-ct"}>
                            <div className={"demand-ad-line-name"}>
                                {item["demandName"]}
                            </div>
                            <div className={"demand-ad-line-com"}>
                                <div className={"demand-ad-line-type"}>
                                    {handleOutcomeType(item["demandType"])}
                                </div>
                                <div className={"demand-ad-line-price"}>
                                    {item["demandPrice"] + "元"}
                                </div>
                            </div>
                            <div className={"demand-ad-line-areas"}>
                                {item["demandAreas"]}
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
    } else {
        list.push(
            <div className={"maintenance-page"}>
                <div>
                    <img className={"img-style contain"} src={MAIN} alt={""}/>
                </div>
                <div>
                    {"网络服务异常，页面走丢了，请稍后重试"}
                </div>
            </div>
        )
    }
    return list;
}

const LoadingSolutionCopAds = (dataList) => {
    return (
        <div className={"ad-ct"}>
            {sltAdLine(dataList)}
        </div>
    )
}

const sltAdLine = (dataList) => {
    let list = [];
    if (dataList.length > 0) {
        dataList.forEach((item, index) => {
            list.push(
                <div className={"solution-ad-wrap"} id={index}
                     style={{height: dataList.length === 5 ? "calc(100% / 5)" : "calc(100% / 7)"}}
                >
                    <div className={"solution-ad-line"}>
                        <div className={"solution-ad-line-sign"}>
                            <img className={"img-style contain"} src={item.img} alt={""}/>
                        </div>
                        <div className={"solution-ad-line-ct"}>
                            <div className={"solution-ad-line-name"}>
                                {item.title}
                            </div>
                            <div className={"solution-ad-line-des"}>
                                {item.des}
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
    } else {
        list.push(
            <div className={"maintenance-page"}>
                <div>
                    <img className={"img-style contain"} src={MAIN} alt={""}/>
                </div>
                <div>
                    {"网络服务异常，页面走丢了，请稍后重试"}
                </div>
            </div>
        )
    }
    return list;
}

const LoadingPolicyCopAds = (dataList) => {
    return (
        <div className={"ad-ct"}>
            {policyAdLine(dataList)}
        </div>
    )
}

const policyAdLine = (dataList) => {
    let userInfo = UserInfoStorage.get("userInfo");
    let list = [];
    if (dataList.length > 0) {
        dataList.forEach((item, index) => {
            list.push(
                <div className={"policy-ad-wrap"} key={index}>
                    <div className={"policy-ad-line"}
                         onClick={() => {
                             handleClickMonitor(userInfo, item["policyId"], 2, 1, PolicyRoot.path + "/" + item["policyId"])
                         }}
                    >
                        <div className={"policy-ad-line-sign"}>
                            {PriAbbrJson[item["policyPri"]]}
                        </div>
                        <div className={"policy-ad-line-ct"}>
                            <div className={"policy-ad-line-name"}>
                                {item["policyName"]}
                            </div>
                            <div className={"policy-ad-line-date"}>
                                {item["policyDate"]}
                            </div>
                        </div>
                    </div>
                </div>

            )
        })
    } else {
        list.push(
            <div className={"maintenance-page"}>
                <div>
                    <img className={"img-style contain"} src={MAIN} alt={""}/>
                </div>
                <div>
                    {"网络服务异常，页面走丢了，请稍后重试"}
                </div>
            </div>
        )
    }
    return list;
}

const SaveBt = (item, index, hoverI, parent, userInfo, favorList, options) => {
    return (
        <div onMouseEnter={() => {MouseHover(index, hoverI, parent)}}
             onMouseLeave={() => {MouseHover(-1, hoverI, parent)}}
        >
            {
                // index === hoverI
                favorList.indexOf(item[handleIdByIndex(options.index)]) > -1
                    ?
                    <StarFilled style={{color: "rgb(255,228,103)", cursor: "pointer", fontSize: "20px"}}
                                onClick={() => {
                                    // window.location.href = "/experts"
                                    favorOpFun(item, userInfo, options.index, false)
                                }}
                    />
                    :
                    <StarOutlined id={index}
                                  style={{fontSize: "20px", color: "rgb(255,228,103)", cursor: "pointer"}}
                                  onClick={() => {
                                      // window.location.href = "/experts"
                                      favorOpFun(item, userInfo, options.index, true)
                                  }}
                    />}
        </div>
    )
}

const FilterHeaderCt = {
    outcome: {
        searchTile: "探索科技成果",
        searchDes: "请输入技术关键词",
        subOne: "发布科技成果",
        subTwo: "企业技术配套",
    },
    expert: {
        searchTile: "寻找科研人才",
        searchDes: "请输入专家关键词",
        subOne: "预约专家咨询",
        subTwo: "建立技术团队",
    },
    demand: {
        searchTile: "技术需求大厅",
        searchDes: "请输入需求关键词",
        subOne: "发布技术需求",
        subTwo: "承接技术需求",
    },
    policy: {
        searchTile: "跟踪最新科技政策动态",
        searchDes: "请输入政策关键词",
        subOne: null,
        subTwo: null,
    }
}

const AdComp = () => {
    return (
        <div className={"func-ad"}>
            <img className={"func-ad-img"} src={Bk} alt={""}/>
            <div className={"func-ad-ct"}>
                <div className={"func-ad-logo-level"}>
                    <div className={"func-logo"}>
                        <img className={"img-style contain"} src={LOGO} alt={"paton"}/>
                    </div>
                </div>
                <div className={"func-ad-title-level"}>
                    <span>{"长三角一体化科创服务平台"}</span>
                </div>
                <div className={"func-ad-des-level"}>
                    <span>{"湃通科技是引领全国科学技术走向企业的先驱者，我们立志于帮助企业推动技术成果转化，构建全方位科学技术生态链，助力企业落实技术转型。"}</span>
                </div>
            </div>
        </div>
    )
}

const validNum = /(^-?[1-9]([0-9]*)$|^-?[0-9]$)/;

const handleAdRightWinSizeInit = (parent) => {
    // let width = document.getElementById(pageOuterId).clientWidth;
    let pWidth = document.documentElement.clientWidth;

    console.log(pWidth);

    let calWidth = pWidth * 0.8;

    let width;

    if (calWidth < 1200) {
        width = 1200;
    } else if (calWidth > 1800) {
        width = 1800;
    } else {
        width = calWidth;
    }

    width = parseInt(width)

    console.log(width)

    let smallP = pWidth < width;
    let gap = 0;
    if (smallP) {
        gap = width - pWidth;
    }
    parent.setState({
        adLeft: smallP ? "100%" : width + "".split(".")[0] + "px",
        gap: gap,
    })
}

const handleAdRightWinSizeChange = (pageOuterId, parent) => {
    let width = document.getElementById(pageOuterId).clientWidth;
    let pWidth = document.documentElement.clientWidth;

    let smallP = pWidth < width;
    let gap = 0;
    if (smallP) {
        gap = width - pWidth;
    }
    parent.setState({
        adLeft: smallP ? "100%" : width + "".split(".")[0] + "px",
        gap: gap,
    })
}

const handlePageScrollMove = (parent, tagId, distanceAboveAd, distanceBtAd, distanceContact, pageId, pagMin) => {
    // distanceAboveAd 页面往上移动多少，右边ad fixed (header + filter + padding + adHeight + contact - 60)
    // distanceBtAd (header + filter + padding + contact - 60)
    // distanceContact (header + filter + padding + contact - 60)
    if (document.getElementById(pageId).clientHeight > pagMin) {
        let distance = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
        let distanceW = Math.max(document.body.scrollLeft, document.documentElement.scrollLeft);
        let fixed = parent.state.fixed;
        let fixedAd = parent.state.fixedAd;
        let adBt = parent.state.adBt;
        let adjustGap = parent.state.adjustGap;
        let findH = distance + window.innerHeight;
        let size = parent.state.openSize;
        let tagH = tagId ? document.getElementById(tagId).clientHeight - 60 : 0;
        let tagD = size + tagH;
        let reachFixed = findH >= distanceAboveAd + tagD;
        let isAdBt = findH >= distanceBtAd + tagD;
        let fixedAdL = reachFixed && !isAdBt;
        let adBtL = isAdBt;
        let adjustGapL = isAdBt ? 0 : distanceW;
        let fixedL = findH < distanceContact + tagD;
        if (fixed !== fixedL || adBt !== adBtL || fixedAd !== fixedAdL || adjustGap !== adjustGapL) {
            parent.setState({
                fixed: fixedL,
                fixedAd: fixedAdL,
                adBt: adBtL,
                adjustGap: adjustGapL,
            })
        }
    }
}

const InitTags = (options, initTag, isClean) => {
    options['from'] = initTag;
    options['level'] = initTag;
    options['area'] = initTag;
    options['method'] = initTag;
    options['title'] = initTag;
    if (isClean) {
        options['keyWords'] = "";
    }
}

const PolicyMap = [
    {
        province: '上海',
        cities: ['市级政策', '黄浦区', '徐汇区', '长宁区', '静安区', '普陀区', '虹口区', '杨浦区', '闵行区', '宝山区', '嘉定区',
            '浦东新区', '金山区', '松江区', '青浦区', '奉贤区', '崇明区'],
    },
    {
        province: '北京',
        cities: ['市级政策', '东城区', '西城区', '崇文区', '宣武区', '朝阳区', '丰台区', '石景山区', '海淀区', '门头沟区', '房山区',
            '通州区', '顺义区', '昌平区', '大兴区', '怀柔区', '平谷区'],
    },
    {
        province: '天津',
        cities: ['市级政策', '和平区', '河东区', '河西区', '南开区', '河北区', '红桥区', '滨海新区', '东丽区', '西青区', '津南区',
            '北辰区', '武清区', '宝坻区', '宁河区', '静海区', '蓟州区'],
    },
    {
        province: '重庆',
        cities: ['市级政策', '万州区', '黔江区', '涪陵区', '渝中区', '大渡口区', '江北区', '沙坪坝区', '九龙坡区', '南岸区', '北碚区',
            '渝北区', '巴南区', '长寿区', '江津区', '合川区', '永川区', '南川区', '綦江区', '大足区', '铜梁区', '璧山区'],
    },
    {
        province: '江苏省',
        cities: ['省级政策', '苏州市', '徐州市', '盐城市', '无锡市', '南京市', '南通市', '连云港市', '常州市', '镇江市', '扬州市', '淮安市', '泰州市', '宿迁市'],
    },
    {
        province: '浙江省',
        cities: ['省级政策', '温州市', '宁波市', '杭州市', '台州市', '嘉兴市', '金华市', '湖州市', '绍兴市', '舟山市', '丽水市', '衢州市'],
    },
    {
        province: '广东省',
        cities: ['省级政策', '东莞市', '广州市', '中山市', '深圳市', '惠州市', '江门市', '珠海市', '汕头市', '佛山市', '湛江市', '河源市',
            '肇庆市', '清远市', '潮州市', '韶关市', '揭阳市', '阳江市', '梅州市', '云浮市', '茂名市', '汕尾市'],
    },
    {
        province: '四川省',
        cities: ['省级政策', '成都市', '绵阳市', '广元市', '达州市', '南充市', '德阳市', '广安市', '阿坝州', '巴中市', '遂宁市', '内江市'
            , '凉山州', '攀枝花市', '乐山市', '自贡市', '泸州市', '雅安市', '宜宾市', '资阳市', '眉山市', '甘孜州'],
    },
    {
        province: '福建省',
        cities: ['省级政策', '漳州市', '厦门市', '泉州市', '福州市', '莆田市', '宁德市', '三明市', '南平市', '龙岩市'],
    },
    {
        province: '山东省',
        cities: ['省级政策', '济南市', '青岛市', '临沂市', '济宁市', '菏泽市', '烟台市', '淄博市', '泰安市', '潍坊市', '日照市', '威海市'
            , '滨州市', '东营市', '聊城市', '德州市', '莱芜市', '枣庄市'],
    },
    {
        province: '辽宁省',
        cities: ['省级政策', '大连市', '沈阳市', '丹东市', '辽阳市', '葫芦岛市', '锦州市', '朝阳市', '营口市', '鞍山市', '抚顺市', '阜新市', '盘锦市', '本溪市', '铁岭市'],
    },
    {
        province: '吉林省',
        cities: ['省级政策', '吉林市', '长春市', '白山市', '延边州', '白城市', '松原市', '辽源市', '通化市', '四平市'],
    },
    {
        province: '安徽省',
        cities: ['省级政策', '芜湖市', '合肥市', '六安市', '宿州市', '阜阳市', '安庆市', '马鞍山市', '蚌埠市', '淮北市', '淮南市', '宣城市'
            , '黄山市', '铜陵市', '亳州市', '池州市', '巢湖市', '滁州市'],
    },
    {
        province: '海南省',
        cities: ['省级政策', '三亚市', '海口市', '琼海市', '文昌市', '东方市', '昌江县', '陵水县', '乐东县', '保亭县', '五指山市', '澄迈县'
            , '万宁市', '儋州市', '临高县', '白沙县', '定安县', '琼中县', '屯昌县'],
    },
    {
        province: '黑龙江省',
        cities: ['省级政策', '齐齐哈尔市', '哈尔滨市', '大庆市', '佳木斯市', '双鸭山市', '牡丹江市', '鸡西市', '黑河市', '绥化市', '鹤岗市', '伊春市', '大兴安岭地区', '七台河市'],
    },
    {
        province: '河北省',
        cities: ['省级政策', '石家庄市', '唐山市', '保定市', '邯郸市', '邢台市', '河北区', '沧州市', '秦皇岛市', '张家口市', '衡水市', '廊坊市', '承德市'],
    },
    {
        province: '河南省',
        cities: ['省级政策', '郑州市', '南阳市', '新乡市', '安阳市', '洛阳市', '信阳市', '平顶山市', '周口市', '商丘市', '开封市', '焦作市'
            , '驻马店市', '濮阳市', '三门峡市', '漯河市', '许昌市', '鹤壁市', '济源市'],
    },
    {
        province: '湖北省',
        cities: ['省级政策', '武汉市', '宜昌市', '襄樊市', '荆州市', '恩施州', '黄冈市', '孝感市', '十堰市', '咸宁市', '黄石市', '仙桃市'
            , '天门市', '随州市', '荆门市', '潜江市', '鄂州市', '神农架林区'],
    },
    {
        province: '山西省',
        cities: ['省级政策', '南昌市', '赣州市', '上饶市', '吉安市', '九江市', '新余市', '抚州市', '宜春市', '景德镇市', '萍乡市', '鹰潭市'],
    },
    {
        province: '陕西省',
        cities: ['省级政策', '西安市', '咸阳市', '宝鸡市', '汉中市', '渭南市', '安康市', '榆林市', '商洛市', '延安市', '铜川市'],
    },
    {
        province: '江西省',
        cities: ['省级政策', '南昌市', '赣州市', '上饶市', '吉安市', '九江市', '新余市', '抚州市', '宜春市', '景德镇市', '萍乡市', '鹰潭市'],
    },
    {
        province: '湖南省',
        cities: ['省级政策', '长沙市', '邵阳市', '常德市', '衡阳市', '株洲市', '湘潭市', '永州市', '岳阳市', '怀化市', '郴州市', '娄底市', '益阳市', '张家界市', '湘西州'],
    },
    {
        province: '云南省',
        cities: ['省级政策', '昆明市', '红河州', '大理州', '文山州', '德宏州', '曲靖市', '昭通市', '楚雄州', '保山市', '玉溪市', '丽江地区'
            , '临沧地区', '思茅地区', '西双版纳州', '怒江州', '迪庆州'],
    },
    {
        province: '贵州省',
        cities: ['省级政策', '贵阳市', '黔东南州', '黔南州', '遵义市', '黔西南州', '毕节地区', '铜仁地区', '安顺市', '六盘水市'],
    },
    {
        province: '甘肃省',
        cities: ['省级政策', '兰州市', '天水市', '庆阳市', '武威市', '酒泉市', '张掖市', '陇南地区', '白银市', '定西地区', '平凉市', '嘉峪关市', '临夏回族自治州', '金昌市', '甘南州'],
    },
    {
        province: '青海省',
        cities: ['省级政策', '西宁市', '海西州', '海东地区', '海北州', '果洛州', '玉树州', '黄南藏族自治州'],
    },
    {
        province: '广西壮族自治区',
        cities: ['省级政策', '贵港市', '玉林市', '北海市', '南宁市', '柳州市', '桂林市', '梧州市', '钦州市', '来宾市', '河池市', '百色市', '贺州市', '崇左市', '防城港市'],
    },
    {
        province: '新疆自治区',
        cities: ['省级政策', '乌鲁木齐市', '伊犁州', '昌吉州', '石河子市', '哈密地区', '阿克苏地区', '巴音郭楞州', '喀什地区', '塔城地区', '克拉玛依市', '和田地区'
            , '阿勒泰州', '吐鲁番地区', '阿拉尔市', '博尔塔拉州', '五家渠市', '克孜勒苏州', '图木舒克市'],
    },
    {
        province: '西藏自治区',
        cities: ['省级政策', '拉萨市', '山南地区', '林芝地区', '日喀则地区', '阿里地区', '昌都地区', '那曲地区'],
    },
    {
        province: '内蒙古自治区',
        cities: ['省级政策', '赤峰市', '包头市', '通辽市', '呼和浩特市', '鄂尔多斯市', '乌海市', '呼伦贝尔市', '兴安盟', '巴彦淖尔盟', '乌兰察布盟', '锡林郭勒盟', '阿拉善盟'],
    },
    {
        province: '宁夏回族自治区',
        cities: ['省级政策', '银川市', '吴忠市', '中卫市', '石嘴山市', '固原市'],
    },
]

const AreaMap = [
    {
        province: '上海',
        cities: ['上海市'],
    },
    {
        province: '北京',
        cities: ['北京市'],
    },
    {
        province: '天津',
        cities: ['天津市'],
    },
    {
        province: '重庆',
        cities: ['重庆市'],
    },
    {
        province: '江苏省',
        cities: ['苏州市', '徐州市', '盐城市', '无锡市', '南京市', '南通市', '连云港市', '常州市', '镇江市', '扬州市', '淮安市', '泰州市', '宿迁市'],
    },
    {
        province: '浙江省',
        cities: ['温州市', '宁波市', '杭州市', '台州市', '嘉兴市', '金华市', '湖州市', '绍兴市', '舟山市', '丽水市', '衢州市'],
    },
    {
        province: '广东省',
        cities: ['东莞市', '广州市', '中山市', '深圳市', '惠州市', '江门市', '珠海市', '汕头市', '佛山市', '湛江市', '河源市',
            '肇庆市', '清远市', '潮州市', '韶关市', '揭阳市', '阳江市', '梅州市', '云浮市', '茂名市', '汕尾市'],
    },
    {
        province: '四川省',
        cities: ['成都市', '绵阳市', '广元市', '达州市', '南充市', '德阳市', '广安市', '阿坝州', '巴中市', '遂宁市', '内江市'
            , '凉山州', '攀枝花市', '乐山市', '自贡市', '泸州市', '雅安市', '宜宾市', '资阳市', '眉山市', '甘孜州'],
    },
    {
        province: '福建省',
        cities: ['漳州市', '厦门市', '泉州市', '福州市', '莆田市', '宁德市', '三明市', '南平市', '龙岩市'],
    },
    {
        province: '山东省',
        cities: ['济南市', '青岛市', '临沂市', '济宁市', '菏泽市', '烟台市', '淄博市', '泰安市', '潍坊市', '日照市', '威海市'
            , '滨州市', '东营市', '聊城市', '德州市', '莱芜市', '枣庄市'],
    },
    {
        province: '辽宁省',
        cities: ['大连市', '沈阳市', '丹东市', '辽阳市', '葫芦岛市', '锦州市', '朝阳市', '营口市', '鞍山市', '抚顺市', '阜新市', '盘锦市', '本溪市', '铁岭市'],
    },
    {
        province: '吉林省',
        cities: ['吉林市', '长春市', '白山市', '延边州', '白城市', '松原市', '辽源市', '通化市', '四平市'],
    },
    {
        province: '安徽省',
        cities: ['芜湖市', '合肥市', '六安市', '宿州市', '阜阳市', '安庆市', '马鞍山市', '蚌埠市', '淮北市', '淮南市', '宣城市'
            , '黄山市', '铜陵市', '亳州市', '池州市', '巢湖市', '滁州市'],
    },
    {
        province: '海南省',
        cities: ['三亚市', '海口市', '琼海市', '文昌市', '东方市', '昌江县', '陵水县', '乐东县', '保亭县', '五指山市', '澄迈县'
            , '万宁市', '儋州市', '临高县', '白沙县', '定安县', '琼中县', '屯昌县'],
    },
    {
        province: '黑龙江省',
        cities: ['齐齐哈尔市', '哈尔滨市', '大庆市', '佳木斯市', '双鸭山市', '牡丹江市', '鸡西市', '黑河市', '绥化市', '鹤岗市', '伊春市', '大兴安岭地区', '七台河市'],
    },
    {
        province: '河北省',
        cities: ['石家庄市', '唐山市', '保定市', '邯郸市', '邢台市', '河北区', '沧州市', '秦皇岛市', '张家口市', '衡水市', '廊坊市', '承德市'],
    },
    {
        province: '河南省',
        cities: ['郑州市', '南阳市', '新乡市', '安阳市', '洛阳市', '信阳市', '平顶山市', '周口市', '商丘市', '开封市', '焦作市'
            , '驻马店市', '濮阳市', '三门峡市', '漯河市', '许昌市', '鹤壁市', '济源市'],
    },
    {
        province: '湖北省',
        cities: ['武汉市', '宜昌市', '襄樊市', '荆州市', '恩施州', '黄冈市', '孝感市', '十堰市', '咸宁市', '黄石市', '仙桃市'
            , '天门市', '随州市', '荆门市', '潜江市', '鄂州市', '神农架林区'],
    },
    {
        province: '山西省',
        cities: ['南昌市', '赣州市', '上饶市', '吉安市', '九江市', '新余市', '抚州市', '宜春市', '景德镇市', '萍乡市', '鹰潭市'],
    },
    {
        province: '陕西省',
        cities: ['西安市', '咸阳市', '宝鸡市', '汉中市', '渭南市', '安康市', '榆林市', '商洛市', '延安市', '铜川市'],
    },
    {
        province: '江西省',
        cities: ['南昌市', '赣州市', '上饶市', '吉安市', '九江市', '新余市', '抚州市', '宜春市', '景德镇市', '萍乡市', '鹰潭市'],
    },
    {
        province: '湖南省',
        cities: ['长沙市', '邵阳市', '常德市', '衡阳市', '株洲市', '湘潭市', '永州市', '岳阳市', '怀化市', '郴州市', '娄底市', '益阳市', '张家界市', '湘西州'],
    },
    {
        province: '云南省',
        cities: ['昆明市', '红河州', '大理州', '文山州', '德宏州', '曲靖市', '昭通市', '楚雄州', '保山市', '玉溪市', '丽江地区'
            , '临沧地区', '思茅地区', '西双版纳州', '怒江州', '迪庆州'],
    },
    {
        province: '贵州省',
        cities: ['贵阳市', '黔东南州', '黔南州', '遵义市', '黔西南州', '毕节地区', '铜仁地区', '安顺市', '六盘水市'],
    },
    {
        province: '甘肃省',
        cities: ['兰州市', '天水市', '庆阳市', '武威市', '酒泉市', '张掖市', '陇南地区', '白银市', '定西地区', '平凉市', '嘉峪关市', '临夏回族自治州', '金昌市', '甘南州'],
    },
    {
        province: '青海省',
        cities: ['西宁市', '海西州', '海东地区', '海北州', '果洛州', '玉树州', '黄南藏族自治州'],
    },
    {
        province: '广西壮族自治区',
        cities: ['贵港市', '玉林市', '北海市', '南宁市', '柳州市', '桂林市', '梧州市', '钦州市', '来宾市', '河池市', '百色市', '贺州市', '崇左市', '防城港市'],
    },
    {
        province: '新疆自治区',
        cities: ['乌鲁木齐市', '伊犁州', '昌吉州', '石河子市', '哈密地区', '阿克苏地区', '巴音郭楞州', '喀什地区', '塔城地区', '克拉玛依市', '和田地区'
            , '阿勒泰州', '吐鲁番地区', '阿拉尔市', '博尔塔拉州', '五家渠市', '克孜勒苏州', '图木舒克市'],
    },
    {
        province: '西藏自治区',
        cities: ['拉萨市', '山南地区', '林芝地区', '日喀则地区', '阿里地区', '昌都地区', '那曲地区'],
    },
    {
        province: '内蒙古自治区',
        cities: ['赤峰市', '包头市', '通辽市', '呼和浩特市', '鄂尔多斯市', '乌海市', '呼伦贝尔市', '兴安盟', '巴彦淖尔盟', '乌兰察布盟', '锡林郭勒盟', '阿拉善盟'],
    },
    {
        province: '宁夏回族自治区',
        cities: ['银川市', '吴忠市', '中卫市', '石嘴山市', '固原市'],
    },
]

const InputTest = {
    userPwd: {test: /^(?!\d+$)[^\u4e00-\u9fa5]{6,20}$/, error: "密码由6-20位英文字母、符号、数字组成，不能为纯数字"},
    userPhone: {
        test: /^((13[0-9])|(14[0-9])|(15[0-9])|(17[0-9])|(18[0-9])|(16[0-9])|(19[0-9])|(92[0-9])|(98[0-9]))\d{8}$/,
        error: "手机号码格式错误"
    },
    userEmail: {test: /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4}){1,50}$/, error: "邮箱格式错误"},
    userNick: {test: /^[\u4e00-\u9fa5_a-zA-Z0-9]{4,10}$/, error: "昵称由4-10位英文字母、中文、下划线组成"},
    code: {test:/^\d{6}$/, error: "验证码为6位数字"},
}

// click监控（opType=1(click), 没有登录时userId传null, dataType为1代表页面2代表item）
const handleClickMonitor = (userInfo, id, dataType, opType, toUrl) => {
    let userId = userInfo ? userInfo.userId : null;
    let params = {
        userId: userId,
        id: id,
        dataType: dataType,  // 1代表页面 2代表item
        opType: opType,  // 1代表click 2代表save
    };
    console.log("click监控params: ", params);
    sendGetRequest(ClickMonitorUrl, params, false).then(res => {
        console.log("click监控res: ", res);
        if (res) {
            // success(("后台记录成功" + "dataType: " + dataType + ", opType: " +  opType));
            if (toUrl) {
                if (dataType === 1) {
                    window.location.href = toUrl;
                } else {
                    window.open(toUrl)
                }
            }
        } else {
            fail("后台记录失败")
        }
    })
}

const PageId = {
    home: 'p-1',
    outcome: 'p-2',
    demand: 'p-3',
    expert: 'p-4',
    solution: 'p-5',
    policy: 'p-6',
    news: 'p-7',
    about: 'p-8',
}

const SolutionId = {
    1: {id: 's-1', ct: {}},
    2: {id: 's-2', ct: {}},
    3: {id: 's-3', ct: {}},
    4: {id: 's-4', ct: {}},
    5: {id: 's-5', ct: {}},
    6: {id: 's-6', ct: {}},
    7: {id: 's-7', ct: {}},
    8: {id: 's-8', ct: {}},
    9: {id: 's-9', ct: {}},
    10: {id: 's-10', ct: {}},
    11: {id: 's-11', ct: {}},
    12: {id: 's-12', ct: {}},
    13: {id: 's-13', ct: {}},
    14: {id: 's-14', ct: {}},
    15: {id: 's-15', ct: {}},
    16: {id: 's-16', ct: {}},
    17: {id: 's-17', ct: {}},
    18: {id: 's-18', ct: {}},
    19: {id: 's-19', ct: {}}
}

const DataType = {
    page: 1,
    item: 2,
    solution: 3
}

// 数据缓存获取移除组件
const UserInfoStorage = {
    set(key, value) {
        let userInfoJ = {
            userInfo: value,
            time: new Date().getTime(),
        }
        localStorage.setItem(key, JSON.stringify(userInfoJ));
    },
    get(key) {
        let userInfoJ = JSON.parse(localStorage.getItem(key));
        return userInfoJ ? userInfoJ.userInfo : null;
    },
    remove(key) {
        localStorage.removeItem(key)
    },
    getJ(key) {
        return JSON.parse(localStorage.getItem(key));
    },
}

// 缓存获取用户信息
const getUserInfoCache = (page, isTmp) => {
    let userInfoCache = UserInfoStorage.get("userInfo");
    if (userInfoCache) {
        page.setState({
            userInfo: userInfoCache,
        },
            () => {
                console.log("获取到的用户信息缓存：", userInfoCache);
                if (isTmp) {
                    let userInfoTmp = JSON.parse(JSON.stringify(userInfoCache));
                    page.setState({
                        userInfoTmp: userInfoTmp,
                    })
                }
            })
    }
}

// 处理登出接口
const handleLogout = (userInfo) => {
    let params = {
        userId: userInfo.userId,
    };
    sendGetRequest(LogoutUrl, params, true).then(res => {
        // console.log("用户退出登录res: ", res);
        if (res === connectionError) {
            fail(findMsgByError(connectionError));
        } else {
            if (res && res.hasOwnProperty("success") && res.success) {
                UserInfoStorage.remove("userInfo");
                // successReturn("账号已退出", LoginRoot);
                window.location.href = LoginRoot;
            } else {
                fail(findMsgByError(res[error]));
            }
        }
    })
}

// 预约开放时间节点list
const schTime = {
    1: '08:00',
    2: '08:30',
    3: '09:00',
    4: '09:30',
    5: '10:00',
    6: '10:30',
    7: '11:00',
    8: '11:30',
    9: '12:00',
    10: '12:30',
    11: '13:00',
    12: '13:30',
    13: '14:00',
    14: '14:30',
    15: '15:00',
    16: '15:30',
    17: '16:00',
    18: '16:30',
    19: '17:00',
    20: '17:30',
    21: '18:00',
    22: '18:30',
    23: '19:00',
    24: '19:30',
    25: '20:00',
}

// 预约开放时间list
const scheduleTime = [
    '08:00 - 08:30',
    '08:30 - 09:00',
    '09:00 - 09:30',
    '09:30 - 10:00',
    '10:00 - 10:30',
    '10:30 - 11:00',
    '11:00 - 11:30',
    '11:30 - 12:00',
    '12:00 - 12:30',
    '12:30 - 13:00',
    '13:00 - 13:30',
    '13:30 - 14:00',
    '14:00 - 14:30',
    '14:30 - 15:00',
    '15:00 - 15:30',
    '15:30 - 16:00',
    '16:00 - 16:30',
    '16:30 - 17:00',
    '17:00 - 17:30',
    '17:30 - 18:00',
    '18:00 - 18:30',
    '18:30 - 19:00',
    '19:00 - 19:30',
    '19:30 - 20:00',
]

// 手机号正则隐藏中间四位
const regPhone = /^(\d{3})\d{4}(\d{4})$/;

// 邮箱正则隐藏
const regEmail = /(.{2}).+(.{2}@.+)/g;

// 科研成果 filter
const filterOutcome = [
    {
        title: "类型",
        ct: ["发明专利", "实用新型专利", "外观设计专利", "软件著作权"],
        // value: ["发明专利", "实用新型专利"],
        value: [],
        ip: 'type',
    },
    {
        title: "技术来源",
        ct: ["全部", "高等院校", "科研院所", "企业"],
        // value: ["全部"],
        value: [],
        ip: 'from',
    },
    {
        title: "成熟度",
        ct: ["全部", "研制阶段", "小试阶段", "中试阶段", "量产阶段", "其他"],
        // value: ["研制阶段", "小试阶段"],
        value: [],
        ip: 'level',
    },
    {
        title: "行业分类",
        ct: ["全部", "生物科技", "医药和医疗器械", "新材料及其应用", "新一代电子信息", "先进制造与自动化", "现代农业", "航空航天技术",
            "绿色化工技术", "新能源与高效节能", "核应用技术", "环境保护与资源利用", "现代交通技术", "城市建设和社会发展", "海洋开发",
            "文化体育与娱乐", "食品饮料", "轻工纺织", "芯片与集成电路", "人工智能与大数据", "其他"],
        // value: ["生物科技", "医药和医疗器械", "新材料及其应用", "其他"],
        value: [],
        ip: 'area',
    },
    {
        title: "合作方式",
        ct: ["全部", "技术转让", "委托开发", "合作开发", "专利许可", "技术入股", "其他"],
        // value: ["技术转让", "委托开发"],
        value: [],
        ip: 'method',
    },
]

const outcomeType = ["发明专利", "实用新型专利", "外观设计专利", "软件著作权"];

// 技术需求 filter
const filterDemand = [
    {
        title: "需求类型",
        ct: ["发明专利", "实用新型专利", "外观设计专利", "软件著作权", "商标注册"],
        // value: ["发明专利", "外观设计专利"],
        value: [],
        ip: 'type',
    },
    {
        title: "需求来源",
        ct: ["全部", "高等院校", "科研院所", "企业"],
        // value: ["全部"],
        value: [],
        ip: 'from',
    },
    {
        title: "行业分类",
        ct: ["全部", "生物科技", "医药和医疗器械", "新材料及其应用", "新一代电子信息", "先进制造与自动化", "现代农业", "航空航天技术",
            "绿色化工技术", "新能源与高效节能", "核应用技术", "环境保护与资源利用", "现代交通技术", "城市建设和社会发展", "海洋开发",
            "文化体育与娱乐", "食品饮料", "轻工纺织", "芯片与集成电路", "人工智能与大数据", "其他"],
        // value: ["核应用技术", "环境保护与资源利用", "芯片与集成电路", "人工智能与大数据"],
        value: [],
        ip: 'area',
    },
    {
        title: "合作方式",
        ct: ["全部", "技术转让", "委托开发", "合作开发", "专利许可", "技术入股", "其他"],
        // value: ["技术转让", "合作开发"],
        value: [],
        ip: 'method',
    },
    {
        title: "需求金额",
        ct: ['全部', '面议', '1万以下', '1万-10万', '10万-50万', '50万-100万', '100万-200万', '200万-500万', '500万-1000万', '1000万以上'],
        // value: ['200万-500万', '500万-1000万'],
        value: [],
        ip: 'level',
    },
]

// 专家人才 filter
const filterExpert = [
    {
        title: "研究领域",
        ct: ['全部', '数学与统计学类', '物理学与力学类', '化学与化工类', '地理地质学与矿业类', '水利与海洋科学类', '生物医学与生物工程类', '工业工程与机械类',
            '仪器与材料类', '能源动力类', '电子信息与计算机类', '电气与自动化类', '土木建筑与测绘类', '轻工与纺织类', '交通运输与物流管理类',
            '航空航天与核工程类', '农业与林业工程类', '环境科学与工程类', '食品科学与工程类', '安全科学与工程类', '中医学与制药学类', '管理科学与工程类类',
            '电子商务类', '心理学类', '天文学类', '大气科学类', '其他'],
        // value: ['能源动力类', '电子信息与计算机类', '电气与自动化类', '土木建筑与测绘类', '轻工与纺织类', '大气科学类'],
        value: [],
        ip: 'area',
    },
    {
        title: "专家来源",
        ct: ["全部", "高等院校", "科研院所", "企业"],
        // value: ["全部"],
        value: [],
        ip: 'from',
    },
    {
        title: "专家职称",
        ct: ['全部', '正高级工程师', '高级工程师', '工程师', '教授', '副教授', '研究员', '副研究员', '主任医师', '副主任医师',
            '正高级教师', '高级教师', '主任技师', '副主任技师', '其他'],
        // value: ['正高级工程师', '高级工程师', '教授', '副教授'],
        value: [],
        ip: 'title',
    },
]

// 科技政策 filter
const filterPolicy = [
    {
        title: '上海',
        ct: ['市级政策', '黄浦区', '徐汇区', '长宁区', '静安区', '普陀区', '虹口区', '杨浦区', '闵行区', '宝山区', '嘉定区',
            '浦东新区', '金山区', '松江区', '青浦区', '奉贤区', '崇明区'],
        // value: ['市级政策', '黄浦区', '徐汇区'],
        value: [],
    },
    {
        title: '北京',
        ct: ['市级政策', '东城区', '西城区', '崇文区', '宣武区', '朝阳区', '丰台区', '石景山区', '海淀区', '门头沟区', '房山区',
            '通州区', '顺义区', '昌平区', '大兴区', '怀柔区', '平谷区'],
        // value: ['市级政策', '东城区'],
        value: [],
    },
    {
        title: '天津',
        ct: ['市级政策', '和平区', '河东区', '河西区', '南开区', '河北区', '红桥区', '滨海新区', '东丽区', '西青区', '津南区',
            '北辰区', '武清区', '宝坻区', '宁河区', '静海区', '蓟州区'],
        // value: ['市级政策'],
        value: [],
    },
    {
        title: '重庆',
        ct: ['市级政策', '万州区', '黔江区', '涪陵区', '渝中区', '大渡口区', '江北区', '沙坪坝区', '九龙坡区', '南岸区', '北碚区',
            '渝北区', '巴南区', '长寿区', '江津区', '合川区', '永川区', '南川区', '綦江区', '大足区', '铜梁区', '璧山区'],
        // value: [],
        value: [],
    },
    {
        title: '江苏省',
        ct: ['省级政策', '苏州市', '徐州市', '盐城市', '无锡市', '南京市', '南通市', '连云港市', '常州市', '镇江市', '扬州市', '淮安市', '泰州市', '宿迁市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '浙江省',
        ct: ['省级政策', '温州市', '宁波市', '杭州市', '台州市', '嘉兴市', '金华市', '湖州市', '绍兴市', '舟山市', '丽水市', '衢州市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '广东省',
        ct: ['省级政策', '东莞市', '广州市', '中山市', '深圳市', '惠州市', '江门市', '珠海市', '汕头市', '佛山市', '湛江市', '河源市',
            '肇庆市', '清远市', '潮州市', '韶关市', '揭阳市', '阳江市', '梅州市', '云浮市', '茂名市', '汕尾市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '四川省',
        ct: ['省级政策', '成都市', '绵阳市', '广元市', '达州市', '南充市', '德阳市', '广安市', '阿坝州', '巴中市', '遂宁市', '内江市'
            , '凉山州', '攀枝花市', '乐山市', '自贡市', '泸州市', '雅安市', '宜宾市', '资阳市', '眉山市', '甘孜州'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '福建省',
        ct: ['省级政策', '漳州市', '厦门市', '泉州市', '福州市', '莆田市', '宁德市', '三明市', '南平市', '龙岩市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '山东省',
        ct: ['省级政策', '济南市', '青岛市', '临沂市', '济宁市', '菏泽市', '烟台市', '淄博市', '泰安市', '潍坊市', '日照市', '威海市'
            , '滨州市', '东营市', '聊城市', '德州市', '莱芜市', '枣庄市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '辽宁省',
        ct: ['省级政策', '大连市', '沈阳市', '丹东市', '辽阳市', '葫芦岛市', '锦州市', '朝阳市', '营口市', '鞍山市', '抚顺市', '阜新市', '盘锦市', '本溪市', '铁岭市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '吉林省',
        ct: ['省级政策', '吉林市', '长春市', '白山市', '延边州', '白城市', '松原市', '辽源市', '通化市', '四平市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '安徽省',
        ct: ['省级政策', '芜湖市', '合肥市', '六安市', '宿州市', '阜阳市', '安庆市', '马鞍山市', '蚌埠市', '淮北市', '淮南市', '宣城市'
            , '黄山市', '铜陵市', '亳州市', '池州市', '巢湖市', '滁州市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '海南省',
        ct: ['省级政策', '三亚市', '海口市', '琼海市', '文昌市', '东方市', '昌江县', '陵水县', '乐东县', '保亭县', '五指山市', '澄迈县'
            , '万宁市', '儋州市', '临高县', '白沙县', '定安县', '琼中县', '屯昌县'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '黑龙江省',
        ct: ['省级政策', '齐齐哈尔市', '哈尔滨市', '大庆市', '佳木斯市', '双鸭山市', '牡丹江市', '鸡西市', '黑河市', '绥化市', '鹤岗市', '伊春市', '大兴安岭地区', '七台河市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '河北省',
        ct: ['省级政策', '石家庄市', '唐山市', '保定市', '邯郸市', '邢台市', '河北区', '沧州市', '秦皇岛市', '张家口市', '衡水市', '廊坊市', '承德市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '河南省',
        ct: ['省级政策', '郑州市', '南阳市', '新乡市', '安阳市', '洛阳市', '信阳市', '平顶山市', '周口市', '商丘市', '开封市', '焦作市'
            , '驻马店市', '濮阳市', '三门峡市', '漯河市', '许昌市', '鹤壁市', '济源市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '湖北省',
        ct: ['省级政策', '武汉市', '宜昌市', '襄樊市', '荆州市', '恩施州', '黄冈市', '孝感市', '十堰市', '咸宁市', '黄石市', '仙桃市'
            , '天门市', '随州市', '荆门市', '潜江市', '鄂州市', '神农架林区'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '山西省',
        ct: ['省级政策', '南昌市', '赣州市', '上饶市', '吉安市', '九江市', '新余市', '抚州市', '宜春市', '景德镇市', '萍乡市', '鹰潭市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '陕西省',
        ct: ['省级政策', '西安市', '咸阳市', '宝鸡市', '汉中市', '渭南市', '安康市', '榆林市', '商洛市', '延安市', '铜川市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '江西省',
        ct: ['省级政策', '南昌市', '赣州市', '上饶市', '吉安市', '九江市', '新余市', '抚州市', '宜春市', '景德镇市', '萍乡市', '鹰潭市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '湖南省',
        ct: ['省级政策', '长沙市', '邵阳市', '常德市', '衡阳市', '株洲市', '湘潭市', '永州市', '岳阳市', '怀化市', '郴州市', '娄底市', '益阳市', '张家界市', '湘西州'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '云南省',
        ct: ['省级政策', '昆明市', '红河州', '大理州', '文山州', '德宏州', '曲靖市', '昭通市', '楚雄州', '保山市', '玉溪市', '丽江地区'
            , '临沧地区', '思茅地区', '西双版纳州', '怒江州', '迪庆州'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '贵州省',
        ct: ['省级政策', '贵阳市', '黔东南州', '黔南州', '遵义市', '黔西南州', '毕节地区', '铜仁地区', '安顺市', '六盘水市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '甘肃省',
        ct: ['省级政策', '兰州市', '天水市', '庆阳市', '武威市', '酒泉市', '张掖市', '陇南地区', '白银市', '定西地区', '平凉市', '嘉峪关市', '临夏回族自治州', '金昌市', '甘南州'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '青海省',
        ct: ['省级政策', '西宁市', '海西州', '海东地区', '海北州', '果洛州', '玉树州', '黄南藏族自治州'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '广西壮族自治区',
        ct: ['省级政策', '贵港市', '玉林市', '北海市', '南宁市', '柳州市', '桂林市', '梧州市', '钦州市', '来宾市', '河池市', '百色市', '贺州市', '崇左市', '防城港市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '新疆自治区',
        ct: ['省级政策', '乌鲁木齐市', '伊犁州', '昌吉州', '石河子市', '哈密地区', '阿克苏地区', '巴音郭楞州', '喀什地区', '塔城地区', '克拉玛依市', '和田地区'
            , '阿勒泰州', '吐鲁番地区', '阿拉尔市', '博尔塔拉州', '五家渠市', '克孜勒苏州', '图木舒克市'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '西藏自治区',
        ct: ['省级政策', '拉萨市', '山南地区', '林芝地区', '日喀则地区', '阿里地区', '昌都地区', '那曲地区'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '内蒙古自治区',
        ct: ['省级政策', '赤峰市', '包头市', '通辽市', '呼和浩特市', '鄂尔多斯市', '乌海市', '呼伦贝尔市', '兴安盟', '巴彦淖尔盟', '乌兰察布盟', '锡林郭勒盟', '阿拉善盟'],
        // value: ['省级政策'],
        value: [],
    },
    {
        title: '宁夏回族自治区',
        ct: ['省级政策', '银川市', '吴忠市', '中卫市', '石嘴山市', '固原市'],
        // value: ['省级政策'],
        value: [],
    },
]

// 类型 filter
const filterType = [
    {label: '发明专利', value: '1', checked: false, disabled: false},
    {label: '实用新型专利', value: '2', checked: false, disabled: false},
    {label: '外观设计专利', value: '3', checked: false, disabled: false},
    {label: '软件著作权', value: '4', checked: false, disabled: false},
]

const filterTypeBySub = [
    {label: '发明专利', value: '发明专利', checked: false, disabled: false},
    {label: '实用新型专利', value: '实用新型专利', checked: false, disabled: false},
    {label: '外观设计专利', value: '外观设计专利', checked: false, disabled: false},
    {label: '软件著作权', value: '软件著作权', checked: false, disabled: false},
]

// 技术来源 filter
const filterSrcType = [
    {label: '高等院校', value: '1', checked: false, disabled: false},
    {label: '科研院所', value: '2', checked: false, disabled: false},
    {label: '企业', value: '3', checked: false, disabled: false},
]

const filterSrcTypeBySub = [
    {label: '高等院校', value: '高等院校', checked: false, disabled: false},
    {label: '科研院所', value: '科研院所', checked: false, disabled: false},
    {label: '企业', value: '企业', checked: false, disabled: false},
]

// 成熟度 filter
const filterStage = [
    {label: '研制阶段', value: '研制阶段', checked: false, disabled: false},
    {label: '小试阶段', value: '小试阶段', checked: false, disabled: false},
    {label: '中试阶段', value: '中试阶段', checked: false, disabled: false},
    {label: '量产阶段', value: '量产阶段', checked: false, disabled: false},
    {label: '其他', value: '其他', checked: false, disabled: false},
]

// 行业领域 filter
const filterAreaTech = [
    {label: '生物科技', value: '生物科技', checked: false, disabled: false},
    {label: '医药和医疗器械', value: '医药和医疗器械', checked: false, disabled: false},
    {label: '新材料及其应用', value: '新材料及其应用', checked: false, disabled: false},
    {label: '新一代电子信息', value: '新一代电子信息', checked: false, disabled: false},
    {label: '先进制造与自动化', value: '先进制造与自动化', checked: false, disabled: false},
    {label: '现代农业', value: '现代农业', checked: false, disabled: false},
    {label: '航空航天技术', value: '航空航天技术', checked: false, disabled: false},
    {label: '绿色化工技术', value: '绿色化工技术', checked: false, disabled: false},
    {label: '新能源与高效节能', value: '新能源与高效节能', checked: false, disabled: false},
    {label: '核应用技术', value: '核应用技术', checked: false, disabled: false},
    {label: '环境保护与资源利用', value: '环境保护与资源利用', checked: false, disabled: false},
    {label: '现代交通技术', value: '现代交通技术', checked: false, disabled: false},
    {label: '城市建设和社会发展', value: '城市建设和社会发展', checked: false, disabled: false},
    {label: '海洋开发', value: '海洋开发', checked: false, disabled: false},
    {label: '文化体育与娱乐', value: '文化体育与娱乐', checked: false, disabled: false},
    {label: '食品饮料', value: '食品饮料', checked: false, disabled: false},
    {label: '轻工纺织', value: '轻工纺织', checked: false, disabled: false},
    {label: '芯片与集成电路', value: '芯片与集成电路', checked: false, disabled: false},
    {label: '人工智能与大数据', value: '人工智能与大数据', checked: false, disabled: false},
    {label: '其他', value: '其他', checked: false, disabled: false},
]

// 行业领域 filter
const filterArea = [
    {label: '数学与统计学类', value: '数学与统计学类', checked: false, disabled: false},
    {label: '物理学与力学类', value: '物理学与力学类', checked: false, disabled: false},
    {label: '化学与化工类', value: '化学与化工类', checked: false, disabled: false},
    {label: '地理地质学与矿业类', value: '地理地质学与矿业类', checked: false, disabled: false},
    {label: '水利与海洋科学类', value: '水利与海洋科学类', checked: false, disabled: false},
    {label: '生物医学与生物工程类', value: '生物医学与生物工程类', checked: false, disabled: false},
    {label: '工业工程与机械类', value: '工业工程与机械类', checked: false, disabled: false},
    {label: '仪器与材料类', value: '仪器与材料类', checked: false, disabled: false},
    {label: '能源动力类', value: '能源动力类', checked: false, disabled: false},
    {label: '电子信息与计算机类', value: '电子信息与计算机类', checked: false, disabled: false},
    {label: '电气与自动化类', value: '电气与自动化类', checked: false, disabled: false},
    {label: '土木建筑与测绘类', value: '土木建筑与测绘类', checked: false, disabled: false},
    {label: '轻工与纺织类', value: '轻工与纺织类', checked: false, disabled: false},
    {label: '交通运输与物流管理类', value: '交通运输与物流管理类', checked: false, disabled: false},
    {label: '航空航天与核工程类', value: '航空航天与核工程类', checked: false, disabled: false},
    {label: '农业与林业工程类', value: '农业与林业工程类', checked: false, disabled: false},
    {label: '环境科学与工程类', value: '环境科学与工程类', checked: false, disabled: false},
    {label: '食品科学与工程类', value: '食品科学与工程类', checked: false, disabled: false},
    {label: '安全科学与工程类', value: '安全科学与工程类', checked: false, disabled: false},
    {label: '中医学与制药学类', value: '中医学与制药学类', checked: false, disabled: false},
    {label: '管理科学与工程类类', value: '管理科学与工程类类', checked: false, disabled: false},
    {label: '电子商务类', value: '电子商务类', checked: false, disabled: false},
    {label: '心理学类', value: '心理学类', checked: false, disabled: false},
    {label: '天文学类', value: '天文学类', checked: false, disabled: false},
    {label: '大气科学类', value: '大气科学类', checked: false, disabled: false},
    {label: '其他', value: '其他', checked: false, disabled: false},
]

// 成熟度 filter
const filterCopWay = [
    {label: '技术转让', value: '1', checked: false, disabled: false},
    {label: '委托开发', value: '2', checked: false, disabled: false},
    {label: '合作开发', value: '3', checked: false, disabled: false},
    {label: '专利许可', value: '4', checked: false, disabled: false},
    {label: '技术入股', value: '5', checked: false, disabled: false},
    {label: '其他', value: '6', checked: false, disabled: false},
]

const filterCopWayBySub = [
    {label: '技术转让', value: '技术转让', checked: false, disabled: false},
    {label: '委托开发', value: '委托开发', checked: false, disabled: false},
    {label: '合作开发', value: '合作开发', checked: false, disabled: false},
    {label: '专利许可', value: '专利许可', checked: false, disabled: false},
    {label: '技术入股', value: '技术入股', checked: false, disabled: false},
    {label: '其他', value: '其他', checked: false, disabled: false},
]

// 职务 filter
const filterTitle = [
    {label: '正高级工程师', value: '正高级工程师', checked: false, disabled: false},
    {label: '高级工程师', value: '高级工程师', checked: false, disabled: false},
    {label: '工程师', value: '工程师', checked: false, disabled: false},
    {label: '教授', value: '教授', checked: false, disabled: false},
    {label: '副教授', value: '副教授', checked: false, disabled: false},
    {label: '研究员', value: '研究员', checked: false, disabled: false},
    {label: '副研究员', value: '副研究员', checked: false, disabled: false},
    {label: '主任医师', value: '主任医师', checked: false, disabled: false},
    {label: '副主任医师', value: '副主任医师', checked: false, disabled: false},
    {label: '正高级教师', value: '正高级教师', checked: false, disabled: false},
    {label: '高级教师', value: '高级教师', checked: false, disabled: false},
    {label: '主任技师', value: '主任技师', checked: false, disabled: false},
    {label: '副主任技师', value: '副主任技师', checked: false, disabled: false},
    {label: '其他', value: '其他', checked: false, disabled: false},
]

// 图片格式list
const picTypes = "image/jpg, image/jpeg, image/png";

// 图片上传处理名字
const getPicTypeByName = (name) => {
    return name.split(".")[1];
}

// 上传图片回调
const imageUploadCallBack = (file, maxWidth, maxHeight) => new Promise(
    (resolve) => {
        // console.log(file)
        let names = file.name.split(".");
        // let type = file.type.split("/")[1];
        let type = names.length > 1 ? names[1] : "jpeg";
        if (type === "jpg") {
            type = "jpeg";
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        let img = new Image();
        reader.onload = function (e) {
            img.src = this.result.toString()
        }
        // console.log(img)
        img.onload = function () {
            // let canvas = document.createElement('canvas');
            // let context = canvas.getContext('2d');
            //
            // // 图片原始尺寸
            // let originWidth = this.width;
            // let originHeight = this.height;
            // // 最大尺寸限制，可通过设置宽高来实现图片压缩程度
            // // 目标尺寸
            // let targetWidth = originWidth,
            //     targetHeight = originHeight;
            // // 图片尺寸超过尺寸的限制
            // if (originWidth > maxWidth || originHeight > maxHeight) {
            //     if (originWidth / originHeight > maxWidth / maxHeight) {
            //         // 更宽，按照宽度限定尺寸
            //         targetWidth = maxWidth;
            //         targetHeight = Math.round(maxWidth * (originHeight / originWidth));
            //     } else {
            //         targetHeight = maxHeight;
            //         targetWidth = Math.round(maxHeight * (originWidth / originHeight));
            //     }
            // }
            // // canvas对图片进行缩放
            // canvas.width = targetWidth;
            // canvas.height = targetHeight;
            // // 清除画布
            // context.clearRect(0, 0, targetWidth, targetHeight);
            // // 图片压缩
            // context.drawImage(img, 0, 0, targetWidth, targetHeight);

            //压缩后的图片转base64 url
            /*canvas.toDataURL(mimeType, qualityArgument),mimeType 默认值是'image/png';
              * qualityArgument表示导出的图片质量，只有导出为jpeg和webp格式的时候此参数才有效，默认值是0.92*/

            let newUrl = "";
            if (type === "svg") {
                newUrl = "data:image/svg+xml;" + img.src.split(";")[1]
            } else {
                newUrl = "data:image/" + type + ";" + img.src.split(";")[1]
                // newUrl = canvas.toDataURL(
                //     'image/' + type, 0.92
                // );//base64 格式
            }

            resolve({
                data: {
                    link: newUrl,
                    type: type,
                }
            })

            // console.log(newUrl)
            // console.log(type)
        }
    }
)

// map转Object
const mapToObj = (strMap) => {
    let list = {};
    strMap.forEach((value, key) => {
        list[key] = value;
    })
    return list;
}

// Object转Arr
const objectToList = (object) => {
    let arr = [];
    for (let i in object) {
        arr.push(
            object[i]
        )
    }
    // console.log("Object转Arr: ", arr);
    return arr;
}

// 专家职称
const handleExpertTitle = (expertTitle) => {
    switch (expertTitle) {
        case 1: {
            return "正高级工程师";
        }
        case 2: {
            return "高级工程师";
        }
        case 3: {
            return "工程师";
        }
        case 4: {
            return "正教授";
        }
        case 5: {
            return "教授";
        }
        case 6: {
            return "副教授";
        }
        case 7: {
            return "研究员";
        }
        case 8: {
            return "副研究员";
        }
        case 9: {
            return "主任医师";
        }
        case 10: {
            return "副主任医师";
        }
        case 11: {
            return "正高级教师";
        }
        case 12: {
            return "高级教师";
        }
        case 13: {
            return "主任技师";
        }
        case 14: {
            return "副主任技师";
        }
        case 15: {
            return "其他";
        }
    }
}

// 专家来源
const handleSrcType = (srcType) => {
    switch (srcType) {
        case 1: {
            return "高等院校";
        }
        case 2: {
            return "科研院所";
        }
        case 3: {
            return "企业";
        }
    }
}

// 数字千分位格式化（正则）
const toThousandsReg = (num) => {
    return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
}

// 判断两个数组是否相同
const arrayIdentical = (arr1, arr2) => {
    let loop = (data1, data2) => {
        if (data1 instanceof Object && data2 instanceof Object) {
            if (Object.keys(data1).length !== Object.keys(data2).length) {
                return false
            };
            for (let item in data1) {
                if (!(item in data2)) {
                    return false
                };
                if (!loop(data1[item], data2[item])) {
                    return false
                };
            }
            return true;
        }
        if (data1 === data2) {
            return true
        };
        return false;
    }
    return loop(arr1, arr2);
}

// 通过userId和种类index拉取显示对应类型数据列表
const findFavorDataByType = (page) => {
    let userInfo = UserInfoStorage.get("userInfo");
    let index = page.state.options.index;
    if (userInfo) {
        let params = {
            userId: userInfo.userId,
            index: index,
        };
        console.log("拉取显示对应类型数据列表params: ", params);
        sendGetRequest(FindFavorDataByTypeUrl, params, true).then(res => {
            console.log("拉取显示对应类型数据列表res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    let list = [];
                    let favorList = res[data];
                    if (favorList) {
                        favorList.forEach((item, item_index) => {
                            list.push(item[handleIdByIndex(index)])
                        })
                        page.setState({
                            favorList: list,
                        })
                    }
                } else {
                    fail(findMsgByError(res[error]))
                }
            }
        })
    }
}

// 处理index对应的id（index：1344，id：outcomeId; index：9919，id：demandId; index：2577，id：expertId; index：3478，id：policyId; index：3566，id：newsId）
const handleIdByIndex = (index) => {
    switch (index) {
        case "1344": {
            return "outcomeId";
        }
        case "9919": {
            return "demandId";
        }
        case "2577": {
            return "expertId";
        }
        case "3478": {
            return "policyId";
        }
    }
}

// 科研成果/技术需求类型type
const handleOutcomeType = (type) => {
    switch (type) {
        case 1: {
            return "发明专利";
        }
        case 2: {
            return "实用新型专利";
        }
        case 3: {
            return "外观设计专利";
        }
        case 4: {
            return "软件著作权";
        }
        case 5: {
            return "商标注册";
        }
    }
}

// 科研成果/技术需求合作方式copWay
const handleCopWay = (way) => {
    switch (way) {
        case 1: {
            return "技术转让";
        }
        case 2: {
            return "委托开发";
        }
        case 3: {
            return "合作开发";
        }
        case 4: {
            return "专利许可";
        }
        case 5: {
            return "技术入股";
        }
        case 6: {
            return "其他";
        }
    }
}

// 点击收藏（收藏/取消收藏）
const favorOpFun = (item, userInfo, index, addFavor) => {
    console.log(item);
    if (userInfo) {
        let list = [];
        let id = item[handleIdByIndex(index)];
        list.push(id);
        let pojo = {
            itemIds: list,
            addFavor: addFavor,
            userId: userInfo.userId,
        };
        console.log("点击收藏pojo: ", addFavor, pojo);
        sendPostRequest(genPojo(FavorOpUrl, pojo), true).then(res => {
            console.log("点击收藏res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    success(addFavor ? "收藏成功" : "取消收藏成功");
                    if (addFavor) {
                        handleClickMonitor(userInfo, id, 2, 2);
                    }
                    window.setTimeout(function () {
                        window.location.reload();
                    },2000)
                } else {
                    fail(findMsgByError(res[error]))
                }
            }
        })
    } else {
        fail("请先登录账号")
    }
}

// 判断是否包含分号（正则）
const isSemicolonValid = /[;-]/;

// 根据包含分号呈现前两个
const showSemData = (str, num) => {
    if (isSemicolonValid.test(str)) {
        return str.split(";")[num];
    } else {
        if (num > 0) {
            return false;
        }
        return str;
    }
}

// 获取该页面数据
async function getPageDataByIndex(page) {
    let url = window.location.href;
    let len = url.split('/').length;
    let itemId =  url.split('/')[len -1];
    console.log("该页面itemId：", itemId);
    if (itemId) {
        let userInfo = UserInfoStorage.get("userInfo");
        let params = {
            userId: userInfo ? userInfo.userId : null,
            itemId: itemId,
        };
        console.log("通过itemId获取详情params: ", params);
        return await sendGetRequest(FindItemDetailByIdUrl, params, true).then(res => {
            console.log("通过itemId获取详情res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
                return false;
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    page.setState({
                        pageData: res[data],
                        isPageDataLoad: true,
                        isMatch: true,
                    })
                    // storage.set(itemId, res[data]);
                    return true;
                } else {
                    fail(findMsgByError(res[error]));
                    return false;
                }
            }
        })

    }
}

// 跳转页签滚动条置顶
const scrollTop = () => {
    let navTop = document.getElementById("pageHeader");
    navTop.scrollIntoView();
    window.scrollTo(0, 0);
}

// 跳转登录界面
const switchLogin = (url, page) => {
    // window.location.href = '/login'
    console.log("调整url: ", url);
    page.props.history.push({
        pathname: LoginRoot,
        state: {
            from: url,
        }
    })
}

// utc-8北京时间
const utcTimeFormat = moment().utcOffset(8);

// 星期list(带空)
const week = ['', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'];

// 日程（星期表）
const schList = {
    1: {
        val: "星期一",
        param1: ""
    },
    2: {
        val: "星期二",
        param1: ""
    },
    3: {
        val: "星期三",
        param1: ""
    },
    4: {
        val: "星期四",
        param1: ""
    },
    5: {
        val: "星期五",
        param1: ""
    },
    6: {
        val: "星期六",
        param1: ""
    },
    7: {
        val: "星期日",
        param1: ""
    },
}

// 补全小时前面0
const formatStrDouble = (str) => {
    if (str <= 9) {
        return "0" + str;
    } else {
        return str;
    }
}

// 判断两个数组是否有重复
async function isHasRepeatList (listA, listB, result) {
    console.log("listA: ", listA);
    console.log("listB: ", listB);
    let len = listA.length;
    for (let i = 0; i < len; i++) {
        listB.filter(item => {
            item.toString() === listA[i].toString() ? result.push(1) : result.push();
            // console.log("result: ", result);
            return result;
        })
    }
}

// 取模
const mod = (n, m) => {
    return ((n % m) + m) % m;
}

// 通过时间戳获取星期几
const getWeek = (date) => {
    let week = moment(date).day();
    switch (week) {
        case 1:
            return '星期一'
        case 2:
            return '星期二'
        case 3:
            return '星期三'
        case 4:
            return '星期四'
        case 5:
            return '星期五'
        case 6:
            return '星期六'
        case 0:
            return '星期日'
    }
}

// 获取两个数组的差集
const getSubtraction = (a, b) => {
    let c = [];
    let tmp = a.concat(b);
    let o = {};
    for (let i = 0; i < tmp.length; i ++) {
        (tmp[i] in o) ? o[tmp[i]] ++ : o[tmp[i]] = 1;
    };
    for (let j in o) {
        if (o[j] == 1) {
            c.push(parseInt(j))
        }
    };
    return c;
}

// 获取已通过的科研成果/技术需求
const findUserAcceptItems = (pageLimit, pageNo, index, page) => {
    let userInfo = UserInfoStorage.get("userInfo");
    let params = {
        userId: userInfo.userId,
        pageLimit: pageLimit,
        pageNo: pageNo,
        index: index,
    };
    sendGetRequest(FindUserAcceptItemsUrl, params, true).then(res => {
        console.log("获取已通过的科研成果/技术需求res: ", res);
        if (res === connectionError) {
            fail(findMsgByError(connectionError));
        } else {
            if (res && res.hasOwnProperty("success") && res.success) {
                page.setState({
                    data: res[data],
                    type: 0,
                })
            } else if (res[error] === loginOutErrOr) {
                UserInfoStorage.remove("userInfo");
                failReturn(findMsgByError(res[error]), LoginRoot)
            } else {
                fail(findMsgByError(res[error]));
            }
        }
    })
}

// 用户下架已通过的科研成果/技术需求
const closeUserItem = (itemId, pageLimit, pageNo, index, closeItemModalStatus, page) => {
    let userInfo = UserInfoStorage.get("userInfo");
    let params = {
        userId: userInfo.userId,
        itemId: itemId,
    };
    // console.log("用户下架已通过的科研成果/技术需求params: ", params);
    sendGetRequest(CloseUserItemByIdUrl, params, true).then(res => {
        console.log("用户下架已通过的科研成果/技术需求res: ", res);
        if (res === connectionError) {
            fail(findMsgByError(connectionError));
        } else {
            if (res && res.hasOwnProperty("success") && res.success) {
                success("已成功下架")
                setCloseItemModalStatus(closeItemModalStatus, '', page);
                findUserAcceptItems(pageLimit, pageNo, index, page);
            } else if (res[error] === loginOutErrOr) {
                UserInfoStorage.remove("userInfo");
                failReturn(findMsgByError(res[error]), LoginRoot)
            } else {
                fail(findMsgByError(res[error]));
            }
        }
    })
}

// 基本信息编辑
const changeSubmitOptions = (e, submitOptions, type, page) => {
    submitOptions[type] = e.target.value;
    page.setState({
            submitOptions: submitOptions,
        },
        () => {
            console.log("——————", "更新后的提交信息：",  submitOptions, "——————");
        });
}

// 弹窗设置修改
const setModalStatus = (modalStatus, page) => {
    page.setState({
        modalStatus: !modalStatus,
    })
}

// 下架弹窗设置修改
const setCloseItemModalStatus = (closeItemModalStatus, itemId, page) => {
    page.setState({
        closeItemModalStatus: !closeItemModalStatus,
        closeItemId: itemId,
    })
}

// 复制文字功能
const copyFun = (id) => {
    window.getSelection().removeAllRanges();  //这段代码必须放在前面否则无效
    let transfer = document.createElement('input');//创建控件
    document.body.appendChild(transfer);
    transfer.value = document.getElementById(id).innerText;
    transfer.focus();
    transfer.select();
    document.execCommand('copy');
    let successful = document.execCommand('copy');  // 执行 copy 操作
    if (successful) {
        success("复制成功")
    } else {
        fail("复制失败，请重试");
    }
    // 移除选中的元素
    // window.getSelection().removeAllRanges();
    document.body.removeChild(transfer);  // 删除控件
}

// 切换对应的行业领域checkbox
const switchCheckBoxType = (type, page) => {
    page.setState({
        checkBoxType: type,
    })
}

// 加载行业领域checkbox以及对应最大选择值
const loadAreaCheckBox = (checkBoxOptions, tag, submitOptions, page) => {
    let list = [];
    if (checkBoxOptions.length > 0) {
        checkBoxOptions.forEach((item, index) => {
            console.log("item: ", item);
            list.push(
                <Checkbox
                    style={{ display: "flex", flexDirection: 'row' }}
                    key={item.value}
                    checked={item.checked}
                    disabled={submitOptions[tag] === '' ? false : !item.disabled}
                    value={item.value}
                    onChange={() => {handleCheckBox(index, item, checkBoxOptions, tag, submitOptions, page)}}
                >
                    {item.label}
                </Checkbox>
            )
        })
    }
    return list;
}

// 处理checkbox选择 checked: 指定当前是否选中, true 选中 false 未选中;disabled: 失效状态, true 失效 false 未失效
const handleCheckBox = (index, item, checkBoxOptions, tag, submitOptions, page) => {
    checkBoxOptions[index].checked = !checkBoxOptions[index].checked;  // 单击会改变 data 中 checked 的状态
    checkBoxOptions[index].disabled = !checkBoxOptions[index].disabled;  // 单击会改变 data 中 disabled 的状态
    let arr = checkBoxOptions.filter(it => it.checked);	// 筛选出 checked 为 true 的个数
    // console.log("arr: ", arr);
    submitOptions[tag] = arr.length > 0 ? arr[0].value : '';
    console.log("update options: ", checkBoxOptions);

    console.log("submitOptions: ", submitOptions);
    page.setState({
        submitOptions: submitOptions,
    })
    //     () => {
            // console.log("原本userInfo： ", this.state.userInfo);
            // console.log("缓存userInfoTmp： ", this.state.userInfoTmp);
            // console.log("原本areaOptions： ", this.state.areaOptions);
            // console.log("缓存areaOptionsTmp： ", this.state.areaOptionsTmp);
        // })
}

// 地理位置
const loadAreaMapSelect = (map, tag, submitOptions, page) => {
    let ui = [];
    if (map.length > 0) {
        ui.push (
            <div className={"select-wrap"}>
                <div className={"account-ct-select-wrap"}>
                    <div className={"account-ct-select-ct"}>
                        {loadProvince(map, tag, submitOptions, page)}
                    </div>
                </div>
                <div className={"account-ct-select-wrap"}
                     style={{display: page.state.showCity === -1 ? "none" : "", marginLeft: '8px'}}
                >
                    <div className={"account-ct-select-ct"}>
                        {loadCities(map, tag, submitOptions, page)}
                    </div>
                </div>
            </div>
        )
    };
    return ui;
}

const loadProvince = (map, tag, submitOptions, page) => {
    let list = [];
    if (map.length > 0) {
        map.forEach((area, index) => {
            let province = area.province;
            list.push(
                <div className={"area-geo-province"}
                     key={index + "area"}
                     onClick={() => {controlCity(index, page)}}
                     // onMouseLeave={() => {controlCity(-1, page)}}
                >
                    <div style={{width: "99%"}}>
                        {province}
                    </div>
                    <CaretRightOutlined style={{fontSize: "12px"}}/>
                </div>
            )
        })
    };
    return list;
}

const loadCities = (map, tag, submitOptions, page) => {
    let list = [];
    if (map.length > 0) {
        map.forEach((area, index) => {
            let cities = [];
            let province = area.province;
            let cityList = area.cities;
            cityList.forEach((city, num) => {
                cities.push(
                    <span className={"area-city-item"} key={num + "city"}
                          onClick={() => {
                              selectGeo(province, city, submitOptions, page)
                          }}
                    >
                        {city}
                    </span>
                )
            })
            list.push(
                <div className={"area-geo-cities"}
                     style={{display: page.state.showCity === index ? "" : "none"}}
                >
                    <div className={"area-city-list"}>
                        {cities}
                    </div>
                </div>
            )
        })
    };
    return list;
}

const controlCity = (num, page) => {
    page.setState({
        showCity: num
    })
}

const selectGeo = (province, city, submitOptions, page) => {
    let geo = province;
    if (city !== null) {
        geo += ("[" + city + "]");
    }
    submitOptions.geo = geo;
    page.setState({
        checkBoxType: '',
        showCity: -1,
        submitOptions: submitOptions,
    })
}

// 加载图片列表
const loadPicList = (imgUrl, submitOptions, tag) => {
    let list = [];
    if (submitOptions[tag].length > 0) {
        imgUrl[tag].forEach((item, index) => {
            list.push(
                <div className={"cert-process-pic-upload-img"}>
                    <img className={"img-style contain"}
                         src={item} alt={"exp_src"}/>
                </div>
            )
        })
        return list;
    } else {
        list.push(
            <div className={"cert-process-pic-upload-img"}>
                <img className={"img-style contain"}
                     src={EXP_SRC_SAMPLE} alt={"exp_src"}/>
            </div>
        )
        return list;
    }
}

const handleImgUploadInList = (e, maxWidth, maxHeight, pojo, imgUrl, tag, page) => {
    let file = e.target.files[0];
    console.log(file);

    let maxSize = 1024 * 1024 * 2;
    let imgSize = file.size;
    if (imgSize <= maxSize) {
        imageUploadCallBack(file, maxWidth, maxHeight).then(res => {
            // console.log("上传图片res：", res);
            let img = res.data.link;  // 包含（data:image/png;base64,）
            let imgToUrl = img.split(',')[1];
            pojo[tag].push(imgToUrl);
            imgUrl[tag].push(img);
            page.setState({
                submitOptions: pojo,
            },
                () => {
                    console.log("submitOptions: ", pojo);
                })
        })

    } else {
        fail("图片大小在2MB以内")
    }
}

// 上传图片
const handleImgUpload = (e, maxWidth, maxHeight, certInfo, imgUrl, tag, page) => {
    // console.log(e);
    let file = e.target.files[0];
    // console.log(file);

    if (file) {
        imageUploadCallBack(file, maxWidth, maxHeight).then(res => {
            // console.log("上传图片res：", res);
            let img = res.data.link;  // 包含（data:image/png;base64,）
            let imgToUrl = img.split(',')[1];
            if (tag === "expSrc") {
                certInfo[tag].push(imgToUrl);
                imgUrl[tag].push(img);
                certInfo["extraSource"].push(imgToUrl);
            } else {
                imgUrl[tag] = img;
                certInfo[tag] = imgToUrl;
                if (tag === "compSrc") {
                    certInfo["extraSource"].push(imgToUrl);
                }
            }
            page.setState({
                    certInfo: certInfo,
                    imgUrl: imgUrl,
                },
                () => {
                    console.log("certInfo: ", certInfo);
                    console.log("imgUrl: ", imgUrl);
                })
        })

    }
}

// 科研成果/技术需求提交页面登陆超时的登录界面
const loadLoginModal = (loginModalStatus, userInfo, loginPojo, loginType, page) => {
    if (loginModalStatus === true) {
        let canSend = page.state.secondI === 30;
        return (
            <div className={"modal-plugin"}>
                <div className={"login-plugin"}>
                    <div className={"modal-header-wrap"}>
                        <div className={"modal-header-title"}>
                            {"用户登录"}
                        </div>
                        <div className={"modal-header-subtitle"}>
                            {"登录已超时，请您重新登录"}
                        </div>
                    </div>
                    {
                        loginType === 1 || loginType === 2 ?
                            <Fragment>
                                <div className={"login-plugin-main"}>
                                    {
                                        loginType === 1 ?
                                            <Fragment>
                                                <input className={"login-plugin-input"}
                                                       id={"pwd"}
                                                       placeholder={"请输入登陆密码"}
                                                       type={page.state.showPwd ? "text" : "password"}
                                                       maxLength={20}
                                                       onChange={(e) => {handleLoginPojo(e, loginPojo, "userPwd", page)}}
                                                       onBlur={(e) => {handleOnBlur(e, "userPwd")}}
                                                />
                                                <EyeOutlined className={"login-plugin-pwd-eye-out"}
                                                             style={{color: page.state.showPwd ? "#8aaed7" : "#c4c4c4",}}
                                                             onClick={() => {
                                                                 page.setState({showPwd: !page.state.showPwd})
                                                             }}/>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <input className={"login-plugin-input"}
                                                       id={"ver-code"}
                                                       type={"text"}
                                                       maxLength={6}
                                                       placeholder={"请输入短信验证码"}
                                                       onChange={(e) => {handleLoginPojo(e, loginPojo, "code", page)}}
                                                       onBlur={(e) => {handleOnBlur(e, "code")}}
                                                />
                                                <div className={!canSend ? "login-plugin-send-code-get save-button-none" : "login-plugin-send-code-get"}
                                                     onClick={() => {getVerCodeByModal(page)}}
                                                >
                                                    {!canSend ? page.state.secondI + "秒后重发" : "发送验证码"}
                                                </div>
                                            </Fragment>
                                    }
                                </div>
                                <div className={"login-plugin-btn-wrap"}>
                                    <button className={"center-ct-button save-button"}
                                            onClick={() => {
                                                confirmLogin(loginModalStatus, loginPojo, page)
                                            }}
                                    >
                                        {"登录"}
                                    </button>
                                    <button className={"center-ct-button cancel-button"} style={{marginLeft: '10px'}}
                                            onClick={() => {
                                                cancelLogin()
                                            }}
                                    >
                                        {"取消"}
                                    </button>
                                </div>
                            </Fragment>
                            :
                            <div className={"login-plugin-qr"}>
                                <div className={"login-plugin-qr-left"}>
                                    <div>
                                        { loginType === 3 ? "微信扫码登录" : "支付宝扫码登录"}
                                    </div>
                                    <button className={"center-ct-button cancel-button"}
                                            onClick={() => {
                                                cancelLogin()
                                            }}
                                    >
                                        {"取消"}
                                    </button>
                                </div>
                                <div className={"login-plugin-qr-right"}>
                                    <img className={"img-style contain"} src={QR_CODE} alt={""}/>
                                </div>
                            </div>

                    }
                    <div className={"login-plugin-way-wrap"}>
                        <div className={"login-plugin-way-header"}>
                            <div className={"login-plugin-way-line"}/>
                            <span className={"login-plugin-way-txt"}>
                                {"其他方式登录"}
                            </span>
                            <div className={"login-plugin-way-line"}/>
                        </div>
                        <div className={"login-plugin-way-ct"}>
                            <MobileOutlined
                                style={{display: loginType === 1 ? "none" : "",
                                    fontSize: "26px", margin: "0 20px 0 20px", color: "rgb(40,40,40)", cursor: "pointer"}}
                                onClick={() => {
                                    switchLoginWay(1, page)
                                }}
                            />
                            <MessageOutlined
                                style={{display: loginType === 2 ? "none" : "",
                                    fontSize: "26px", margin: "0 20px 0 20px", color: "rgb(40,40,40)", cursor: "pointer"}}
                                onClick={() => {
                                    switchLoginWay(2, page)
                                }}
                            />
                            <WechatFilled
                                style={{display: loginType === 3 ? "none" : "",
                                    fontSize: "28px", margin: "0 20px 0 20px", color: "rgb(0,231,43)", cursor: "pointer"}}
                                onClick={() => {
                                    switchLoginWay(3, page)
                                }}
                            />
                            <AlipayCircleFilled
                                style={{display: loginType === 4 ? "none" : "",
                                    fontSize: "26px", margin: "0 20px 0 20px", color: "rgb(0,166,255)", cursor: "pointer"}}
                                onClick={() => {
                                    switchLoginWay(4, page)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// 处理loginPojo
const handleLoginPojo = (e, loginPojo, tag, page) => {
    let value = e.target.value;
    loginPojo[tag] = value;
    page.setState({
        loginPojo: loginPojo,
    })
}

// 监听输入结束
const handleOnBlur = (e, tag) => {
    let value = e.target.value;
    console.log("监听结果：", value);
    if (value && !InputTest[tag].test.test(value)) {
        fail(InputTest[tag].error);
    }
}

// 获取验证码发送接口（type 1 手机； 2 邮箱）
const getVerCodeByModal = (page) => {
    let userInfo = UserInfoStorage.get("userInfo");
    let params = {
        phone: userInfo.userPhone,
        tempId: TempId,
        reg: false,
    };
    console.log("获取验证码发送接口：", params);
    sendGetRequest(SendPhoneValidUrl, params, true).then(res => {
        console.log("发送验证码res: ", res);
        if (res === connectionError) {
            fail(findMsgByError(connectionError));
        } else {
            if (res && res.hasOwnProperty("success") && res.success) {
                handleCountDown(30, page);
                success("验证码发送成功，请查收！")
            } else {
                fail(findMsgByError(res[error]));
            }
        }
    })
}

// 切换登陆方式
const switchLoginWay = (type, page) => {
    page.setState({
        loginType: type,
    })
}

// 登录超时弹窗
const setLoginModalStatus = (status, page) => {
    page.setState({
        loginModalStatus: !status,
    })
}

// 确认登陆
const confirmLogin = (loginModalStatus, loginPojo, page) => {
    let userInfo = UserInfoStorage.get("userInfo");
    loginPojo["userPhone"] = userInfo.userPhone;
    console.log("登陆超时再次登录pojo: ", loginPojo);
    sendPostRequest(genPojo(LoginUrl, loginPojo), true).then(res => {
        if (res === connectionError) {
            fail(findMsgByError(connectionError));
        } else {
            if (res && res.hasOwnProperty("success") && res.success) {
                console.log(res[data]);
                success("登录成功");
                setLoginModalStatus(loginModalStatus, page)
            } else {
                fail(findMsgByError(res[error]));
            }
        }
    })
}

// 取消登录
const cancelLogin = () => {
    UserInfoStorage.remove("userInfo");
    window.location.href = '/login';
}

// 深拷贝
const deepClone = (obj) => {
    let objCopy = obj instanceof Array ? [] : {};
    for (let item in obj) {
        objCopy[item] = typeof obj[item] === 'object' ? deepClone(obj[item]) : obj[item];
    }
    return objCopy;
}

// push/adv数据的minSize
const pushAndAdvMin = {
    outcomePushMin: 6,
    outcomeAdvMin: 5,
    demandPushMin: 6,
    demandAdvMin: 5,
    expertPushMin: 6,
    expertAdvMin: 6,
    policyPushMin: 6,
    policyAdvMin: 6,
    newsPushMin: 6,
    newsAdvMin: 6,
}

// 获取push数据
const getPushData = (indexList, page) => {
    indexList.forEach((item) => {
        let params = {
            index: item,
        };
        sendGetRequest(GetPushDataUrl, params, false).then(res => {
            console.log("获取push数据res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    switchPushData(item, page, res);
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    })
}

// switch push数据
const switchPushData = (index, page, res) => {
    switch (index) {
        case dataIndexE.outcome: {
            page.setState({
                outcomePushData: res[data],
            })
            break;
        }
        case dataIndexE.expert: {
            page.setState({
                expertPushData: res[data],
            })
            break;
        }
        case dataIndexE.demand: {
            page.setState({
                demandPushData: res[data],
            })
            break;
        }
        case dataIndexE.policy: {
            page.setState({
                policyPushData: res[data],
            })
            break;
        }
        case dataIndexE.news: {
            page.setState({
                newsPushData: res[data],
            })
            break;
        }
    }
}

// 不同数据类型的index
const dataIndexE = {
    outcome: "1344",
    demand: "9919",
    expert: "2577",
    policy: "3478",
    news: "3566",
}

// adv数据对应页面的list
const advList = {
    outcome: [dataIndexE.policy],
    demand: [dataIndexE.policy],
    expert: [dataIndexE.policy],
    solution: [dataIndexE.outcome, dataIndexE.expert, dataIndexE.policy],
    policy: [dataIndexE.outcome, dataIndexE.expert],
    news: [dataIndexE.policy, dataIndexE.demand],
};


// 获取adv数据
const getAdvData = (indexList, page) => {
    let map = new Map();
    indexList.forEach((item) => {
        let params = {
            index: item,
        };
        console.log("获取adv数据params: ", params);
        sendGetRequest(GetAdvDataUrl, params, false).then(res => {
            console.log("获取adv数据res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    map.set(item, res[data]);
                    if (map.size === indexList.length) {
                        page.setState({
                            advMap: map,
                            isLoadAdvMap: true,
                        })
                    }
                } else {
                    fail(findMsgByError(res[error]));
                    map.set(item, []);
                    page.setState({
                        advMap: map,
                    })
                }
            }
        })
    })
}

// 根据省份显示对应的缩写
const PriAbbrJson = {
    "中华人民共和国": "国",
    "上海": "沪",
}

// 查询用户所有解锁项目
const getUnlockItem = (id, page) => {
    let userInfo = UserInfoStorage.get("userInfo");
    let params = {
        userId: userInfo.userId,
        // index: index,
    };
    sendGetRequest(CheckUnlockItemByUserUrl, params, true).then(res => {
        console.log("查询用户所有解锁项目res: ", res);
        if (res === connectionError) {
            fail(findMsgByError(connectionError));
        } else {
            if (res && res.hasOwnProperty("success") && res.success) {
                let pageData = page.state.pageData;
                let unlockList = res[data];
                let len = unlockList.length;
                for (let i = 0; i < len; i++) {
                    if (unlockList[i].itemId === pageData[id]) {
                        page.setState({
                            isUnlock: true,
                        });
                        break;
                    }
                }
            } else if (res[error] === loginOutErrOr) {
                UserInfoStorage.remove("userInfo");
                failReturn(findMsgByError(res[error]), LoginRoot)
            } else {
                fail(findMsgByError(res[error]));
            }
        }
    })
}

// 处理bigData
// 适用于policy和news
const handleBigData = (data, type, userInfo) => {
    let bigData = data["bigData"];
    let orders = bigData["orders"];
    let pics = bigData["pics"];
    let texts = bigData["texts"];
    console.log(bigData);
    let ui = [];
    let len = orders.length;
    if (len > 0) {
        for (let i = 0; i < len; i++) {
            if (orders[i].substr(0, 1) === "p") {
                // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
                // let picDir = require.context(srcHeader + "/src/", true, /\.(png|jpg)$/);
                // let imgUrl = picDir("./" + data[type + "Id"] + "/" + orders[i] + ".jpg");
                let imgUrl = SrcHeader + data[type + "Id"] + "/" + orders[i] + ".jpg"
                ui.push(
                    <div className={"article-img"}>
                        <img className={"img-style contain"} src={imgUrl} alt={""}/>
                    </div>
                )
            } else {
                ui.push(
                    <p>{texts[orders[i]]}</p>
                )
            }
            if (type === "policy" && orders[i] === "t1") {
                if (i + 1 !== len) {
                    console.log("t1不是最后一个");
                    ui.push(
                        userInfo ?
                            texts[orders[i + 1]] ?
                                null
                                :
                                <div className={"lock-part"}>
                                    <img className={"img-style cover"} src={LOCK_PART} alt={""}/>
                                    <PayPanel parent={this} type={2}
                                              classWrap={"pay-panel"} title={"升级为会员后即可解锁所有科技政策"}
                                              itemId={data[type + "Id"]}
                                    />
                                </div>
                            :
                            <div className={"lock-part"}>
                                <img className={"img-style cover"} src={LOCK_PART} alt={""}/>
                                <PayPanel parent={this} type={1}
                                          classWrap={"login-panel"} title={"会员登陆后即可解锁所有科技政策"}
                                />
                            </div>
                    )
                } else {
                    console.log("t1是最后一个");
                    break
                }
            }
        }
    }
    return ui;
}

// 支付接口首个步骤
const genInitOrder = (orderPojo, index, ct, back) => {
    let params = {
        order: orderPojo,
        index: index,
        ct: ct,
    };
    sendGetBack(InitOrderUrl, params, true, back);
}


const genProcessBack = (myBack, hasData, failBack = commFail()) => {
    const back: DataBack = {
        successBack(res: any) {
            if (hasData) {
                myBack.callDataBack(res);
            } else {
                myBack.callBack();
            }
        },

        failBack(error: any) {
            failBack.callDataBack(error)
        }
    }
    return back;
}

const commFail = () => {
    const back: WinBack = {
        callDataBack(data: any) {
            if (data === loginOutErrOr) {
                UserInfoStorage.remove("userInfo");
                failReturn(findMsgByError(data), window.location.href);
            } else {
                fail(findMsgByError(data))
            }
        }
    }
    return back;
}

const keySSS = 'asdasdsadacasdas112323';

// 缓存滚动条位置
const pageBeforeLoad = () => {
    let scrollPos;
    if (typeof window.pageYOffset !== 'undefined') {
        scrollPos = window.pageYOffset;
    }
    else if (typeof document.compatMode !== 'undefined' && document.compatMode !== 'BackCompat') {
        scrollPos = document.documentElement.scrollTop;
    }
    else if (typeof document.body !== 'undefined') {
        scrollPos = document.body.scrollTop;
    }
    storage.set("scrollTop", scrollPos)
}

const pageOnLoad = () => {
    // if (document.cookie.match(/scrollTop=([^;]+)(;|$)/) != null) {
        // let arr = document.cookie.match(/scrollTop=([^;]+)(;|$)/); //cookies中不为空，则读取滚动条位置
        let arr = storage.get("scrollTop");
        if (arr) {
            if (parseInt(arr) <= 1480) {
                document.documentElement.scrollTop = parseInt(arr);
                document.body.scrollTop = parseInt(arr);
            } else {

            }

        }

    // }
}

// 数据缓存获取移除组件
const storage = {
    set(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value))
    },
    getF(key) {
        let value = JSON.parse(sessionStorage.getItem(key));
        return value === "undefined" ? null : value;
    },
    get(key) {
        return JSON.parse(sessionStorage.getItem(key));
    },
    remove(key) {
        sessionStorage.removeItem(key)
    }
}

// url获取state
const handleUrlState = (statePart) => {
    let stateCodes = statePart[0].split("state=")[1];
    let toIndex = stateCodes.split("-")[0];
    let stateCode = stateCodes.split("-")[1];
    console.log(statePart, toIndex, stateCode);

    return {index: toIndex, stateCode: stateCode};
}

// 支付宝账号调用
const handleAliAuth = (index) => {
    /**
     * app_id 参数是商户实际使用的 appId
     * scope 参数传递 auth_user
     * redirect_uri 为页面跳回地址（重定向地址）
     * state 为商户自定义参数
     */
    let appId = AliId;
    let scope = 'auth_user';
    let redirectUrl = encodeURIComponent(Header + AliRoot);
    let num = Math.ceil(Math.random() * 1000)
    let state = index + "-" + num;
    let storageKey = (Number.parseInt(index) === 1 ? "aliLoginState" : "aliBindState");
    console.log(storageKey);
    storage.set(storageKey, num);
    let url = AliOAuthUrl + "?app_id=" + appId + "&scope=" + scope + "&redirect_uri=" + redirectUrl + "&state=" + state;
    window.location.href = url;
}

// 处理货币价格
const handlePrice = (num) => {
    if (num && num !== 0 && String(num).indexOf(".") === -1) {
        num = num.toString().replace(',','');
        for (let i = 0; i < Math.floor((num.length-(1 + i)) / 3); i++){
            // 货币，千分位间隔
            num = num.substring(0, num.length-(4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
        }
    }
    return num;
}

export {
    MouseHover,
    SolutionRoot,
    AboutRoot,
    OutcomeRoot,
    HomeRoot,
    FilterHeaderCt,
    AreaMap,
    InitTags,
    ExpertRoot,
    DemandRoot,
    RegisterRoot,
    handleAdRightWinSizeChange,
    handlePageScrollMove,
    validNum,
    LoginRoot,
    AdComp,
    PolicyRoot,
    PolicyMap,
    NewsRoot,
    SaveBt,
    LoadingOutcomeCopAds,
    LoadingExpertCopAds,
    LoadingDemandCopAds,
    LoadingSolutionCopAds,
    LoadingPolicyCopAds,
    LoadAds,
    solutionTechSpeList,
    solutionTechPerList,
    solutionTechCopList,
    CenterRoot,
    handleClickMonitor,
    PageId,
    SolutionId,
    Wechat,
    AliRoot,
    InputTest,
    handleCountDown,
    handleSendButton,
    UserInfoStorage,
    getUserInfoCache,
    handleLogout,
    schTime,
    scheduleTime,
    regPhone,
    regEmail,
    filterOutcome,
    filterDemand,
    filterExpert,
    filterPolicy,
    filterType,
    filterTypeBySub,
    filterSrcType,
    filterSrcTypeBySub,
    filterStage,
    filterAreaTech,
    filterArea,
    filterCopWay,
    filterCopWayBySub,
    filterTitle,
    picTypes,
    getPicTypeByName,
    imageUploadCallBack,
    mapToObj,
    objectToList,
    handleExpertTitle,
    handleSrcType,
    toThousandsReg,
    arrayIdentical,
    findFavorDataByType,
    handleIdByIndex,
    handleOutcomeType,
    handleCopWay,
    favorOpFun,
    isSemicolonValid,
    showSemData,
    getPageDataByIndex,
    scrollTop,
    switchLogin,
    utcTimeFormat,
    week,
    schList,
    formatStrDouble,
    isHasRepeatList,
    mod,
    getWeek,
    getSubtraction,
    findUserAcceptItems,
    closeUserItem,
    changeSubmitOptions,
    setModalStatus,
    setCloseItemModalStatus,
    copyFun,
    switchCheckBoxType,
    loadAreaCheckBox,
    handleCheckBox,
    loadAreaMapSelect,
    loadPicList,
    handleImgUploadInList,
    handleImgUpload,
    loadLoginModal,
    setLoginModalStatus,
    deepClone,
    pushAndAdvMin,
    getPushData,
    getAdvData,
    dataIndexE,
    advList,
    PriAbbrJson,
    getUnlockItem,
    handleBigData,
    genInitOrder,
    genProcessBack,
    keySSS,
    outcomeType,
    handleAdRightWinSizeInit,
    pageBeforeLoad,
    pageOnLoad,
    storage,
    handleUrlState,
    handleAliAuth,
    handlePrice,
};
