import React, {Component} from "react";
import "./center.css";
import {data, error, FindExpertByRefUrl, loginOutErrOr, sendGetRequest, SrcHeader} from "../../tool/http/Request";
import {handleExpertTitle, handleSrcType, LoginRoot, UserInfoStorage} from "../../tool/Const";
import {connectionError, fail, failReturn, findMsgByError} from "../../tool/http/ErrorMgr";
import AUTH_CHECK from "../../assets/img/auth_check.png";

class ExpertResume extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expertRes: '',  // 专家简历
        }
    }

    // 加载专家简历
    loadExpertResume = (expertRes) => {
        // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
        // let picDir = require.context("../../assets/expert/", true, /\.(png|jpg)$/);
        if (expertRes) {
            // let imgUrl = picDir("./" + expertRes["expertBanner"]);
            let imgUrl = SrcHeader + expertRes["expertId"] + "/" + expertRes["expertBanner"];
            return (
                <div className={"expert-resume-ct"}>
                    <div className={"expert-resume-ele pic-ele-style"}>
                        <div className={"expert-resume-txt"}>
                            {"照片："}
                        </div>
                        <div className={"expert-resume-pic"}>
                            <img className={"img-style contain"} src={imgUrl} alt={"avatar"}/>
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"姓名："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input"}*/}
                        {/*       id={"expertName"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={expertRes.expertName}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input"}>
                            {expertRes["expertName"]}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"毕业院校："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input"}*/}
                        {/*       id={"expertSch"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={expertRes.expertSch}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input"}>
                            {expertRes["expertSch"]}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"荣誉职称："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input"}*/}
                        {/*       id={"expertHor"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={expertRes.expertHor}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input"}>
                            {expertRes["expertHor"]}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"职称："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input"}*/}
                        {/*       id={"expertTitle"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={handleExpertTitle(expertRes.expertTitle)}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input"}>
                            {handleExpertTitle(expertRes["expertTitle"])}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"专家来源："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input"}*/}
                        {/*       id={"srcType"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={handleSrcType(expertRes.srcType)}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input"}>
                            {handleSrcType(expertRes["srcType"])}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"所属机构："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input"}*/}
                        {/*       id={"expertRoot"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={expertRes.expertRoot}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input"}>
                            {expertRes["expertRoot"]}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"担任职务："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input"}*/}
                        {/*       id={"expertJob"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={expertRes.expertJob}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input"}>
                            {expertRes["expertJob"]}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"研究领域："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input"}*/}
                        {/*       id={"expertAreas"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={expertRes.expertAreas}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input"}>
                            {expertRes["expertAreas"]}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"地理位置："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input"}*/}
                        {/*       id={"expertGeo"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={expertRes.expertGeo}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input"}>
                            {expertRes["expertGeo"]}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"预约费用："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input"}*/}
                        {/*       id={"expertPrice"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={expertRes.expertPrice}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input"}>
                            {expertRes["expertPrice"]}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"浏览次数："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input"}*/}
                        {/*       id={"clickNum"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={expertRes.clickNum}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input"}>
                            {expertRes["clickNum"]}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"收藏次数："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input"}*/}
                        {/*       id={"saveNum"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={expertRes.saveNum}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input"}>
                            {expertRes["saveNum"]}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"专家资源页面："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input"}*/}
                        {/*       id={"expertId"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={expertRes.expertId}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input"}>
                            {expertRes["expertId"]}
                        </div>
                        <div className={"expert-resume-txt"}
                             onClick={() => {
                                 window.open('/expert/' + expertRes["expertId"], "_blank")
                             }}
                        >
                            {"点击跳转"}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"简介："}
                        </div>
                        {/*<div className={"account-ct-user-setting-input long-txt"}*/}
                        {/*       id={"expertDes"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={expertRes.expertDes}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input long-txt"}>
                            {expertRes["expertDes"]}
                        </div>
                    </div>
                    <div className={"expert-resume-ele"}>
                        <div className={"expert-resume-txt"}>
                            {"正文："}
                        </div>
                        {/*<input className={"account-ct-user-setting-input long-txt"}*/}
                        {/*       id={"expertCt"}*/}
                        {/*       type={"text"}*/}
                        {/*       value={expertRes.expertCt}*/}
                        {/*       readOnly={"readonly"}*/}
                        {/*/>*/}
                        <div className={"account-ct-user-setting-input long-txt"}>
                            {expertRes["expertCt"]}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={"expert-resume-empty-ct"}>
                    <div className={"cert-check-img"}>
                        <img className={"img-style contain"} src={AUTH_CHECK} alt={""}/>
                    </div>
                    <div className={"cert-check-txt"}>
                        {"尊敬的专家，您未填写相关的专家简历，请联系管理员进行沟通与申请，谢谢。"}
                    </div>
                </div>
            )
        }
    }

    componentDidMount() {
        this.findExpertByRef();
    }

    // 根据refId获取专家简历
    findExpertByRef = () => {
        let userInfoCache = UserInfoStorage.get("userInfo");
        let userId = userInfoCache.userId;
        let params = {
            refId: userId,
        }
        sendGetRequest(FindExpertByRefUrl, params, true).then(res => {
            console.log("根据refId获取专家简历res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.setState({
                        expertRes: res[data],
                    })
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    // fail(findMsgByError(res[error]));
                }
            }
        })
    }

    render() {
        let expertRes = this.state.expertRes;

        return (
            <div className={"expert-fun-ct"}>
                <div className={"center-ct-header"}>
                    <div className={"center-ct-header-wrap"}>
                        <div className={"center-ct-header-icon"}/>
                        <div className={"center-ct-header-title"}>
                            {"专家简历"}
                        </div>
                        <div className={"center-ct-header-subtitle"}>
                            {"专家入驻，完善资料，变现能力价值"}
                        </div>
                    </div>
                </div>
                <div className={"expert-resume-wrap"}>
                    {this.loadExpertResume(expertRes)}
                </div>
            </div>
        )
    }
}

export default ExpertResume;
