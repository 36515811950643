import React, {Component} from 'react';
import "../tool.css"
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {InitTags} from "../Const";

const LineMax = 54;

class FilterMain extends Component {
    loadingFilter = (options, filterCt, parent, initTag) => {
        let select = parent.state.selectItem;
        let items = [];
        filterCt.forEach((item, index) => {
            items.push(this.fillItem(item, index, select, options, parent, initTag))
        })
        return items;
    }

    fillItem = (item, index, select, options, parent, initTag) => {
        let filled = this.fillSubItems(item, index, select, options, parent, initTag);
        return (
            [<div className={"filter-item"} key={index}>
                <div className={"filter-item-title"} id={"title" + index}
                     style={select === index ? {
                         background: "rgba(45,108,184,0.8)",
                         color: "white",
                         transitionProperty: "background, opacity",
                         transitionDuration: "400ms",
                     } : {}}>
                    <span>{item.title}</span>
                </div>
                {filled[0]}
            </div>,
                <div className={"filter-more-item-outer"}
                     style={select === index ? {
                         height: "auto", transitionProperty: "height, opacity",
                         transitionDuration: "400ms",
                     } : {
                         height: "0", margin: "0", transitionProperty: "height, opacity",
                         transitionDuration: "400ms",
                     }}
                     key={index + "more"}>
                    {select === index ?
                        <ul className={"filter-more-ul"} id={index + "ul"}>
                            {filled[1]}
                        </ul> : null}
                </div>]
        )
    }

    setSelectItem = (ip, value, options, parent, initTag) => {
        let pre = options[ip];
        if (pre !== value) {
            options[ip] = value;
            if (ip === "type") {
                InitTags(options, initTag, false)
            }
            parent.setState(
                {
                    selectItem: -1,
                }
            )
            parent.setOptions(options)
        }
    }

    handleClickMore = (surIndex, select, parent) => {
        let set = select === surIndex ? -1 : surIndex;
        parent.setState({
            selectItem: set,
        })
    }

    fillSubItems = (item, surIndex, select, options, parent, initTag) => {
        let ip = item.ip;
        let value = options[ip];
        let subs = [];
        let mores = [];
        let curMax = 0;
        let hasMore = false;
        item.ct.forEach((item, index) => {
            let len = item.length + 2;
            if (len + curMax < LineMax) {
                let sub = (<div className={"filter-item-sub"} key={index + "sub"}
                                style={value === item ? {color: "#0073ff"} : {}} onClick={() => {
                    this.setSelectItem(ip, item, options, parent, initTag)
                }}>{item}</div>)
                subs.push(sub)
            } else {
                if (!hasMore) {
                    let more = (
                        <div className={"filter-item-more"} key={index + "moreSub"}>
                            <div className={"filter-more-bt"} onClick={() => {
                                this.handleClickMore(surIndex, select, parent)
                            }}>
                                {surIndex === select ? <UpOutlined/> : <DownOutlined/>}
                            </div>
                        </div>
                    )
                    subs.push(more)
                    hasMore = true
                }
                mores.push(
                    <li className={"filter-sub-more-item"} key={index + "sub"}
                        style={value === item ? {color: "#0073ff"} : {}} onClick={() => {
                        this.setSelectItem(ip, item, options, parent, initTag)
                    }}>
                        <a>{item}</a>
                    </li>
                )
            }
            curMax += len;
        })
        return [subs, mores];
    }

    render() {
        let parent = this.props.parent;
        let options = this.props.options;
        let filterCt = this.props.filterCt;
        let initTag = this.props.initTag;
        return this.loadingFilter(options, filterCt, parent, initTag)
    }
}

export default FilterMain;