import React, {Component, Fragment} from "react";
import "./tool.css";
import PayOperator from "../page/center/PayOperator";
import {genProcessBack} from "./Const";
import {CheckOrderFinishUrl, data, genPojo, sendPostRequest} from "./http/Request";
import {connectionError} from "./http/ErrorMgr";
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons";
import {delWin} from "./Launcher";
import QR_SAMPLE from "../assets/img/qr_sample.png";
import FINISH from "../assets/icon/finish.png";
import CANCEL from "../assets/icon/cancel.png";

const imgHeader = "data:image/png;base64,";

const countDownNum = 30;

class QrWin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            qr: '',
            count: 0,
            order: '',
            secondI: countDownNum,  // 支付间隔（s）
            payStatus: 0,  // 支付状态;0 正在支付;1 完成支付;2 支付超时
        }
    }

    loadOrderInfo = (requestPojo, secondI) => {
        let orderPojo = requestPojo.orderPojo;
        return (
            <Fragment>
                <div className={"qr-info-top"}>
                    <div className={"qr-info-tip"}>
                        <span>{"请在"}</span>
                        <span className={"pay-time"}>{secondI}</span>
                        <span>{"秒内支付完成"}</span>
                    </div>
                    <div className={"order-info-id"}>
                        <span>{"支付未完成前,请不要关闭当前支付页面"}</span>
                        {/*<span>{"订单号："}</span>*/}
                        {/*<span>{orderPojo.orderId}</span>*/}
                    </div>
                </div>
                <div className={"qr-info-bottom"}>
                    <span>{"支付金额："}</span>
                    <span className={"pay-num"}>{"¥ " + orderPojo.rmbPrice + ".00"}</span>
                </div>
            </Fragment>
        )
    }

    loadCt = (loaded, qr, way, secondI, payStatus) => {
        return (
            <Fragment>
                <div className={"qr-box"}>
                    {
                        loaded ?
                            this.handleQrOverTime(qr, way, secondI)
                            :
                            <LoadingOutlined />
                    }
                </div>
                <div className={"qr-text"}>
                    {this.switchPayStatus(payStatus, way)}
                </div>
            </Fragment>
        )
    }

    switchPayStatus = (payStatus, way) => {
        switch (payStatus) {
            case 0 : {
                return (
                    way === 1 ? "请使用微信扫码支付" : "请使用支付宝扫码支付"
                )
            }
            case 1: {
                return (
                    way === 1 ?
                        <div className={"qr-status-wrap"}>
                            <div className={"qr-status-icon"}>
                                <img className={"img-style contain"} src={FINISH} alt={"finish"}/>
                            </div>
                            <span style={{paddingLeft: '5px'}}>{"微信支付已完成"}</span>
                        </div>
                        :
                        <div className={"qr-status-wrap"}>
                            <div className={"qr-status-icon"}>
                                <img className={"img-style contain"} src={FINISH} alt={"finish"}/>
                            </div>
                            <span style={{paddingLeft: '5px'}}>{"支付宝支付已完成"}</span>
                        </div>
                )
            }
            case 2: {
                return (
                    <div className={"qr-status-wrap"}>
                        <div className={"qr-status-icon"}>
                            <img className={"img-style contain"} src={CANCEL} alt={"finish"}/>
                        </div>
                        <span style={{paddingLeft: '5px'}}>{"支付已超时"}</span>
                    </div>
                )
            }
        }
    }

    handleCountDownByPay = (seconds) => {
        let second = seconds;
        const countDown = () => {
            if (second > 0) {
                second--;
                this.setState({
                    secondI: second,
                })
            }
            // if (second === 0) {
            //     second = seconds;
            //     this.setState({
            //         payStatus: 2,
            //         loaded: false,
            //     });
            //     return;
            // }
            setTimeout(countDown, 1000);
        };
        setTimeout(countDown, 1000);
    };

    handleQrOverTime = (qr, way, secondI) => {
        if (secondI === 0) {
            return (
                <div className={"qr-sample"} onClick={() => {this.refreshQr()}}>
                    <img className={"img-style contain"} src={QR_SAMPLE} alt={"qr"}/>
                </div>
            )
        } else {
            return (
                way === 1 ?
                    <div className={"qr-sample"}>
                        <img className={"img-style contain"} src={imgHeader + qr} alt={"qr"}/>
                    </div>
                    :
                    <iframe className={"qr-iframe"} src={qr}/>
            )
        }
    }

    refreshQr = () => {
        this.setState({
            secondI: countDownNum,
            payStatus: 0,
            count: 0,
        })
    }

    handlePayByAli = (pojo) => {
        let operator = new PayOperator();

        let _this = this;
        const myBack: WinBack = {
            callDataBack(data: any) {
                _this.props.setPojo(pojo, data['orderId']);
                _this.setState({
                    qr: data['qrCode'],
                    loaded: true,
                })

            }
        }

        operator.payByAli(pojo, genProcessBack(myBack, true));
    }

    handlePayByWx = (pojo) => {
        let operator = new PayOperator();

        let _this = this;
        const myBack: WinBack = {
            callDataBack(data: any) {
                console.log("wx: ", data);
                _this.props.setPojo(pojo, data['orderId']);
                _this.setState({
                    qr: data['qr'],
                    loaded: true,
                })

            }
        }

        operator.payByWx(pojo, genProcessBack(myBack, true));
    }

    async checkPayFinish() {
        let pojo = this.props.requestPojo;

        console.log("监测订单是否支付pojo: ", pojo);
        return await sendPostRequest(genPojo(CheckOrderFinishUrl, pojo), true).then(res => {
            console.log(res);
            if (res === connectionError) {

            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    console.log("支付宝或微信支付完成res：", res);
                    this.props.fun(pojo, res[data]);
                    this.setState({
                        payStatus: 1,
                    });
                    setTimeout(() => delWin(this.props.parentId, this.props.winId), 4000)
                } else {
                    let count = this.state.count;
                    console.log(count);
                    if (count < 30) {
                        count++;
                        this.setState({
                                count: count,
                            },
                            () => {
                                this.time = setTimeout(() => {
                                    this.checkPayFinish()
                                }, 1000)
                            })
                    } else {
                        // failReturn("支付超时", window.location.href);
                        this.setState({
                            payStatus: 2,
                            count: 0,
                        })
                    }
                }
            }
        })
    }

    componentDidMount() {
        this.init();
        this.handleCountDownByPay(countDownNum, this);
    }

    init = () => {
        let way = this.props.way;
        let requestPojo = this.props.requestPojo;
        if (way === 1) {
            console.log("微信支付");
            this.handlePayByWx(requestPojo);
        } else {
            console.log("支付宝支付");
            this.handlePayByAli(requestPojo);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.loaded !== this.state.loaded || (prevState.payStatus !== this.state.payStatus && this.state.payStatus === 0)) {
            if (this.state.loaded === true) {
                console.log("二维码收到，开始监测是否订单成功");
                this.checkPayFinish().then(res => {

                })
            }
        }

        if (prevState.secondI !== this.state.secondI && this.state.secondI === countDownNum) {
            this.handleCountDownByPay(countDownNum, this);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.time);
        this.time = null;
    }

    render() {
        let parentId = this.props.parentId;
        let winId = this.props.winId;
        let way = this.props.way;
        let requestPojo = this.props.requestPojo;

        // console.log("requestPojo: ", requestPojo);

        let loaded = this.state.loaded;
        let qr = this.state.qr;

        let order = this.state.order;

        let secondI = this.state.secondI;
        let payStatus = this.state.payStatus;

        console.log("secondI: ", secondI);

        return (
            <div className={"qr-plugin"}>
                <div className={"pay-way-header"}>
                    <span>{"支付中心"}</span>
                </div>
                <div className={"qr-wrap"}>
                    <div className={"qr-info"}>
                        {this.loadOrderInfo(requestPojo, secondI)}
                    </div>
                    <div className={"qr-code"}>
                        {this.loadCt(loaded, qr, way, secondI, payStatus)}
                    </div>
                </div>
                <div className={"modal-close-wrap"}>
                    <CloseOutlined onClick={() => {delWin(parentId, winId)}}/>
                </div>
            </div>
        );
    }
}

export default QrWin;