import React, {Component} from 'react';
import "./about.css";
import Bk from "../pic/aboutCp.png";
import CULTURE1 from "../pic/culture1.jpg";
import CULTURE2 from "../pic/culture2.jpg";
import CULTURE3 from "../pic/culture3.jpg";
import CULTURE4 from "../pic/culture4.jpg";
import IMG1 from "../../assets/about/company_culture_1.jpg";
import IMG2 from "../../assets/about/company_culture_2.jpg";

class CompanyCulture extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bannerImg: Bk,
        }
    }

    componentDidMount() {
        this.props.parent.loadData(this.state.bannerImg)
    }

    render() {
        return (
            <div className={"about-com-ct"}>
                <div className={"about-culture-ct"}>
                    <div className={"about-culture-ct-title"}>
                        {"核心价值观"}
                    </div>
                    <div className={"about-culture-ct-content"}>
                        <div className={"about-culture-wrap"}>
                            <div className={"about-culture-icon"}>
                                <img className={"img-style cover"} src={CULTURE1} alt={"icon"}/>
                            </div>
                            <div className={"about-culture-title"}>
                                {/*<span style={{paddingRight: "10px", paddingBottom: "4px"}}>{"--"}</span>*/}
                                {"创新"}
                                {/*<span style={{paddingLeft: "10px", paddingBottom: "4px"}}>{"--"}</span>*/}
                            </div>
                        </div>
                        <div className={"about-culture-wrap"}>
                            <div className={"about-culture-icon"}>
                                <img className={"img-style cover"} src={CULTURE2} alt={"icon"}/>
                            </div>
                            <div className={"about-culture-title"}>
                                {/*<span style={{paddingRight: "10px", paddingBottom: "4px"}}>{"--"}</span>*/}
                                {"协同"}
                                {/*<span style={{paddingLeft: "10px", paddingBottom: "4px"}}>{"--"}</span>*/}
                            </div>
                        </div>
                        <div className={"about-culture-wrap"}>
                            <div className={"about-culture-icon"}>
                                <img className={"img-style cover"} src={CULTURE3} alt={"icon"}/>
                            </div>
                            <div className={"about-culture-title"}>
                                {/*<span style={{paddingRight: "10px", paddingBottom: "4px"}}>{"--"}</span>*/}
                                {"赋能"}
                                {/*<span style={{paddingLeft: "10px", paddingBottom: "4px"}}>{"--"}</span>*/}
                            </div>
                        </div>
                        <div className={"about-culture-wrap"}>
                            <div className={"about-culture-icon"}>
                                <img className={"img-style cover"} src={CULTURE4} alt={"icon"}/>
                            </div>
                            <div className={"about-culture-title"}>
                                {/*<span style={{paddingRight: "10px", paddingBottom: "4px"}}>{"--"}</span>*/}
                                {"共赢"}
                                {/*<span style={{paddingLeft: "10px", paddingBottom: "4px"}}>{"--"}</span>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{height: '40px'}}/>
                <div className={"about-com-ct-level-one"}>
                    <div className={"about-com-ct-title"}>
                        {"我们的愿景"}
                    </div>
                    <div className={"about-com-ct-content-other"}>
                        <span>{"搭建全球科技创新生态系统"}</span>
                    </div>
                    <div className={"about-com-ct-img"}>
                        <img className={"img-style contain"} src={IMG1} alt={"company"}/>
                    </div>
                </div>
                <div style={{height: '20px'}}/>
                <div className={"about-com-ct-level-one"}>
                    <div className={"about-com-ct-title"}>
                        {"我们的使命"}
                    </div>
                    <div className={"about-com-ct-content-other"}>
                       <span>{"加速创新科技成果转换为现实生产力"}</span>
                    </div>
                    <div className={"about-com-ct-img"}>
                        <img className={"img-style contain"} src={IMG2} alt={"company"}/>
                    </div>
                </div>
                <div style={{height: '40px'}}/>
            </div>
        )
    }
}

export default CompanyCulture;
