import React, {Component} from "react";
import "./page.css";
import {Header, SrcHeader} from "../tool/http/Request";
import {CenterRoot, LoginRoot, storage} from "../tool/Const";
import {Spin} from "antd";

class WxPlugin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        }
    }

    componentDidMount() {
        this.initWx();
    }

    initWx = () => {
        let index = this.props.index;

        let appId = "wxdfcf72510070f1b4";
        let redirectUrl = index === 1 ? encodeURIComponent(Header + LoginRoot) : encodeURIComponent(Header + CenterRoot + "?type=account&way=wx");
        let num = Math.ceil(Math.random() * 1000);
        let state = index + "-" + num;
        let storageKey = (index === 1 ? "wxLoginState" : "wxBindState");
        storage.set(storageKey, num);

        let smallStyle = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30=';
        let bigStyle = "";
        let hrefStyle = index === 1 ? smallStyle : bigStyle;

        let obj = new window.WxLogin({
            self_redirect: false,
            id: "login",
            appid: appId,
            scope: "snsapi_login",
            redirect_uri: redirectUrl,
            state: state,
            style: "",
            // href: encodeURIComponent(SrcHeader + '/style/wechatLogin.css'),
            href: hrefStyle,
        });
        this.setState({ isLoading: false });
        // iframe样式修改
        if (index === 1) {
            let iframeDocument = document.getElementsByTagName("iframe")[0];
            iframeDocument.height = '280px';
        };
        let div = document.getElementById("wx_default_tip");
        console.log(div)
        // let p = div.getElementsByClassName("web_qrcode_app")[0];
        // p.innerText = "长三角一体化科创服务平台";
    }

    render() {
        return (
            <Spin spinning={this.state.isLoading}>
                <div className={"wx-qr"} id='login'>

                </div>
            </Spin>

        );
    }
}

export default WxPlugin;