import React, {Component} from 'react';
import "./../page.css";
import QueueAnim from "rc-queue-anim";
import {CalendarOutlined, EyeOutlined} from "@ant-design/icons";
import {DemandRoot, handleClickMonitor, PageId, UserInfoStorage} from "../../tool/Const";

class Demand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardWidth: '',
        }
    }

    // 加载push内容
    loadDemand = (data, userInfo) => {
        if (data.length > 0) {
            let ui = [];
            data.forEach((item, index) => {
                let cardWidth = this.state.cardWidth;
                let cardMarginT = this.state.cardMarginT;
                let margin = cardMarginT === 0 ? ((index + 1) % 3 === 2 ? '12px 2%' : '12px 0') : ((index + 1) % 2 === 1 ? '12px 5% 12px 0' : '12px 0 12px 5%');
                ui.push(
                    <div className={"demand-item"}
                         key={index}
                         style={{margin: margin, width: cardWidth}}
                         onClick={() => {
                             handleClickMonitor(userInfo, item["demandId"], 2, 1, DemandRoot.path + "/" + item["demandId"])
                         }}
                    >
                        <div className={"demand-title-ot"}>
                            <div className={"demand-title"}>
                                <span>{item["demandName"]}</span>
                            </div>
                        </div>
                        <div className={"demand-info"}>
                            <div className={"demand-info-line"}>
                                <div className={"info-item"} style={{width: '100%'}}>
                                    <span>
                                        {"所属行业："}
                                    </span>
                                    <span>
                                        {item["demandAreas"]}
                                    </span>
                                </div>
                            </div>
                            <div className={"demand-info-line"}>
                                <div className={"info-item"} style={{width: '55%'}}>
                                    <span>
                                        {"所属区域："}
                                    </span>
                                    <span>
                                        {this.handleArea(item["demandGeo"])}
                                        {/*{item["demandGeo"]}*/}
                                    </span>
                                </div>
                                <div className={"info-item"} style={{marginLeft: '10px', width: '45%'}}>
                                    <span>
                                        {"金额："}
                                    </span>
                                    <span>
                                        {item["demandPrice"]}
                                    </span>
                                    <span>
                                        {"元"}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={"demand-bt"}>
                            <div className={"info-item"} style={{width: '55%'}}>
                                <CalendarOutlined style={{marginRight: "10px"}}/>
                                {item["relDate"]}
                            </div>
                            <div className={"info-item"} style={{marginLeft: '10px', width: '45%'}}>
                                <EyeOutlined style={{marginRight: "10px"}}/>
                                {item["clickNum"]}
                            </div>
                        </div>
                    </div>
                )
            });
            return ui;
        }
    }

    handleArea = (geo) => {
        return geo.split(";")[1];
    }

    componentDidMount() {
        this.listenPageWidth();
        window.addEventListener("resize", this.listenPageWidth);
    }

    // 监听页面宽度辩变化
    listenPageWidth = () => {
        let pWidth = document.documentElement.clientWidth;
        let calWidth = pWidth * 0.8;
        console.log(calWidth);
        if (pWidth < 1400) {
            this.setState({
                pWidth: pWidth,
                cardWidth: '45%',
                cardMarginT: 1,
                // cardMargin: "(index + 1) % 2 === 1 ? '12px 5% 12px 0' : '12px 0 12px 5%'",
            })
        } else {
            this.setState({
                pWidth: pWidth,
                cardWidth: '32%',
                cardMarginT: 0,
                // cardMargin: "(index + 1) % 3 === 2 ? '12px 5%' : '12px 0'",
            })
        }
    }

    render() {
        let userInfo = UserInfoStorage.get("userInfo");
        let demand = this.props.demand;
        let pushData = this.props.pushData;

        return <div className={"out-content"}>
            {
                demand && pushData.length > 0 ?
                    <div className={"demand-ot"}>
                        <div className={"page-ct"}>
                            <div className={"outcome-title"}>
                                <div className={"title-hall"}
                                     onClick={() => {
                                         handleClickMonitor(userInfo, PageId.demand, 1, 1, DemandRoot.path)
                                     }}
                                >
                                    {"进入需求大厅"}
                                </div>
                                <QueueAnim animConfig={{opacity: [1, 0], translateX: [0, 200]}} duration={2000} delay={100}>
                                    <div className={"outcome-title-card"} key={"dem"}>
                                        <div style={{width: "6px", height: "100%", background: "rgb(60, 128, 212)"}}/>
                                        <div className={"outcome-title-ct"}>
                                            <div className={"title-main"}>
                                                {"精选热门技术需求"}
                                            </div>
                                            <div className={"title-des"}>
                                                {"多方位多渠道汇集需求 分程度分领域精准匹配"}
                                            </div>
                                        </div>
                                    </div>
                                </QueueAnim>
                            </div>
                            <div style={{height: "20px", width: "100%"}}/>
                            <QueueAnim animConfig={{opacity: [1, 0], translateY: [0, 100]}} duration={2000} delay={400}>
                                <div className={"demand-ct"} key={"dem-ct"}>
                                    <div className={"demand-line"}>
                                        {this.loadDemand(pushData, userInfo)}
                                    </div>
                                    <div className={"demand-enter"}>
                                        <div className={"expert-join"}>
                                            {"完成湃通专家认证，获取更多需求资源"}
                                        </div>
                                    </div>
                                </div>
                            </QueueAnim>
                        </div>
                    </div>
                    : null
            }
        </div>
    }
}

export default Demand;