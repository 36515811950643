import React, {Component} from 'react';
import "./expert.css";
import FilterHeader from "../../tool/filter/FilterHeader";
import GeoFilter from "../../tool/filter/GeoFilter";
import FilterMain from "../../tool/filter/FilterMain";
import FilterTags from "../../tool/filter/FilterTags";
import BK1 from "../pic/expertBk1.png";
import BK2 from "../pic/expertBk2.png";
import {AreaMap, filterExpert} from "../../tool/Const";

const filterCt = [
    {
        title: "研究领域",
        ct: ['全部', '数学与统计学类', '物理学与力学类', '化学与化工类', '地理地质学与矿业类', '水利与海洋科学类', '生物医学与生物工程类', '工业工程与机械类',
            '仪器与材料类', '能源动力类', '电子信息与计算机类', '电气与自动化类', '土木建筑与测绘类', '轻工与纺织类', '交通运输与物流管理类',
            '航空航天与核工程类', '农业与林业工程类', '环境科学与工程类', '食品科学与工程类', '安全科学与工程类', '中医学与制药学类', '管理科学与工程类类',
            '电子商务类', '心理学类', '天文学类', '大气科学类', '其他'],
        ip: 'area',
    },
    {
        title: "专家来源",
        ct: ["全部", "高等院校", "科研院所", "企业"],
        ip: 'from',
    },
    {
        title: "专家职称",
        ct: ['全部', '正高级工程师', '高级工程师', '工程师', '教授', '副教授', '研究员', '副研究员', '主任医师', '副主任医师',
            '正高级教师', '高级教师', '主任技师', '副主任技师', '其他'],
        ip: 'title',
    },
]

const filterMainStyle = {
    header: "expert-filter-header",
    title: "expert-filter-search-title",
    input: "expert-filter-input",
    tag: "expert-filter-tag",
    search: "expert-filter-search-bt",
    outer: "filter-search-expert",
    signT: "filter-sign-expert",
}

const TagList = ['area', 'from', 'title']

class ExpertFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectItem: -1,
            openGeo: false,
            showCity: -1,
        };
    }

    setOptions = (options) => {
        this.props.parent.setState({
            options: Object.assign({}, options)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let item = this.state.selectItem;
        if (prevState.selectItem !== item) {
            this.props.parent.setState({
                openSize: item !== -1 ? 270 : 0,
            })
        }
    }

    render() {
        let options = this.props.options;
        let initTag = this.props.initTag;

        return (

            <div style={{minHeight: "570px", width: "100%", background: "white"}} id={"filter"}>
                <div className={"export-bk"}>
                    <div className={"export-bk-o"}>
                        <div className={"export-bk-l"}/>
                        <div className={"export-bk-r"}/>
                    </div>
                    <div className={"expert-banner"}>
                        <img className={"expert-banner-img-one"} src={BK1} alt={""}/>
                        <img className={"expert-banner-img-two"} src={BK2} alt={""}/>
                    </div>
                    <div className={"expert-filter"}>
                        <FilterHeader page={"expert"} parent={this} filterStyle={filterMainStyle} options={options}/>
                    </div>
                </div>
                <div className={"expert-filter-ct"}>
                    <div className={"expert-filter-outer"}>
                        <div style={{height: "8px", width: "100%"}}/>
                        <GeoFilter options={options} parent={this}
                                   areaClass={"filter-area"} titleClass={"filter-area-geo-title"}
                                   map={AreaMap} isIcon={true}
                                   mainType={"全国所有省市"} header={"地理位置:"}/>
                        <FilterMain options={options} parent={this} filterCt={filterExpert} initTag={initTag}/>
                        <div style={{height: "14px", width: "100%"}}/>
                    </div>
                </div>
                <div className={"filter-selected-tags"} id={"e-tag"}>
                    <FilterTags options={options} parent={this} initTag={initTag} tags={TagList} titleType={"专家"}
                                total={this.props.total}/>
                </div>
            </div>
        )
    }
}

export default ExpertFilter;
