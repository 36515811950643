import React, {Component, Fragment} from "react";
import "./center.css";
import {handleOutcomeType, LoginRoot, UserInfoStorage} from "../../tool/Const";
import {CheckUnlockItemByUserUrl, data, error, loginOutErrOr, sendGetRequest} from "../../tool/http/Request";
import {connectionError, fail, failReturn, findMsgByError} from "../../tool/http/ErrorMgr";
import NODATA from "../../assets/icon/record_empty.svg";
import {FileSearchOutlined} from "@ant-design/icons";

class UnlockMgr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 0,
            data: [],
        }
    }

    loadTable = (index, title, type, area, fun) => {
        return (
            <Fragment>
                <div className={"table-cell index"}>
                    {index}
                </div>
                <div className={"table-cell title"}>
                    {title}
                </div>
                <div className={"table-cell type"}>
                    {type}
                </div>
                <div className={"table-cell date"}>
                    {area}
                </div>
                <div className={"table-header-cell operation"}>
                    {fun}
                </div>
            </Fragment>
        )
    }

    loadTableCt = (data, type) => {
        let table = [];
        if (data.length > 0) {
            data.forEach((item, index) => {
                let indexS = type === 0 ? item['demandId'] : item['policyId'];
                let title = type === 0 ? item['demandName'] : item['policyName'];
                let typeS = type === 0 ? handleOutcomeType(item['demandType']) : item['policyPri'];
                let area = type === 0 ? item['demandAreas'] : item['policyRoot'];
                let fun = <div className={"operation-plugin add-fun"}
                               onClick={() => {window.open('/' + this.handleIdByType(type) + '/' + item[this.handleIdByType(type) + "Id"], "_blank")}}>
                                <FileSearchOutlined className={"operation-plugin-icon"}/>
                                <div className={"operation-plugin-txt"}>
                                    {"查看"}
                                </div>
                          </div>;
                table.push(
                    <div className={"table-ct-wrap"} key={index}>
                        {this.loadTable(indexS, title, typeS, area, fun)}
                    </div>
                )
            })
        } else {
            return (
                <div className={"table-ct-no-data"}>
                    <img className={"table-ct-no-data-icon"} src={NODATA} alt={"no-data"}/>
                    <div className={"table-ct-no-data-tip"}>
                        {"暂无数据"}
                    </div>
                </div>
            )
        }
        return table;
    }

    handleIdByType = (type) => {
        switch (type) {
            case 0: {
                return "demand";
            }
            case 1: {
                return "policy";
            }
        }
    }

    componentDidMount() {
        this.genUnlockItem("9919", 0);
    }

    genUnlockItem = (index, type) => {
        let userInfo = UserInfoStorage.get("userInfo");
        let params = {
            userId: userInfo.userId,
            index: index,
        };
        sendGetRequest(CheckUnlockItemByUserUrl, params, true).then(res => {
            console.log("查询用户所有解锁项目res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.setState({
                        data: res[data],
                        type: type,
                    })
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }


    render() {
        let type = this.state.type;
        let data = this.state.data;

        return (
            <div className={"collection-mgr-ct"}>
                <div className={"center-ct-header"}>
                    <div className={"center-ct-header-wrap"}>
                        <div className={"center-ct-header-icon"}/>
                        <div className={"center-ct-header-title"}>
                            {"解锁管理"}
                        </div>
                        {/*<div className={"center-ct-header-subtitle"}>*/}
                        {/*    {"查看每日日程"}*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className={"my-service-plugin-menu"}>
                    <div className={type === 0 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                         onClick={() => {this.genUnlockItem("9919", 0)}}>
                        <div>
                            {"技术需求"}
                        </div>
                    </div>
                    <div className={type === 1 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                         onClick={() => {this.genUnlockItem("3478", 1)}}>
                        <div>
                            {"科技政策"}
                        </div>
                    </div>
                </div>
                <div className={"appointment-mgr-ct-table"}>
                    <div className={"table-wrap"}>
                        <div className={"table-header"}>
                            {this.loadTable("索引", "标题", type === 0 ? "类型" : "适用区域", type === 0 ? "行业" : "发布机构", "操作")}
                        </div>
                        <div className={"table-ct"}>
                            {this.loadTableCt(data, type)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UnlockMgr;