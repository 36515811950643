import React, {Component} from "react";
import "./tool.css";
import {CloseOutlined} from "@ant-design/icons";
import {delWin} from "./Launcher";
import ALI from "../assets/img/alipay.png";
import WX from "../assets/img/wxpay.svg";
import LunchWin from "./Tool";
import QrWin from "./QrWin";

class PayWin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectType: 1,
        }
    }

    launchPay = (way, requestPojo) => {
        let id = "wrap";
        let winId = "qr";
        delWin(id, "pay")
        LunchWin(<QrWin parentId={id} winId={winId} way={way} requestPojo={requestPojo} setPojo={this.props.setPojo} fun={this.props.fun}/>, id, winId);
    }

    render() {
        let parentId = this.props.parentId;
        let winId = this.props.winId;
        let requestPojo = this.props.requestPojo;

        let selectType = this.state.selectType;

        // console.log("requestPojo: ", requestPojo);

        return (
            <div className={"pay-way-plugin"}>
                <div className={"pay-way-header"}>
                    <span>{"请选择支付方式"}</span>
                </div>
                <div className={"pay-way-wrap"}>
                    <div className={selectType === 1 ? "pay-way-item-select" : "pay-way-item"}
                         onClick={() => {this.setState({selectType: 1})}}
                    >
                        {/*<input className={"checkbox-circle"} type={"checkbox"}*/}
                        {/*       onChange={() => {*/}
                        {/*           this.launchPay(1, requestPojo)*/}
                        {/*       }}*/}
                        {/*/>*/}
                        <div className={"pay-img"}>
                            <img className={"img-style contain"} src={WX} alt={"wechat"}/>
                        </div>
                        <div className={"pay-txt"}>
                            {"微信支付"}
                        </div>
                    </div>
                    <div className={selectType === 2 ? "pay-way-item-select" : "pay-way-item"}
                         onClick={() => {this.setState({selectType: 2})}}
                    >
                        {/*<input className={"checkbox-circle"} type={"checkbox"}*/}
                        {/*       onChange={() => {*/}
                        {/*           this.launchPay(2, requestPojo)*/}
                        {/*       }}*/}
                        {/*/>*/}
                        <div className={"pay-img"}>
                            <img className={"img-style contain"} src={ALI} alt={"wechat"}/>
                        </div>
                        <div className={"pay-txt"}>
                            {"支付宝"}
                        </div>
                    </div>
                </div>
                <div className={"next-step-btn"}
                     onClick={() => {
                         this.launchPay(selectType, requestPojo)
                     }}
                >
                    {"下一步"}
                </div>
                <div className={"modal-close-wrap"}>
                    <CloseOutlined onClick={() => {delWin(parentId, winId)}}/>
                </div>
            </div>
        );
    }
}

export default PayWin;