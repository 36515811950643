import React, {Component} from 'react';
import QueueAnim from "rc-queue-anim";
import {Autoplay} from 'swiper';
import "./../page.css";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/scrollbar';
import {
    ExpertRoot,
    handleClickMonitor,
    handleExpertTitle,
    PageId,
    showSemData,
    UserInfoStorage
} from "../../tool/Const";
import {SrcHeader} from "../../tool/http/Request";
import TRAFFIC_ONE from "../../assets/img/traffic_ot_ep.jpg";

class Experts extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // 加载专家轮播图
    loadExpertSwiper = (data, start, end, userInfo) => {
        let len = data.length;
        let ui = [];
        let uiUp = [];
        let uiDown = [];
        if (len > 0) {
            for (let i = 0; i < len; i++) {
                // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
                // let picDir = require.context("../pic/", true, /\.(png|jpg)$/);
                // let imgUrl = picDir("./" + data[i]["expertBanner"]);
                let imgUrl = SrcHeader + data[i]["expertId"] + "/" + data[i]["expertBanner"];
                if (i%2 !== 1) {
                    uiUp.push(
                        <SwiperSlide>
                            <div className={"expert-line"}>
                                <div className={"expert-content"}>
                                    <div className={"expert-title"}>
                                        <div className={"expert-name"}>
                                            {data[i]["expertName"]}
                                        </div>
                                        <div className={"expert-jobs"}>
                                            <div className={"expert-tag"}>
                                                {handleExpertTitle(data[i]["expertTitle"])}
                                            </div>
                                            {
                                                data[i]["expertHor"] ?
                                                    <div className={"expert-tag"}>
                                                        {showSemData(data[i]["expertHor"], 0)}
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <div className={"expert-area"}>
                                        <div className={"expert-pos"}>
                                        <span style={{paddingRight: "10px"}}>
                                            {"所属机构："}
                                        </span>
                                            <span>
                                            {data[i]["expertRoot"]}
                                        </span>
                                        </div>
                                        <div className={"expert-pos"}>
                                         <span style={{paddingRight: "10px"}}>
                                            {"地理位置："}
                                        </span>
                                            <span>
                                            {data[i]["expertGeo"]}
                                        </span>
                                        </div>
                                        <div className={"expert-studies"}>
                                            {"担任职务："}
                                            <div className={"expert-job-tag"}>
                                                {showSemData(data[i]["expertJob"], 0)}
                                            </div>
                                        </div>
                                        <div className={"expert-studies"}>
                                            {"研究领域："}
                                            <div className={"expert-area-tag"}>
                                                {data[i]["expertAreas"]}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"expert-pic"}>
                                    <img src={imgUrl} alt={""} className={"expert-img"}/>
                                </div>
                                <div className={"expert-space"}>
                                    <div className={"expert-slogan"}>
                                        {"“"}
                                        {data[i]["expertTarget"]}
                                        {"”"}
                                    </div>
                                    <div className={"expert-selection"}>
                                        <div className={"expert-button"}>
                                            {"预约专家"}
                                        </div>
                                        <div className={"expert-button"}
                                             onClick={() => {
                                                 handleClickMonitor(userInfo, data[i]["expertId"], 2, 1, ExpertRoot.path + "/" + data[i]["expertId"])
                                             }}
                                        >
                                            {"专家详情"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                } else {
                    uiDown.push(
                        <SwiperSlide>
                            <div className={"expert-line"}>
                                <div className={"expert-space"}>
                                    <div className={"expert-slogan"}>
                                        {"“"}
                                        {data[i]["expertTarget"]}
                                        {"”"}
                                    </div>
                                    <div className={"expert-selection"}>
                                        <div className={"expert-button"}>
                                            {"预约专家"}
                                        </div>
                                        <div className={"expert-button"}
                                             onClick={() => {
                                                 handleClickMonitor(userInfo, data[i]["expertId"], 2, 1, ExpertRoot.path + "/" + data[i]["expertId"])
                                             }}
                                        >
                                            {"专家详情"}
                                        </div>
                                    </div>
                                </div>
                                <div className={"expert-pic"}>
                                    <img src={imgUrl} alt={""} className={"expert-img"}/>
                                </div>
                                <div className={"expert-content"}>
                                    <div className={"expert-title"}>
                                        <div className={"expert-name"}>
                                            {data[i]["expertName"]}
                                        </div>
                                        <div className={"expert-jobs"}>
                                            <div className={"expert-tag"}>
                                                {handleExpertTitle(data[i]["expertTitle"])}
                                            </div>
                                            {
                                                data[i]["expertHor"] ?
                                                    <div className={"expert-tag"}>
                                                        {showSemData(data[i]["expertHor"], 0)}
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <div className={"expert-area"}>
                                        <div className={"expert-pos"}>
                                        <span style={{paddingRight: "10px"}}>
                                            {"所属机构："}
                                        </span>
                                            <span>
                                            {data[i]["expertRoot"]}
                                        </span>
                                        </div>
                                        <div className={"expert-pos"}>
                                         <span style={{paddingRight: "10px"}}>
                                            {"地理位置："}
                                        </span>
                                            <span>
                                            {data[i]["expertGeo"]}
                                        </span>
                                        </div>
                                        <div className={"expert-studies"}>
                                            {"担任职务："}
                                            <div className={"expert-job-tag"}>
                                                {showSemData(data[i]["expertJob"], 0)}
                                            </div>
                                        </div>
                                        <div className={"expert-studies"}>
                                            {"研究领域："}
                                            <div className={"expert-area-tag"}>
                                                {data[i]["expertAreas"]}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                }
            }
            ui.push(
                [<Swiper spaceBetween={50}
                        slidesPerView={1}
                        loop={true}
                        speed={1500}
                        autoplay={{
                            "delay": 4000,
                            "disableOnInteraction": false,
                        }}
                        modules={[Autoplay]}
                        className={"swiper-style-exp"}
                >
                    {uiUp}
                </Swiper>,
                <Swiper spaceBetween={50}
                        slidesPerView={1}
                        loop={true}
                        speed={1500}
                        autoplay={{
                            "delay": 4000,
                            "disableOnInteraction": false,
                            "reverseDirection": true
                        }}
                        modules={[Autoplay]}
                        className={"swiper-style-exp"}
                >
                    {uiDown}
                </Swiper>]
            )
            return ui;
        }
    }

    render() {
        let userInfo = UserInfoStorage.get("userInfo");
        let expert = this.props.expert;
        let pushData = this.props.pushData;

        return <div className={"out-content"}>
            <div className={"expert-ot traffic-bg-one"} style={{height: "200px"}}>
                <div className={"traffic-ct"}>
                    {/*<div className={"outcome-expert"}>*/}
                    <img className={"img-style cover"} src={TRAFFIC_ONE} alt={"traffic"}/>
                    <div className={"traffic-txt-wrap"}>
                        <div className={"traffic-ct-txt"}>
                            <span className={"traffic-txt"}>{"携手数千位科研专家"}</span>
                            <span className={"traffic-txt"} style={{textIndent: '100px'}}>{"汇聚百万项科技成果"}</span>
                            <span className={"traffic-txt"} style={{textIndent: '200px'}}>{"共筑万亿级创新生态"}</span>
                        </div>
                    </div>

                    {/*</div>*/}
                </div>

            </div>
            {
                expert && pushData.length > 0 ?
                    <div className={"expert-ot"} style={{height: "840px", background: "white"}} id={"expert-pos"}>
                        <div className={"page-ct"}>
                            <div className={"expert-ct"}>
                                <div className={"outcome-title"}>
                                    <div className={"title-hall"}
                                         onClick={() => {
                                             handleClickMonitor(userInfo, PageId.expert, 1, 1, ExpertRoot.path)
                                         }}
                                    >
                                        {"进入专家大厅"}
                                    </div>
                                    <QueueAnim animConfig={{opacity: [1, 0], translateX: [0, 200]}} duration={2000} delay={100}>
                                        <div className={"outcome-title-card"} key={"expert"}>
                                            <div style={{width: "6px", height: "100%", background: "rgb(60, 128, 212)"}}/>
                                            <div className={"outcome-title-ct"}>
                                                <div className={"title-main"}>
                                                    {"权威科技专家团队"}
                                                </div>
                                                <div className={"title-des"}>
                                                    {"汇聚顶尖科研人才 打造产业智慧大脑 破解企业人才困境"}
                                                </div>
                                            </div>
                                        </div>
                                    </QueueAnim>
                                </div>
                                <div style={{height: "40px", width: "100%"}}/>
                                <div className={"expert-ct-swipes"}>
                                    {this.loadExpertSwiper(pushData, userInfo)}
                                    {/*<Swiper spaceBetween={50}*/}
                                    {/*        slidesPerView={1}*/}
                                    {/*        loop={true}*/}
                                    {/*        speed={1500}*/}
                                    {/*        autoplay={{*/}
                                    {/*            "delay": 4000,*/}
                                    {/*            "disableOnInteraction": false,*/}
                                    {/*        }}*/}
                                    {/*        modules={[Autoplay]}*/}
                                    {/*        className={"swiper-style-exp"}*/}
                                    {/*>*/}
                                    {/*    {this.loadExpertSwiper(pushData, 0, 2, userInfo)}*/}
                                    {/*</Swiper>*/}
                                    {/*<Swiper spaceBetween={50}*/}
                                    {/*        slidesPerView={1}*/}
                                    {/*        loop={true}*/}
                                    {/*        speed={1500}*/}
                                    {/*        autoplay={{*/}
                                    {/*            "delay": 4000,*/}
                                    {/*            "disableOnInteraction": false,*/}
                                    {/*            "reverseDirection": true*/}
                                    {/*        }}*/}
                                    {/*        modules={[Autoplay]}*/}
                                    {/*        className={"swiper-style-exp"}*/}
                                    {/*>*/}
                                    {/*    {this.loadExpertSwiper(pushData, 2, 4, userInfo)}*/}
                                    {/*</Swiper>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }

        </div>
    }
}

export default Experts;