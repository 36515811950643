import React from "react";
import "./outcome.css";
import "../page.css";
import Header from "../Header";
import Contact from "../home/Contact";
import Footer from "../Footer";
import {
    advList,
    dataIndexE,
    getAdvData,
    getPageDataByIndex,
    handleAdRightWinSizeChange,
    handleAdRightWinSizeInit,
    handleCopWay,
    handleOutcomeType,
    handlePageScrollMove,
    handleSrcType,
    LoadingPolicyCopAds,
    LoadingSolutionCopAds,
    PolicyRoot,
    scrollTop,
    SolutionRoot,
    solutionTechCopList,
    UserInfoStorage
} from "../../tool/Const";
import {Breadcrumb} from "antd";
import AdRight from "../../tool/AdRight";
import CONTACT from "../../assets/img/contact.png";
import IMG_BG from "../../assets/img/img_bg.png";
// Import Swiper React components
import {SwiperSlide} from 'swiper/react';
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {SwiperComponent} from "./SwiperComponent";
import {LoadingOutlined} from "@ant-design/icons";
import {SrcHeader} from "../../tool/http/Request";
import ScriptParent from "../ScriptParent";
import ErrorPage from "../Error/ErrorPage";
import SERVICE_PROCESS from "../../assets/service/service-process-outcome.png";
import SAFETY_GUARANTEE from "../../assets/service/safety-guarantee.png";

const adClasses = {
    adOuter: "outcome-ct-ad",
    adOne: "outcome-ct-ad-one",
    adTwo: "outcome-ct-ad-two",
}

class OutcomeScript extends ScriptParent {
    constructor(props) {
        super(props);
        this.state = {
            pageData: '',  // 该页面数据
            isPageDataLoad: false,  // 该页面数据是否加载完
            fixed: true,
            fixedAd: false,
            adLeft: "",
            adBt: false,
            showAd: true,
            gap: 0,
            cH: 0,
            adjustGap: 0,
            openSize: 0,
            advMap: new Map(),  // adv数据
            isMatch: false,
        }
    }

    // 加载Header
    loadHeader = (data) => {
        if (data) {
            // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
            // let picDir = require.context("../pic/", true, /\.(png|jpg)$/);
            // let imgUrl = picDir("./" + data["outcomeBanner"]);
            let imgUrl = SrcHeader + data["outcomeId"] + "/" + data["outcomeBanner"];
            return (
                <div className={"script-header outcome-header"}>
                    <div className={"script-header-ct"}>
                        <div className={"script-header-l"}>
                            <div className={"script-name outcome-name"}>
                                {data["outcomeName"]}
                            </div>
                            <div className={"script-tag"}>
                                <span style={{color: '#d6d6d6'}}>
                                    {"专利（申请）号："}
                                </span>
                                <span>
                                    {data["patentNo"]}
                                </span>
                            </div>
                            <div className={"script-tag"}>
                                <span style={{color: '#d6d6d6'}}>
                                    {"专利类型："}
                                </span>
                                <span>
                                    {handleOutcomeType(data["outcomeType"])}
                                </span>
                            </div>
                            <div className={"script-tag"}>
                                <span style={{color: '#d6d6d6'}}>
                                    {"行业："}
                                </span>
                                <span>
                                    {data["outcomeAreas"]}
                                </span>
                            </div>
                            <div className={"contact-bt"}>
                                <div className={"contact-bt-icon"}>
                                    <img className={"img-style contain"} src={CONTACT} alt={""}/>
                                </div>
                                <div className={"contact-bt-text"}>
                                    {"点击立即咨询"}
                                </div>
                            </div>
                        </div>
                        <div className={"script-header-r"}>
                            <img className={"script-img-bg"} src={IMG_BG} alt={""}/>
                            <div className={"script-img"}>
                                <img className={"img-style cover"} src={imgUrl} alt={""}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 加载内容
    loadMain = (data) => {
        if (data) {
            let picSrc = data.picSrc.split(";");  // ['gar.jpg', 'gar1.jpg']
            return (
                <div className={"script-main"}>
                    <div className={"script-main-header"}>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item style={{marginRight: '5px'}}>
                                {"您所在的位置: "}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a href="/">{"首页"}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item>
                                <a href="/outcome">{"科技成果"}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Separator />
                            <Breadcrumb.Item style={{color: '#0077ff'}}>
                                <strong>{"正文"}</strong>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className={"script-main-ct"}>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"科技成果基本情况"}
                            </div>
                            <div className={"script-item-info"}>
                                <div className={"script-item-info-left"}>
                                    {this.loadInfoLayout("技术来源：", handleSrcType(data["srcType"]))}
                                    {this.loadInfoLayout("成熟度：", data["outcomeStage"])}
                                    {this.loadInfoLayout("行业分类：", data["outcomeAreas"])}
                                    {this.loadInfoLayout("合作方式：", handleCopWay(data["copWay"]))}
                                    {this.loadInfoLayout("地理位置：", data["outcomeGeo"])}
                                    {this.loadInfoLayout("所属机构：", data["outcomeRoot"])}
                                </div>
                                <div className={"script-item-info-right"}>
                                    <SwiperComponent parent={this} loadSwiperImg={this.loadSwiperImg} picSrc={picSrc} itemId={data['outcomeId']}/>
                                </div>
                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"科技成果简介"}
                            </div>
                            <div className={"script-item-content"}>
                                {data["outcomeDes"]}
                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"运用场景描述"}
                            </div>
                            <div className={"script-item-content"}>
                                {data["usageCt"]}
                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"未来前景描述"}
                            </div>
                            <div className={"script-item-content"}>
                                {data["futureCt"]}
                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"联系咨询"}
                            </div>
                            <div className={"contact-plugin"}>
                                <div className={"contact-bt-icon"}>
                                    <img className={"img-style contain"} src={CONTACT} alt={""}/>
                                </div>
                                <div className={"contact-bt-text"}>
                                    {"点击立即咨询"}
                                </div>
                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"科技成果转移服务流程"}
                            </div>
                            <div className={"service-process"}>
                                <img className={"img-style contain"} src={SERVICE_PROCESS} alt={""}/>
                            </div>
                        </div>
                        <div className={"script-item-ct"}>
                            <div className={"script-item-title"}>
                                {"安全保障"}
                            </div>
                            <div className={"safety-guarantee"}>
                                <img className={"img-style contain"} src={SAFETY_GUARANTEE} alt={""}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 加载轮播图
    loadSwiperImg = (itemId, picSrc, style) => {
        let list = [];
        picSrc.forEach((item, index) => {
            // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
            // let picDir = require.context("../pic/", true, /\.(png|jpg)$/);
            // let imgUrl = picDir("./" + item);
            let imgUrl = SrcHeader + itemId + "/" + item;
            list.push (
                <SwiperSlide className={"img-style"}>
                    <img className={style} src={imgUrl} alt={"img"}/>
                </SwiperSlide>
            )
        });
        return list;
    }

    componentDidMount() {
        getPageDataByIndex(this).then(res => {
            if (res) {

            }
        });
        getAdvData(advList.outcome, this);
        handleAdRightWinSizeInit(this);
        window.addEventListener("resize", this.handleResize);
        window.onscroll = () => {
            let cH = document.getElementById("script-outer").clientHeight;
            let cHC = this.state.cH;
            handlePageScrollMove(this, "", 1680, 500 + cH,
                400 + cH,
                "script-outer", 940)
            if (cHC !== cH) {
                this.setState({
                    cH: cH,
                })
            }
        };
        scrollTop();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        handleAdRightWinSizeChange("script-outer", this);
    }

    render() {
        let userInfo = UserInfoStorage.get("userInfo");
        let pageData = this.state.pageData;
        let isPageDataLoad = this.state.isPageDataLoad;
        // console.log("data：", pageData);

        let adLeft = this.state.adLeft;
        let right = "calc((100% - " + adLeft + " ) / 2)";
        let totalGap = this.state.gap - this.state.adjustGap;
        let gapR = totalGap === 0 ? "0" : parseInt(totalGap + "") + "px";
        let wH = parseInt((this.state.cH - 30) + "") + "px";

        let advMap = this.state.advMap;
        console.log("advMap: ", advMap);

        let isMatch = this.state.isMatch;
        console.log("isMatch: ", isMatch);

        return (
            <div className={"home-ct"}>
                <Header/>
                <div className={"script-ct"} id={"pageHeader"}>
                    {this.loadHeader(pageData)}
                    <div className={"script-page-content"} id={"script-outer"}>
                        <div className={"script-ct-data"}>
                            {
                                isPageDataLoad ?
                                    this.loadMain(pageData, userInfo)
                                    :
                                    <div className={"load-plugin"}>
                                        <LoadingOutlined className={"load-icon"}/>
                                        <div className={"load-txt"}>
                                            {"页面加载中，请稍候..."}
                                        </div>
                                    </div>
                            }
                        </div>
                        {
                            advMap.size === advList.outcome.length ?
                                <AdRight fixedAd={this.state.fixedAd} adBt={this.state.adBt} gapR={gapR} right={right}
                                         wholeHgt={wH} adClasses={adClasses}
                                         adTitleOne={"企业技术创新"} adOneCt={LoadingSolutionCopAds(solutionTechCopList)} adOneUrl={SolutionRoot}
                                         adTitleTwo={"政策精要"} adTwoCt={LoadingPolicyCopAds(advMap.get(dataIndexE.policy))} adTwoUrl={PolicyRoot.path}
                                />
                                : null
                        }
                    </div>
                </div>
                <Contact fixed={this.state.fixed}/>
                <div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default OutcomeScript;