import React, {Component} from 'react';
import "../tool.css"
import {FilterHeaderCt} from "../Const";

class FilterHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: this.props.page,
            keyWords: "",
        };
    }

    setKeywords = (e) => {
        let vl = e.target.value
        if (vl) {
            this.setState({keyWords: vl})
        }
    }

    setKeyParent = () => {
        let words = this.state.keyWords;
        if (words) {
            let parent = this.props.parent;
            let options = this.props.options;
            options.keyWords = words;
            parent.setOptions(options);
            document.getElementById("search").value = "";
            this.setState({
                keyWords: "",
            })
        }
    }

    pressEnter = (e) => {
        if (e.keyCode === 13) {
            this.setKeyParent();
        }
    }

    render() {
        let style = this.props.filterStyle;
        let page = this.state.page;
        let subOne = FilterHeaderCt[page].subOne;
        let subTwo = FilterHeaderCt[page].subTwo;
        return (
            <div className={style.header}>
                <div className={style.outer}>
                    <div className={style.title}>
                        {FilterHeaderCt[page].searchTile}
                    </div>
                    <div className={"filter-search-input"}>
                        <div className={"filter-search-input-ct"}>
                            <input className={style.input} placeholder={FilterHeaderCt[page].searchDes}
                                   id={"search"}
                                   maxLength={15}
                                   onChange={(e) => {
                                       this.setKeywords(e)
                                   }} onKeyDown={(e) => {
                                this.pressEnter(e)
                            }}/>
                        </div>
                        <div className={"filter-search-input-bt"} onClick={() => {
                            this.setKeyParent()
                        }}>
                            <div className={style.search}>
                                <span>{"查询"}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {subOne !== null || subTwo !== null ? <div className={style.signT ? style.signT : "filter-sign"}>
                    <div className={style.tag}>
                        <span>{subOne}</span>
                    </div>
                    <div style={{height: "100%", width: "4px"}}/>
                    <div className={style.tag}>
                        <span>{subTwo}</span>
                    </div>
                </div> : null}
            </div>
        )
    }
}

export default FilterHeader;