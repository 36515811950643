import React, {Component} from 'react';
import "../tool.css"
import {CloseOutlined, DeleteOutlined} from "@ant-design/icons";
import {InitTags} from "../Const";

class FilterTags extends Component {
    closeTag = (ip, options, initTag, parent) => {
        options[ip] = ip === "keyWords" ? "" : initTag;
        parent.setOptions(options)
    }

    closeTagAll = (options, initTag, parent) => {
        InitTags(options, initTag, true)
        parent.setOptions(options)
    }

    loadingTagGroup = (tags, options, initTag, parent) => {
        let group = [];
        if (options.keyWords) {
            group.push(<span className={"option-tag"} key={"tagKey"}>
                        {"关键词:" + options.keyWords}
                <CloseOutlined style={{cursor: "pointer"}}
                               onClick={() => {
                                   this.closeTag("keyWords", options, initTag, parent)
                               }}/>
                        </span>)
        }
        tags.forEach((tag, index) => {
            let single = (
                options[tag] === initTag ? null
                    :
                    <span className={"option-tag"} key={index + "tag"}>
                        {options[tag]}
                        <CloseOutlined style={{cursor: "pointer"}}
                                       onClick={() => {
                                           this.closeTag(tag, options, initTag, parent)
                                       }}/>
                        </span>
            )
            group.push(single)
        })
        return group;
    }

    loadingTags = (options, initTag, parent, tags) => {
        let show = false;
        tags.forEach((tag) => {
            if (options[tag] !== initTag) {
                show = true;
            }
        })
        if (options.keyWords) {
            show = true;
        }

        return (
            <div className={"filter-selected-outer"}>
                {show ? <div className={"option-tag-outer"}>
                    <span style={{fontSize: "14px", letterSpacing: "2px", padding: "6px 0 6px 0"}}>{"已选标签:"}</span>
                    {this.loadingTagGroup(tags, options, initTag, parent)}
                    <div className={"option-tag-delete"} onClick={() => {
                        this.closeTagAll(options, initTag, parent)
                    }}>
                        <span className={"option-tag-all"}>{"清空"}</span>
                        <DeleteOutlined
                            style={{fontSize: "18px", padding: "6px", background: "#89b6e5", color: "#f5f5f5"}}
                        />
                    </div>
                </div> : null}
                <div className={"data-tag-title"}>
                    <span style={{marginRight: "6px"}}>{"已为您筛选"}</span>
                    <span style={{marginRight: "6px", color: "rgb(58,127,231)", fontWeight: "bold"}}>{options.geo}</span>
                    <span style={{color: "red", fontWeight: "bold", fontSize: "18px"}}>{this.props.total}</span>
                    <span style={{marginLeft: "6px"}}>{"条"}</span>
                    <span style={{
                        marginLeft: "6px",
                        color: "rgb(0,101,255)",
                        fontWeight: "bold"
                    }}>{this.props.titleType}</span>
                    <span style={{marginLeft: "6px"}}>{"相关信息:"}</span>
                </div>
            </div>
        )
    }

    render() {
        let options = this.props.options;
        let initTag = this.props.initTag;
        let parent = this.props.parent;
        let tags = this.props.tags;
        return this.loadingTags(options, initTag, parent, tags)
    }
}

export default FilterTags;