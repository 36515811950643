import React, {Fragment} from 'react';
import "./page.css";
import {AlipayCircleFilled, CloseOutlined, EyeOutlined, KeyOutlined, WechatFilled} from "@ant-design/icons";
import {
    AdComp,
    AliRoot,
    CenterRoot,
    genProcessBack, handleAliAuth,
    handleSendButton,
    handleUrlState,
    InputTest,
    LoginRoot,
    RegisterRoot,
    storage,
    UserInfoStorage
} from "../tool/Const";
import {
    ChangePassUrl,
    data,
    error,
    genPojo,
    LoginByWxUrl,
    LoginUrl,
    sendGetBack,
    sendPostRequest,
} from "../tool/http/Request";
import {connectionError, fail, failReturn, findMsgByError, successReturn} from "../tool/http/ErrorMgr";
import PATON_ICON from "../assets/icon/paton_icon.jpg";
import WxPlugin from "./WxPlugin";

const ByPassItems = ["userPwd"];

const ByCodeItem = ["userPhone", "code"];

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 0,
            passStatus: 0,
            secondI: 30,
            showChangeWin: false,
            loginByPass: true,
            passError: false,
            codeError: false,
            showPass: false,
            focusValid: false,
            account: {
                type: 0,
                value: null,
            },
            pojo: {
                type: 0,
                userEmail: null,
                userPhone: null,
                userPwd: null,
                code: null,
                wechatId: null,
                aliId: null,
            },
            passPojo: {
                userPhone: null,
                code: null,
                userPwd: null,
            },
            passRepeat: null,
            wechatLogin: false,
            setSrc: '',
            from: '',
        };
    }

    fillValue = (e, tag, pojo) => {
        let val = e.target.value;
        if (tag === "account") {
            let account = this.state.account;
            if (InputTest.userEmail.test.test(val)) {
                account = {type: 2, value: val}
                if (pojo.userPhone) {
                    pojo.userPhone = null;
                }
            } else if (InputTest.userPhone.test.test(val)) {
                account = {type: 1, value: val}
                if (pojo.userEmail) {
                    pojo.userEmail = null;
                }
            } else {
                if (account.type !== 0) {
                    account = {type: 0, value: null}
                    pojo.userEmail = null;
                    pojo.userPhone = null;
                }
            }
            this.setState({
                account: account,
                pojo: pojo,
            })
            return;
        }
        pojo = this.setPojoCommon(pojo, tag, val);
        this.setState({
            pojo: pojo,
        })
    }

    setNewPassPojo = (e, pojo, tag) => {
        let val = e.target.value;
        if (tag === "passRepeat") {
            this.setState({
                passRepeat: val,
            })
            return;
        }
        this.setState({
            passPojo: this.setPojoCommon(pojo, tag, val),
        })
    }

    setPojoCommon = (pojo, tag, val) => {
        if (InputTest[tag].test.test(val)) {
            pojo[tag] = val;
        } else {
            if (pojo[tag] !== null) {
                pojo[tag] = null;
            }
        }
        return pojo;
    }

    getErrorInputMsg = (status, tag, byPass, pojo) => {
        if (status === 1) {
            if (byPass) {
                if (this.state.passError) {
                    if (tag === "account" && this.state.account.type === 0) {
                        return "输入的手机号或邮箱无效"
                    }
                    if (ByPassItems.indexOf(tag) !== -1) {
                        return this.findErrorInputCommon(pojo, tag);
                    }
                }
            } else {
                if (this.state.codeError && ByCodeItem.indexOf(tag) !== -1) {
                    return this.findErrorInputCommon(pojo, tag);
                }
            }
        }
    }

    findErrorInputCommon = (pojo, tag) => {
        return pojo[tag] ? "" : InputTest[tag].error;
    }

    login = (pojo, byPass) => {
        let account = this.state.account;
        let accountType = account.type;
        if (byPass) {
            if (accountType === 0 || !pojo.userPwd) {
                this.setState({
                    status: 1,
                    passError: true,
                    codeError: false,
                })
                return;
            } else {
                if (accountType === 1) {
                    pojo.userPhone = account.value;
                } else {
                    pojo.userEmail = account.value;
                }
            }
        } else {
            if (!pojo.userPhone || !pojo.code) {
                this.setState({
                    codeError: true,
                    passError: false,
                    status: 1,
                })
                return;
            }
        }

        pojo.type = 1;
        console.log(pojo)
        sendPostRequest(genPojo(LoginUrl, pojo), true).then(res => {
            console.log("登录res: ", res);
            if (res === connectionError) {
                failReturn(findMsgByError(connectionError), LoginRoot);
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    console.log(res[data])
                    // sessionStorage.setItem("user", res[data]);
                    UserInfoStorage.set("userInfo", res[data]);

                    // 处理来自demand和policy的返回需求
                    let toFrom = storage.getF("historyFrom");
                    const urlTo = toFrom ? toFrom : CenterRoot;

                    console.log("账号登录-demand和policy的返回需求: ", toFrom);
                    console.log("账号登录url: ", urlTo);
                    successReturn("登录成功", urlTo);
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    loadInputByMethod = (byPass, pojo, status, secondI, canSend, showPass) => {
        if (byPass) {
            return (
                <div className={"func-ct-input-outer"}>
                    <input className={"func-ct-input"} key={"account"} placeholder={"手机/邮箱"} maxLength={40}
                           autoComplete={"off"}
                           onChange={(e) => {
                               this.fillValue(e, "account", pojo)
                           }}/>
                    <span
                        className={"func-input-notice"}>{this.getErrorInputMsg(status, "account", byPass, pojo)}</span>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <input className={"func-ct-input"} placeholder={"请输入密码"} type={showPass ? "text" : "password"}
                               autoComplete={"off"}
                               maxLength={20}
                               onChange={(e) => {
                                   this.fillValue(e, "userPwd", pojo)
                               }}/>
                        <EyeOutlined style={{
                            fontSize: "18px",
                            position: "absolute",
                            transform: "translateX(344px)",
                            color: showPass ? "#8aaed7" : "#c4c4c4",
                            cursor: "pointer",
                        }} onClick={() => {
                            this.setState({showPass: !showPass})
                        }}/>
                    </div>
                    <span
                        className={"func-input-notice"}>{this.getErrorInputMsg(status, "userPwd", byPass, pojo)}</span>
                </div>
            )
        }
        return (
            <div className={"func-ct-input-outer"}>
                <input className={"func-ct-input"} key={"phone"} placeholder={"手机号码"} maxLength={11} onChange={(e) => {
                    this.fillValue(e, "userPhone", pojo)
                }}/>
                <span className={"func-input-notice"}>{this.getErrorInputMsg(status, "userPhone", byPass, pojo)}</span>
                <div className={"func-ct-input-valid"}>
                    <div className={"func-ct-valid-line"}>
                        <div className={"send-valid-sign"} style={{background: this.state.focusValid ? "#5292db" : ""}}>
                            <KeyOutlined/>
                        </div>
                        <input className={"func-ct-input-valid-num"} placeholder={"请输入验证码"} maxLength={6}
                               onFocus={() => {
                                   this.setState({focusValid: true})
                               }} onBlur={() => {
                            this.setState({focusValid: false})
                        }} onChange={(e) => {
                            this.fillValue(e, "code", pojo)
                        }}/>
                        <div className={"send-valid-bt"}
                             style={!canSend ? {
                                 border: "1px solid #b3b3b3",
                                 background: "#b3b3b3",
                                 color: "#ffffff",
                                 cursor: "not-allowed"
                             } : {}} onClick={() => {
                            handleSendButton(30, canSend, pojo.userPhone, false, this)
                        }}>
                            {!canSend ? secondI + "秒后可重发" : "发送验证码"}
                        </div>
                    </div>
                    <span className={"func-input-notice"}>{this.getErrorInputMsg(status, "code", byPass, pojo)}</span>
                </div>
            </div>
        )
    }

    submitPassChange = (pojo) => {
        let pwd = pojo.userPwd
        if (!pojo.code || !pojo.userPhone || !pwd) {
            this.setState({
                passStatus: 1
            })
            return;
        }
        if (pwd !== this.state.passRepeat) {
            fail("两次输入密码不一致！");
            return;
        }
        sendPostRequest(genPojo(ChangePassUrl, pojo), true).then(res => {
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    successReturn("修改密码成功！", LoginRoot);
                } else {
                    fail(findMsgByError(res[error]))
                }
            }
        })
    }

    initPassPojo = () => {
        this.setState({
            showChangeWin: false,
            passPojo: {
                userPhone: null,
                code: null,
                userPwd: null,
            },
            passStatus: 0,
            passRepeat: null,
        })
    }

    genChangePassWin = (secondI, canSend, showPass) => {
        let pojo = this.state.passPojo;
        let status = this.state.passStatus;
        return (
            <div className={"func-ct-outer"}>
                <div className={"change-pass-title-outer"}>
                    <div style={{
                        height: "34px",
                        width: "34px",
                        borderRadius: "30px",
                        background: "#518cd5",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "14px"
                    }}>
                        <KeyOutlined style={{color: "white", fontSize: "18px"}}/>
                    </div>
                    {"修改密码"}
                </div>
                <div style={{height: "20px"}}/>
                <input className={"func-ct-input"} key={"phone"} placeholder={"手机号码"} maxLength={11}
                       onChange={(e) => {
                           this.setNewPassPojo(e, pojo, "userPhone")
                       }}
                />
                <div
                    className={"func-input-notice"}>{status === 1 ? this.findErrorInputCommon(pojo, "userPhone") : ""}</div>
                <div className={"func-ct-input-valid"}>
                    <div className={"func-ct-valid-line"}>
                        <div className={"send-valid-sign"} style={{background: this.state.focusValid ? "#5292db" : ""}}>
                            <KeyOutlined/>
                        </div>
                        <input key={"change-valid"} className={"func-ct-input-valid-num"} placeholder={"请输入验证码"}
                               maxLength={6}
                               onFocus={() => {
                                   this.setState({focusValid: true})
                               }} onBlur={() => {
                            this.setState({focusValid: false})
                        }}
                               onChange={(e) => {
                                   this.setNewPassPojo(e, pojo, "code")
                               }}
                        />
                        <div className={"send-valid-bt"}
                             style={!canSend ? {
                                 border: "1px solid #b3b3b3",
                                 background: "#b3b3b3",
                                 color: "#ffffff",
                                 cursor: "not-allowed"
                             } : {}} onClick={() => {
                            handleSendButton(30, canSend, pojo.userPhone, false, this)
                        }}>
                            {!canSend ? secondI + "秒后可重发" : "发送验证码"}
                        </div>
                    </div>
                    <div
                        className={"func-input-notice"}>{status === 1 ? this.findErrorInputCommon(pojo, "code") : ""}</div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <input key={"new-pass"} className={"func-ct-input"} placeholder={"请输入新密码"}
                               type={showPass ? "text" : "password"}
                               maxLength={20}
                               onChange={(e) => {
                                   this.setNewPassPojo(e, pojo, "userPwd")
                               }}/>
                        <EyeOutlined style={{
                            fontSize: "18px",
                            position: "absolute",
                            transform: "translateX(344px)",
                            color: showPass ? "#8aaed7" : "#c4c4c4",
                            cursor: "pointer",
                        }} onClick={() => {
                            this.setState({showPass: !showPass})
                        }}/>
                    </div>
                    <div
                        className={"func-input-notice"}>{status === 1 ? this.findErrorInputCommon(pojo, "userPwd") : ""}</div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <input key={"new-pass-repeat"} className={"func-ct-input"} placeholder={"请输再次输入新密码"}
                               type={showPass ? "text" : "password"}
                               maxLength={20}
                               onChange={(e) => {
                                   this.setNewPassPojo(e, pojo, "passRepeat")
                               }}/>
                        <EyeOutlined style={{
                            fontSize: "18px",
                            position: "absolute",
                            transform: "translateX(344px)",
                            color: showPass ? "#8aaed7" : "#c4c4c4",
                            cursor: "pointer",
                        }} onClick={() => {
                            this.setState({showPass: !showPass})
                        }}/>
                    </div>
                    <div className={"func-input-notice"}>{}</div>
                    <div className={"func-main-bt"} onClick={() => {
                        this.submitPassChange(pojo)
                    }}>
                        {"确认修改"}
                    </div>
                </div>
            </div>

        )
    }

    loginByAccount = () => {
        this.setState({
            wechatLogin: false,
        })
    }

    // 微信登录
    loginByWechat = (index: number) => {
        /**
         * appid：应用的 AppID
         * redirect_uri：用户允许授权之后微信重定向回网站的地址，需要在微信开放平台的网站应用中配置“授权回调域”
         * response_type：请求用户授权的地址中这里填写 code
         * scope：应用授权作用域，拥有多个作用域用逗号（,）分隔，网页应用目前仅填写snsapi_login即可
         * state：该参数可选，用于保持请求和回调的状态，授权请求后原样带回给第三方
         */
        // let appId = "wxdfcf72510070f1b4";
        // let redirectUrl = encodeURIComponent(Header + LoginRoot);
        // let responseType = 'code';
        // let scope = 'snsapi_login';
        // let num = Math.ceil(Math.random() * 1000);
        // let state = index + "-" + num;
        // storage.set("aliLoginState", num);
        // let url = WechatOAuthUrl + "?appid=" + appId + "&redirect_uri=" + redirectUrl + "&response_type=" + responseType + "&scope=" + scope + "&state=" + state + "#wechat_redirect";
        // window.location.href = url;
        this.setState({
            wechatLogin: true,
        })
    }

    loadAccountLogin = (byPass, pojo, status, secondI, canSend, showPass) => {
        return (
            <Fragment>
                {this.loadInputByMethod(byPass, pojo, status, secondI, canSend, showPass)}
                <div className={"other-func"}>
                                <span style={{cursor: "pointer"}} onClick={() => {
                                    this.setState({
                                        status: 0,
                                        loginByPass: !byPass,
                                        pojo: {
                                            type: 0,
                                            userEmail: null,
                                            userPhone: null,
                                            userPwd: null,
                                            code: null,
                                            wechatId: null,
                                            aliId: null,
                                        }
                                    })
                                }}>
                                    {byPass ? "短信验证登录" : "使用密码登录"}
                                </span>
                </div>
                <div className={"func-main-bt"}
                     onClick={() => {this.login(pojo, byPass)}}>
                    {"登录"}
                </div>
                <div className={"func-center-helper"}
                     onClick={() => {this.setState({showChangeWin: true})}}>
                    {"已有账号,忘记密码?"}
                </div>
            </Fragment>
        )
    }

    componentDidMount() {
        this.handleStateFrom();
        this.handleWxRedirectUrl();
    }

    /**
     * 记录from
     */
    handleStateFrom = () => {
        //来源记录
        let from;
        if (this.props.location.state != null) {
            from = this.props.location.state.from;
            console.log(from);
            if (from) {
                storage.set("historyFrom", from);
            }
        }
    }

    handleWxRedirectUrl = () => {
        let url = window.location.href;
        let params = url.split("?")[1];
        console.log(params);
        if (params) {
            let paramsList = params.split("&");
            let authCodePart = paramsList.filter(it => it.includes("code="));
            let authCode = authCodePart[0].split("code=")[1];
            console.log(authCodePart);
            if (authCodePart.length === 0) {
                failReturn("微信登录失败", LoginRoot);
                return;
            }
            let statePart = paramsList.filter(it => it.includes("state="));
            if (statePart) {
                let stateList = handleUrlState(statePart);
                let code = stateList.stateCode;
                let stateSession = storage.get("wxLoginState");
                console.log("stateSession: ", stateSession);
                if (Number.parseInt(code) === Number.parseInt(stateSession)) {
                    // 校验通过
                    console.log("state校验通过");
                    let param = {
                        authCode: authCode,
                    }

                    let _this = this;
                    const wxLoginBack: WinBack = {
                        callDataBack(data: any) {
                            console.log("微信登录：", data);
                            let userId = data.userId;
                            if (userId) {
                                // 直接登录
                                UserInfoStorage.set("userInfo", data);
                                // 处理来自demand和policy的返回需求
                                // let toFrom = _this.state.from;
                                let toFrom = storage.getF("historyFrom");
                                const urlTo = toFrom || CenterRoot;
                                console.log("demand和policy的返回需求: ", toFrom);
                                console.log("微信登录url: ", urlTo);
                                successReturn("微信登录成功", urlTo);
                            } else {
                                // 未绑定过账号，需要填写信息
                                if (Number.parseInt(stateList.index) === 1) {
                                    // 注册界面
                                    _this.goRegByWx(data);
                                } else {
                                    // 个人中心界面
                                    // Header + CenterRoot;
                                }
                            }
                        }
                    }

                    sendGetBack(LoginByWxUrl, param, true, genProcessBack(wxLoginBack, true))
                } else {
                    // 触发CSRF攻击
                    // 不可证明客户端和当前第三方网站的登录认证状态存在关联
                    console.log("WX触发CSRF攻击[state校验不通过]");
                    window.location.href = LoginRoot;
                }
            } else {
                // 触发CSRF攻击
                // 不可证明客户端和当前第三方网站的登录认证状态存在关联
                console.log("WX触发CSRF攻击[没有state]");
                window.location.href = LoginRoot;
            }
        }
    }

    /**
     * 执行注册流程
     * 传参userInfo
     */
    goRegByWx = (userInfo) => {
        //必须在使用withRouter的情况下，该组件在this.props中才有路由参数和方法
        //否则，会报错
        let wechatId = userInfo.wechatId;
        this.props.history.push({
            pathname: RegisterRoot,
            state: {
                wechatId: wechatId,
            }
        })
    }

    render() {
        let byPass = this.state.loginByPass;
        let pojo = this.state.pojo;
        let status = this.state.status;
        let showChangeWin = this.state.showChangeWin;
        let secondI = this.state.secondI;
        let canSend = secondI === 30;
        let showPass = this.state.showPass;
        let wechatLogin = this.state.wechatLogin;
        let setSrc = this.state.setSrc;
        let from = this.state.from;

        return (
            [
                <div>{showChangeWin ?
                    <div className={"change-pass-win"} key={"win"}>
                        <div className={"change-win-inner"}>
                            <div style={{width: "100%", height: "40px", display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                                <CloseOutlined style={{fontSize: "20px",marginRight: "10px",color: "rgb(72,72,72)",cursor: "pointer"}}
                                               onClick={() => {this.initPassPojo()}}/>
                            </div>
                            {this.genChangePassWin(secondI, canSend, showPass)}
                        </div>
                    </div> : null}
                </div>,
                <div className={"func-pg-bk"} key={"main"}>
                    <div className={"login-page"}>
                        {AdComp()}
                        <div className={"func-ct"}>
                            <div className={"func-ct-outer"}>
                                <div className={"func-ct-title"}>
                                    <div className={"func-title-name"}>{"登录"}</div>
                                    <div className={"func-title-corner"}>
                                        <span>{"没有账号?"}</span>
                                        <span style={{paddingLeft: "8px",color: "rgb(33,115,199)",cursor: "pointer"}}
                                              onClick={() => {window.location.href = RegisterRoot}}>
                                            {"点此注册"}
                                        </span>
                                    </div>
                                </div>
                                {wechatLogin ?
                                    <div className={"wechat-scan-wrap"}>
                                        <WxPlugin index={1}/>
                                    </div>
                                    :
                                    this.loadAccountLogin(byPass, pojo, status, secondI, canSend, showPass)
                                }
                                <div className={"func-login-method"}>
                                    <div className={"func-style-line"}/>
                                    <span className={"login-method-des"}>
                                        {"其他方式登录"}
                                    </span>
                                    <div className={"func-style-line"}/>
                                </div>
                                <div className={"login-other-methods"}>
                                    <img className={"oauth-btn"} src={PATON_ICON} alt={"paton"}
                                         onClick={() => {this.loginByAccount()}}/>
                                    <WechatFilled style={{fontSize: "38px", margin: "0 20px 0 20px", color: "#07c160", cursor: "pointer"}}
                                                  onClick={() => {this.loginByWechat(1)}}/>
                                    <AlipayCircleFilled style={{fontSize: "34px", margin: "0 20px 0 20px", color: "rgb(0,166,255)", cursor: "pointer"}}
                                                        onClick={() => {handleAliAuth(1)}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ]
        )
    }
}

export default Login;
