import React from 'react';
import "../page.css";
import {CSSTransition} from "react-transition-group";

// import Swiper core and required modules
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper';

import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/effect-fade";
import {RightOutlined} from "@ant-design/icons";
import {handleClickMonitor, NewsRoot, PageId, UserInfoStorage} from "../../tool/Const";
import {SrcHeader} from "../../tool/http/Request";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

class News extends React.Component {
    constructor() {
        super();
        this.state = {
            label: 99,
            isHover: false,
        }
    }

    MouseEnter(label) {
        this.setState({
            label: label,
            isHover: true,
        })
    }

    MouseLeave(label) {
        this.setState({
            label: label,
            isHover: false,
        })
    }

    genNews = (data, label, isHover, userInfo) => {
        if (data.length > 0) {
            return (
                <Swiper
                    className={"swiper-style"}
                    spaceBetween={50}
                    slidesPerView={data.length >= 3 ? 3 : data.length}
                    // navigation
                    loop={true}
                    autoplay={{
                        "delay": 3000,
                        "disableOnInteraction": false,
                        "pauseOnMouseEnter": true
                    }}
                    pagination={{ clickable: true }}
                    // scrollbar={{ draggable: true }}
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                >
                    {
                        data.map((item, index) => {
                            // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
                            // let picDir = require.context("../pic/", true, /\.(png|jpg)$/);
                            // let imgUrl = picDir("./" + item["newsBanner"]);
                            let imgUrl = SrcHeader + item["newsId"] + "/" + item["newsBanner"];
                            return (
                                <SwiperSlide className={"swiper-slide-style"}
                                             style={{opacity: isHover ? (label === index ? 1 : 0.5) : 1}}
                                             key={item + index}
                                             onMouseEnter={this.MouseEnter.bind(this, index)}
                                             onMouseLeave={this.MouseLeave.bind(this, 99)}
                                >
                                    <div className={"news-img"}
                                         onClick={() => {
                                             handleClickMonitor(userInfo, item["newsId"], 2, 1, NewsRoot.path + "/" + item["newsId"])
                                         }}
                                    >
                                        <CSSTransition in={label === index}
                                                       classNames={"news-img-transition"}
                                                       timeout={4000}
                                                       appear>
                                            <img className={"img-size"} src={imgUrl} alt={""}/>
                                        </CSSTransition>
                                    </div>
                                    <div className={"news-container"}>
                                        <div className={"news-title"}>
                                            {item["newsName"]}
                                        </div>
                                        <div className={"news-date"}>
                                            {item["newsDate"]}
                                        </div>
                                        <div className={"news-des"}>
                                            {item["newsDes"]}
                                        </div>
                                        <div className={label === index ? "news-link-active" : "news-link"}
                                             onClick={() => {
                                                 handleClickMonitor(userInfo, item["newsId"], 2, 1, NewsRoot.path + "/" + item["newsId"])
                                             }}
                                        >
                                            点击查看
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            )
        }
    }

    textFormat = (text, num) => {
        return text.substring(0, num) + "..."
    }

    render() {
        let userInfo = UserInfoStorage.get("userInfo");
        let news = this.props.news;
        let pushData = this.props.pushData;
        let label = this.state.label;
        let isHover = this.state.isHover;

        return (
            <div className={"out-content"}>
                {
                    news && pushData.length > 0 ?
                        <div className={"news-ot"}>
                            <div className={"page-ct"}>
                                <div className={"info-header-title"}>
                                    <div className={"info-header-name"}>
                                        {"科技新闻"}
                                    </div>
                                    <div className={"info-header-des"}>
                                        {"了解实时科技新闻资讯"}
                                    </div>
                                </div>
                                <div className={"news-content"}>
                                    {this.genNews(pushData, label, isHover, userInfo)}
                                </div>
                                <div className={"page-more"}
                                     onClick={() => {
                                         handleClickMonitor(userInfo, PageId.news, 1, 1, NewsRoot.path)
                                     }}
                                >
                            <span className={"page-more-text"}>
                                查看更多
                            </span>
                                    <RightOutlined className={"page-more-icon"}/>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        )
    }
}

export default News;
