import React, {Component} from 'react';
import "./outcome.css";
import "../page.css";
import Header from "../Header";
import Footer from "../Footer";
import OutcomeFilter from "./OutcomeFilter";
import Contact from "../home/Contact";
import AdRight from "../../tool/AdRight";
import {
    advList,
    dataIndexE,
    filterAreaTech,
    filterTypeBySub,
    findFavorDataByType,
    getAdvData,
    handleAdRightWinSizeChange, handleAdRightWinSizeInit,
    handleClickMonitor,
    handlePageScrollMove,
    LoadingPolicyCopAds,
    LoadingSolutionCopAds,
    OutcomeRoot,
    PolicyRoot,
    SaveBt,
    SolutionRoot,
    solutionTechCopList,
    UserInfoStorage
} from "../../tool/Const";
import Partitioner from "../../tool/Partitioner";
import EMPTY from "../../assets/img/record_empty.svg";
import {SrcHeader} from "../../tool/http/Request";

const loadSetting = {
    loadingBatch: 12,
    classes: {
        outer: "outcome-page-partition-ct",
    }
}

const adClasses = {
    adOuter: "outcome-ct-ad",
    adOne: "outcome-ct-ad-one",
    adTwo: "outcome-ct-ad-two",
}

const initType = "发明专利";

// const initGeo = "上海;上海市";
const initGeo = "全国所有省市";

const initTag = "全部";

class OutcomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fixed: true,
            fixedAd: false,
            adLeft: "",
            adBt: false,
            showAd: true,
            gap: 0,
            hoverI: -1,
            cH: 0,
            adjustGap: 0,
            openSize: 0,
            partitioner: {
                totalSize: 0,
                showData: [],
                loadComplete: false,
            },
            options: {
                index: '1344',
                geo: initGeo,
                type: initType,
                from: initTag,
                level: initTag,
                area: initTag,
                method: initTag,
                keyWords: "",
            },
            favorList: [],
            idList: [],
            advMap: new Map(),  // adv数据
        };
        this.timeout = null;
    }

    componentDidMount() {
        handleAdRightWinSizeInit(this);
        window.addEventListener("resize", this.handleResize);

        window.onscroll = () => {
            let cH = document.getElementById("outcome-outer").clientHeight;
            let cHC = this.state.cH;

            let headerH = document.getElementById("header").clientHeight;
            let filterH = document.getElementById("filter").clientHeight;
            let paddingH = 0;
            let adH = document.getElementById("ad").clientHeight;
            let contactH = 42;
            let distanceAboveAd = headerH + filterH + paddingH + adH + contactH - 60;  // 1860
            let distanceBtAd = headerH + filterH + paddingH + contactH - 60;  // 700
            let distanceContact = headerH + filterH + paddingH + contactH - 90;  // 670

            handlePageScrollMove(this, "o-tag", distanceAboveAd, distanceBtAd + cH,
                distanceContact + cH,
                "outcome-outer", 940)
            if (cHC !== cH) {
                this.setState({
                    cH: cH,
                })
            }
        };
        findFavorDataByType(this);
        getAdvData(advList.outcome, this);
        this.handleMenuLink();
    }

    handleMenuLink = () => {
        let search = this.props.location.search;
        if (search) {
            let params = search.replace("?", "");
            let filterType = params.split("&")[0];
            let filterArea = params.split("&")[1];
            let filterTypeName = filterType.split("=")[0];
            let filterTypeValue = filterType.split("=")[1];
            let filterAreaName = filterArea.split("=")[0];
            let filterAreaValue = filterArea.split("=")[1];
            // console.log(filterTypeName, filterTypeValue);
            // console.log(filterAreaName, filterAreaValue);
            let options = this.state.options;
            // console.log(options);
            options['type'] = filterTypeBySub[filterTypeValue]['value'];
            options['area'] = filterAreaTech[filterAreaValue]['value'];
            this.setState({
                options: Object.assign({}, options),
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        handleAdRightWinSizeChange("outcome-outer", this);
    }

    loadOutcomeData = () => {
        let data = []
        let showData = this.state.partitioner.showData;
        let hoverI = this.state.hoverI;
        let userInfo = UserInfoStorage.get("userInfo");
        let favorList = this.state.favorList;
        let options = this.state.options;

        console.log("userInfo: ", userInfo);
        console.log("showData: ", showData);
        console.log("favorList: ", favorList);

        if (showData) {
            showData.forEach((item, index) => {
                // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
                // let picDir = require.context("../pic/", true, /\.(png|jpg)$/);
                // let imgUrl = picDir("./" + item["outcomeBanner"]);
                let imgUrl = SrcHeader + item["outcomeId"] + "/" + item["outcomeBanner"];
                data.push(
                    <div className={"outcome-data-item"} key={index} style={{marginTop: index === 0 ? "0" : ""}}>
                        <div className={"outcome-item-square"}>
                            {SaveBt(item, index, hoverI, this, userInfo, favorList, options)}
                            <div className={"save-policy"} style={{display: index === hoverI ? "" : "none"}}>
                                {favorList.indexOf(item["outcomeId"]) > -1 ? "取消收藏" : "点击收藏"}
                            </div>
                        </div>
                        <div className={"outcome-data-item-main"}
                             onClick={() => {
                                 handleClickMonitor(userInfo, item["outcomeId"], 2, 1, OutcomeRoot.path + "/" + item["outcomeId"])
                             }}
                        >
                            <div className={"outcome-data-item-pic-outer"}>
                                <div className={"outcome-data-item-pic-inner"}>
                                    <img className={"outcome-item-pic"}
                                         style={hoverI === index ? {transform: "scale(1.2, 1.2)"} : {}}
                                         src={imgUrl}
                                         alt={""}
                                    />
                                </div>
                            </div>
                            <div className={"outcome-data-item-ct-outer"}>
                                <div className={"outcome-data-item-title"}>
                                <span className={"item-flow"}>
                                    {item["outcomeName"]}
                                </span>
                                </div>
                                <div className={"outcome-data-item-tags"}>
                                <span className={"outcome-data-item-tag-title"}>
                                    {"发布日期:"}
                                </span>
                                    <div className={"outcome-tag"} style={{marginRight: "30px"}}>
                                        {item["relDate"]}
                                    </div>
                                    <span className={"outcome-data-item-tag-title"}>
                                    {"成熟度:"}
                                </span>
                                    <div className={"expert-job-tag"}>
                                        {item["outcomeStage"]}
                                    </div>
                                </div>
                                <div className={"outcome-data-item-tags"}>
                                <span className={"outcome-data-item-tag-title"}>
                                    {"所属领域:"}
                                </span>
                                    <div className={"expert-area-tag"}>
                                        {item["outcomeAreas"]}
                                    </div>
                                </div>
                                <div className={"outcome-data-item-des"}>
                                <span className={"item-flow-des"}>
                                    <span className={"outcome-data-item-tag-title"}>
                                        {"项目简介:"}
                                    </span>
                                    <span style={{fontSize: "14px", lineHeight: "24px", color: "black"}}>
                                        {item["outcomeDes"]}
                                    </span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            data.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }
        return data;
    }

    render() {
        let adLeft = this.state.adLeft;
        console.log(adLeft)
        let right = "calc((100% - " + adLeft + " ) / 2)";

        let totalGap = this.state.gap - this.state.adjustGap;
        let gapR = totalGap === 0 ? "0" : parseInt(totalGap + "") + "px";
        let wH = parseInt((this.state.cH - 30) + "") + "px";
        let partitioner = this.state.partitioner;
        let options = this.state.options;
        let idList = this.state.idList;
        let advMap = this.state.advMap;

        // console.log("options: ", options);
        // console.log("idList: ", idList);
        // console.log("advMap: ", advMap);
        // console.log(document.getElementById("outcome-outer").clientWidth);

        return (
            <div className={"home-ct"}>
                <Header/>
                <div className={"outcome-page"}>
                    <OutcomeFilter parent={this} total={this.state.partitioner.totalSize} options={options}
                                   initTag={initTag}/>
                    <div className={"outcome-page-content"} id={"outcome-outer"}>
                        <div className={"outcome-ct-data"}>
                            {this.loadOutcomeData()}
                            <div className={"outcome-page-partition"}
                                 style={{display: partitioner.showData ? "" : "none"}}
                            >
                                <Partitioner parent={this} loadSetting={loadSetting} options={options}
                                             // handleCheckMonitor={handleIdList}
                                />
                            </div>
                        </div>
                        {
                            advMap.size === advList.outcome.length && adLeft ?
                                <AdRight fixedAd={this.state.fixedAd} adBt={this.state.adBt} gapR={gapR} right={right}
                                         wholeHgt={wH} adClasses={adClasses}
                                         adTitleOne={"企业技术创新"} adOneCt={LoadingSolutionCopAds(solutionTechCopList)} adOneUrl={SolutionRoot}
                                         adTitleTwo={"政策精要"} adTwoCt={LoadingPolicyCopAds(advMap.get(dataIndexE.policy))} adTwoUrl={PolicyRoot.path}
                                />
                                : null
                        }
                    </div>
                </div>
                <Contact fixed={this.state.fixed}/>
                <div>
                    <Footer/>
                </div>
            </div>
        )
    }
}

export default OutcomePage;
