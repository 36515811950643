import React, {Component, Fragment} from 'react';
import "../tool.css";
import {DownOutlined} from "@ant-design/icons";
import {schTime} from "../Const";

class CommonSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openOption: false,
        }
    }

    // option开关
    controlOption = (openOption) => {
        this.setState({
            openOption: !openOption,
        })
    }

    // 加载option
    loadOption = (data, tag, parent) => {
        let list = [];
        if (data.size > 0) {
            let schList = data.get(tag);
            console.log(schList)
            schList.forEach((item, index) => {
                list.push(
                    <span className={"option-item"} key={index}
                          onClick={() => {
                              this.props.parent.handleSchSel(item)
                          }}
                    >
                        {schTime[item]}
                    </span>
                    // <Option className={"calendar-date-select-cell"} value={schTime[item]} key={index}>
                    //     {schTime[item]}
                    // </Option>
                )
            })
            return list;
        }
    }

    render() {
        let parent = this.props.parent;
        // let options = this.props.options;
        let classWrap = this.props.classWrap;
        let selTimePoint = this.props.selTimePoint;
        let data = this.props.data;
        let tag = this.props.tag;
        let openOption = this.state.openOption;

        return (
            <Fragment>
                <span className={classWrap}
                      onClick={() => {
                          this.controlOption(openOption)
                      }}
                >
                    <span className={"select-txt"}>
                         {selTimePoint}
                    </span>
                    <div className={"select-arrow-icon"}>
                          <DownOutlined />
                    </div>
                    {openOption ?
                        <div className={"option-wrap"}>
                            {this.loadOption(data, tag, parent)}
                        </div>
                        : null
                    }
                </span>
            </Fragment>
        )
    }
}

export default CommonSelect;