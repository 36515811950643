import {
    AddPtcUrl,
    CheckOrderFinishUrl,
    genPojo, InitOrderUrl,
    PayByAliUrl,
    PayByCoinUrl,
    PayByWxUrl, sendGetBack,
    sendPostBack
} from "../../tool/http/Request";

class PayOperator {
    initOrder = (params, back: DataBack) => {
        sendGetBack(InitOrderUrl, params, true, back);
    }

    payByCoin = (currencyRequest: CurrencyRequest, back: DataBack) => {
        sendPostBack(genPojo(PayByCoinUrl, currencyRequest), true, back);
    }

    payByAli = (currencyRequest: CurrencyRequest, back: DataBack) => {
        sendPostBack(genPojo(PayByAliUrl, currencyRequest), true, back);
    }

    payByWx = (currencyRequest: CurrencyRequest, back: DataBack) => {
        sendPostBack(genPojo(PayByWxUrl, currencyRequest), true, back);
    }

    addPtc = (currencyRequest: CurrencyRequest, back: DataBack) => {
        sendPostBack(genPojo(AddPtcUrl, currencyRequest), true, back);
    }

    checkOrderFinish = (currencyRequest: CurrencyRequest, back: DataBack) => {
        sendPostBack(genPojo(CheckOrderFinishUrl, currencyRequest), true, back);
    }
}

export default PayOperator;