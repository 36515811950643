import React from 'react';
import "./../page.css";
import Static from "../pic/state1.png"
import Cir from "../pic/state2.png"
import TweenOne from "rc-tween-one";
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';

TweenOne.plugins.push(Children);

class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 13526,
            value2: 3008,
            value3: 1225,
            value4: 2896,
            animation: null,
            animation2: null,
            animation3: null,
            animation4: null,
            formatMoney: false,
            loaded: false,
        };
    }

    onLoadData = () => {
        let loaded = this.state.loaded;
        if (!loaded) {
            const {value, value2, value3, value4, formatMoney} = this.state;
            this.setState({
                animation: {
                    Children: {
                        value: value, floatLength: 0,
                        formatMoney,
                    },
                    duration: 1000,
                },
                animation2: {
                    Children: {
                        value: value2, floatLength: 0,
                        formatMoney,
                    },
                    duration: 1000,
                },
                animation3: {
                    Children: {
                        value: value3, floatLength: 0,
                        formatMoney,
                    },
                    duration: 1000,
                },
                animation4: {
                    Children: {
                        value: value4, floatLength: 0,
                        formatMoney,
                    },
                    duration: 1000,
                },
                loaded: true,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.data !== prevProps.data) {
            this.onLoadData()
        }
    }

    render() {
        return <div className={"out-content"}>
            <div className={"stat-ot"}>
                <div className={"page-ct"} style={{display: "flex", alignItems: "fex-start"}}>
                    <div className={"static-ct"}>
                        <img src={Static} alt={""} className={"data-bk-c"}/>
                        <img src={Cir} alt={""} className={"data-bk"}/>
                        <div className={"data-content"}>
                            <div className={"data-level"}>
                                <div className={"data-item"}>
                                    <div className={"data-title"}>
                                        {"技术成果累计"}
                                    </div>
                                    <div className={"data-num"}>
                                        <TweenOne
                                            className={"roll-num"}
                                            animation={this.state.animation}
                                            // style={{fontSize: 46, color: "rgb(94,94,94)", letterSpacing: "1px"}}
                                        >
                                            0
                                        </TweenOne>
                                        <span className={"data-unit"}>{"项"}</span>
                                    </div>
                                </div>
                                <div style={{width: "340px", height: "100%"}}/>
                                <div className={"data-item"}>
                                    <div className={"data-title"}>
                                        {"专家技术团队"}
                                    </div>
                                    <div className={"data-num"}>
                                        <TweenOne
                                            className={"roll-num"}
                                            animation={this.state.animation2}
                                        >
                                            0
                                        </TweenOne>
                                        <span className={"data-unit"}>{"位"}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"data-level"}>
                                <div className={"data-item"}>
                                    <div className={"data-title"}>
                                        {"对接企业总计"}
                                    </div>
                                    <div className={"data-num"}>
                                        <TweenOne
                                            className={"roll-num"}
                                            animation={this.state.animation3}
                                        >
                                            0
                                        </TweenOne>
                                        <span className={"data-unit"}>{"家"}</span>
                                    </div>
                                </div>
                                <div style={{width: "340px", height: "100%"}}/>
                                <div className={"data-item"}>
                                    <div className={"data-title"}>
                                        {"科技咨询发布"}
                                    </div>
                                    <div className={"data-num"}>
                                        <TweenOne
                                            className={"roll-num"}
                                            animation={this.state.animation4}
                                        >
                                            0
                                        </TweenOne>
                                        <span className={"data-unit"}>{"条"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Statistics;