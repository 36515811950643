import React, {ReactNode} from 'react';
import "./tool.css";
// import {createRoot} from "react-dom/client";
import ReactDOM from "react-dom";

// let warp: HTMLElement;

export const createWin = () => {
    return (win: ReactNode, id: ReactNode, winId: ReactNode) => {
        if (typeof document === "undefined") {
            return;
        }
        let winElement = document.getElementById(winId);
        if (!winElement) {
            let warp: HTMLElement = document.createElement("div");
            warp.id = winId;
            document.body && document.body.appendChild(warp);
            let page = document.getElementById(id);
            page.appendChild(warp)
            let divs = document.getElementById("lunch-win");
            if (!divs) {
                divs = document.createElement("div");
                warp.appendChild(divs);
                // let root = createRoot(warp);
                ReactDOM.render(
                    <Win win={win} parentDom={warp} rootDom={page}/>, warp
                )
            }
        }
    }
}

export const delWin = (id, winId) => {
    let page = document.getElementById(id);
    if (page) {
        const child = document.getElementById(winId);
        if (child) {
            const win = document.getElementById("lunch-win");
            if (win) {
                child.removeChild(win);
            }
            page.removeChild(child);
        }
    }
}

export type MessageProps = {
    rootDom: HTMLElement;
    parentDom: Element | DocumentFragment;
    win: ReactNode;
}

export function Win(props: MessageProps) {
    const {win} = props;

    return document.getElementById("lunch-win") === null ?
        <div className={"lunch-win-out"} id={"lunch-win"} key={"lunch-win-key"}>
            {win}
        </div>
        :
        null;
}