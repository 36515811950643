import React from 'react';
import "./page.css";
import Footer from "./Footer";
import Header from "./Header";
import BannerHome from "./home/BannerHome";
import TagsDown from "./home/TagsDown";
import Statistics from "./home/Statistics";
import Outcome from "./home/Outcome";
import Demand from "./home/Demand";
import Experts from "./home/Experts";
import Policy from "./home/Policy";
import News from "./home/News";
import Partner from "./home/Partner";
import Search from "./home/Search";
import Map from "./pic/map.svg"
import Solution from "./home/Solution";
import Contact from "./home/Contact";
import {dataIndexE, getPushData, getUserInfoCache} from "../tool/Const";

const pushIndexList = [dataIndexE.outcome, dataIndexE.expert, dataIndexE.demand, dataIndexE.policy, dataIndexE.news];

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: '',
            loadData: false,
            outcome: false,
            outcomePushData: [],
            expert: false,
            expertPushData: [],
            solution: false,
            demanded: false,
            demandPushData: [],
            policy: false,
            policyPushData: [],
            news: false,
            newsPushData: [],
            fixed: true,
        };
    }

    handleDataShow = () => {
        let loadData = this.state.loadData;
        if (!loadData) {
            let height = document.documentElement.clientHeight;
            let distance = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
            let above = 100 + 500 + 180 + 200;
            let showLine = 50;
            if ((height + distance) > (above + showLine)) {
                this.setState({
                    loadData: true
                })
            }
        }
    }

    handleTechIn = () => {
        let distance = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
        // console.log(window.innerHeight);

        let headerH = document.getElementById("header-pos").clientHeight;
        let bannerH = document.getElementById("banner-pos").clientHeight;
        let tagsDownH = document.getElementById("tagsDown-pos").clientHeight;
        let searchH = document.getElementById("search-pos").clientHeight;
        let statisticsH = document.getElementById("statistics-pos").clientHeight;
        let solutionH = document.getElementById("solution-pos").clientHeight;
        let outcomeH = document.getElementById("outcome-pos").clientHeight;
        let expertH = document.getElementById("expert-pos").clientHeight;
        let demandH = document.getElementById("demand-pos").clientHeight;
        let policyH = document.getElementById("policy-pos").clientHeight;
        let newsH = document.getElementById("news-pos").clientHeight;
        let partnerH = document.getElementById("partner-pos").clientHeight;

        let ttlH = headerH + bannerH + tagsDownH + searchH + statisticsH + solutionH + outcomeH + expertH + demandH + policyH + newsH + partnerH;
        console.log("headerH: ", headerH);
        console.log("bannerH: ", bannerH);
        console.log("tagsDownH: ", tagsDownH);
        console.log("searchH: ", searchH);
        console.log("statisticsH: ", statisticsH);
        console.log("solutionH: ", solutionH);
        console.log("outcomeH: ", outcomeH);
        console.log("expertH: ", expertH);
        console.log("demandH: ", demandH);
        console.log("policyH: ", policyH);
        console.log("newsH: ", newsH);
        console.log("partnerH: ", partnerH);
        console.log("ttlH: ", ttlH);

        if (distance > 580 && !this.state.solution) {
            this.setState({
                solution: true,
            })
        }
        if (distance > 1380 && !this.state.outcome) {
            this.setState({
                outcome: true,
            })
        }
        if (distance > 2700 && !this.state.expert) {
            this.setState({
                expert: true,
            })
        }
        if (distance > 3580 && !this.state.demanded) {
            this.setState({
                demanded: true,
            })
        }
        if (distance > 4180 && !this.state.policy) {
            this.setState({
                policy: true,
            })
        }
        if (distance > 4980 && !this.state.news) {
            this.setState({
                news: true,
            })
        }
        let fixed = this.state.fixed
        if (distance + window.innerHeight >= ttlH) {
            fixed && this.setState({fixed: false});
        } else {
            !fixed && this.setState({fixed: true});
        }
    }

    componentDidMount() {
        getUserInfoCache(this);
        getPushData(pushIndexList, this);
        // pageOnLoad();
        window.onscroll = () => {
            this.handleDataShow();
            this.handleTechIn();
            // pageBeforeLoad();
        };
    }

    scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
            }
        }
    }

    render() {
        return <div className={"home-ct"}>
            <div id={"header-pos"}>
                <Header/>
            </div>
            <div id={"banner-pos"}>
                <BannerHome/>
            </div>
            <div id={"tagsDown-pos"}>
                <TagsDown parent={this}/>
            </div>
            <div id={"search-pos"}>
                <Search/>
            </div>
            <div id={"statistics-pos"}>
                <div className={"home-map"}>
                    <img src={Map} alt={""} className={"home-map-img"}/>
                </div>
                <Statistics data={this.state.loadData}/>
            </div>
            <div id={"solution-pos"}>
                <Solution solution={this.state.solution}/>
            </div>
            <div id={"outcome-pos"}>
                <Outcome outcome={this.state.outcome} pushData={this.state.outcomePushData}/>
            </div>
            <div id={"expert-pos"}>
                <Experts expert={this.state.expert} pushData={this.state.expertPushData}/>
            </div>
            <div id={"demand-pos"}>
                <Demand demand={this.state.demanded} pushData={this.state.demandPushData}/>
            </div>
            <div id={"policy-pos"}>
                <Policy policy={this.state.policy} pushData={this.state.policyPushData}/>
            </div>
            <div id={"news-pos"}>
                <News news={this.state.news} pushData={this.state.newsPushData}/>
            </div>
            <div id={"partner-pos"}>
                <Partner/>
            </div>
            <Contact fixed={this.state.fixed}/>
            <div className={"footer-line"}>
                <Footer/>
            </div>
        </div>
    }
}

export default Home;