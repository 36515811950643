import React, {Component, Fragment} from "react";
import "./center.css";
import {LoginRoot, UserInfoStorage} from "../../tool/Const";
import {CheckStatusOrdersUrl, data, error, loginOutErrOr, sendGetRequest} from "../../tool/http/Request";
import NODATA from "../../assets/icon/record_empty.svg";
import {connectionError, fail, failReturn, findMsgByError} from "../../tool/http/ErrorMgr";

class OrdersMgr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 0,
            data: [],
        }
    }

    loadTable = (orderId, subTime, realPrice, rmbPrice, paiPrice, disPrice, method, serviceItem) => {
        return (
            <Fragment>
                <div className={"table-cell order-id"}>
                    {orderId}
                </div>
                <div className={"table-cell order-time"}>
                    {subTime}
                </div>
                <div className={"table-cell order-price"}>
                    {realPrice}
                </div>
                <div className={"table-cell order-price"}>
                    {rmbPrice}
                </div>
                <div className={"table-cell order-price"}>
                    {paiPrice}
                </div>
                <div className={"table-cell order-price"}>
                    {disPrice}
                </div>
                <div className={"table-cell order-method"}>
                    {method}
                </div>
                <div className={"table-cell order-service"}>
                    {serviceItem}
                </div>
            </Fragment>
        )
    }

    loadTableCt = (data) => {
        let table = [];
        if (data.length > 0) {
            data.forEach((item, index) => {
                let orderId = item.orderId;
                let subTime = item.subTime;
                let realPrice = item.realPrice;
                let rmbPrice = item.rmbPrice;
                let paiPrice = item.paiPrice;
                let disPrice = item.disPrice;
                let method = this.handlePayMethod(item.method);
                let serviceItem = item.serviceItem;
                table.push(
                    <div className={"table-ct-wrap"} key={index}>
                        {this.loadTable(orderId, subTime, realPrice, rmbPrice, paiPrice, disPrice, method, serviceItem)}
                    </div>
                )
            })
        } else {
            return (
                <div className={"table-ct-no-data"}>
                    <img className={"table-ct-no-data-icon"} src={NODATA} alt={"no-data"}/>
                    <div className={"table-ct-no-data-tip"}>
                        {"暂无数据"}
                    </div>
                </div>
            )
        }
        return table;
    }

    handlePayMethod = (method) => {
        switch (method) {
            case 1: return "微信";
            case 2: return "支付宝";
            case 3: return "湃通币";
            case 4: return "其他";
        }
    }

    componentDidMount() {
        this.getOrders(0);
    }

    // 用户查询订单记录
    getOrders = (status) => {
        let userInfo = UserInfoStorage.get("userInfo");
        let params = {
            userId: userInfo.userId,
            status: status,
        };
        sendGetRequest(CheckStatusOrdersUrl, params, true).then(res => {
            console.log("用户查询订单记录res: ", status, res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.setState({
                        data: res[data],
                        type: status,
                    })
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    render() {
        let type = this.state.type;
        let data = this.state.data;

        return (
            <div className={"collection-mgr-ct"}>
                <div className={"center-ct-header"}>
                    <div className={"center-ct-header-wrap"}>
                        <div className={"center-ct-header-icon"}/>
                        <div className={"center-ct-header-title"}>
                            {"订单管理"}
                        </div>
                        {/*<div className={"center-ct-header-subtitle"}>*/}
                        {/*    {"查看每日日程"}*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className={"my-service-plugin-menu"}>
                    <div className={type === 0 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                         onClick={() => {this.getOrders(0)}}>
                        <div>
                            {"待支付"}
                        </div>
                    </div>
                    <div className={type === 1 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                         onClick={() => {this.getOrders(1)}}>
                        <div>
                            {"待审核"}
                        </div>
                    </div>
                    <div className={type === 2 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                         onClick={() => {this.getOrders(2)}}>
                        <div>
                            {"已完成"}
                        </div>
                    </div>
                </div>
                <div className={"appointment-mgr-ct-table"}>
                    <div className={"table-wrap"}>
                        <div className={"table-header"}>
                            {this.loadTable("订单id", "提交时间", "金额", "实付金额", "湃通币金额", "折扣金额", "支付方式", "服务内容")}
                        </div>
                        <div className={"table-ct"}>
                            {this.loadTableCt(data)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OrdersMgr;