import React, {Component, Fragment} from "react";
import "./center.css";
import {CloseOutlined, EyeOutlined, KeyOutlined} from "@ant-design/icons";
import {
    ChangePassUrl,
    error, genPojo,
    sendGetRequest,
    SendPhoneValidUrl, sendPostRequest,
    TempId,
    UpdateEmailUrl,
    UpdatePhonePostUrl,
    UpdatePhonePreUrl
} from "../../tool/http/Request";
import {connectionError, fail, findMsgByError, success, successReturn} from "../../tool/http/ErrorMgr";
import {handleCountDown, InputTest, LoginRoot, regPhone, UserInfoStorage} from "../../tool/Const";

class AccountMgrModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            secondI: 30,  // 验证码发送间隔（s）
            code: '',
            newPhone: '',
            newEmail: '',
            newPwd: '',
            pwdRepeat: '',
            showPwd: false,
            showPwdRepeat: false,
        }
    }

    // 切换修改设置界面（type 1 修改手机第一步 2 修改手机第二步 / 3 修改邮箱 / 4 修改密码）
    switchType = (userInfo, type, secondI, code, newPhone, newEmail, newPwd, pwdRepeat, showPwd, showPwdRepeat) => {
        let canSend = secondI === 30;
        switch (type) {
            case 1: {
                return (
                    <Fragment>
                        <div className={"send-code-msg"}>
                            {"验证码将发送到手机："}{userInfo.userPhone.replace(regPhone, '$1****$2')}
                        </div>
                        <div className={"send-code-tip"}>
                            {"如果长时间未收到验证码，请检查是否将运营商拉黑"}
                        </div>
                        <div className={"send-code-ct"}>
                            <div className={"send-code-ct-text"}>
                                {"填写验证码："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"ver-code"}
                                   style={{width: '65%'}}
                                   type={"text"}
                                   maxLength={6}
                                   onChange={(e) => {this.handleVerCode(e)}}
                                   onBlur={(e) => {this.handleOnBlur(e, "code")}}
                            />
                            <div className={!canSend ? "send-code-get save-button-none" : "send-code-get"}
                                 onClick={() => {this.getVerCode(userInfo, type)}}
                            >
                                {!canSend ? secondI + "秒后重发" : "发送验证码"}
                            </div>
                        </div>
                        <div className={"send-code-ct"}>
                            <div className={"send-code-ct-text"}>
                                {"输入新手机："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   style={{width: '65%'}}
                                   type={"text"}
                                   maxLength={11}
                                   onChange={(e) => {this.handleNewPhone(e)}}
                                   onBlur={(e) => {this.handleOnBlur(e, "userPhone")}}
                            />
                        </div>
                        <div className={ code && newPhone ? "send-code-next-step save-button" : "send-code-next-step save-button-none"}
                             onClick={() => {this.handleNextStep(userInfo, secondI, code, newPhone)}}
                        >
                            {"下一步"}
                        </div>
                    </Fragment>
                )
            }
            case 2: {
                return (
                    <Fragment>
                        <div className={"send-code-msg"}>
                            {"验证码已经发送至新手机："}{newPhone.replace(regPhone, '$1****$2')}
                        </div>
                        <div className={"send-code-tip"}>
                            {"如果长时间未收到验证码，请检查是否将运营商拉黑"}
                        </div>
                        <div className={"send-code-ct"}>
                            <div className={"send-code-ct-text"}>
                                {"填写验证码："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"ver-code"}
                                   style={{width: '65%'}}
                                   type={"text"}
                                   maxLength={6}
                                   onChange={(e) => {this.handleVerCode(e)}}
                                   onBlur={(e) => {this.handleOnBlur(e, "code")}}
                            />
                        </div>
                        <div style={{height: '70px'}}/>
                        <div className={ code ? "send-code-next-step save-button" : "send-code-next-step save-button-none"}
                             onClick={() => {this.saveNewPhoneModify(userInfo, code, newPhone)}}
                        >
                            {"确认修改"}
                        </div>
                    </Fragment>
                )

            }
            case 3: {
                return (
                    <Fragment>
                        <div className={"send-code-msg"}>
                            {"验证码将发送到手机："}{userInfo.userPhone.replace(regPhone, '$1****$2')}
                        </div>
                        <div className={"send-code-tip"}>
                            {"如果长时间未收到验证码，请检查是否将运营商拉黑"}
                        </div>
                        <div className={"send-code-ct"}>
                            <div className={"send-code-ct-text"}>
                                {"填写验证码："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"ver-email-code"}
                                   style={{width: '65%'}}
                                   type={"text"}
                                   maxLength={6}
                                   onChange={(e) => {this.handleVerCode(e)}}
                                   onBlur={(e) => {this.handleOnBlur(e, "code")}}
                            />
                            <div className={!canSend ? "send-code-get save-button-none" : "send-code-get"}
                                 onClick={() => {this.getVerCode(userInfo, type)}}
                            >
                                {!canSend ? secondI + "秒后可重发" : "发送验证码"}
                            </div>
                        </div>
                        <div className={"send-code-ct"}>
                            <div className={"send-code-ct-text"}>
                                {userInfo.userEmail ? "输入新邮箱：" : "绑定邮箱："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   style={{width: '65%'}}
                                   type={"text"}
                                   maxLength={40}
                                   // defaultValue={userInfo.userEmail}
                                   onChange={(e) => {this.handleNewEmail(e)}}
                                   onBlur={(e) => {this.handleOnBlur(e, "userEmail")}}
                            />
                        </div>
                        <div className={ code && newEmail ? "send-code-next-step save-button" : "send-code-next-step save-button-none"}
                             onClick={() => {this.saveNewEmailModify(userInfo, code, newEmail)}}
                        >
                            {userInfo.userEmail ? "确认修改" : "确认绑定"}
                        </div>
                    </Fragment>
                )
            }
            case 4: {
                return (
                    <Fragment>
                        <div className={"send-code-msg"}>
                            {"验证码将发送到手机："}{userInfo.userPhone.replace(regPhone, '$1****$2')}
                        </div>
                        <div className={"send-code-tip"}>
                            {"如果长时间未收到验证码，请检查是否将运营商拉黑"}
                        </div>
                        <div className={"send-code-ct"}>
                            <div className={"send-code-ct-text"}>
                                {"填写验证码："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"ver-pwd-code"}
                                   style={{width: '65%'}}
                                   type={"text"}
                                   maxLength={6}
                                   onChange={(e) => {this.handleVerCode(e)}}
                                   onBlur={(e) => {this.handleOnBlur(e, "code")}}
                            />
                            <div className={!canSend ? "send-code-get save-button-none" : "send-code-get"}
                                 onClick={() => {this.getVerCode(userInfo, type)}}
                            >
                                {!canSend ? secondI + "秒后可重发" : "发送验证码"}
                            </div>
                        </div>
                        <div className={"send-code-ct"}>
                            <div className={"send-code-ct-text"}>
                                {"输入新密码："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   key={"new-pwd"}
                                   style={{width: '65%'}}
                                   type={showPwd ? "text" : "password"}
                                   placeholder={"请输入新密码"}
                                   maxLength={20}
                                   onChange={(e) => {this.handleNewPwd(e)}}
                                   onBlur={(e) => {this.handleOnBlur(e, "userPwd")}}
                            />
                            <EyeOutlined className={"pwd-eye-out"}
                                         style={{ color: showPwd ? "#8aaed7" : "#c4c4c4",}}
                                         onClick={() => {
                                             this.setState({showPwd: !showPwd})
                                         }}/>
                        </div>
                        <div className={"send-code-ct"}>
                            <div className={"send-code-ct-text"}>
                                {"确认新密码："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   key={"new-pwd-repeat"}
                                   style={{width: '65%'}}
                                   type={showPwdRepeat ? "text" : "password"}
                                   placeholder={"请再次输入新密码"}
                                   maxLength={20}
                                   onChange={(e) => {this.handleNewPwdRepeat(e)}}
                                   onBlur={(e) => {this.handleOnBlur(e, "userPwd")}}
                            />
                            <EyeOutlined className={"pwd-eye-out"}
                                         style={{ color: showPwdRepeat ? "#8aaed7" : "#c4c4c4",}}
                                         onClick={() => {
                                             this.setState({showPwdRepeat: !showPwdRepeat})
                                         }}/>
                        </div>
                        <div className={ code && newPwd && pwdRepeat ? "send-code-next-step save-button" : "send-code-next-step save-button-none"}
                             onClick={() => {this.saveNewPwdModify(userInfo, code, newPwd, pwdRepeat)}}
                        >
                            {"确认修改"}
                        </div>
                    </Fragment>
                )
            }
        }
    }

    // 处理短信验证码发送接口
    handleVerCode = (e) => {
        let value = e.target.value;
        console.log("输入验证码为：", value);
        if (value && InputTest.code.test.test(value)) {
            this.setState({
                code: value,
            })
        }
    }

    // 获取验证码发送接口（type 1 手机； 2 邮箱）
    getVerCode = (userInfo, type) => {
        let params = {
            phone: userInfo.userPhone,
            tempId: TempId,
            reg: false,
        };
        sendGetRequest(SendPhoneValidUrl, params, true).then(res => {
            console.log("发送验证码res: ", type, res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    handleCountDown(30, this);
                    success("验证码发送成功，请查收！")
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 处理新手机号
    handleNewPhone = (e) => {
        let value = e.target.value;
        console.log("输入新手机为：", value)
        if (value && InputTest.userPhone.test.test(value)) {
            this.setState({
                newPhone: value,
            })
        }
    }

    // 监听输入结束
    handleOnBlur = (e, tag) => {
        let value = e.target.value;
        console.log("监听结果：", value);
        if (value && !InputTest[tag].test.test(value)) {
            fail(InputTest[tag].error);
        }
    }

    // 调用验证码发送后回传老手机验证码，新手机后自动调用新手机发送验证码接口
    handleNextStep = (userInfo, secondI, code, newPhone) => {
        if (code && newPhone) {
            if (newPhone && InputTest.userPhone.test.test(newPhone)) {
                this.setState({
                    newPhone: newPhone,
                })
            } else {
                fail(InputTest.userPhone.error);
            }
            let params = {
                phone: userInfo.userPhone,
                newPhone: newPhone,
                tempId: TempId,
                code: code,
            };
            console.log("修改手机 下一步 params: ", params);
            sendGetRequest(UpdatePhonePreUrl, params, true).then(res => {
                console.log("发送验证码res: ", res);
                if (res === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    if (res && res.hasOwnProperty("success") && res.success) {
                        success("新手机验证码已发送，请注意查收");
                        this.props.parent.switchModalType(2);
                        this.setState({
                            code: '',
                        });
                    } else {
                        fail(findMsgByError(res[error]));
                    }
                }
            })
        }
    }

    // 确认修改手机，回传新手机及新手机收到的验证码后按userId更新手机号
    saveNewPhoneModify = (userInfo, code, newPhone) => {
        let params = {
            userId: userInfo.userId,
            newPhone: newPhone,
            code: code,
        };
        console.log("确认修改手机 params: ", params);
        sendGetRequest(UpdatePhonePostUrl, params, true).then(res => {
            console.log("确认修改手机res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    success("用户绑定手机修改成功");
                    this.props.parent.setUserInfo(userInfo, 1, newPhone);
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 处理绑定/修改邮箱
    handleNewEmail = (e) => {
        let value = e.target.value;
        console.log("输入新邮箱为：", value)
        if (value && InputTest.userEmail.test.test(value)) {
            this.setState({
                newEmail: value,
            })
        }
    }

    // 确认绑定/修改邮箱
    saveNewEmailModify = (userInfo, code, newEmail) => {
        let params = {
            userId: userInfo.userId,
            phone: userInfo.userPhone,
            email: newEmail,
            code: code,
        };
        console.log("确认修改邮箱 params: ", params);
        sendGetRequest(UpdateEmailUrl, params, true).then(res => {
            console.log("确认修改邮箱res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    success(userInfo.userEmail ? "用户邮箱修改成功" : "用户邮箱绑定成功");
                    this.props.parent.setUserInfo(userInfo, 2, newEmail);
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 处理输入新密码
    handleNewPwd = (e) => {
        let value = e.target.value;
        console.log("输入新密码为：", value)
        if (value && InputTest.userPwd.test.test(value)) {
            this.setState({
                newPwd: value,
            })
        }
    }

    // 处理再次输入新密码
    handleNewPwdRepeat = (e) => {
        let value = e.target.value;
        console.log("再次输入新密码为：", value)
        if (value && InputTest.userPwd.test.test(value)) {
            this.setState({
                pwdRepeat: value,
            })
        }
    }

    // 确认修改密码
    saveNewPwdModify = (userInfo, code, newPwd, pwdRepeat) => {
        if (newPwd !== pwdRepeat) {
            fail("两次输入密码不一致！");
            return;
        }
        let params = {
            userPhone: userInfo.userPhone,
            code: code,
            userPwd: newPwd,
        };
        console.log("确认修改密码 params: ", params);
        sendPostRequest(genPojo(ChangePassUrl, params), true).then(res => {
            console.log("确认修改密码res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    UserInfoStorage.remove("userInfo");
                    successReturn("修改密码成功", LoginRoot);
                    // this.props.parent.setUserInfo(userInfo, 3, newPwd);
                } else {
                    fail(findMsgByError(res[error]))
                }
            }
        })
    }

    render() {
        let userInfo = this.props.userInfo;
        let title = this.props.title;
        let type = this.props.type;

        let secondI = this.state.secondI;
        let code = this.state.code;
        let newPhone = this.state.newPhone;
        let newEmail = this.state.newEmail;
        let newPwd = this.state.newPwd;
        let pwdRepeat = this.state.pwdRepeat;
        let showPwd = this.state.showPwd;
        let showPwdRepeat = this.state.showPwdRepeat;

        return (
            <div className={"account-modal-bg"}>
                <div className={"account-modal-ct"}
                     style={type === 4 ? {height: '460px'} : {}}
                >
                    <div className={"modal-header-wrap"}>
                        <div className={"modal-header-title"}>
                            {title}
                        </div>
                    </div>
                    <div className={"schedule-modal-setting-wrap"}>
                        {this.switchType(userInfo, type, secondI, code, newPhone, newEmail, newPwd, pwdRepeat, showPwd, showPwdRepeat)}
                    </div>
                    <div className={"modal-close-wrap"}>
                        <CloseOutlined  onClick={() => {this.props.parent.setModal(true)}}/>
                    </div>
                    {/*<div className={"account-modal-close-wrap"}*/}
                    {/*     style={type === 4 ? {top: 'calc(50% - 200px)'} : {}}*/}
                    {/*>*/}
                    {/*    <CloseOutlined className={"account-modal-close"}*/}
                    {/*                   onClick={() => {this.props.parent.setModal(true)}}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div className={"account-modal-title"}>*/}
                    {/*    <div className={"icon-style-wrap"}>*/}
                    {/*        <KeyOutlined className={"icon-style"}/>*/}
                    {/*    </div>*/}
                    {/*    {title}*/}
                    {/*</div>*/}

                    {/*<div className={"schedule-setting-ct-button-wrap"}>*/}
                    {/*    <button className={"center-ct-button save-button"}*/}
                    {/*        // onClick={() => {this.saveModify(dataTmp, weekType, scheduleList, rawData, showModal)}}*/}
                    {/*    >*/}
                    {/*        {"保存"}*/}
                    {/*    </button>*/}
                    {/*    <button className={"center-ct-button cancel-button"} style={{marginLeft: '60px'}}*/}
                    {/*            onClick={() => {this.props.parent.setModal(true)}}*/}
                    {/*    >*/}
                    {/*        {"取消"}*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}

export default AccountMgrModal;
