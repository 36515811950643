import React, {Component, Fragment} from "react";
import "./center.css";
import {CloseOutlined, FormOutlined, MinusCircleOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {formatStrDouble, LoginRoot, mapToObj, MouseHover, scheduleTime, schTime, UserInfoStorage, week} from "../../tool/Const";
import {connectionError, fail, failReturn, findMsgByError, success} from "../../tool/http/ErrorMgr";
import moment from "moment";
import {
    data,
    error,
    FindExpertScheduleInfoUrl,
    genPojo,
    loginOutErrOr,
    sendGetRequest,
    sendPostRequest,
    UpdateExpertScheduleInfoUrl
} from "../../tool/http/Request";

// 星期list
const weekBase = week.filter((i) => {
    return i && i.trim()
});

class Schedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: '',
            rawData: new Map(),  // 接收到后台的原始数据
            rawDataTmp: new Map(),  // 接收到后台的复制数据，作为缓存
            data: [],  // 原始数据处理后的数据
            dataTmp: [],  // 处理后的数据复制数据，作为缓存
            modifyStatus: false,  // 修改状态
            showModifyModal: false,  // 添加日程弹窗开关
            showModal: false,  // 日程修改弹窗开关
            weekType: 0,  // 星期切换状态
            hoverI: -1,
            // scheduleList: [],  // 具体日程开放预约时间list
            scheduleMap: new Map(),  // 日程开放预约时间map
            initScheduleMap: false,  // 日程开放预约时间map初始化状态
            addSchedule: {  // 添加日程参数
                startHour: '8',
                startMinute: '00',
                startIndex: '',
                endHour: '9',
                endMinute: '00',
                endIndex: '',
                week: [],
            },
        };
    }

    // 加载表头（星期）
    loadWeek = (date, hoverI, showModal, dataTmp, initScheduleMap, addSchedule, rawDataTmp) => {
        let list = [];
        date.forEach((item, index) => {
            list.push(
                <div className={"table-header-cell week" + index} key={'week_' + index}>
                    <div className={"table-header-cell-text"}>
                        {item}
                    </div>
                    <div className={"table-header-cell-icon"}
                         onMouseEnter={() => {MouseHover(index, hoverI, this)}}
                         onMouseLeave={() => {MouseHover(-1, hoverI, this)}}
                         onClick={() => {
                             this.setModal(showModal, index - 1, dataTmp, initScheduleMap, addSchedule, rawDataTmp)
                         }}
                    >
                        {item && <FormOutlined/>}
                        <div className={"schedule-week-tooltip"} style={{display: index === hoverI ? "" : "none"}}>
                            {"编辑" + item + "日程设置"}
                        </div>
                    </div>
                </div>
            )
        })
        return list;
    }

    // 加载预约时间段的时刻表
    loadScheduleTime = () => {
        let table = [];
        for (let i = 1; i < 25; i++) {
            table.push(
                <div className={"table-ct-cell schedule-time"} key={"key_" + i}
                     style={{borderTop: i === 1 ? '1px solid rgba(227, 227, 227, 0.8)' : ""}}
                >
                    {schTime[i] + " - " + schTime[i + 1]}
                </div>
            )
        }
        return table;
    }

    // 加载预约时间段的时刻表对应的预约排期
    loadScheduleWeek = (data) => {
        // console.log("==========开始绘画时刻表对应的预约排期！==========")
        // console.log("绘图data: ", data);
        let table = [];
        if (data.length > 1) {
            data.forEach((item, index) => {
                scheduleTime.forEach((time, time_index) => {
                    table.push(
                        <div className={"table-ct-cell open-time"} key={index}
                             style={{background: item[time] === 1 ? 'rgba(150, 232, 162, 0.2)' : ''}}
                        />
                    )
                })
            })
        }
        return table;
    }

    // 日程设置弹窗开关
    setModal = (showModal, weekType, dataTmp, initScheduleMap, addSchedule, rawDataTmp) => {
        // console.log("==========打开/关闭日程设置弹窗==========");
        this.props.parent.stopScroll(showModal);
        if (initScheduleMap === false) {
            // 加载星期对应预约排期
            this.loadModalScheduleByWeek(dataTmp, rawDataTmp);
        }
        // 预约日程适用于星期几初始化
        this.initSelectWeekList(weekType, addSchedule);
        this.setState({
            showModal: !showModal,
            weekType: weekType,
            initScheduleMap: true,
        })
    }

    // 预约日程初始化（基本参数）
    initSelectWeekList = (weekType, addSchedule) => {
        let selectList = [];
        selectList.push(weekType);
        addSchedule.week = selectList;
        addSchedule.startHour = '8';
        addSchedule.startMinute = '00';
        addSchedule.endHour = '9';
        addSchedule.endMinute = '00';
    }


    // 保存专家日程设置修改
    saveModify = (dataTmp, weekType, scheduleMap, rawData, showModal) => {
        // console.log("==========保存==========");
        // 禁止滚动条功能
        this.props.parent.stopScroll(showModal);
        console.log("更新前的dataTmp：", dataTmp);
        console.log("更新前的weekType：", weekType);
        console.log("要修改的scheduleMap：", scheduleMap)
        let newList = [];
        let schSet = new Set();
        let len = scheduleMap.size;
        for (let i = 1; i <= len; i++) {
            let scheduleList = scheduleMap.get(i);
            // console.log("scheduleList：", i, scheduleList);
            if (scheduleList.length > 0) {
                scheduleList.forEach((item) => {
                    // console.log("item.index：", item.index);
                    schSet.add(item.index);
                    schSet.add(item.index + 1)
                    newList = Array.from(schSet);
                    rawData.set(i, newList);
                })
                newList = [];
                schSet = new Set();
            } else {
                rawData.set(i, []);
            }
        }
        console.log("rawData：", rawData);
        this.updateExpSchInfo(rawData, showModal)

    }

    // 更新专家日程api
    updateExpSchInfo = (rawData, showModal) => {
        let userInfo = this.props.userInfo;
        let userId = userInfo.userId;
        let pojo = {
            scheduleMap: mapToObj(rawData),
            userId: userId
        };
        console.log("pojo：", pojo);
        sendPostRequest(genPojo(UpdateExpertScheduleInfoUrl, pojo), true).then(res => {
            console.log("更新专家日程设置结果：", res)
            if (res === connectionError) {
                // failReturn(findMsgByError(connectionError), LoginRoot);
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    success("日程修改成功！")
                    this.setState({
                            showModal: !showModal,
                            modifyStatus: false,
                        },
                        () => {this.initData()})
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 取消专家日程设置修改
    cancelModify = (data, showModal) => {
        // console.log("==========取消开始==========");
        console.log("取消还原的数据：", data);
        // 禁止滚动条功能
        this.props.parent.stopScroll(showModal);
        let dataTmp = JSON.parse(JSON.stringify(data))
        this.setState({
            dataTmp: dataTmp,
            showModal: !showModal,
            modifyStatus: false,
            initScheduleMap: false,
        })
        // console.log("==========取消结束==========");
    }


    // 日程修改弹窗
    loadModal = (showModal, data, dataTmp, weekType, scheduleMap, showModifyModal, modifyStatus, rawData, addSchedule) => {
        console.log("日程修改弹窗缓存数据：", dataTmp)
        if (showModal === true) {
            return (
                <div className={"schedule-modal-bg"}>
                    <div className={"schedule-modal-ct"}>
                        <div className={"modal-header-wrap"}>
                            <div className={"modal-header-title"}>
                                {"专家日程设置"}
                            </div>
                        </div>
                        <div className={"schedule-modal-week-list"}>
                            {this.loadModalWeek(weekBase, weekType, addSchedule)}
                        </div>
                        <div className={"schedule-modal-schedule-by-week"}>
                            <div className={"schedule-modal-schedule-by-week-header"}>
                                <div className={"schedule-modal-schedule-by-week-header-ele schedule-index"}>
                                    {"序号"}
                                </div>
                                <div className={"schedule-modal-schedule-by-week-header-ele schedule-open-time"}>
                                    {"开始时间（24h制）"}
                                </div>
                                <div className={"schedule-modal-schedule-by-week-header-ele schedule-open-time"}>
                                    {"结束时间（24h制）"}
                                </div>
                                <div className={"schedule-modal-schedule-by-week-header-ele add-icon"}
                                     onClick={() => {this.setModifyModal(showModifyModal)}}
                                >
                                    <PlusSquareOutlined />
                                    <div style={{marginLeft: '10px'}}>
                                        {"添加预约日程"}
                                    </div>
                                </div>
                            </div>
                            <div className={"schedule-modal-schedule-by-week-wrap"}>
                                <div className={"schedule-modal-schedule-by-week-ct"}>
                                    {this.scheduleByWeek(scheduleMap, dataTmp, weekType)}
                                </div>
                            </div>
                            <div className={"schedule-setting-ct-button-wrap"}>
                                <button className={modifyStatus ? "center-ct-button save-button" : "center-ct-button save-button-none"}
                                        onClick={() => {this.saveModify(dataTmp, weekType, scheduleMap, rawData, showModal)}}
                                    // onClick={() => {this.setCertModal(showCertModal)}}
                                >
                                    {"保存"}
                                </button>
                                <button className={"center-ct-button cancel-button"} style={{marginLeft: '60px'}}
                                        onClick={() => {this.cancelModify(data, showModal)}}
                                >
                                    {"取消"}
                                </button>
                            </div>
                        </div>
                        <div className={"modal-close-wrap"}>
                            <CloseOutlined  onClick={() => {this.cancelModify(data, showModal)}}/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 日程设置弹窗-加载星期
    loadModalWeek = (date, weekType, addSchedule) => {
        let list = [];
        date.forEach((item, index) => {
            list.push(
                <div className={weekType === index ? "schedule-modal-week-ele-selected" : "schedule-modal-week-ele"}
                     key={'week_' + index}
                     onClick={() => {
                         // if (modifyStatus) {
                         //     fail("请先保存当前修改！");
                         // } else {
                             this.switchWeekType(index, addSchedule)
                         // }
                     }}
                >
                    {item}
                </div>
            )
        })
        return list;
    }

    // 日程设置弹窗-切换星期及对应预约排期
    switchWeekType = (weekType, addSchedule) => {
        // this.loadModalScheduleByWeek(weekType, dataTmp, scheduleTime);
        // 预约日程适用于星期几初始化
        this.initSelectWeekList(weekType, addSchedule);
        this.setState({
            weekType: weekType,
            // addSchedule: addSchedule,
        })
    }

    // 加载星期对应预约排期
    loadModalScheduleByWeek = (dataTmp, rawDataTmp) => {
        // let scheduleByWeek = dataTmp.filter(item => item.id === weekType);
        console.log("加载星期对应预约排期: ", dataTmp);
        let rawData = this.state.rawData;
        console.log("加载星期对应预约排期rawData: ", rawData);
        console.log("加载星期对应预约排期rawDataTmp: ", rawDataTmp);
        let map = new Map();
        let list = [];
        for (let i = 1; i <= 7; i++) {
            let scheduleByWeek = dataTmp.filter(item => item.id === i);
            console.log("加载星期对应预约排期: ", i, scheduleByWeek);
            scheduleByWeek.filter((item) => {
                scheduleTime.forEach((time, index) => {
                    if (rawDataTmp.get(i).length > 0) {
                        return item[time] === 1 ?
                            list.push({"index": index + 1, "time": time}) + map.set(i, list)
                            : false;
                    } else {
                        map.set(i, [])
                    }
                });
                list = [];
            });
        }
        // console.log("list: ", list);
        console.log("map: ", map);
        this.setState({
            scheduleMap: map,
        });
    }

    // 加载星期对应的预约排期
    scheduleByWeek = (scheduleMap, dataTmp, weekType) => {
        console.log("scheduleMap: ", scheduleMap)
        let time = [];
        let times = [];
        let list = [];
        // 获取对应的schedule by index
        let scheduleListByIndex = scheduleMap.get(weekType + 1);
        console.log("scheduleListByIndex：", weekType + 1, scheduleListByIndex)
        if (scheduleListByIndex.length > 0) {
            // 排序sort by index
            let scheduleListSortByIndex = scheduleListByIndex.sort(function (a, b) {
                return a.index - b.index;
            })
            let len = scheduleListSortByIndex.length;
            console.log("scheduleListSortByIndex：", scheduleListSortByIndex)
            for (let i = 0; i < len - 1; i++) {
                if (scheduleListSortByIndex[i].index + 1 === scheduleListSortByIndex[i + 1].index) {
                    // console.log(scheduleList[i].time);
                    time.push(scheduleListSortByIndex[i].time);
                    time.push(scheduleListSortByIndex[i + 1].time);
                    // console.log(time);
                    if (i === len - 2) {
                        times.push(this.uniqueArray(time));
                    }
                } else {
                    times.push(this.uniqueArray(time));
                    // console.log(times)
                    time = [];
                }
            }
            console.log("源数据最终预约时间段：", times);
            if (times.length > 0) {
                times.forEach((item, index) => {
                    list.push(
                        <div className={"schedule-modal-schedule-by-week-ct-ele"}>
                            <div className={"schedule-modal-schedule-by-week-ct-cell schedule-index"}>
                                {index + 1}
                            </div>
                            <div className={"schedule-modal-schedule-by-week-ct-cell schedule-open-time"}>
                                {item[0].split('-')[0]}
                            </div>
                            <div className={"schedule-modal-schedule-by-week-ct-cell schedule-open-time"}>
                                {item[item.length - 1].split('-')[1]}
                            </div>
                            <div className={"schedule-modal-schedule-by-week-ct-cell delete-icon"}
                                 onClick={() => {this.deleteScheduleList(times, index, dataTmp, weekType)}}
                            >
                                <MinusCircleOutlined />
                                <div style={{marginLeft: '10px'}}>
                                    {"删除"}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        } else {
            list.push(
                <div className={"schedule-modal-schedule-by-week-ct-cell no-schedule"}>
                    {"今日暂无日程安排，请好好休息"}
                </div>
            )
        }
        return list;
    }

    // list去重复
    uniqueArray = (array) => {
        let res = [];
        let json = {};
        for(let i = 0; i < array.length; i++){
            if(!json[array[i]]){
                res.push(array[i]);
                json[array[i]] = 1;
            }
        }
        return res;
    }

    // 删除对应的预约排期
    deleteScheduleList = (times, index, dataTmp, weekType) => {
        console.log("timesTmp: ", times);
        console.log("删除index", index);
        let deleteList = times[index];
        console.log("删除掉的：", deleteList);
        // 临时数据删除对应的预约排期
        times.splice(index, index + 1);
        console.log("删除后的timeTmp: ", times);
        let len = deleteList.length;
        let dataByWeek = dataTmp[weekType];

        let rawDataTmp = this.state.rawDataTmp;
        rawDataTmp.set(weekType + 1, times);
        console.log(weekType + 1, rawDataTmp)

        for (let i = 0; i < len; i++) {
            dataByWeek[deleteList[i]] = 0;
            // console.log(deleteList[i])
            // console.log(dataList[deleteList[i]])
        }
        console.log("dataByWeek: ", dataByWeek)
        this.setState({
            dataTmp: dataTmp,
            modifyStatus: true,
            initScheduleMap: false,
        },
            () => {this.loadModalScheduleByWeek(dataTmp, rawDataTmp)})
    }

    // 预约日程添加弹窗开关
    setModifyModal = (showModifyModal, weekType, addSchedule) => {
        if (showModifyModal === true) {
            this.initSelectWeekList(weekType, addSchedule);
        }

        this.setState({
            showModifyModal: !showModifyModal,
            // addSchedule: addSchedule,
        })
    }

    // 添加预约日程添加弹窗
    loadModifyModal = (showModifyModal, weekType, scheduleMap, modifyStatus, addSchedule) => {
        if (showModifyModal === true) {
            return (
                <div className={"schedule-modal-bg"}>
                    <div className={"schedule-modify-modal-ct"}>
                        <div className={"schedule-modal-title"}>
                            {"添加预约日程"}
                        </div>
                        <div className={"schedule-modify-modal-add-wrap"}>
                            <div className={"schedule-modify-modal-add-header"}>
                                <div className={"schedule-modal-schedule-by-week-header-ele schedule-open-time"}>
                                    {"开始时间（24h制）"}
                                </div>
                                <div className={"schedule-modal-schedule-by-week-header-ele schedule-open-time"}>
                                    {"开始时间（24h制）"}
                                </div>
                            </div>
                            <div className={"schedule-modify-modal-add"}>
                                <div className={"schedule-modify-modal-add-ele"}>
                                    <select className={"schedule-modify-date-cell"}
                                            value={addSchedule.startHour}
                                            onChange={(e) => {this.handleHourSel(e, 0, addSchedule)}}
                                    >
                                        {this.loadHourSel()}
                                    </select>
                                    <select className={"schedule-modify-date-cell"}
                                            value={addSchedule.startMinute}
                                            onChange={(e) => {this.handleMinuteSel(e, 0, addSchedule)}}
                                    >
                                        {this.loadMinuteSel(addSchedule.startHour)}
                                    </select>
                                </div>
                                <div className={"schedule-modify-modal-add-ele"}>
                                    <select className={"schedule-modify-date-cell"}
                                            value={addSchedule.endHour}
                                            onChange={(e) => {this.handleHourSel(e, 1, addSchedule)}}
                                    >
                                        {this.loadHourSel()}
                                    </select>
                                    <select className={"schedule-modify-date-cell"}
                                            value={addSchedule.endMinute}
                                            onChange={(e) => {this.handleMinuteSel(e, 1, addSchedule)}}
                                    >
                                        {this.loadMinuteSel(addSchedule.endHour)}
                                    </select>
                                </div>
                            </div>
                            <div className={"schedule-modify-modal-add-week"}>
                                <div className={"schedule-modify-modal-add-week-title"}>
                                    {"预约日程适用到："}
                                </div>
                                <div className={"schedule-modify-modal-add-week-ct"}>
                                    {this.loadSelectWeek(weekBase, weekType, addSchedule)}
                                </div>
                            </div>
                        </div>
                        <div className={"schedule-setting-ct-button-wrap"}>
                            <button className={"center-ct-button save-button"}
                                    onClick={() => {this.saveAddSchedule(showModifyModal, addSchedule, weekType, scheduleTime, scheduleMap, modifyStatus)}}
                            >
                                {"保存"}
                            </button>
                            <button className={"center-ct-button cancel-button"} style={{marginLeft: '60px'}}
                                    onClick={() => {this.setModifyModal(showModifyModal, weekType, addSchedule)}}
                            >
                                {"取消"}
                            </button>
                        </div>
                    </div>
                    {/*<div className={"schedule-modify-modal-close"}*/}
                    {/*     onClick={() => {this.setModifyModal(showModifyModal)}}*/}
                    {/*>*/}
                    {/*    <img className={"img-style contain"} src={CLOSE} alt={"close"}/>*/}
                    {/*</div>*/}
                </div>
            )
        }
    }

    // 加载小时分位select
    loadHourSel = () => {
        let list = [];
        // 预约开放时间——小时list
        let arr = [];
        Array.prototype.range = function ( start,end ){
            let length = end - start +1;
            let step = start - 1;
            return Array.apply(null,{length:length}).map(function (v,i){step++; return step;});
        };
        let hourList = arr.range(8, 20);
        // console.log("---", "预约开放时间——小时list: ",  hourList, "---");
        if (hourList.length > 0) {
            hourList.forEach((item, index) => {
                // console.log(item)
                list.push(
                    <option className={"schedule-modify-date-select-cell"} value={item} key={index}>
                        {item}
                    </option>
                )
            })
            return list;
        }
    }

    // 处理时间 hour select
    handleHourSel = (e, type, addSchedule) => {
        let value = e.target.value;
        console.log("hourSelect: ", value)
        if (type === 0) {
            addSchedule.startHour = value;
        } else {
            addSchedule.endHour = value;
        }
        this.setState({
            addSchedule: addSchedule,
        },
            () => {
                console.log("---", "添加的日程（更新小时）：", addSchedule, "---")
            })
    }

    // 加载分钟分位select
    loadMinuteSel = (hour) => {
        let list = [];
        let minuteList = [];
        if (hour === "20") {
            minuteList = ["00"];
        } else {
            minuteList = ["00", "30"];
        }
        if (minuteList.length > 0) {
            minuteList.forEach((item, index) => {
                // console.log(item)
                list.push(
                    <option className={"schedule-modify-date-select-cell"} value={item} key={index}>
                        {item}
                    </option>
                )
            })
            return list;
        }
    }

    // 处理时间 minute select
    handleMinuteSel = (e, type, addSchedule) => {
        let value = e.target.value;
        console.log("minuteSelect: ", value)
        if (type === 0) {
            addSchedule.startMinute = value;
        } else {
            addSchedule.endMinute = value;
        }
        this.setState({
                addSchedule: addSchedule,
            },
            () => {
                console.log("---", "添加的日程（更新分钟）：", addSchedule, "---")
            })
    }

    // 加载星期选择
    loadSelectWeek = (week, weekType, addSchedule) => {
        let list = [];
        if (week.length > 0) {
            week.forEach((item, index) => {
                list.push(
                    <div className={"select-week-cell"}>
                        <input className={""}
                               type={"checkbox"}
                               checked={addSchedule.week.indexOf(index) > -1}
                               onChange={() => {
                                   this.handleSelectWeek(index, addSchedule)
                               }}
                        />
                        <div>
                            {item}
                        </div>
                    </div>
                )
            })
        }
        return list;
    }

    // 处理星期select多选
    handleSelectWeek = (index, addSchedule) => {
        let list = addSchedule.week;
        if (list.indexOf(index) === -1) {
            // 将一个或多个新元素插入到数组的指定位置，插入位置的元素自动后移，返回
            // console.log("==========新增：", index, "==========");
            list.splice(index, 0, index);
        } else {
            // console.log("==========删除：", index, "==========");
            let len = list.length;
            for (let i = 0; i < len; i++) {
                if (list[i] === index) {
                    list.splice(i, 1);
                }
            }
        }
        addSchedule.week = list;
        this.setState({
            addSchedule: addSchedule
        },
            () => {console.log("星期select：", list)}
        )
    }

    // 保存添加日程
    saveAddSchedule = (showModifyModal, addSchedule, weekType, scheduleTime, scheduleMap, modifyStatus) => {
        console.log("addSchedule: ", addSchedule)
        let startHour = formatStrDouble(addSchedule.startHour);
        let startTime = startHour + ":" + addSchedule.startMinute;
        let endHour = formatStrDouble(addSchedule.endHour);
        let endTime = endHour + ":" + addSchedule.endMinute;
        let date1 = moment(startTime, 'hh:mm');
        let date2 = moment(endTime, 'hh:mm');
        let diff = date2.diff(date1, "hours");
        // console.log(diff);
        if (diff >= 1) {
            let list = [];
            // 处理添加的预约日程
            let rawList = [];
            // let rawListLen = [];
            let arrLength = scheduleTime.length;
            for (let i = 0; i < arrLength; i++) {
                for (let j = i; j < arrLength; j++) {
                    let startTimeF = scheduleTime[i].split('-')[0].replace(/\s+/g,"");
                    let endTimeF = scheduleTime[j].split('-')[1].replace(/\s+/g,"");
                    if (startTime === startTimeF && endTime === endTimeF) {
                        // console.log(i, j)
                        // list.push({"index": i + 1, "time": scheduleTime[i]});
                        // rawListLen.push(i, j);
                        addSchedule.startIndex = i + 1;
                        addSchedule.endIndex = j + 1;
                        rawList.push(scheduleTime.slice(i, j + 1));
                    }
                }
            }
            // console.log("rawListLen: ", rawListLen);
            // console.log("rawList: ", rawList);
            // console.log("==========", "最终添加的日程：", addSchedule, "==========：");
            // console.log("==========", "最终添加的index", weekType, "==========");
            // 处理添加的预约日程index和对应的time
            let listLen = rawList[0].length;
            for (let i = 0; i < listLen; i++) {
                list.push({"index": addSchedule.startIndex + i, "time": rawList[0][i]})
            }
            console.log("list: ", list);
            // 处理是否有重复日程
            let selectList = addSchedule.week;
            let someArr = [];
            selectList.forEach((item, index) => {
                let selectSchedule = scheduleMap.get(item + 1);
                console.log("selectSchedule：", selectSchedule)
                if (selectSchedule) {
                    selectSchedule.find(hasTime => {
                        console.log("原本的预约日程：", hasTime)
                        list.some(item => {
                            someArr.push(item.index === hasTime.index)
                        });
                        // console.log("新添加的预约日程：",item);
                    })
                }
            })
            console.log("someArr：", someArr)
            if (someArr.indexOf(true) > -1) {
                fail("预约日程设置重复，请重新选择！");
            } else {
                selectList.forEach((item, index) => {
                    console.log("selectList: ", selectList)

                    let selectSchedule = scheduleMap.get(item + 1);
                    console.log("selectSchedule：", item + 1, selectSchedule);

                    let scheduleListNew = selectSchedule.concat(list);
                    console.log("scheduleListNew：", scheduleListNew);

                    scheduleMap.set(item + 1, scheduleListNew);
                    console.log("scheduleMap：", scheduleMap);
                })

                this.initSelectWeekList(weekType, addSchedule);
                this.setState({
                    scheduleMap: scheduleMap,
                    showModifyModal: !showModifyModal,
                    modifyStatus: true,
                })
            }
        } else {
            fail("预约日程设置不规范，请重新选择！");
        }
    }

    // 数字隐藏格式化
    numFormat = (data, m, n) => {
        return data.substring(0, m) + "*****" + data.substring(data.length - n, data.length) // 截取第一个和最后一个字符
    }

    componentDidMount() {
        this.initData();
    }

    // 初始化数据
    initData = () => {
        // console.log("==========开始数据初始化！==========")
        let map = new Map();
        let mapTmp = new Map();
        // 获取专家日程设置
        let userInfo = UserInfoStorage.get("userInfo");
        if (userInfo) {
            let params = {
                userId: userInfo.userId,
                expertId: userInfo.userId,
            };
            sendGetRequest(FindExpertScheduleInfoUrl, params, true).then(res => {
                console.log("获取专家日程设置res: ", res)
                if (res === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    if (res && res.hasOwnProperty("success") && res.success) {
                        let scheduleMap = res[data].scheduleMap;
                        for (let i = 1; i <= 7; i++) {
                            // console.log(i, scheduleMap[i]);
                            map.set(i, scheduleMap[i] ? scheduleMap[i] : []);
                            mapTmp.set(i, scheduleMap[i] ? scheduleMap[i] : [])
                        }
                        console.log("拷贝map数据：", mapTmp);
                        this.setState({
                                rawData: map,
                                rawDataTmp: mapTmp,
                            },
                            () => {
                                this.convertInitData();
                            });
                    } else if (res[error] === loginOutErrOr) {
                        UserInfoStorage.remove("userInfo");
                        failReturn(findMsgByError(res[error]), LoginRoot)
                    } else {
                        fail(findMsgByError(res[error]));
                    }
                }
            })
        }
    }

    // 转换初始化数据格式
    convertInitData = () => {
        // console.log("==========开始转换初始化数据格式！==========")
        let rawData = this.state.rawData;
        let list = [];
        for (let i = 1; i <= 7; i++) {
            let object = {};
            object["id"] = i;
            scheduleTime.forEach((time, index) => {
                object[time] = 0;
            });
            // 时间段标准格式化
            let schList = rawData.get(i);
            let len = schList.length;
            if (len > 0) {
                for (let j = 0; j < len - 1; j++) {
                    if (schList[j] + 1 === schList[j + 1]) {
                        let time = schTime[schList[j]] + " - " + schTime[schList[j + 1]];
                        object[time] = 1;
                    }
                };
            }
            list.push(object);
        }
        // 深拷贝
        let listTmp = JSON.parse(JSON.stringify(list));
        // console.log("拷贝数据：", listTmp);
        this.setState({
            data: list,
            dataTmp: listTmp,
        });
        // console.log("data初始化：", list);
    }

    render() {
        let userInfo = this.props.userInfo;

        let rawData = this.state.rawData;
        let rawDataTmp = this.state.rawDataTmp;

        let data = this.state.data;
        let dataTmp = this.state.dataTmp;

        let modifyStatus = this.state.modifyStatus;
        let showModifyModal = this.state.showModifyModal;

        let showModal = this.state.showModal;

        let weekType = this.state.weekType;
        let hoverI = this.state.hoverI;

        let scheduleMap = this.state.scheduleMap;
        let initScheduleMap = this.state.initScheduleMap;
        let addSchedule = this.state.addSchedule;

        console.log("==========data更新后==========：", data);
        console.log("==========data拷贝==========：", dataTmp);

        return (
            [
                showModal && this.loadModal(showModal, data, dataTmp, weekType, scheduleMap, showModifyModal, modifyStatus, rawData, addSchedule),
                showModifyModal && this.loadModifyModal(showModifyModal, weekType, scheduleMap, modifyStatus, addSchedule),
                <div className={"schedule-setting-ct-table"}>
                    <div className={"table-wrap"}>
                        <div className={"table-header"}>
                            {this.loadWeek(week, hoverI, showModal, dataTmp, initScheduleMap, addSchedule, rawDataTmp)}
                        </div>
                        <div className={"table-ct"} style={{display: 'flex'}}>
                            <div className={"schedule-time-wrap"}>
                                {this.loadScheduleTime()}
                            </div>
                            <div className={"schedule-table-wrap"}>
                                {this.loadScheduleWeek(data)}
                            </div>
                        </div>
                    </div>
                </div>
            ]
        )
    }
}

export default Schedule;
