import React, {Component, Fragment} from "react";
import "./center.css";
import {CloseOutlined, FileSearchOutlined, LeftOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import NODATA from "../../assets/icon/record_empty.svg";
import {
    AreaMap,
    changeSubmitOptions,
    closeUserItem,
    deepClone,
    filterArea,
    filterCopWay,
    filterSrcType,
    filterType,
    findUserAcceptItems,
    getUserInfoCache,
    handleCopWay,
    handleImgUploadInList,
    handleOutcomeType,
    handleSrcType,
    loadAreaCheckBox,
    loadLoginModal,
    loadPicList,
    LoginRoot,
    picTypes,
    setCloseItemModalStatus,
    setLoginModalStatus,
    switchCheckBoxType,
    UserInfoStorage
} from "../../tool/Const";
import {
    CheckDemandsSubByIdAndStatusUrl,
    data,
    error,
    genPojo,
    loginOutErrOr,
    sendGetRequest,
    sendPostRequest,
    SubmitDemandUrl
} from "../../tool/http/Request";
import {connectionError, fail, failReturn, findMsgByError, success} from "../../tool/http/ErrorMgr";
import GeoFilter from "../../tool/filter/GeoFilter";

const defaultState = {
    name: '',
    areas: '',
    root: '',
    geo: '',
    copWay: '',
    des: '',
    srcType: '',
    userId: '',
    picList: [],

    demandType: '',
    demandPrice: '',
    demandCt: '',
    accepterRequest: '',
    extraInfo: '',
}

class DemandMgr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: '',
            step: 0,  // 切换步骤
            type: 0,  // 切换类型（已发布/待审核/审核未通过）
            data: '',  // 页面数据（已发布/待审核/审核未通过）
            openGeo: false,  // 地理位置组件开关
            modalStatus: false,  // 提交弹窗开关
            submitOptions: {  // 提交的表单内容
                name: '',
                areas: '',
                root: '',
                geo: '',
                copWay: '',
                des: '',
                srcType: '',
                userId: '',
                picList: [],

                demandType: '',
                demandPrice: '',
                demandCt: '',
                accepterRequest: '',
                extraInfo: '',
            },
            filterOptions: {
                filterTypeTmp: deepClone(filterType),
                filterSrcTypeTmp: deepClone(filterSrcType),
                filterAreaTmp: deepClone(filterArea),
                filterCopWayTmp: deepClone(filterCopWay)
            },
            imgUrl: {
                picList: [],
            },
            checkBoxType: '',  // 行业领域checkbox type，默认0，不显示；1为所属行业，最大选择值为1；2为感兴趣领域，最大选择值为3
            showCity: -1,
            closeItemModalStatus: false,  // 下架弹窗开关
            closeItemId: '',  // 下架itemId
            loginModalStatus: false,  // 登录超时的登录弹窗开关
            loginPojo: {
                type: 1,
                userEmail: null,
                userPhone: null,
                userPwd: null,
                code: null,
                wechatId: null,
                aliId: null,
            },
            loginType: 1,  // 1手机密码登录 2手机验证码登录 3微信登陆 4支付宝登陆
            secondI: 30,  // 验证码发送间隔（s）
            showPwd: false,
        }
    }

    // 加载管理界面
    loadMgr = (type, data, closeItemModalStatus) => {
        return (
            <Fragment>
                <div className={"outcome-mgr-ct-menu"}>
                    <div className={"outcome-mgr-ct-menu-l"}>
                        <div className={type === 0 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                             onClick={() => {
                                 findUserAcceptItems(40, 1, '9919', this)
                             }}
                        >
                            <div>
                                {"已发布"}
                            </div>
                        </div>
                        <div className={type === 1 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                             onClick={() => {
                                 this.checkDemandSub(1, 1)
                             }}
                        >
                            <div>
                                {"待审核"}
                            </div>
                        </div>
                        <div className={type === 2 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                             onClick={() => {
                                 this.checkDemandSub(3, 2)
                             }}
                        >
                            <div>
                                {"审核未通过"}
                            </div>
                        </div>
                    </div>
                    <div className={"outcome-mgr-ct-menu-r"}
                         onClick={() => {
                             this.switchStep(1)
                         }}
                    >
                        <div className={"operation-plugin add-fun"}>
                            <PlusOutlined className={"operation-plugin-icon"}/>
                            <div className={"operation-plugin-txt"}>
                                {"添加"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"outcome-mgr-ct-table"}>
                    <div className={"table-wrap"}>
                        <div className={"table-header"}>
                            <div className={"table-header-cell index"}>
                                {"索引"}
                            </div>
                            <div className={"table-header-cell title"}>
                                {"标题"}
                            </div>
                            <div className={"table-header-cell type"}>
                                {"类型"}
                            </div>
                            <div className={"table-header-cell date"}>
                                {type === 0 ? "发布日期" : "提交日期"}
                            </div>
                            <div className={"table-header-cell operation"}>
                                {"操作"}
                            </div>
                        </div>
                        <div className={"table-ct"}>
                            {this.loadTable(type, data, closeItemModalStatus)}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    // 切换步骤
    switchStep = (step) => {
        defaultState.picList = [];
        this.setState({
            step: step,
            submitOptions: Object.assign({}, defaultState),
            imgUrl: {
                picList: [],
            },
        })
    }

    // 加载表单
    loadTable = (type, data, closeItemModalStatus) => {
        let table = [];
        if (data.length > 0) {
            data.forEach((item, index) => {
                table.push(
                    <div className={"table-ct-wrap"} key={index}>
                        <div className={"table-ct-cell index"}>
                            {type ===0 ? item["demandId"] : item["subIndex"]}
                        </div>
                        <div className={"table-ct-cell title-ct"}>
                            {type ===0 ? item["demandName"] : item["name"]}
                        </div>
                        <div className={"table-ct-cell type"}>
                            {handleOutcomeType(item["demandType"])}
                        </div>
                        <div className={"table-ct-cell date"}>
                            {type ===0 ? item["relDate"] : item["subTime"]}
                        </div>
                        <div className={"table-ct-cell operation"}>
                            {
                                type ===0 ?
                                    <Fragment>
                                        <div className={"operation-plugin add-fun"}
                                             onClick={() => {
                                                 window.open('/demand/' + item["demandId"], "_blank")
                                             }}
                                        >
                                            <FileSearchOutlined className={"operation-plugin-icon"}/>
                                            <div className={"operation-plugin-txt"}>
                                                {"查看"}
                                            </div>
                                        </div>
                                        <div style={{width: '20px'}}/>
                                        <div className={"operation-plugin add-fun"}>
                                            <MinusOutlined className={"operation-plugin-icon"}/>
                                            <div className={"operation-plugin-txt"}
                                                 onClick={() => {
                                                     setCloseItemModalStatus(closeItemModalStatus, item["demandId"], this)
                                                 }}
                                            >
                                                {"下架"}
                                            </div>
                                        </div>
                                    </Fragment>
                                    :
                                    <FileSearchOutlined className={"table-ct-cell-icon"}/>
                            }
                        </div>
                    </div>
                )
            })
        } else {
            return (
                <div className={"table-ct-no-data"}>
                    <img className={"table-ct-no-data-icon"} src={NODATA} alt={"no-data"}/>
                    <div className={"table-ct-no-data-tip"}>
                        {"暂无数据"}
                    </div>
                </div>
            )
        }
        return table;
    }

    // 加载表单提交界面
    loadForm = (submitOptions, checkBoxType, imgUrl, loginModalStatus) => {
        let filter = this.state.filterOptions;
        return (
            <Fragment>
                <div className={"form-plugin-header"}>
                    <div className={"back-icon"}
                         onClick={() => {this.switchStep(0)}}
                    >
                        <LeftOutlined />
                    </div>
                    <div className={"cert-process-type"}>
                        <div className={"cert-process-type-title"}>
                            {"技术需求表单"}
                        </div>
                        <div className={"cert-process-type-subtitle"}>
                            {"请完善以下信息，方便我们更好地为您服务。"}
                        </div>
                    </div>
                </div>
                <div className={"form-plugin-paper-wrap"}>
                    <div className={"form-plugin-paper-ct"}>
                        <div className={"form-plugin-paper-item"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"技术需求名称："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"outcomeName"}
                                   type={"text"}
                                   placeholder={"请输入技术需求名称"}
                                   onChange={(e) => {changeSubmitOptions(e, submitOptions, "name", this)}}
                            />
                        </div>
                        <div className={"form-plugin-paper-item"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"类型："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"demandType"}
                                   type={"text"}
                                   placeholder={"请选择类型"}
                                   value={handleOutcomeType(parseInt(submitOptions.demandType)) || ''}
                                   onClick={() => {switchCheckBoxType("demandType", this)}}
                                   readOnly={"readonly"}
                                // onChange={(e) => {changeSubmitOptions(e, submitOptions, "outcomeType", this)}}
                            />
                        </div>
                        <div className={"account-ct-checkbox-wrap"}>
                            <div className={"account-ct-checkbox-ct"}>
                                {checkBoxType === "demandType" ?
                                    loadAreaCheckBox(filter.filterTypeTmp, "demandType", submitOptions, this) : ""
                                }
                            </div>
                        </div>
                        <div className={"form-plugin-paper-item"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"技术来源："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"srcType"}
                                   type={"text"}
                                   placeholder={"请选择技术来源"}
                                   value={handleSrcType(parseInt(submitOptions.srcType)) || ''}
                                   onClick={() => {switchCheckBoxType("srcType", this)}}
                                   readOnly={"readonly"}
                                // onChange={(e) => {changeSubmitOptions(e, submitOptions, "srcType", this)}}
                            />
                        </div>
                        <div className={"account-ct-checkbox-wrap"}>
                            <div className={"account-ct-checkbox-ct"}>
                                {checkBoxType === "srcType" ?
                                    loadAreaCheckBox(filter.filterSrcTypeTmp, "srcType", submitOptions, this) : ""
                                }
                            </div>
                        </div>
                        <div className={"form-plugin-paper-item"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"所属行业："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"areas"}
                                   type={"text"}
                                   placeholder={"请输入所属行业"}
                                   value={submitOptions.areas || ''}
                                   onClick={() => {switchCheckBoxType("areas", this)}}
                                   readOnly={"readonly"}
                                // onChange={(e) => {changeSubmitOptions(e, submitOptions, "areas", this)}}
                            />
                        </div>
                        <div className={"account-ct-checkbox-wrap"}>
                            <div className={"account-ct-checkbox-ct"}>
                                {checkBoxType === "areas" ?
                                    loadAreaCheckBox(filter.filterAreaTmp, "areas", submitOptions, this) : ""
                                }
                            </div>
                        </div>
                        <div className={"form-plugin-paper-item"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"合作方式："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"copWay"}
                                   type={"text"}
                                   placeholder={"请选择合作方式"}
                                   value={handleCopWay(parseInt(submitOptions.copWay)) || ''}
                                   onClick={() => {switchCheckBoxType("copWay", this)}}
                                   readOnly={"readonly"}
                                // onChange={(e) => {changeSubmitOptions(e, submitOptions, "copWay", this)}}
                            />
                        </div>
                        <div className={"account-ct-checkbox-wrap"}>
                            <div className={"account-ct-checkbox-ct"}>
                                {checkBoxType === "copWay" ?
                                    loadAreaCheckBox(filter.filterCopWayTmp, "copWay", submitOptions, this) : ""
                                }
                            </div>
                        </div>
                        <GeoFilter options={submitOptions} parent={this}
                                   areaClass={"form-plugin-paper-item"}
                                   titleClass={"account-ct-user-setting-label"}
                                   map={AreaMap} isIcon={false}
                                   header={"地理位置："}
                        />
                        {/*<div className={"form-plugin-paper-item"}>*/}
                        {/*    <div className={"account-ct-user-setting-label"}>*/}
                        {/*        {"地理位置："}*/}
                        {/*    </div>*/}
                        {/*    <input className={"account-ct-user-setting-input"}*/}
                        {/*           id={"geo"}*/}
                        {/*           type={"text"}*/}
                        {/*           placeholder={"请选择地理位置"}*/}
                        {/*           value={(submitOptions.geo) || ''}*/}
                        {/*           onClick={() => {switchCheckBoxType("geo", this)}}*/}
                        {/*           readOnly={"readonly"}*/}
                        {/*        // onChange={(e) => {changeSubmitOptions(e, submitOptions, "geo", this)}}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*{*/}
                        {/*    checkBoxType === "geo" ?*/}
                        {/*        loadAreaMapSelect(AreaMap, "geo", submitOptions, this)*/}
                        {/*        : null*/}
                        {/*}*/}
                        <div className={"form-plugin-paper-item"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"所属机构："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"root"}
                                   type={"text"}
                                   placeholder={"请输入所属机构"}
                                   onChange={(e) => {changeSubmitOptions(e, submitOptions, "root", this)}}
                            />
                        </div>
                        <div className={"form-plugin-paper-item"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"价格："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"demandPrice："}
                                   type={"text"}
                                   placeholder={"请输入技术需求价格"}
                                   onChange={(e) => {changeSubmitOptions(e, submitOptions, "demandPrice", this)}}
                            />
                        </div>
                        <div className={"form-plugin-paper-item"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"技术需求简介："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"des"}
                                   type={"text"}
                                   placeholder={"请输入技术需求简介"}
                                   onChange={(e) => {changeSubmitOptions(e, submitOptions, "des", this)}}
                            />
                        </div>
                        <div className={"form-plugin-paper-item"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"技术需求详情："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"demandCt"}
                                   type={"text"}
                                   placeholder={"请输入技术需求详情"}
                                   onChange={(e) => {changeSubmitOptions(e, submitOptions, "demandCt", this)}}
                            />
                        </div>
                        <div className={"form-plugin-paper-item"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"承接方要求描述："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"accepterRequest"}
                                   type={"text"}
                                   placeholder={"请输入承接方要求描述"}
                                   onChange={(e) => {changeSubmitOptions(e, submitOptions, "accepterRequest", this)}}
                            />
                        </div>
                        <div className={"form-plugin-paper-item"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"补充说明描述："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"extraInfo"}
                                   type={"text"}
                                   placeholder={"请输入补充说明描述"}
                                   onChange={(e) => {changeSubmitOptions(e, submitOptions, "extraInfo", this)}}
                            />
                        </div>
                        <div className={"form-plugin-paper-item-pic"}>
                            <div className={"account-ct-user-setting-label"}>
                                {"上传照片："}
                            </div>
                            <div className={"cert-process-pic-upload-ele"} style={{width: 'auto'}}>
                                <div className={"cert-process-pic-upload-img-wrap"}>
                                    {loadPicList(imgUrl, submitOptions, "picList")}
                                </div>
                                <div className={"cert-process-pic-upload-fun"}>
                                    <div className={"cert-process-pic-upload-txt"}>
                                        {"上传技术需求相关图片（2M以内, 最多上传三张）"}
                                    </div>
                                    <label htmlFor={"picList"} className={"cert-process-pic-upload-bt"}
                                           style={{pointerEvents : submitOptions["picList"].length === 3 ? "none" : "",
                                               background: submitOptions["picList"] === 3 ? "#888888" : "",
                                               color: submitOptions["picList"].length === 3 ? "#e1e1e1" : ""
                                           }}
                                    >
                                        {"点击上传"}
                                    </label>
                                    <input type={"file"} name={"file"} id={"picList"}
                                           className={"input-file"}
                                           accept={picTypes}
                                           onChange={(e) => {
                                               handleImgUploadInList(e,1400, 1400, submitOptions, imgUrl, "picList", this)
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"form-plugin-btn-wrap-submit"}>
                    <button className={"center-ct-button save-button"}
                            onClick={() => {
                                this.submitDemand(submitOptions, loginModalStatus)
                            }}
                    >
                        {"提交"}
                    </button>
                </div>
            </Fragment>
        )
    }

    setOptions = (options) => {
        this.setState({
            submitOptions: Object.assign({}, options)
        })
    }

    // 提交科技需求
    submitDemand = (submitOptions, loginModalStatus) => {
        let userInfo = this.state.userInfo;
        submitOptions.userId = userInfo.userId;
        let options = Object.assign({}, this.state.submitOptions);
        options.geo = encodeURIComponent(options.geo);
        console.log("科技需求成果Options: ", options);
        sendPostRequest(genPojo(SubmitDemandUrl, options), true).then(res => {
            console.log("科技需求成果res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    success("科技需求表单提交成功，请等待审核，谢谢");
                    this.switchStep(0);
                    findUserAcceptItems(40, 1, '9919', this);
                } else if (res[error] === loginOutErrOr) {
                    setLoginModalStatus(loginModalStatus, this)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 获取驳回或者等待的技术需求
    // WAIT(1),
    // ACCEPT(2),
    // REJECT(3);
    checkDemandSub = (status, type) => {
        let userInfo = this.state.userInfo;
        let params = {
            userId: userInfo.userId,
            status: status,
        };
        sendGetRequest(CheckDemandsSubByIdAndStatusUrl, params, true).then(res => {
            console.log("获取驳回或者等待的技术需求res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.setState({
                        data: res[data],
                        type: type,
                    })
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 下架提醒弹窗
    loadCloseItemModal = (closeItemModalStatus, closeItemId) => {
        if (closeItemModalStatus === true) {
            return (
                <div className={"modal-plugin"}>
                    <div className={"close-item-plugin"}>
                        <div className={"modal-header-wrap"}>
                            <div className={"modal-header-title"}>
                                {"下架技术需求"}
                            </div>
                            <div className={"modal-header-subtitle"}>
                                {"请谨慎选择下架该技术需求，7个自然日后清楚记录，"}
                            </div>
                        </div>
                        <div className={"close-item-wrap"}>
                            <button className={"center-ct-button save-button"}
                                    onClick={() => {
                                        closeUserItem(closeItemId, 40, 1, '9919', closeItemModalStatus, this)
                                    }}
                            >
                                {"下架"}
                            </button>
                            <button className={"center-ct-button cancel-button"}
                                    onClick={() => {
                                        setCloseItemModalStatus(closeItemModalStatus, '', this)
                                    }}
                            >
                                {"取消"}
                            </button>
                        </div>
                        <div className={"modal-close-wrap"}>
                            <CloseOutlined onClick={() => {setCloseItemModalStatus(closeItemModalStatus, '', this)}}/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    componentDidMount() {
        getUserInfoCache(this);  // 缓存获取用户信息
        findUserAcceptItems(40, 1, '9919', this);
    }

    render() {
        let userInfo = this.state.userInfo;
        let data = this.state.data;
        let step = this.state.step;
        let type = this.state.type;

        let submitOptions = this.state.submitOptions;
        let checkBoxType = this.state.checkBoxType;
        let imgUrl = this.state.imgUrl;
        console.log("submitOptions: ", submitOptions);

        let closeItemModalStatus = this.state.closeItemModalStatus;
        let closeItemId = this.state.closeItemId;

        let loginModalStatus = this.state.loginModalStatus;
        let loginPojo = this.state.loginPojo;
        let loginType = this.state.loginType;

        return (
            <div className={"demand-mgr-ct"}>
                {loginModalStatus && loadLoginModal(loginModalStatus, userInfo, loginPojo, loginType, this)}
                {closeItemModalStatus && this.loadCloseItemModal(closeItemModalStatus, closeItemId)}
                <div className={"center-ct-header"}>
                    <div className={"center-ct-header-wrap"}>
                        <div className={"center-ct-header-icon"}/>
                        <div className={"center-ct-header-title"}>
                            {"需求管理"}
                        </div>
                        <div className={"center-ct-header-subtitle"}>
                            {"领先一步获取先机，轻松助力成果转化"}
                        </div>
                    </div>
                </div>
                {
                    step === 0 ?
                        this.loadMgr(type, data, closeItemModalStatus)
                        :
                        this.loadForm(submitOptions, checkBoxType, imgUrl, loginModalStatus)
                }
            </div>
        )
    }
}

export default DemandMgr;
