import React from 'react';
import "./page.css";
import {AdComp, handleSendButton, InputTest, LoginRoot, RegisterRoot} from "../tool/Const";
import {AlipayCircleFilled, CloseOutlined, EyeOutlined, KeyOutlined, WechatFilled} from "@ant-design/icons";
import {connectionError, fail, failReturn, findMsgByError, successReturn} from "../tool/http/ErrorMgr";
import {error, genPojo, RegisterUrl, sendPostRequest} from "../tool/http/Request";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 0,
            secondI: 60,
            showPass: false,
            showValidWin: false,
            // emailError: "",
            checkedPolicy: false,
            registerPojo: {
                type: 1,
                userPwd: null,
                userPhone: null,
                userEmail: null,
                userNick: null,
                code: null,
                aliId: null,
                wechatId: null,
            }
        };
    }

    checkPolicy = (e) => {
        let check = e.target.checked;
        this.setState({
            checkedPolicy: check,
        })
    }

    setRegisterValue = (e, tag, pojo) => {
        let val = e.target.value;
        // let pojo = this.state.registerPojo
        console.log(val)
        if (tag === "userEmail" || InputTest[tag].test.test(val)) {
            pojo[tag] = val;
            this.setState({
                registerPojo: pojo,
            })
        } else {
            if (pojo[tag] !== null) {
                pojo[tag] = null;
                this.setState({
                    registerPojo: pojo,
                })
            }
        }
    }

    getErrorByTag = (tag, pojo, status) => {
        if (status === 1) {
            if (tag === "userEmail") {
                let val = pojo[tag];
                if (val && !InputTest[tag].test.test(val)) {
                    return InputTest[tag].error;
                }
            } else {
                if (!pojo[tag]) {
                    return InputTest[tag].error;
                }
            }
        }
    }

    register = (pojo, status) => {
        console.log(status);
        if (status !== 2) {
            if (!pojo.userPwd || !pojo.userPhone || !pojo.userNick) {
                this.setState({
                    status: 1,
                })
                return;
            }
            if (pojo.userEmail && !InputTest.userEmail.test.test(pojo.userEmail)) {
                this.setState({
                    status: 1,
                })
                return;
            }
            if (!this.state.checkedPolicy) {
                fail("请先勾选已阅读协议！")
                this.setState({
                    status: 1,
                })
                return;
            }
            this.setState({
                status: 2,
                emailError: "",
            })
            console.log(pojo);
            this.setState({
                showValidWin: true,
            })
        }
    }

    submitRegister = (pojo) => {
        if (pojo.code) {
            sendPostRequest(genPojo(RegisterUrl, pojo), true).then(res => {
                if (res === connectionError) {
                    failReturn(findMsgByError(connectionError), RegisterRoot)
                } else {
                    if (res && res.hasOwnProperty("success") && res.success) {
                        successReturn("注册成功，2秒后跳转登录页面！", LoginRoot)
                    } else {
                        if (res[error] === "CODE_ERROR") {
                            fail(findMsgByError(res[error]))
                        } else {
                            failReturn(findMsgByError(res[error]), RegisterRoot)
                        }
                    }
                }
            })
        } else {
            this.setState({
                status: 1,
            })
        }
    }

    componentDidMount() {
        this.handleAliReg();
        this.handleWxReg();
    }

    handleAliReg = () => {
        let state = this.props.location.state;
        console.log("支付宝扫码注册：", state);
        if (state) {
            let aliId = state.aliId;
            if (aliId) {
                let pojo = this.state.registerPojo;
                pojo.aliId = aliId;
                this.setState({
                    registerPojo: pojo,
                })
            }
        }
    }

    handleWxReg = () => {
        let state = this.props.location.state;
        console.log("微信扫码注册：", state);
        if (state) {
            let wechatId = state.wechatId;
            if (wechatId) {
                let pojo = this.state.registerPojo;
                pojo.wechatId = wechatId;
                this.setState({
                    registerPojo: pojo,
                })
            }
        }
    }

    render() {
        let pojo = this.state.registerPojo;
        let showPass = this.state.showPass;
        let status = this.state.status
        let showValidWin = this.state.showValidWin;
        let secondI = this.state.secondI;
        let canSend = secondI === 60;

        console.log("注册账号pojo：", pojo);

        return (
            [<div>{showValidWin ?
                <div className={"change-pass-win"} key={"win"}>
                    <div className={"change-win-inner"} style={{height: "300px"}}>
                        <div style={{
                            width: "100%",
                            height: "40px",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center"
                        }}>
                            <CloseOutlined
                                style={{
                                    fontSize: "20px",
                                    marginRight: "10px",
                                    color: "rgb(72,72,72)",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    this.setState({
                                        code: "",
                                        showValidWin: false,
                                    })
                                }}/>
                        </div>
                        <div className={"func-ct-outer"}>
                            <div className={"change-pass-title-outer"} style={{fontSize: "18px"}}>
                                {"请发送验证码至手机: " + pojo.userPhone}
                            </div>
                            <div style={{height: "20px"}}/>
                            <div className={"func-ct-input-valid"}>
                                <div className={"func-ct-valid-line"}>
                                    <div className={"send-valid-sign"}
                                         style={{background: this.state.focusValid ? "#5292db" : ""}}>
                                        <KeyOutlined/>
                                    </div>
                                    <input key={"change-valid"} className={"func-ct-input-valid-num"}
                                           placeholder={"请输入验证码"}
                                           maxLength={6}
                                           onFocus={() => {
                                               this.setState({focusValid: true})
                                           }} onBlur={() => {
                                        this.setState({focusValid: false})
                                    }}
                                           onChange={(e) => {
                                               this.setRegisterValue(e, "code", pojo)
                                           }}
                                    />
                                    <div className={"send-valid-bt"}
                                         style={!canSend ? {
                                             border: "1px solid #b3b3b3",
                                             background: "#b3b3b3",
                                             color: "#ffffff",
                                             cursor: "not-allowed"
                                         } : {}} onClick={() => {
                                        handleSendButton(60, canSend, pojo.userPhone, true, this)
                                    }}>
                                        {!canSend ? secondI + "秒后可重发" : "发送验证码"}
                                    </div>
                                </div>
                                <div className={"func-input-notice"}>
                                    {this.getErrorByTag('code', pojo, status)}
                                </div>
                                <div className={"func-main-bt"} onClick={() => {
                                    this.submitRegister(pojo)
                                }}>
                                    {"确认注册"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
            </div>, <div className={"func-pg-bk"}>
                <div className={"login-page"}>
                    {AdComp()}
                    <div className={"func-ct"}>
                        <div className={"func-reg-ct-outer"}>
                            <div className={"func-ct-title"}>
                                <div className={"func-title-name"}>{"注册"}</div>
                                <div className={"func-title-corner"}>
                                    <span>{"已有账号?"}</span>
                                    <span style={{
                                        paddingLeft: "8px",
                                        color: "rgb(33,115,199)",
                                        cursor: "pointer"
                                    }} onClick={() => {
                                        window.location.href = LoginRoot
                                    }}>{"点此登录"}</span>
                                </div>
                            </div>
                            <div className={"func-reg-ct-input-outer"}>
                                <input className={"func-ct-input-reg"} placeholder={"请输入手机号"} maxLength={11}
                                       onChange={(e) => {
                                           this.setRegisterValue(e, 'userPhone', pojo)
                                       }}/>
                                <span
                                    className={"func-reg-input-notice"}>{this.getErrorByTag('userPhone', pojo, status)}</span>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <input className={"func-ct-input-reg"}
                                           placeholder={"请输入登录密码"} maxLength={20} type={showPass ? "text" : "password"}
                                           onChange={(e) => {
                                               this.setRegisterValue(e, 'userPwd', pojo)
                                           }}/>
                                    <EyeOutlined style={{
                                        fontSize: "18px",
                                        position: "absolute",
                                        transform: "translateX(344px)",
                                        color: showPass ? "#8aaed7" : "#c4c4c4",
                                        cursor: "pointer",
                                    }} onClick={() => {
                                        this.setState({showPass: !showPass})
                                    }}/>
                                </div>
                                <span
                                    className={"func-reg-input-notice"}>{this.getErrorByTag('userPwd', pojo, status)}</span>
                                <input className={"func-ct-input-reg"} placeholder={"请输入昵称"} maxLength={10}
                                       onChange={(e) => {
                                           this.setRegisterValue(e, 'userNick', pojo)
                                       }}/>
                                <span
                                    className={"func-reg-input-notice"}>{this.getErrorByTag('userNick', pojo, status)}</span>
                                <input className={"func-ct-input-reg"} placeholder={"请输入邮箱(选填)"} maxLength={40}
                                       onChange={(e) => {
                                           this.setRegisterValue(e, 'userEmail', pojo)
                                       }}/>
                                <span
                                    className={"func-reg-input-notice"}>{this.getErrorByTag('userEmail', pojo, status)}</span>
                                <div className={"reg-notice-checkbox"}>
                                    <input type={"checkbox"} name={"known"} onChange={(e) => {
                                        this.checkPolicy(e)
                                    }}/>
                                    <span style={{padding: "0 4px 0 6px", color: "rgb(66,66,66)"}}>{"我已阅读并同意"}</span>
                                    <span style={{color: "rgb(33,115,199)", cursor: "pointer"}}>{"《湃通会员服务协议》"}</span>
                                    <span style={{color: "rgb(33,115,199)", cursor: "pointer"}}>{"《湃通交易服务协议》"}</span>
                                    <span style={{color: "rgb(33,115,199)", cursor: "pointer"}}>{"《湃通隐私保护协议》"}</span>
                                </div>
                            </div>
                            <div className={"func-main-bt"} style={{marginTop: "10px"}}
                                 onClick={() => {
                                     this.register(pojo, status)}}
                            >
                                {"立即注册"}
                            </div>
                            {/*<div className={"func-login-method"}>*/}
                            {/*    <div className={"func-style-line"}/>*/}
                            {/*    <span className={"login-method-des"}>{"其他方式登录"}</span>*/}
                            {/*    <div className={"func-style-line"}/>*/}
                            {/*</div>*/}
                            {/*<div className={"login-other-methods"}>*/}
                            {/*    <WechatFilled style={{fontSize: "38px", margin: "0 20px 0 20px", color: "#07c160", cursor: "pointer"}}*/}
                            {/*                  onClick={() => {this.loginByWechat()}}/>*/}
                            {/*    <AlipayCircleFilled style={{fontSize: "34px", margin: "0 20px 0 20px", color: "rgb(0,166,255)", cursor: "pointer"}}*/}
                            {/*                        onClick={() => {this.loginByAli()}}/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>]
        )
    }
}

export default Register;
