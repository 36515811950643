import PageRoutes from "./PageRoutes";
import React from 'react';
import {LoginRoot, UserInfoStorage} from "./tool/Const";
import {CheckLoginValidUrl, Header, sendGetRequest,} from "./tool/http/Request";
import {failReturn, findMsgByError} from "./tool/http/ErrorMgr";

const standard = 21600000;

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        }
    }

    // 检查用户登陆状态 (true 正常 false 失效)
    checkLoginValid = () => {
        let userInfoCache = UserInfoStorage.getJ("userInfo");
        console.log("app userInfo: ", userInfoCache);
        let url = Header;
        let curUrl = window.location.href;
        if (userInfoCache) {
            let cacheTime = userInfoCache.time;
            let curTime = new Date().getTime();
            let valid = (curTime - cacheTime) < standard;
            if (valid) {
                let userInfo = userInfoCache.userInfo;
                let params = {
                    userId: userInfo.userId,
                }
                sendGetRequest(CheckLoginValidUrl, params, true).then(res => {
                    console.log("检查用户登陆状态res：", res);
                    if (!res) {
                        console.log("用户登录状态失效");
                        UserInfoStorage.remove("userInfo");
                        if (curUrl === url) {
                            window.location.reload();
                        } else {
                            failReturn(findMsgByError("LOGIN_OUT"), LoginRoot);
                        }
                    }
                })
            } else {
                UserInfoStorage.remove("userInfo");
            }
        }
        this.setState({
            isLoaded: true,
        })
    }

    componentDidMount() {
        this.checkLoginValid()
    }

    render() {
        let isLoaded = this.state.isLoaded;

        return (
            <div className="App">
                {
                    isLoaded ? <PageRoutes/> : null
                }
            </div>
        );
    }
}

export default App;

