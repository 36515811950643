import React, {Component} from 'react';
import "./page.css"
import OAQR from "../assets/img/official_account_qr.jpg";
import BADGE from "../assets/icon/icp.png";

class Footer extends Component {
    render() {
        return (
            <div className={"out-content"}>
                <div className={"footer-ot"}>
                    <div className={"page-ct"}>
                        {/*<div className={"footer-navigation"}>*/}
                        {/*    <a className={"footer-navigation-item"}>*/}
                        {/*        查看更多内容*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                        {/*<hr className={"footer-divider"}/>*/}
                        <div className={"footer-link"}>
                            <div className={"footer-link-wrap"}>
                                <li className={"footer-link-item"}>
                                    <a className={"footer-font"}>
                                        友情链接：
                                    </a>
                                </li>
                                <li className={"footer-link-item"}>
                                    <a className={"footer-link-item-info"}
                                       href={"https://www.most.gov.cn/index.html"}
                                       target={"_blank"}
                                    >
                                        中华人民共和国科学技术部
                                    </a>
                                </li>
                                <li className={"footer-link-item"}>
                                    <a className={"footer-link-item-info"}
                                       href={"https://stcsm.sh.gov.cn/"}
                                       target={"_blank"}
                                    >
                                        上海市科学技术委员会
                                    </a>
                                </li>
                                <li className={"footer-link-item"}>
                                    <a className={"footer-link-item-info"}
                                       href={"http://kjt.zj.gov.cn/"}
                                       target={"_blank"}
                                    >
                                        浙江省科学技术厅
                                    </a>
                                </li>
                                <li className={"footer-link-item"}>
                                    <a className={"footer-link-item-info"}
                                       href={"http://kxjst.jiangsu.gov.cn/"}
                                       target={"_blank"}
                                    >
                                        江苏省科学技术厅
                                    </a>
                                </li>
                                <li className={"footer-link-item"}>
                                    <a className={"footer-link-item-info"}
                                       href={"http://kjt.ah.gov.cn/"}
                                       target={"_blank"}
                                    >
                                        安徽省科学技术厅
                                    </a>
                                </li>
                                <li className={"footer-link-item"}>
                                    <a className={"footer-link-item-info"}
                                       href={"https://www.sast.gov.cn/"}
                                       target={"_blank"}
                                    >
                                        上海市科学技术协会
                                    </a>
                                </li>
                                <li className={"footer-link-item"}>
                                    <a className={"footer-link-item-info"}
                                       href={"https://www.zast.org.cn/"}
                                       target={"_blank"}
                                    >
                                        浙江省科学技术协会
                                    </a>
                                </li>
                            </div>
                        </div>
                        <div className={"footer-legal"}>
                            <div className={"footer-legal-wrap"}>
                                 {/*<div className={"footer-logo"}>*/}
                                 {/*    logo*/}
                                 {/*</div>*/}
                                 <li className={"footer-legal-item"}>
                                     <a className={"footer-legal-link footer-font"}>
                                         Copyright © 2022 长三角一体化科创服务平台
                                     </a>
                                 </li>
                                 <li className={"footer-legal-item"}>
                                     <a className={"footer-legal-link footer-font"}>
                                         法律声明
                                     </a>
                                 </li>
                                 <li className={"footer-legal-item"}>
                                     <a className={"footer-legal-link footer-font"}>
                                         案例中心
                                     </a>
                                 </li>
                                 <li className={"footer-legal-item"}>
                                     <a className={"footer-legal-link footer-font"}>
                                         下载中心
                                     </a>
                                 </li>
                                 <li className={"footer-legal-item"}>
                                     <a className={"footer-legal-link footer-font"}>
                                         使用帮助
                                     </a>
                                 </li>
                            </div>
                        </div>
                        <div className={"footer-base"}>
                            <div className={"footer-base-wrap"}>
                                <div className={"footer-qrs"}>
                                    {/*<div className={"footer-qr"}>*/}
                                    {/*    平台小程序二维码*/}
                                    {/*</div>*/}
                                    <div className={"footer-qr"}>
                                        {/*湃通公众号二维码*/}
                                        <img className={"img-style contain"} src={OAQR} alt={"qr"}/>
                                    </div>
                                </div>
                                <div className={"footer-infos"}>
                                    <div className={"footer-infos-wrap"}>
                                        <div className={"footer-info footer-font"}>
                                            全国免费服务热线：021-1234 5678
                                        </div>
                                        <div className={"footer-info footer-font"}>
                                            运营商: 上海湃通科技有限责任公司
                                        </div>
                                        <div className={"footer-info footer-font"}>
                                            地址：上海市静安区江场三路 228 号 15 幢 705 室
                                        </div>
                                        <div className={"footer-info footer-font"}>
                                            联系电话：021-1234 5678
                                        </div>
                                        <a className={"footer-info footer-font"}
                                           style={{cursor: "pointer"}}
                                           href={"https://beian.miit.gov.cn/"}
                                           target={"_blank"}
                                        >
                                            备案号：沪ICP备2022015374号-1
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;
