import React, {Component} from 'react';
import "./demand.css";
import FilterHeader from "../../tool/filter/FilterHeader";
import GeoFilter from "../../tool/filter/GeoFilter";
import FilterMain from "../../tool/filter/FilterMain";
import FilterTags from "../../tool/filter/FilterTags";
import {DownOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {AreaMap} from "../../tool/Const";
import Bk1 from "./../pic/demandBk1.png";
import BK2 from "./../pic/demandBk2.png"

const filterCt = [
    {
        title: "需求类型",
        ct: ["发明专利", "新型实用专利", "外观设计专利", "软件著作权", "商标注册"],
        ip: 'type',
    },
    {
        title: "需求来源",
        ct: ["全部", "高等院校", "科研院所", "企业"],
        ip: 'from',
    },
    {
        title: "行业分类",
        ct: ["全部", "生物科技", "医药和医疗器械", "新材料及其应用", "新一代电子信息", "先进制造与自动化", "现代农业", "航空航天技术",
            "绿色化工技术", "新能源与高效节能", "核应用技术", "环境保护与资源利用", "现代交通技术", "城市建设和社会发展", "海洋开发",
            "文化体育与娱乐", "食品饮料", "轻工纺织", "芯片与集成电路", "人工智能与大数据", "其他"],
        ip: 'area',
    },
    {
        title: "合作方式",
        ct: ["全部", "技术转让", "委托开发", "合作开发", "专利许可", "技术入股", "其他"],
        ip: 'method',
    },
    {
        title: "需求金额",
        ct: ['全部', '面议', '1万以下', '1万-10万', '10万-50万', '50万-100万', '100万-200万', '200万-500万', '500万-1000万', '1000万以上'],
        ip: 'level',
    },
]

const TagList = ['from', 'area', 'method', 'level']

const filterMainStyle = {
    header: "expert-filter-header",
    title: "demand-filter-search-title",
    input: "expert-filter-input",
    tag: "demand-filter-tag",
    search: "expert-filter-search-bt",
    outer: "filter-search",
}

class DemandFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectItem: -1,
            openGeo: false,
            openFilter: true,
            showCity: -1,
        };
    }

    setOptions = (options) => {
        this.props.parent.setState({
            options: Object.assign({}, options)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let openFilter = this.state.openFilter;
        let selectItem = this.state.selectItem;
        if (prevState.openFilter !== openFilter || prevState.selectItem !== selectItem) {
            if (openFilter) {
                this.props.parent.setState({
                    openSize: selectItem === -1 ? 418 : 698,
                })
            } else {
                this.props.parent.setState({
                    openSize: 0,
                })
            }
        }
    }

    render() {
        let options = this.props.options;
        let initTag = this.props.initTag;
        let openFilter = this.state.openFilter;
        return (
            <div style={{minHeight: "400px", width: "100%", background: "white"}} id={"filter"}>
                <div className={"demand-bk"}>
                    <div className={"demand-banner"}>
                        <img className={"demand-banner-img-one"} src={BK2} alt={""}/>
                        <img className={"demand-banner-img-two"} src={Bk1} alt={""}/>
                    </div>
                    <div className={"expert-filter"}>
                        <FilterHeader page={"demand"} parent={this} filterStyle={filterMainStyle} options={options}/>
                    </div>
                </div>
                <div className={"demand-filter-ct"}>
                    <div style={{height: "16px", width: "100%"}}/>
                    <div className={"demand-filter-open"}>
                        <div className={"demand-filter-open-outer"}
                             onClick={() => {
                                 this.setState({openFilter: !openFilter})
                             }}>
                            <span className={"demand-filter-title"}>{openFilter ? "隐藏多条件搜索栏" : "打开多条件搜索栏"}</span>
                            {openFilter ?
                                <DownOutlined style={{fontSize: "14px", cursor: "pointer"}}/>
                                :
                                <RightOutlined style={{fontSize: "14px", cursor: "pointer"}}/>}
                        </div>
                    </div>
                    {openFilter ? <div className={"demand-filter-outer"} id={"filter-size"}
                    >
                        <div className={"demand-filter-outer-layout"}>
                            <div style={{height: "8px", width: "100%"}}/>
                            <GeoFilter options={options} parent={this}
                                       areaClass={"filter-area"} titleClass={"filter-area-geo-title"}
                                       map={AreaMap} isIcon={true}
                                       mainType={"全国所有省市"} header={"地理位置:"}/>
                            <FilterMain options={options} parent={this} filterCt={filterCt} initTag={initTag}/>
                            <div style={{height: "14px", width: "100%"}}/>
                        </div>
                    </div> : null}
                </div>
                <div className={"filter-selected-tags"} id={"d-tag"}>
                    <FilterTags options={options} parent={this} initTag={initTag} tags={TagList}
                                titleType={options.type} total={this.props.total}/>
                </div>
            </div>
        )
    }
}

export default DemandFilter;
