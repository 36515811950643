import React, {Component} from 'react';
import "./expert.css";
import "../page.css";
import Header from "../Header";
import Footer from "../Footer";
import ExpertFilter from "./ExpertFilter";
import AdRight from "../../tool/AdRight";
import Contact from "../home/Contact";
import {
    advList,
    dataIndexE,
    ExpertRoot,
    filterArea,
    filterSrcTypeBySub,
    filterTitle,
    findFavorDataByType,
    getAdvData,
    handleAdRightWinSizeChange, handleAdRightWinSizeInit,
    handleClickMonitor,
    handleExpertTitle,
    handlePageScrollMove,
    LoadingPolicyCopAds,
    LoadingSolutionCopAds,
    PolicyRoot,
    SaveBt,
    showSemData,
    SolutionRoot,
    solutionTechPerList,
    UserInfoStorage
} from "../../tool/Const";
import Partitioner from "../../tool/Partitioner";
import EMPTY from "../../assets/img/record_empty.svg";
import {SrcHeader} from "../../tool/http/Request";

const adClasses = {
    adOuter: "expert-ct-ad",
    adOne: "expert-ct-ad-one",
    adTwo: "expert-ct-ad-two",
}

const loadSetting = {
    loadingBatch: 8,
    classes: {
        outer: "outcome-page-partition-ct",
    }
}

// const initGeo = "上海[上海市]";
const initGeo = "全国所有省市";

const initTag = "全部";

class ExpertPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fixed: true,
            fixedAd: false,
            adLeft: "",
            adBt: false,
            showAd: true,
            gap: 0,
            cH: 0,
            hoverI: -1,
            adjustGap: 0,
            openSize: 0,
            partitioner: {
                totalSize: 0,
                showData: [],
                loadComplete: false,
            },
            options: {
                index: '2577',
                geo: initGeo,
                from: initTag,
                area: initTag,
                title: initTag,
                keyWords: "",
            },
            favorList: [],
            idList: [],
            advMap: new Map(),  // adv数据
        };
        this.timeout = null;
    }

    componentDidMount() {
        handleAdRightWinSizeInit(this);
        window.addEventListener("resize", this.handleResize)
        window.onscroll = () => {
            let cH = document.getElementById("expert-outer").clientHeight;
            let cHC = this.state.cH;

            let headerH = document.getElementById("header").clientHeight;
            let filterH = document.getElementById("filter").clientHeight;
            let paddingH = 0;
            let adH = document.getElementById("ad").clientHeight;
            let contactH = 42;
            let distanceAboveAd = headerH + filterH + paddingH + adH + contactH - 60;  // 1970
            let distanceBtAd = headerH + filterH + paddingH + contactH - 60;  // 708
            let distanceContact = headerH + filterH + paddingH + contactH - 90;  // 678

            handlePageScrollMove(this, "e-tag", distanceAboveAd, distanceBtAd + cH, distanceContact + cH, "expert-outer", 940)
            if (cHC !== cH) {
                this.setState({
                    cH: cH,
                })
            }
        };
        findFavorDataByType(this);
        getAdvData(advList.expert, this);
        this.handleMenuLink();
    }

    handleMenuLink = () => {
        let search = this.props.location.search;
        if (search) {
            let params = search.replace("?", "");
            let filterType = params.split("=")[0];
            let filterValue = params.split("=")[1];
            console.log(filterType, filterValue);
            let options = this.state.options;
            if (filterType === "area") {
                options[filterType] = filterArea[filterValue]['value'];
            }
            if (filterType === "title") {
                options[filterType] = filterTitle[filterValue]['value'];
            }
            if (filterType === "from") {
                options[filterType] = filterSrcTypeBySub[filterValue]['value'];
            }
            if (filterType === "from") {
                options[filterType] = filterSrcTypeBySub[filterValue]['value'];
            }
            if (filterType === "keyWords") {
                let filterValueD = decodeURIComponent(filterValue);
                options[filterType] = filterValueD;
            }
            this.setState({
                options: Object.assign({}, options),
            })
        }
    }

    handleResize = () => {
        handleAdRightWinSizeChange("expert-outer", this);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize)
    }

    loadExpertData = () => {
        let data = []
        let showData = this.state.partitioner.showData;
        let hoverI = this.state.hoverI;
        let userInfo = UserInfoStorage.get("userInfo");
        let favorList = this.state.favorList;
        let options = this.state.options;

        if (showData) {
            showData.forEach((item, index) => {
                let imgUrl = SrcHeader + item["expertId"] + "/" + item["expertBanner"];
                data.push(<div className={"expert-data-item"} key={index} style={{marginTop: index === 0 ? "0" : ""}}>
                    <div className={"expert-item-square"}>
                        {SaveBt(item, index, hoverI, this, userInfo, favorList, options)}
                        <div className={"save-policy"} style={{display: index === hoverI ? "" : "none"}}>
                            {favorList.indexOf(item.expertId) > -1 ? "取消收藏" : "点击收藏"}
                        </div>
                    </div>
                    <div className={"expert-item-pic-outer"}>
                        <img src={imgUrl} alt={""} className={"expert-item-pic"}/>
                    </div>
                    <div className={"expert-item-ct-outer"}>
                        <div className={"expert-item-ct"}>
                            <div className={"expert-item-ct-line"} style={{background: "rgb(238,238,238)"}}>
                                <span className={"expert-item-name"}>
                                    {/*{"潘建伟"}*/}
                                    {item["expertName"]}
                                </span>
                                <div className={"expert-tag"}>
                                    {/*{"教授"}*/}
                                    {handleExpertTitle(item["expertTitle"])}
                                </div>
                                <div className={"expert-tag"} style={{display: showSemData(item["expertHor"], 0) ? "" : "none"}}>
                                    {/*{"中国科学院院士"}*/}
                                    {showSemData(item["expertHor"], 0)}
                                </div>
                                <div className={"expert-tag"} style={{display: showSemData(item["expertHor"], 1) ? "" : "none"}}>
                                    {/*{"中国科学院院士"}*/}
                                    {showSemData(item["expertHor"], 1)}
                                </div>

                            </div>
                            <div className={"expert-item-ct-line"}>
                                <div className={"expert-item-line-sp"}>
                                    <span className={"expert-item-line-key"}>
                                        {"所属机构："}
                                    </span>
                                    <span>
                                        {item["expertRoot"]}
                                    </span>
                                </div>
                                <div className={"expert-item-line-sp"}>
                                    <span className={"expert-item-line-key"}>
                                        {"地理位置："}
                                    </span>
                                    <span>
                                        {item["expertGeo"]}
                                    </span>
                                </div>
                            </div>
                            <div className={"expert-item-ct-line"}>
                                <div className={"expert-item-ct-container"}>
                                     <span className={"expert-item-line-key"}>
                                        {"担任职务："}
                                    </span>
                                    <div className={"expert-job-tag"}>
                                        {/*{"九三学社中央副主席"}*/}
                                        {showSemData(item["expertJob"], 0)}
                                    </div>
                                    <div className={"expert-job-tag"} style={{display: showSemData(item["expertJob"], 1) ? "" : "none", marginRight: '0'}}>
                                        {/*{"中国科学院院士"}*/}
                                        {showSemData(item["expertJob"], 1)}
                                    </div>
                                </div>
                            </div>
                            <div className={"expert-item-ct-line"}>
                                <div className={"expert-item-ct-container"}>
                                    <span className={"expert-item-line-key"}>
                                        {"研究领域："}
                                    </span>
                                    <div className={"expert-area-tag"}>
                                        {/*{"量子物理学"}*/}
                                        {item["expertAreas"]}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"expert-item-fun-outer"}>
                        <div className={"expert-item-button"}>
                            {"预约专家"}
                        </div>
                        <div className={"expert-item-button"}
                             onClick={() => {
                                 handleClickMonitor(userInfo, item["expertId"], 2, 1, ExpertRoot.path + "/" + item["expertId"])
                             }}
                        >
                            {"专家详情"}
                        </div>
                    </div>
                </div>)
            })
        } else {
            data.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }
        return data;
    }

    render() {
        let adLeft = this.state.adLeft;
        let right = "calc((100% - " + adLeft + " ) / 2)";
        let totalGap = this.state.gap - this.state.adjustGap;
        let gapR = totalGap === 0 ? "0" : parseInt(totalGap + "") + "px";
        let wH = parseInt((this.state.cH - 30) + "") + "px";
        let partitioner = this.state.partitioner;
        let options = this.state.options;
        let advMap = this.state.advMap;
        console.log(options);

        return (
            <div className={"home-ct"}>
                <Header/>
                <div className={"expert-page"}>
                    <ExpertFilter parent={this} total={this.state.partitioner.totalSize} options={options}
                                  initTag={initTag}/>
                    <div className={"expert-page-content"} id={"expert-outer"}>
                        <div className={"expert-ct-data"}>
                            {this.loadExpertData()}
                            <div className={"outcome-page-partition"}
                                 style={{display: partitioner.showData ? "" : "none"}}
                            >
                                <Partitioner parent={this} loadSetting={loadSetting} options={options}
                                             // handleCheckMonitor={handleIdList}
                                />
                            </div>
                        </div>
                        {
                            advMap.size === advList.expert.length ?
                                <AdRight fixedAd={this.state.fixedAd} adBt={this.state.adBt} gapR={gapR} right={right}
                                         wholeHgt={wH} adClasses={adClasses}
                                         adTitleOne={"科技人才创新"} adOneCt={LoadingSolutionCopAds(solutionTechPerList)} adOneUrl={SolutionRoot}
                                         adTitleTwo={"政策精要"} adTwoCt={LoadingPolicyCopAds(advMap.get(dataIndexE.policy))} adTwoUrl={PolicyRoot.path}
                                />
                                : null
                        }
                    </div>
                </div>
                <Contact fixed={this.state.fixed}/>
                <div>
                    <Footer/>
                </div>
            </div>
        )
    }
}

export default ExpertPage;
