import React from "react";
import "./center.css";
import AVATAR from "../../assets/icon/icon_avatar.svg";
import moment from "moment";
import {genProcessBack, UserInfoStorage, utcTimeFormat} from "../../tool/Const";
import {CloseOutlined} from "@ant-design/icons";
import {delWin} from "../../tool/Launcher";
import PayOperator from "./PayOperator";
import LunchWin from "../../tool/Tool";
import PayWin from "../../tool/PayWin";
import {error, genPojo, sendPostRequest, SubmitVipAppointUrl} from "../../tool/http/Request";
import {connectionError, fail, findMsgByError, successReturn} from "../../tool/http/ErrorMgr";
import PayParent from "./PayParent";

class VipWin extends PayParent {
    constructor(props) {
        super(props);
        this.state = {
            selectVip: {
                selectType: 1,  // 选择的套餐类型
                selectPrice: 1200,  // 选择的套餐价格
                selectDays: 365,  // 选择的套餐天数
            },
            payStatus: {
                realPrice: '',
                paiPrice: '',
                rmbPrice: '',
                disPrice: '',
            },
            isDeduct: false,
            orderPojo: {
                orderId: '',
                userId: '',
                serviceNum: '',
                realPrice: '',
                paiPrice: '',
                rmbPrice: '',
                disPrice: '',
            },
        }
    }

    // 切换选择的套餐
    switchSelectPrice = (selectVip, type, num, days) => {
        selectVip.selectType = type;
        selectVip.selectPrice = num;
        selectVip.selectDays = days;
        this.setState({
                selectVip: selectVip,
                isDeduct: false,
            },
            () => {
                this.setPayStatus(selectVip.selectPrice, 0, selectVip.selectPrice, 0)
            })
    }

    // 设置（realPrice, paiPrice, rmbPrice, disPrice）
    setPayStatus = (realPrice, paiPrice, rmbPrice, disPrice, back) => {
        let pay = {
            realPrice: realPrice,
            paiPrice: paiPrice,
            rmbPrice: rmbPrice,
            disPrice: disPrice,
        };
        this.setState({
                payStatus: pay,
            }, () =>
                back
        );
    }

    // 抵扣湃通币
    handleDeductCoin = (isDeduct, payStatus, userCoin, selectVip) => {
        this.setState({
                isDeduct: !isDeduct,
            },
            () => {
                if (this.state.isDeduct === true) {
                    // let deduct = parseInt(userCoin.toString() / 100);
                    // let paiP = deduct > payStatus.realPrice ? payStatus.realPrice : deduct;
                    // let modNum = mod(userCoin.toString(), 100)
                    // // console.log("deduct: ", deduct);
                    // // console.log("modNum: ", modNum);
                    // let nowToPay = NP.minus(payStatus.realPrice, paiP);

                    // paiPrice = realPrice * 100 - userCoin > 0 ? userCoin : realPrice * 100 - userCoin
                    let paiRmb = parseInt(userCoin.toString() / 100);
                    let cover = paiRmb > payStatus.realPrice ? payStatus.realPrice : paiRmb;
                    let rmbPrice = payStatus.realPrice - cover;
                    let paiPrice = cover * 100;

                    payStatus.paiPrice = paiPrice;
                    payStatus.rmbPrice = rmbPrice;
                    this.setState({
                        payStatus: payStatus,
                    })
                } else {
                    payStatus.paiPrice = 0;
                    payStatus.rmbPrice = selectVip.selectPrice;
                    this.setState({
                        payStatus: payStatus,
                    })
                }
            })
    }

    // 调用支付方式对应接口
    handlePay = () => {
        let orderPojo = this.state.orderPojo;
        let isCoin = orderPojo.rmbPrice === 0;

        let pojo = {
            orderPojo: orderPojo,
        }

        if (isCoin) {
            console.log("调用payByCoin");
            this.handlePayByCoin(pojo);
        } else {
            console.log("调用payByAli或payByWx");
            delWin("wrap", "vip");
            let id = "wrap";
            let winId = "pay";
            LunchWin(<PayWin parentId={id} winId={winId} requestPojo={pojo} setPojo={this.setRequestPojo} fun={this.handleVip}/>, id, winId);
        }
    }

    setRequestPojo = (requestPojo, id) => {
        requestPojo.orderPojo.orderId = id;
        this.setState({
            requestPojo: requestPojo,
        })
    }

    handleVip = (pojo, backData) => {
        if (backData === 5 && pojo.orderPojo.paiPrice > 0) {
            this.handlePayByCoin(pojo)
        } else {
            this.handleVipAppoint();
        }
    }

    // 湃通币支付接口
    handlePayByCoin = (pojo) => {
        let operator = new PayOperator();

        let _this = this;
        const myBack: WinBack = {
            callDataBack(data: any) {
                console.log("湃通币支付接口res: ", data);
                _this.handleVipAppoint();
            }
        }

        operator.payByCoin(pojo, genProcessBack(myBack, true));
    }


    // 用户提交VIP服务
    handleVipAppoint = () => {
        let userInfo = UserInfoStorage.get("userInfo");
        let selectVip = this.state.selectVip;
        let orderPojo = this.state.orderPojo;
        let par = {
            userId: userInfo.userId,
            days: selectVip.selectDays,
        }
        let pojo = {
            orderId: orderPojo.orderId,
            realPrice: orderPojo.realPrice,
            serviceCt: JSON.stringify(par),
        };
        console.log("用户提交VIP服务pojo: ", pojo);
        sendPostRequest(genPojo(SubmitVipAppointUrl, pojo), false).then(res => {
            console.log("用户提交VIP服务res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.refreshUser(userInfo);
                    successReturn("vip会员充值成功", window.location.href);
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    testA = () => {
        let orderPojo1 = {orderId: '018-48306a8f-f4d6-4250-98f3-215be52889d6', userId: '0501739161649486438018', serviceNum: 1, realPrice: 100, paiPrice: 10000, rmbPrice: 0, disPrice: 0, serviceItem: "VIP服务:1"};
        let orderPojo2 = {orderId: '018-48306a8f-f4d6-4250-98f3-215be52889d7', userId: '0501739161649486438018', serviceNum: 1, realPrice: 200, paiPrice: 20000, rmbPrice: 0, disPrice: 0, serviceItem: "VIP服务:2"};
        let orderPojo3 = {orderId: '018-48306a8f-f4d6-4250-98f3-215be52889d8', userId: '0501739161649486438018', serviceNum: 1, realPrice: 300, paiPrice: 30000, rmbPrice: 0, disPrice: 0, serviceItem: "VIP服务:3"};
        let orderPojo4 = {orderId: '018-48306a8f-f4d6-4250-98f3-215be52889d9', userId: '0501739161649486438018', serviceNum: 1, realPrice: 400, paiPrice: 40000, rmbPrice: 0, disPrice: 0, serviceItem: "VIP服务:4"};
        let orderPojo5 = {orderId: '018-48306a8f-f4d6-4250-98f3-215be52889d1', userId: '0501739161649486438018', serviceNum: 1, realPrice: 500, paiPrice: 50000, rmbPrice: 0, disPrice: 0, serviceItem: "VIP服务:5"};
        let orderPojo6 = {orderId: '018-48306a8f-f4d6-4250-98f3-215be52889d2', userId: '0501739161649486438018', serviceNum: 1, realPrice: 600, paiPrice: 60000, rmbPrice: 0, disPrice: 0, serviceItem: "VIP服务:6"};
        let orderPojo7 = {orderId: '018-48306a8f-f4d6-4250-98f3-215be52889d3', userId: '0501739161649486438018', serviceNum: 1, realPrice: 700, paiPrice: 70000, rmbPrice: 0, disPrice: 0, serviceItem: "VIP服务:7"};
        let orderPojo8 = {orderId: '018-48306a8f-f4d6-4250-98f3-215be52889d4', userId: '0501739161649486438018', serviceNum: 1, realPrice: 800, paiPrice: 80000, rmbPrice: 0, disPrice: 0, serviceItem: "VIP服务:8"};
        let orderPojo9 = {orderId: '018-48306a8f-f4d6-4250-98f3-215be52889d5', userId: '0501739161649486438018', serviceNum: 1, realPrice: 900, paiPrice: 90000, rmbPrice: 0, disPrice: 0, serviceItem: "VIP服务:9"};
        let orderPojo10 = {orderId: '018-48306a8f-f4d6-4250-98f3-215be52889d0', userId: '0501739161649486438018', serviceNum: 1, realPrice: 1000, paiPrice: 100000, rmbPrice: 0, disPrice: 0, serviceItem: "VIP服务:10"};
        let pojo1 = {orderPojo: orderPojo1,};
        let pojo2 = {orderPojo: orderPojo2,};
        let pojo3 = {orderPojo: orderPojo3,};
        let pojo4 = {orderPojo: orderPojo4,};
        let pojo5 = {orderPojo: orderPojo5,};
        let pojo6 = {orderPojo: orderPojo6,};
        let pojo7 = {orderPojo: orderPojo7,};
        let pojo8 = {orderPojo: orderPojo8,};
        let pojo9 = {orderPojo: orderPojo9,};
        let pojo10 = {orderPojo: orderPojo10,};
        this.handlePayByCoin(pojo1);
        this.handlePayByCoin(pojo2);
        this.handlePayByCoin(pojo3);
        this.handlePayByCoin(pojo4);
        this.handlePayByCoin(pojo5);
        this.handlePayByCoin(pojo6);
        this.handlePayByCoin(pojo7);
        this.handlePayByCoin(pojo8);
        this.handlePayByCoin(pojo9);
        this.handlePayByCoin(pojo10);
    }

    componentDidMount() {
        this.setPayStatus(this.state.selectVip.selectPrice, 0, this.state.selectVip.selectPrice, 0)
    }

    render() {
        let parentId = this.props.parentId;
        let winId = this.props.winId;
        let userInfo = this.props.userInfo;
        let vipStatus = this.props.vipStatus;

        let selectVip = this.state.selectVip;
        let payStatus = this.state.payStatus;
        let isDeduct = this.state.isDeduct;

        console.log(payStatus);

        return (
            <div className={"pay-plugin-box"}>
                <div className={"pay-plugin-header-wrap"}>
                    <div className={"pay-plugin-header-avatar"}>
                        <img className={"img-style contain"} src={AVATAR} alt={"avatar"}/>
                    </div>
                    <div className={"pay-plugin-header-left"}>
                        <div className={"pay-plugin-header-name"}>
                            {userInfo.userReal ? userInfo.userReal : userInfo.userNick}
                        </div>
                        <div className={"pay-plugin-header-info"}>
                            {"Vip会员："}
                            {
                                vipStatus.level === 0 ?
                                    "未开通过vip会员"
                                    :
                                    moment(utcTimeFormat).isBefore(moment(vipStatus.toTime).format("YYYY-MM-DD")) ?
                                        (moment(vipStatus.toTime).format("YYYY-MM-DD") + " 到期") : " 已逾期"
                            }
                        </div>
                    </div>
                </div>
                <div className={"pay-plugin-tab-wrap"}>
                    <div className={"pay-plugin-tab-item"}>
                        {"Vip会员套餐"}
                    </div>
                </div>
                <div className={"pay-plugin-price-list-wrap"}>
                    <div
                        className={selectVip.selectType === 1 ? "pay-plugin-price-item-active" : "pay-plugin-price-item"}
                        onClick={() => {
                            this.switchSelectPrice(selectVip, 1, 1200, 365)
                        }}
                    >
                        <div className={"pay-plugin-price-name"}>
                            {"年度会员"}
                        </div>
                        <div className={"pay-plugin-price-num"}>
                            {"￥1200"}
                        </div>
                    </div>
                    <div
                        className={selectVip.selectType === 2 ? "pay-plugin-price-item-active" : "pay-plugin-price-item"}
                        onClick={() => {
                            this.switchSelectPrice(selectVip, 2, 600, 180)
                        }}
                    >
                        <div className={"pay-plugin-price-name"}>
                            {"半年度会员"}
                        </div>
                        <div className={"pay-plugin-price-num"}>
                            {"￥600"}
                        </div>
                    </div>
                    <div
                        className={selectVip.selectType === 3 ? "pay-plugin-price-item-active" : "pay-plugin-price-item"}
                        onClick={() => {
                            this.switchSelectPrice(selectVip, 3, 300, 90)
                        }}
                    >
                        <div className={"pay-plugin-price-name"}>
                            {"季度会员"}
                        </div>
                        <div className={"pay-plugin-price-num"}>
                            {"￥300"}
                        </div>
                    </div>
                    <div
                        className={selectVip.selectType === 4 ? "pay-plugin-price-item-active" : "pay-plugin-price-item"}
                        onClick={() => {
                            this.switchSelectPrice(selectVip, 4, 100, 30)
                        }}
                    >
                        <div className={"pay-plugin-price-name"}>
                            {"月度会员"}
                        </div>
                        <div className={"pay-plugin-price-num"}>
                            {"￥100"}
                        </div>
                    </div>
                </div>
                <div className={"pay-plugin-deduction-wrap"}>
                    <div className={"pay-plugin-deduction-txt"}>
                        {"可抵扣的湃通币：" + userInfo['userCoin']}
                    </div>
                    <div className={"pay-plugin-deduction-check"}>
                        <input className={""}
                               type={"checkbox"}
                               checked={isDeduct}
                               disabled={userInfo['userCoin'] < 100}
                               onChange={() => {
                                   this.handleDeductCoin(isDeduct, payStatus, userInfo['userCoin'], selectVip)
                               }}
                        />
                        <div className={"pay-plugin-deduction-txt"}
                             style={{marginLeft: '8px'}}
                        >
                            {"是否抵扣"}
                        </div>
                    </div>
                </div>
                <div className={"pay-plugin-pay-wrap"}>
                    <div className={"pay-plugin-qr-num"}>
                        {"￥" + payStatus.rmbPrice}
                    </div>
                    <button className={"pay-plugin-pay-btn"}
                            onClick={() =>{
                                this.handlePayPre(2, 1, selectVip.selectDays)
                                // this.testA();
                            }}
                    >
                        {"点击支付"}
                    </button>
                </div>
                <div className={"modal-close-wrap"}>
                    <CloseOutlined onClick={() => {delWin(parentId, winId)}}/>
                </div>
            </div>
        );
    }
}

export default VipWin;