import React from 'react';
import "./page.css";
import QueueAnim from "rc-queue-anim";
import {PoweroffOutlined, RightOutlined} from "@ant-design/icons";
import {
    AboutRoot,
    CenterRoot,
    DemandRoot,
    ExpertRoot, filterArea, filterAreaTech, filterExpert, filterSrcTypeBySub, filterTitle, filterType,
    handleClickMonitor, handleLogout,
    HomeRoot,
    LoginRoot,
    NewsRoot,
    OutcomeRoot, outcomeType,
    PageId,
    PolicyRoot,
    RegisterRoot,
    SolutionRoot,
    UserInfoStorage
} from "../tool/Const";
import {Link} from "react-router-dom";
import LOGO from "../assets/icon/logo-blue.svg";
import SUESSP from "../assets/official/suessp.jpg";
import TheNationalUniversitySciencePark from "../assets/official/TheNationalUniversitySciencePark.jpg";

const solutions = () => {
    return (
        <div className={"solution-outer"}>
            <div className={"solution-menu"}>
                <div className={"solution-line"} id={"en"} style={{height: "30px", color: "rgb(97,130,165)"}}>
                    <div className={"expert-chara"}>
                        <span>{"全方位资源覆盖 全要素协调发展 全科技生态打造"}</span>
                    </div>
                </div>
                <div className={"solution-menu-ct"}>
                    <div className={"solution-line"} id={"eq"}>
                        <div className={"expert-chara"} style={{width: "160px", marginRight: "20px"}}>
                            <span>{"企业技术创新"}</span>
                            <div style={{height: "100%", width: "20px"}}/>
                            <div className={"title-sp"}/>
                        </div>
                        <span className={"solution-item"} style={{marginRight: '10px'}}>{"校企产学研合作"}</span>
                        <span className={"solution-item"} style={{marginRight: '10px'}}>{"共建企业技术中心"}</span>
                        <span className={"solution-item"} style={{marginRight: '10px'}}>{"共建联合实验室"}</span>
                        <span className={"solution-item"} style={{marginRight: '10px'}}>{"专精特新咨询申报"}</span>
                        <span className={"solution-item"} style={{marginRight: '10px'}}>{"科技奖项申报"}</span>
                        <span className={"solution-item"} style={{marginRight: '10px'}}>{"研发情报指南"}</span>
                        <span className={"solution-item"} style={{marginRight: '10px'}}>{"知识产权分析布局"}</span>
                        <span className={"solution-item"} style={{marginRight: '10px'}}>{"专家云会议"}</span>
                        <span className={"solution-item"} style={{marginRight: '10px'}}>{"专家面对面"}</span>
                    </div>
                    <div className={"solution-line"} id={"ep"}>
                        <div className={"expert-chara"} style={{width: "160px", marginRight: "20px"}}>
                            <span>{"政府协调创新"}</span>
                            <div style={{height: "100%", width: "20px"}}/>
                            <div className={"title-sp"}/>
                        </div>
                        <span className={"solution-item"}>{"区域技术服务平台"}</span>
                        <span className={"solution-item"}>{"科创飞地运营"}</span>
                        <span className={"solution-item"}>{"科技创新活动"}</span>
                        <span className={"solution-item"}>{"政策解读与培训"}</span>
                        <span className={"solution-item"}>{"产地园区运营"}</span>
                        <span className={"solution-item"}>{"科技孵化器运营"}</span>
                        <span className={"solution-item"}>{"众创空间运营"}</span>
                    </div>
                    <div className={"solution-line"} id={"ec"}>
                        <div className={"expert-chara"} style={{width: "160px", marginRight: "20px"}}>
                            <span>{"院校科研创新"}</span>
                            <div style={{height: "100%", width: "20px"}}/>
                            <div className={"title-sp"}/>
                        </div>
                        <span className={"solution-item"}>{"技术转移分中心"}</span>
                        <span className={"solution-item"}>{"新型研发机构"}</span>
                        <span className={"solution-item"}>{"科技工作站"}</span>
                        <span className={"solution-item"}>{"科技成果评估"}</span>
                        <span className={"solution-item"}>{"检测与认证服务"}</span>
                        <span className={"solution-item"}>{"校外实训基地"}</span>
                    </div>
                    <div className={"solution-line"} id={"et"}>
                        <div className={"expert-chara"} style={{width: "160px", marginRight: "20px"}}>
                            <span>{"科技人才创新"}</span>
                            <div style={{height: "100%", width: "20px"}}/>
                            <div className={"title-sp"}/>
                        </div>
                        <span className={"solution-item"}>{"技术智囊团"}</span>
                        <span className={"solution-item"}>{"科研人才招聘"}</span>
                        <span className={"solution-item"}>{"创新人才工作站"}</span>
                        <span className={"solution-item"}>{"技术经纪人培训"}</span>
                        <span className={"solution-item"}>{"企业技术人员培训"}</span>
                        <span className={"solution-item"}>{"高层次人才引进"}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const expertResult = () => {
    return (
        <div className={"menu-item-pg-ot"}>
            <div className={"menu-item-pg"}>
                <div className={"tech-header"}>
                    <div className={"expert-chara"} style={{width: "52%"}}>
                        <div className={"title-sp"}/>
                        {"研究领域"}
                    </div>
                    <div className={"expert-chara"} style={{width: "34%"}}>
                        <div className={"title-sp"}/>
                        {"专家职称"}
                    </div>
                    <div className={"expert-chara"} style={{width: "14%"}}>
                        <div className={"title-sp"}/>
                        {"所属机构"}
                    </div>
                </div>
                <div style={{height: "86%", width: "100%", display: "flex"}}>
                    <div className={"expert-item-area-ot"}>
                        {loadExpertFilter(filterArea, 13, "area", "menu-item-area")}
                        <span className={"menu-item-area-more"}>
                            <span
                                onClick={() => {
                                    window.location.href = ExpertRoot.path
                                }}
                            >{"更多研究领域"}</span>
                            <RightOutlined style={{fontSize: "14px", color: "#0070ff"}}/>
                        </span>
                        {/*<div className={"expert-area-col"}>*/}
                        {/*    <span className={"menu-item-name"}*/}
                        {/*          onClick={() => {window.location.href = ExpertRoot.path + "?area=" + "0"}}*/}
                        {/*    >{"数学与统计学类"}</span>*/}
                        {/*    <span className={"menu-item-name"}>{"物理学与力学类"}</span>*/}
                        {/*    <span className={"menu-item-name"}>{"化学与化工类"}</span>*/}
                        {/*    <span className={"menu-item-name"}>{"地理地质学与矿业类"}</span>*/}
                        {/*    <span className={"menu-item-name"}>{"水利与海洋科学类"}</span>*/}
                        {/*</div>*/}
                        {/*<div className={"expert-area-col"}>*/}
                        {/*    <span className={"menu-item-name"}>{"生物医学与生物工程类"}</span>*/}
                        {/*    <span className={"menu-item-name"}>{"工业工程与机械类"}</span>*/}
                        {/*    <span className={"menu-item-name"}>{"仪器与材料类"}</span>*/}
                        {/*    <span className={"menu-item-name"}>{"能源动力类"}</span>*/}
                        {/*    <span className={"menu-item-name"}>{"电子信息与计算机类"}</span>*/}
                        {/*</div>*/}
                        {/*<div className={"expert-area-col"}>*/}
                        {/*    <span className={"menu-item-name"}>{"电气与自动化类"}</span>*/}
                        {/*    <span className={"menu-item-name"}>{"土木建筑与测绘类"}</span>*/}
                        {/*    <span className={"menu-item-name"}>{"交通运输与物流管理类"}</span>*/}
                        {/*    <span className={"menu-item-name"}>{"电子商务类"}</span>*/}
                        {/*    <span className={"menu-item-name-more"}>*/}
                        {/*        <span*/}
                        {/*            onClick={() => {*/}
                        {/*                window.location.href = ExpertRoot.path*/}
                        {/*            }}*/}
                        {/*        >{"更多研究领域"}</span>*/}
                        {/*        <RightOutlined style={{fontSize: "14px", color: "#0070ff"}}/>*/}
                        {/*    </span>*/}
                        {/*</div>*/}
                        <div style={{height: "100%", width: "4%"}}/>
                    </div>
                    <div style={{height: "100%", width: "2%"}}/>
                    <div className={"expert-item-job-ot"}>
                        {loadExpertFilter(filterTitle, 8, "title", "menu-item-job")}
                        <span className={"menu-item-job-more"}>
                            <span
                                onClick={() => {
                                    window.location.href = ExpertRoot.path
                                }}
                            >{"更多专家职称"}</span>
                            <RightOutlined style={{fontSize: "14px", color: "#0070ff"}}/>
                        </span>
                    </div>

                    {/*<div className={"expert-item-job-ot"}>*/}
                    {/*    {loadExpertFilter(filterTitle, 8, "menu-item-job")}*/}
                    {/*    <span className={"menu-item-name"}>{"正高级工程师"}</span>*/}
                    {/*    <span className={"menu-item-name"}>{"高级工程师"}</span>*/}
                    {/*    <span className={"menu-item-name"}>{"教授"}</span>*/}
                    {/*    <span className={"menu-item-name"}>{"副教授"}</span>*/}
                    {/*    <span className={"menu-item-name"}>{"研究员"}</span>*/}
                    {/*</div>*/}
                    {/*<div className={"expert-item-job-ot"}>*/}
                    {/*    <span className={"menu-item-name"}>{"副研究员"}</span>*/}
                    {/*    <span className={"menu-item-name"}>{"主任医师"}</span>*/}
                    {/*    <span className={"menu-item-name"}>{"正高级教师"}</span>*/}
                    {/*    <span className={"menu-item-name"}>{"主任技师"}</span>*/}
                    {/*    <span className={"menu-item-name-more"}>*/}
                    {/*        <span*/}
                    {/*            onClick={() => {*/}
                    {/*                window.location.href = ExpertRoot.path*/}
                    {/*            }}*/}
                    {/*        >{"更多专家职称"}</span>*/}
                    {/*        <RightOutlined style={{fontSize: "14px", color: "#0070ff"}}/>*/}
                    {/*    </span>*/}
                    {/*</div>*/}
                    <div style={{height: "100%", width: "6%"}}/>
                    <div className={"expert-item-place-ot"}>
                        {loadExpertFilter(filterSrcTypeBySub, 3, "from", "menu-item-place")}
                        {/*<span className={"menu-item-name"}>{"高等院校"}</span>*/}
                        {/*<span className={"menu-item-name"}>{"研究院所"}</span>*/}
                        {/*<span className={"menu-item-name"}>{"企业机构"}</span>*/}
                        <span className={"menu-item-place"}>{""}</span>
                        <span className={"menu-item-place-more"}>
                        <span
                            onClick={() => {
                                window.location.href = ExpertRoot.path
                            }}
                        >{"更多专家来源"}</span>
                        <RightOutlined style={{fontSize: "14px", color: "#0070ff"}}/>
                    </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const loadExpertFilter = (data, num, param, classWrap) => {
    let ui = [];
    data.map((item, index) => {
        ui.push(
            <span className={classWrap}
                  key={item + index}
                  style={{display: index > num ? "none" : ""}}
                  onClick={() => {
                      window.location.href = ExpertRoot.path + "?" + param + "=" + index
                  }}
            >
                {item.value}
            </span>
        )
    });
    return ui;
}

const techResult = (downs, parent, cur) => {
    return (
        <div className={"menu-item-pg"}>
            <div className={"tech-header"}>
                <div style={{display: "flex"}}>
                    <div className={"title-sp"}/>
                    {"技术领域"}
                </div>
                <select className={"menu-type-dwn"} value={cur} onChange={(e) => parent.setType(e, parent)}>
                    {downs}
                </select>
            </div>
            <div className={"menu-item-lst"}>
                {loadOutcomeFilter(filterAreaTech, 20, "menu-item-name", parent)}
            </div>

            {/*<div className={"menu-item-lst"}>*/}
            {/*    <div className={"tech-item-column"}>*/}
            {/*        <span className={"menu-item-name"}>{"生物科技"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"医药和医疗器械"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"新材料及其应用"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"新一代电子信息"}</span>*/}
            {/*    </div>*/}
            {/*    <div className={"tech-item-column"}>*/}
            {/*        <span className={"menu-item-name"}>{"先进制造与自动化"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"现代农业"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"航空航天技术"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"绿色化工技术"}</span>*/}
            {/*    </div>*/}
            {/*    <div className={"tech-item-column"}>*/}
            {/*        <span className={"menu-item-name"}>{"新能源与高效节能"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"核应用技术"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"环境保护与资源利用"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"现代交通技术"}</span>*/}
            {/*    </div>*/}
            {/*    <div className={"tech-item-column"}>*/}
            {/*        <span className={"menu-item-name"}>{"城市建设和社会发展"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"海洋开发"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"文化体育与娱乐"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"食品饮料"}</span>*/}
            {/*    </div>*/}
            {/*    <div className={"tech-item-column"}>*/}
            {/*        <span className={"menu-item-name"}>{"轻工纺织"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"芯片与集成电路"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"人工智能与大数据"}</span>*/}
            {/*        <span className={"menu-item-name"}>{"其他"}</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={"menu-more-info"}>
                <span onClick={() => {
                    window.location.href = OutcomeRoot.path
                }}>{"查看更多成果"}</span>
                <RightOutlined style={{fontSize: "16px", color: "#0070ff"}}/>
            </div>
        </div>
    )
}

const loadOutcomeFilter = (data, num, classWrap, dom) => {
    let ui = [];
    let outcomeType = dom.state.outcomeType;
    data.map((item, index) => {
        ui.push(
            <span className={classWrap}
                  key={item + index}
                  style={{display: index > num ? "none" : ""}}
                  onClick={() => {
                      window.location.href = OutcomeRoot.path + "?type=" + outcomeType + "&area=" + index
                  }}
            >
                {item.value}
            </span>
        )
    });
    return ui;
}

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            label: 0,
            mLine: "3px solid rgb(11, 101, 236)",
            outcomeType: 0,
        }
        this.timeout = null;
    }

    loadingDown = (options) => {
        let downs = [];
        options.forEach((op, index) => {
            downs.push(<option value={op.value} key={op.value} id={index}>
                {op.label}
            </option>)
        })
        return downs;
    }

    MouseEnter(label) {
        this.timeout = setTimeout(() => {
            let labelC = this.state.label;
            if (labelC !== label) {
                this.setState({
                    label: label
                })
            }
        }, 100)
    }

    setType = (e) => {
        let val = e.target.value - 1;
        this.setState({
            outcomeType: val,
        })
    }

    MouseLeave(label) {
        clearTimeout(this.timeout);
        this.timeout = null;
        this.timeout = setTimeout(() => {
            let labelC = this.state.label;
            if (labelC !== label) {
                this.setState({
                    label: label
                })
            }
        }, 100)
    }

    findMenuItems = (label) => {
        if (label === 1) {
            return techResult(this.loadingDown(filterType), this, this.state.outcomeType);
        } else if (label === 3) {
            return expertResult();
        } else {
            return solutions();
        }
    }

    loadingMenu = (label) => {
        let isMenuDown = label === 1 || label === 3 || label === 5;
        let move = label === 1 ? -303 : -323;
        return (
            <div className={"menu-layout"}
                 onMouseEnter={this.MouseEnter.bind(this, label)}
                 onMouseLeave={this.MouseLeave.bind(this, 0)}
            >
                <div className={"menu-ct"}>
                    <div style={{height: "3px", width: "100%", background: "#3c80d4", position: "absolute"}}/>
                    <QueueAnim type={"top"} animConfig={{translateY: [0, move]}}
                               duration={500} delay={100}>

                        {isMenuDown ? <div className={"menu-ct-up"} key={"menu-move"}>
                            {this.findMenuItems(label)}
                        </div> : null}
                    </QueueAnim>
                </div>
            </div>

        )
    }

    handlerRedirect = () => {
        let fromUrl = window.location.href;
        let length = fromUrl.split("/").length;
        // console.log(fromUrl);
        // console.log(length);
        if (length === 5) {
            return window.location.href;
        } else {
            return null;
        }

        // let fromUrl = window.location.href;
        // let length = fromUrl.split("/").length;
        // this.props.history.push(
        //     LoginRoot,
        //     {from: length === 5 ? fromUrl : null}
        // );
        // window.location.href = LoginRoot
    }

    render() {
        // console.log(this.state.outcomeType)
        let label = this.state.label;
        let userInfo = UserInfoStorage.get("userInfo");

        // console.log(label)
        return [<div className={"out-content"} style={{position: "fixed", zIndex: "999"}} id={"header"}>
            <div className={"header-ot"}>
                <div className={"header-top"}>
                    <div className={"header-tp-ct"}>
                        <div className={"header-op"}>
                            <div className={"header-op-left"}>
                                {
                                    userInfo ?
                                        <div className={"header-item"}>
                                            {userInfo.userNick}
                                        </div>
                                        :
                                        <Link to={{
                                            pathname: LoginRoot,
                                            state: {from: this.handlerRedirect()}
                                        }}
                                              className={"header-item"}
                                        >
                                            {"登录"}
                                        </Link>

                                }
                                <div className={"header-item"}>
                                    {"|"}
                                </div>
                                {
                                    userInfo ?
                                        <div className={"header-item"} style={{width: 'auto', cursor: "pointer"}}
                                             onClick={() => {
                                                 window.location.href = CenterRoot
                                             }}>
                                            {"个人中心"}
                                        </div>
                                        :
                                        <div className={"header-item"} style={{cursor: "pointer"}}
                                             onClick={() => {
                                                 window.location.href = RegisterRoot
                                             }}>
                                            {"注册"}
                                        </div>
                                }
                            </div>
                            {
                                userInfo ?
                                    <div className={"header-op-right"}
                                         style={{width: 'auto', cursor: "pointer"}}
                                         onClick={() => {
                                             handleLogout(userInfo)
                                         }}
                                    >
                                        <div className={"logout-icon"}>
                                            <PoweroffOutlined/>
                                        </div>
                                        <div className={"header-item"}>
                                            {"退出"}
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div style={{width: "100%", display: "flex", alignItems: "center", height: "80px"}}>
                    <div className={"header-logo-wrap"}>
                        <img className={"sues-img"} src={SUESSP} alt={"suess"}/>
                        <div className={"header-logo-top"}>
                            <img className={"tusp-img"} src={TheNationalUniversitySciencePark} alt={"tusp"}/>
                            <div className={"header-logo"}
                                 onClick={() => {
                                     window.location.href = HomeRoot
                                 }}
                            >
                                {/*{"Logo 位置"}*/}
                                <img className={"header-logo-img"} src={LOGO} alt={"logo"}/>
                            </div>
                        </div>
                    </div>

                    <div className={"header-bottom-outer"}>
                        <div className={"header-bottom"}>

                            <div className={"header-bt-ct"}>
                                {/*<div className={"header-slogan"}>*/}
                                {/*    <div className={"header-logo"}>*/}
                                {/*        /!*{"Logo 位置"}*!/*/}
                                {/*        <img className={"img-style contain"} src={LOGO} alt={"logo"}/>*/}
                                {/*    </div>*/}
                                {/*<div className={"header-topic"}>*/}
                                {/*    {"长三角科学技术导向平台"}*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                <div className={"header-menu"}>
                                    <div className={"menu-item"}
                                         onClick={() => {
                                             handleClickMonitor(userInfo, PageId.home, 1, 1, HomeRoot)
                                         }}
                                    >
                                        <span style={label === 8 ? {color: "#0070ff"} : {}}>{"首页"}</span>
                                    </div>
                                    <div className={"menu-item"}
                                         onMouseEnter={this.MouseEnter.bind(this, 1)}
                                         onMouseLeave={this.MouseLeave.bind(this, 0)}
                                         onClick={() => {
                                             handleClickMonitor(userInfo, PageId.outcome, 1, 1, OutcomeRoot.path)
                                         }}
                                    >
                                        <span style={label === 1 ? {color: "#0070ff"} : {}}>{"科技成果"}</span>
                                    </div>
                                    <div className={"menu-item"}
                                         onClick={() => {
                                             handleClickMonitor(userInfo, PageId.demand, 1, 1, DemandRoot.path)
                                         }}
                                    >
                                        <span style={label === 2 ? {color: "#0070ff"} : {}}>{"技术需求"}</span>
                                    </div>
                                    <div className={"menu-item"}
                                         onMouseEnter={this.MouseEnter.bind(this, 3)}
                                         onMouseLeave={this.MouseLeave.bind(this, 0)}
                                         onClick={() => {
                                             handleClickMonitor(userInfo, PageId.expert, 1, 1, ExpertRoot.path)
                                         }}
                                    >
                                        <span style={label === 3 ? {color: "#0070ff"} : {}}>{"专家人才"}</span>
                                    </div>
                                    <div className={"menu-item"}
                                         onMouseEnter={this.MouseEnter.bind(this, 5)}
                                         onMouseLeave={this.MouseLeave.bind(this, 0)}
                                         onClick={() => {
                                             handleClickMonitor(userInfo, PageId.solution, 1, 1, SolutionRoot)
                                         }}
                                    >
                                        <span style={label === 5 ? {color: "#0070ff"} : {}}>{"解决方案"}</span>
                                    </div>
                                    <div className={"menu-item"}
                                         onClick={() => {
                                             handleClickMonitor(userInfo, PageId.policy, 1, 1, PolicyRoot.path)
                                         }}
                                    >
                                        <span style={label === 4 ? {color: "#0070ff"} : {}}>{"科技政策"}</span>
                                    </div>
                                    <div className={"menu-item"}
                                         onClick={() => {
                                             handleClickMonitor(userInfo, PageId.news, 1, 1, NewsRoot.path)
                                         }}
                                    >
                                        <span style={label === 6 ? {color: "#0070ff"} : {}}>{"科技资讯"}</span>
                                    </div>
                                    <div className={"menu-item"}
                                         onClick={() => {
                                             handleClickMonitor(userInfo, PageId.about, 1, 1, AboutRoot)
                                             // window.location.href = AboutRoot
                                         }}
                                    >
                                        <span style={label === 7 ? {color: "#0070ff"} : {}}>{"关于平台"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>, <div style={{height: "120px"}} id={"space"}/>, <div>{this.loadingMenu(label)}</div>]
    }
}

export default Header;
