import React, {Component} from 'react';
import "./outcome.css";
import "../../tool/tool.css"
import FilterHeader from "../../tool/filter/FilterHeader";
import Bk from "../pic/outcomeBk2.png"
import GeoFilter from "../../tool/filter/GeoFilter";
import FilterMain from "../../tool/filter/FilterMain";
import FilterTags from "../../tool/filter/FilterTags";
import {AreaMap, filterOutcome} from "../../tool/Const";

const filterCt = [
    {
        title: "类型",
        ct: ["发明专利", "实用新型专利", "外观设计专利", "软件著作权"],
        ip: 'type',
    },
    {
        title: "技术来源",
        ct: ["全部", "高等院校", "科研院所", "企业"],
        ip: 'from',
    },
    {
        title: "成熟度",
        ct: ["全部", "研制阶段", "小试阶段", "中试阶段", "量产阶段", "其他"],
        ip: 'level',
    },
    {
        title: "行业分类",
        ct: ["全部", "生物科技", "医药和医疗器械", "新材料及其应用", "新一代电子信息", "先进制造与自动化", "现代农业", "航空航天技术",
            "绿色化工技术", "新能源与高效节能", "核应用技术", "环境保护与资源利用", "现代交通技术", "城市建设和社会发展", "海洋开发",
            "文化体育与娱乐", "食品饮料", "轻工纺织", "芯片与集成电路", "人工智能与大数据", "其他"],
        ip: 'area',
    },
    {
        title: "合作方式",
        ct: ["全部", "技术转让", "委托开发", "合作开发", "专利许可", "技术入股", "其他"],
        ip: 'method',
    },
]

const TagList = ['from', 'level', 'area', 'method']

const filterMainStyle = {
    header: "filter-header",
    title: "filter-search-title",
    input: "filter-input",
    tag: "filter-tag",
    search: "filter-search-bt",
    outer: "filter-search",
}

class OutcomeFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectItem: -1,
            openGeo: false,
            showCity: -1,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let item = this.state.selectItem;
        if (prevState.selectItem !== item) {
            this.props.parent.setState({
                openSize: item !== -1 ? 288 : 0,
            })
        }
    }

    setOptions = (options) => {
        this.props.parent.setState({
            options: Object.assign({}, options)
        })
    }

    render() {
        let options = this.props.options;
        let initTag = this.props.initTag;
        return (

            <div style={{minHeight: "570px", width: "100%", background: "white"}} id={"filter"}>
                <div className={"outcome-bk"}>
                    <img className={"outcome-banner-img"} src={Bk} alt={""}/>
                    <div className={"outcome-filter"}>
                        <FilterHeader page={"outcome"} parent={this} filterStyle={filterMainStyle} options={options}/>
                    </div>
                </div>
                <div className={"outcome-filter-ct"}>
                    <div className={"filter-outer"}>
                        <div style={{height: "8px", width: "100%"}}/>
                        <GeoFilter options={options} parent={this}
                                   areaClass={"filter-area"} titleClass={"filter-area-geo-title"}
                                   map={AreaMap} isIcon={true}
                                   mainType={"全国所有省市"} header={"地理位置:"}/>
                        <FilterMain options={options} parent={this} filterCt={filterOutcome} initTag={initTag}/>
                        <div style={{height: "14px", width: "100%"}}/>
                    </div>
                </div>
                <div className={"filter-selected-tags"} id={"o-tag"}>
                    <FilterTags options={options} parent={this} initTag={initTag} tags={TagList}
                                titleType={options.type} total={this.props.total}/>
                </div>
            </div>
        )
    }
}

export default OutcomeFilter;
