import React, {Fragment} from 'react';
import "./../page.css";
import QueueAnim from "rc-queue-anim";
import COM_BG from "../../assets/home/solutionCompany/bg.png";
import COM_IMG_1 from "../../assets/home/solutionCompany/551_105 601_65.png";
import COM_IMG_2 from "../../assets/home/solutionCompany/204_157 254_117.png";
import COM_IMG_3 from "../../assets/home/solutionCompany/118_433 169_392.png";
import COM_IMG_4 from "../../assets/home/solutionCompany/445_541 494_501.png";
import COM_IMG_5 from "../../assets/home/solutionCompany/946_455 997_416.png";
import COM_IMG_6 from "../../assets/home/solutionCompany/983_222 1034_182.png";
import COM_IMG_7 from "../../assets/home/solutionCompany/713_214 764_173.png";
import COM_IMG_8 from "../../assets/home/solutionCompany/290_298 339_257.png";
import COM_IMG_9 from "../../assets/home/solutionCompany/646_426 696_384.png";
import COM_IMG_0 from "../../assets/home/solutionCompany/536_303 603_213.png";
import COM_CT_0 from "../../assets/home/solutionCompany/ct_0.png";
import COM_CT_1 from "../../assets/home/solutionCompany/ct_1.png";
import COM_CT_2 from "../../assets/home/solutionCompany/ct_2.png";
import COM_CT_3 from "../../assets/home/solutionCompany/ct_3.png";
import COM_CT_4 from "../../assets/home/solutionCompany/ct_4.png";
import COM_CT_5 from "../../assets/home/solutionCompany/ct_5.png";
import COM_CT_6 from "../../assets/home/solutionCompany/ct_6.png";
import COM_CT_7 from "../../assets/home/solutionCompany/ct_7.png";
import COM_CT_8 from "../../assets/home/solutionCompany/ct_8.png";
import COM_CT_9 from "../../assets/home/solutionCompany/ct_9.png";
import GOV_BG from "../../assets/home/solutionGovernment/bg.png";
import GOV_IMG_0 from "../../assets/home/solutionGovernment/mod_0.png";
import GOV_IMG_1 from "../../assets/home/solutionGovernment/mod_1.png";
import GOV_IMG_2 from "../../assets/home/solutionGovernment/mod_2.png";
import GOV_IMG_3 from "../../assets/home/solutionGovernment/mod_3.png";
import GOV_IMG_4 from "../../assets/home/solutionGovernment/mod_4.png";
import GOV_IMG_5 from "../../assets/home/solutionGovernment/mod_5.png";
import GOV_IMG_6 from "../../assets/home/solutionGovernment/mod_6.png";
import GOV_IMG_7 from "../../assets/home/solutionGovernment/mod_7.png";
import GOV_CT_0 from "../../assets/home/solutionGovernment/ct_0.png";
import GOV_CT_1 from "../../assets/home/solutionGovernment/ct_1.png";
import GOV_CT_2 from "../../assets/home/solutionGovernment/ct_2.png";
import GOV_CT_3 from "../../assets/home/solutionGovernment/ct_3.png";
import GOV_CT_4 from "../../assets/home/solutionGovernment/ct_4.png";
import GOV_CT_5 from "../../assets/home/solutionGovernment/ct_5.png";
import GOV_CT_6 from "../../assets/home/solutionGovernment/ct_6.png";
import GOV_CT_7 from "../../assets/home/solutionGovernment/ct_7.png";
import ACD_BG from "../../assets/home/solutionAcademy/bg.png";
import ACD_IMG_0 from "../../assets/home/solutionAcademy/mod_0.png";
import ACD_IMG_1 from "../../assets/home/solutionAcademy/mod_1.png";
import ACD_IMG_2 from "../../assets/home/solutionAcademy/mod_2.png";
import ACD_IMG_3 from "../../assets/home/solutionAcademy/mod_3.png";
import ACD_IMG_4 from "../../assets/home/solutionAcademy/mod_4.png";
import ACD_IMG_5 from "../../assets/home/solutionAcademy/mod_5.png";
import ACD_IMG_6 from "../../assets/home/solutionAcademy/mod_6.png";
import ACD_CT_0 from "../../assets/home/solutionAcademy/ct_0.png";
import ACD_CT_1 from "../../assets/home/solutionAcademy/ct_1.png";
import ACD_CT_2 from "../../assets/home/solutionAcademy/ct_2.png";
import ACD_CT_3 from "../../assets/home/solutionAcademy/ct_3.png";
import ACD_CT_4 from "../../assets/home/solutionAcademy/ct_4.png";
import ACD_CT_5 from "../../assets/home/solutionAcademy/ct_5.png";
import ACD_CT_6 from "../../assets/home/solutionAcademy/ct_6.png";
import TAL_BG from "../../assets/home/solutionTalent/bg.png";
import TAL_IMG_0 from "../../assets/home/solutionTalent/mod_0.png";
import TAL_IMG_1 from "../../assets/home/solutionTalent/mod_1.png";
import TAL_IMG_2 from "../../assets/home/solutionTalent/mod_2.png";
import TAL_IMG_3 from "../../assets/home/solutionTalent/mod_3.png";
import TAL_IMG_4 from "../../assets/home/solutionTalent/mod_4.png";
import TAL_IMG_5 from "../../assets/home/solutionTalent/mod_5.png";
import TAL_IMG_6 from "../../assets/home/solutionTalent/mod_6.png";
import TAL_CT_1 from "../../assets/home/solutionTalent/ct_1.png";
import TAL_CT_2 from "../../assets/home/solutionTalent/ct_2.png";
import TAL_CT_3 from "../../assets/home/solutionTalent/ct_3.png";
import TAL_CT_4 from "../../assets/home/solutionTalent/ct_4.png";
import TAL_CT_5 from "../../assets/home/solutionTalent/ct_5.png";
import TAL_CT_6 from "../../assets/home/solutionTalent/ct_6.png";
import COM_DERAIL_1 from "../../assets/home/solutionCompany/jishuchuangxin.png";
import COM_DERAIL_2 from "../../assets/home/solutionCompany/xiangmushenbao.png";
import COM_DERAIL_3 from "../../assets/home/solutionCompany/duoyanghuayuyue.png";
import GOV_DERAIL_1 from "../../assets/home/solutionGovernment/shichanghuayunying.png";
import GOV_DERAIL_2 from "../../assets/home/solutionGovernment/chuangxinpingtai.png";
import GOV_DERAIL_3 from "../../assets/home/solutionGovernment/kejihuod .png";
import GOV_DERAIL_4 from "../../assets/home/solutionGovernment/zhengcejiedu.png";
import ACD_DERAIL_1 from "../../assets/home/solutionAcademy/kejichengguo.png";
import ACD_DERAIL_2 from "../../assets/home/solutionAcademy/jiehechanye.png";
import ACD_DERAIL_3 from "../../assets/home/solutionAcademy/zhuanyetuandui.png";
import TAL_DERAIL_1 from "../../assets/home/solutionTalent/zhenduishaixuan.png";
import TAL_DERAIL_2 from "../../assets/home/solutionTalent/peiyang.png";

const selectStyle = {paddingLeft: '2px', width: "calc(20% - 3px)", borderLeft: "3px solid rgb(60, 128, 212)", color: "#2f2f2f"};

const comStyle = [
    {img: COM_IMG_0, corX: 'calc(520 / 1200 * 100%)', corY: 'calc(200 / 600 * 100%)', ctImg: COM_CT_0, ctCorX: 'calc(19% / 2)', ctCorY: '-40%'},
    {img: COM_IMG_1, corX: 'calc(552 / 1200 * 100%)', corY: 'calc(60 / 600 * 100%)', ctImg: COM_CT_1, ctCorX: '', ctCorY: '-60%'},
    {img: COM_IMG_2, corX: 'calc(206 / 1200 * 100%)', corY: 'calc(126 / 600 * 100%)', ctImg: COM_CT_2, ctCorX: '', ctCorY: '-60%'},
    {img: COM_IMG_3, corX: 'calc(120 / 1200 * 100%)', corY: 'calc(385 / 600 * 100%)', ctImg: COM_CT_3, ctCorX: '', ctCorY: '-60%'},
    {img: COM_IMG_4, corX: 'calc(445 / 1200 * 100%)', corY: 'calc(498 / 600 * 100%)', ctImg: COM_CT_4, ctCorX: '', ctCorY: '-60%'},
    {img: COM_IMG_5, corX: 'calc(935 / 1200 * 100%)', corY: 'calc(405 / 600 * 100%)', ctImg: COM_CT_5, ctCorX: '', ctCorY: '-60%'},
    {img: COM_IMG_6, corX: 'calc(965 / 1200 * 100%)', corY: 'calc(185 / 600 * 100%)', ctImg: COM_CT_6, ctCorX: '', ctCorY: '-60%'},
    {img: COM_IMG_7, corX: 'calc(702 / 1200 * 100%)', corY: 'calc(172 / 600 * 100%)', ctImg: COM_CT_7, ctCorX: '', ctCorY: '-60%'},
    {img: COM_IMG_8, corX: 'calc(295 / 1200 * 100%)', corY: 'calc(254 / 600 * 100%)', ctImg: COM_CT_8, ctCorX: '', ctCorY: '-60%'},
    {img: COM_IMG_9, corX: 'calc(640 / 1200 * 100%)', corY: 'calc(380 / 600 * 100%)', ctImg: COM_CT_9, ctCorX: '', ctCorY: '-60%'},
]

const govStyle = [
    {img: GOV_IMG_0, corX: 'calc(480 / 1200 * 100%)', corY: 'calc(60 / 600 * 100%)', ctImg: GOV_CT_0, ctCorX: 'calc(22% / 2)', ctCorY: '20%'},
    {img: GOV_IMG_1, corX: 'calc(125 / 1200 * 100%)', corY: 'calc(165 / 600 * 100%)', ctImg: GOV_CT_1, ctCorX: '-8%', ctCorY: '-75%'},
    {img: GOV_IMG_2, corX: 'calc(325 / 1200 * 100%)', corY: 'calc(320 / 600 * 100%)', ctImg: GOV_CT_2, ctCorX: '-15%', ctCorY: '-75%'},
    {img: GOV_IMG_3, corX: 'calc(150 / 1200 * 100%)', corY: 'calc(405/ 600 * 100%)', ctImg: GOV_CT_3, ctCorX: '-14%', ctCorY: '-73%'},
    {img: GOV_IMG_4, corX: 'calc(710 / 1200 * 100%)', corY: 'calc(160 / 600 * 100%)', ctImg: GOV_CT_4, ctCorX: '-7%', ctCorY: '-72%'},
    {img: GOV_IMG_5, corX: 'calc(980 / 1200 * 100%)', corY: 'calc(180 / 600 * 100%)', ctImg: GOV_CT_5, ctCorX: '-10%', ctCorY: '-75%'},
    {img: GOV_IMG_6, corX: 'calc(660 / 1200 * 100%)', corY: 'calc(380 / 600 * 100%)', ctImg: GOV_CT_6, ctCorX: '-8%', ctCorY: '-75%'},
    {img: GOV_IMG_7, corX: 'calc(965 / 1200 * 100%)', corY: 'calc(410 / 600 * 100%)', ctImg: GOV_CT_7, ctCorX: '-8%', ctCorY: '-75%'},
]

const acdStyle = [
    {img: ACD_IMG_0, corX: 'calc(407 / 1200 * 100%)', corY: 'calc(110 / 600 * 100%)', ctImg: ACD_CT_0, ctCorX: '14%', ctCorY: '-15%'},
    {img: ACD_IMG_1, corX: 'calc(380 / 1200 * 100%)', corY: 'calc(160 / 600 * 100%)', ctImg: ACD_CT_1, ctCorX: '-170%', ctCorY: '30%'},
    {img: ACD_IMG_2, corX: 'calc(180 / 1200 * 100%)', corY: 'calc(250 / 600 * 100%)', ctImg: ACD_CT_2, ctCorX: '-150%', ctCorY: '30%'},
    {img: ACD_IMG_3, corX: 'calc(300 / 1200 * 100%)', corY: 'calc(330 / 600 * 100%)', ctImg: ACD_CT_3, ctCorX: '-135%', ctCorY: '30%'},
    {img: ACD_IMG_4, corX: 'calc(780 / 1200 * 100%)', corY: 'calc(340 / 600 * 100%)', ctImg: ACD_CT_4, ctCorX: '', ctCorXR: '-150%', ctCorY: '60%'},
    {img: ACD_IMG_5, corX: 'calc(950 / 1200 * 100%)', corY: 'calc(250 / 600 * 100%)', ctImg: ACD_CT_5, ctCorX: '', ctCorXR: '-140%', ctCorY: '30%'},
    {img: ACD_IMG_6, corX: 'calc(750 / 1200 * 100%)', corY: 'calc(160 / 600 * 100%)', ctImg: ACD_CT_6, ctCorX: '', ctCorXR: '-140%', ctCorY: '30%'},
]

const talStyle = [
    {img: TAL_IMG_0, corX: 'calc(450 / 1200 * 100%)', corY: 'calc(70 / 600 * 100%)', ctImg: null},
    {img: TAL_IMG_1, corX: 'calc(250 / 1200 * 100%)', corY: 'calc(140 / 600 * 100%)', ctImg: TAL_CT_1, ctCorX: '-120%', ctCorY: '30%'},
    {img: TAL_IMG_2, corX: 'calc(200 / 1200 * 100%)', corY: 'calc(280 / 600 * 100%)', ctImg: TAL_CT_2, ctCorX: '-120%', ctCorY: '35%'},
    {img: TAL_IMG_3, corX: 'calc(400 / 1200 * 100%)', corY: 'calc(380 / 600 * 100%)', ctImg: TAL_CT_3, ctCorX: '-105%', ctCorY: '45%'},
    {img: TAL_IMG_4, corX: 'calc(680 / 1200 * 100%)', corY: 'calc(380 / 600 * 100%)', ctImg: TAL_CT_4, ctCorX: '', ctCorXR: '-90%', ctCorY: '45%'},
    {img: TAL_IMG_5, corX: 'calc(870 / 1200 * 100%)', corY: 'calc(280 / 600 * 100%)', ctImg: TAL_CT_5, ctCorX: '', ctCorXR: '-135%', ctCorY: '35%'},
    {img: TAL_IMG_6, corX: 'calc(830 / 1200 * 100%)', corY: 'calc(140 / 600 * 100%)', ctImg: TAL_CT_6, ctCorX: '', ctCorXR: '-120%', ctCorY: '30%'},
]

const comDetail = [
    {
        title: '技术创新一站式解决方案',
        img: COM_DERAIL_1,
    },
    {
        title: '项目申报专业化培训辅导',
        img: COM_DERAIL_2,
    },
    {
        title: '专家咨询多样化预约方式',
        img: COM_DERAIL_3,
    }
]

const govDetail = [
    {
        title: '创新要素市场化运营',
        img: GOV_DERAIL_1,
    },
    {
        title: '创新平台专业化管理',
        img: GOV_DERAIL_2,
    },
    {
        title: '科技活动定制化举办',
        img: GOV_DERAIL_3,
    },
    {
        title: '科技政策精准性解读',
        img: GOV_DERAIL_4,
    }
]

const acdDetail = [
    {
        title: '科技成果精准分析评估转化',
        img: ACD_DERAIL_1,
    },
    {
        title: '结合产业布局技术转移载体',
        img: ACD_DERAIL_2,
    },
    {
        title: '专业团队深入挖掘技术需求',
        img: ACD_DERAIL_3,
    }
]

const talDetail = [
    {
        title: '技术创新专家针对性筛选',
        img: TAL_DERAIL_1,
    },
    {
        title: '科技服务人才批量化培养',
        img: TAL_DERAIL_2,
    },
]

class Solution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 0,
            pWidth: '',
            flexStyle: '',
            ctClass: '',
            detailStyle: '',
        };
    }

    findSelectCt = (st, ctClass, detailStyle) => {
        if (st === 0) {
            return (
                <Fragment>
                    <div className={"solution-wrap"}>
                        {this.loadSolutionLayout(COM_BG, comStyle)}
                    </div>
                    <div className={ctClass}>
                        {this.loadSolutionDetail(comDetail, detailStyle)}
                    </div>
                </Fragment>
            )
        } else if (st === 1) {
            return (
                <Fragment>
                    <div className={"solution-wrap"}>
                        {this.loadSolutionLayout(GOV_BG, govStyle)}
                    </div>
                    <div className={ctClass}>
                        {this.loadSolutionDetail(govDetail, detailStyle)}
                    </div>
                </Fragment>
            )
        } else if (st === 2) {
            return (
                <Fragment>
                    <div className={"solution-wrap"}>
                        {this.loadSolutionLayout(ACD_BG, acdStyle)}
                    </div>
                    <div className={ctClass}>
                        {this.loadSolutionDetail(acdDetail, detailStyle)}
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div className={"solution-wrap"}>
                        {this.loadSolutionLayout(TAL_BG, talStyle)}
                    </div>
                    <div className={ctClass}>
                        {this.loadSolutionDetail(talDetail, detailStyle)}
                    </div>
                </Fragment>
            )
        }
    }

    loadSolutionLayout = (bg, style) => {
        return (
            <div className={"solution-image-wrap"}>
                <img className={"img-style contain"} src={bg} alt={"bg"}/>
                {this.loadImgLayout(style)}
            </div>
        )
    }

    loadSolutionDetail = (list, style) => {
        let ui = [];
        let uii = [];

        let len = list.length;
        if (style === 1) {
            list.forEach((item, index) => {
                uii.push(
                  [<div className={"solution-detail-row-item"} key={index}>
                      <div className={"solution-detail-item-img"}>
                          <img className={"img-style contain"} src={item.img} alt={""}/>
                      </div>
                      <div className={"solution-detail-row-item-txt"}>
                          {item.title}
                      </div>
                  </div>,
                  (index + 1 )=== len ? null : <div className={"solution-detail-line"}/>]
                )
            })
            ui.push(
              <div className={"solution-detail-row-wrap"}>
                  {uii}
              </div>
            )
        } else {
            list.forEach((item, index) => {
                uii.push(
                  <div className={"solution-detail-column-item"} key={index} style={{border: (index + 1) === len ? 'none' : ''}}>
                      <div className={"solution-detail-item-img"}>
                          <img className={"img-style contain"} src={item.img} alt={""}/>
                      </div>
                      <div className={"solution-detail-item-txt"}>
                          {item.title}
                      </div>
                  </div>
                )
            })
            ui.push(
              <div className={"solution-detail-column-wrap"}>
                  {uii}
              </div>
            )
        }
        return ui;
    }

    loadImgLayout = (data) => {
        let ui = [];
        data.forEach((item, index) => {
            ui.push(
                <div className={"solution-item-wrap"} style={{left: item.corX, top: item.corY}} key={index}>
                    <img className={"img-style contain"} src={item.img} alt={"bg"}/>
                    {
                        item.ctImg ?
                            <div className={"solution-item-ct-wrap"} style={{left: item.ctCorX, right: item.ctCorXR, top: item.ctCorY}}>
                                <img className={"img-style contain"} src={item.ctImg} alt={"ct"}/>
                            </div>
                            : null
                    }
                </div>
            )
        })
        return ui;
    }

    componentDidMount() {
        this.listenSolWidth();
        window.addEventListener("resize", this.listenSolWidth);
    }

    // 监听页面宽度辩变化
    listenSolWidth = () => {
        let pWidth = document.documentElement.clientWidth;
        let calWidth = pWidth * 0.8;
        // console.log(calWidth);
        let style = calWidth < 1400 ? "solution-slide-ct-column" : "solution-slide-ct";
        let ctClass = calWidth < 1400 ? "solution-txt-column" : "solution-txt";
        let detailStyle = calWidth < 1400 ? 1 : 2;
        this.setState({
            pWidth: calWidth,
            flexStyle: style,
            ctClass: ctClass,
            detailStyle: detailStyle,
        })
    }

    render() {
        let selected = this.state.selected;

        let flexStyle = this.state.flexStyle;
        let ctClass = this.state.ctClass;
        let detailStyle = this.state.detailStyle;

        return <div className={"out-content"}>
            <div className={"solution-ot"}>
                <div className={"page-ct"} style={{height: "auto"}}>
                    <div className={"outcome-title"}>
                        <QueueAnim animConfig={{opacity: [1, 0], translateY: [0, -80]}} duration={2000} delay={100}>
                            {this.props.solution ?
                                <div className={"solution-title-card"} key={"sol"}>
                                    <div className={"solution-title-ct"}>
                                        <div className={"solution-title-main"}>
                                            {"科技创新解决方案"}
                                        </div>
                                        <div className={"solution-title-des"}>
                                            {"全方位资源覆盖 全要素协调发展 全科技生态打造"}
                                        </div>
                                    </div>
                                </div>
                                :
                                null}
                        </QueueAnim>
                    </div>
                    <div style={{height: "2px", width: "100%", marginTop: "4px"}}/>
                    <QueueAnim animConfig={{opacity: [1, 0], translateY: [0, 80]}} duration={2000} delay={100}>
                        {this.props.solution ?
                            <div className={"solution-slide-page"} key={"sol-ct"}>
                                <div className={"solution-slide-menu"}>
                                    <span className={"solution-selection"}
                                          style={selected === 0 ? selectStyle : {}}
                                          onClick={() => {
                                              this.setState({selected: 0})
                                          }}>
                                        {"企业技术创新"}
                                    </span>
                                    <span className={"solution-selection"}
                                          style={selected === 1 ? selectStyle : {}}
                                          onClick={() => {
                                              this.setState({selected: 1})
                                          }}>
                                        {"政府协同创新"}
                                    </span>
                                    <span className={"solution-selection"}
                                          style={selected === 2 ? selectStyle : {}}
                                          onClick={() => {
                                              this.setState({selected: 2})
                                          }}>
                                        {"院校科研创新"}
                                    </span>
                                    <span className={"solution-selection"}
                                          style={selected === 3 ? selectStyle : {}}
                                          onClick={() => {
                                              this.setState({selected: 3})
                                          }}>
                                        {"科技人才创新"}
                                    </span>
                                </div>
                                <div className={flexStyle} id={"solution-wrap"}>
                                    {this.findSelectCt(selected, ctClass, detailStyle)}
                                </div>
                            </div>
                            :
                            null}
                    </QueueAnim>
                </div>
            </div>
        </div>
    }
}

export default Solution;