import React, {Component} from "react";
import "./center.css";
import {FileSearchOutlined} from "@ant-design/icons";
import NODATA from "../../assets/icon/record_empty.svg";
import {handleExpertTitle, handleOutcomeType, UserInfoStorage} from "../../tool/Const";
import {data, error, FindFavorDataByTypeUrl, sendGetRequest} from "../../tool/http/Request";
import {connectionError, fail, findMsgByError} from "../../tool/http/ErrorMgr";

class CollectionMgr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 0,
            data: '',
            userPojo: {
                outcomeCollection: 22,
                demandCollection: 50,
                expertCollection: 3,
                policyCollection: 0,
            },
        }
    }

    switchType = (type, data) => {
        this.setState({
            type: type,
            data: data,
        })
    }

    loadTable = (data, type) => {
        let table = [];
        if (data.length > 0) {
            data.forEach((item, index) => {
                table.push(
                    <div className={"table-ct-wrap"} key={index}>
                        <div className={"table-ct-cell index"}>
                            {item[this.handleIdByType(type) + "Id"]}
                        </div>
                        <div className={"table-ct-cell title-ct"}>
                            {item[this.handleIdByType(type) + "Name"]}
                        </div>
                        <div className={"table-ct-cell type"}>
                            {
                                type === 2 ?
                                handleExpertTitle(item["expertTitle"]) :
                                    type === 3 ?
                                        item["policyPri"] : handleOutcomeType(item[this.handleIdByType(type) + "Type"])
                            }
                        </div>
                        <div className={"table-ct-cell date"}>
                            {type === 3 ? item["policyRoot"] : item[this.handleIdByType(type) + "Areas"]}
                        </div>
                        <div className={"table-ct-cell operation"}>
                            <div className={"operation-plugin add-fun"}
                                 onClick={() => {
                                     window.open('/' + this.handleIdByType(type) + '/' + item[this.handleIdByType(type) + "Id"], "_blank")
                                 }}
                            >
                                <FileSearchOutlined className={"operation-plugin-icon"}/>
                                <div className={"operation-plugin-txt"}>
                                    {"查看"}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            return (
                <div className={"table-ct-no-data"}>
                    <img className={"table-ct-no-data-icon"} src={NODATA} alt={"no-data"}/>
                    <div className={"table-ct-no-data-tip"}>
                        {"暂无数据"}
                    </div>
                </div>
            )
        }
        return table;
    }

    componentDidMount() {
        this.getFavorDataByType(0);
    }

    // 根据type通过userId和种类index拉取显示对应类型数据列表
    getFavorDataByType = (type) => {
        let userInfo = UserInfoStorage.get("userInfo");
        let index = this.handleIndexByType(type);
        if (userInfo) {
            let params = {
                userId: userInfo.userId,
                index: index,
            };
            console.log("收藏的数据params: ", params);
            sendGetRequest(FindFavorDataByTypeUrl, params, true).then(res => {
                console.log("收藏的数据res: ", index, res);
                if (res === connectionError) {
                    fail(findMsgByError(connectionError));
                } else {
                    if (res && res.hasOwnProperty("success") && res.success) {
                        this.setState({
                            data: res[data],
                            type: type,
                        })
                    } else {
                        fail(findMsgByError(res[error]))
                    }
                }
            })
        }
    }

    // 根据type获取对应的index
    // （index：1344，id：outcomeId; index：9919，id：demandId; index：2577，id：expertId; index：3478，id：policyId; index：3566，id：newsId）
    handleIndexByType = (type) => {
        switch (type) {
            case 0: {
                return '1344';
            }
            case 1: {
                return '9919';
            }
            case 2: {
                return '2577';
            }
            case 3: {
                return '3478';
            }
        }
    }

    handleIdByType = (type) => {
        switch (type) {
            case 0: {
                return "outcome";
            }
            case 1: {
                return "demand";
            }
            case 2: {
                return "expert";
            }
            case 3: {
                return "policy";
            }
        }
    }

    render() {
        let type = this.state.type;
        let data = this.state.data;

        return (
            <div className={"collection-mgr-ct"}>
                <div className={"center-ct-header"}>
                    <div className={"center-ct-header-wrap"}>
                        <div className={"center-ct-header-icon"}/>
                        <div className={"center-ct-header-title"}>
                            {"收藏管理"}
                        </div>
                        {/*<div className={"center-ct-header-subtitle"}>*/}
                        {/*    {"查看每日日程"}*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className={"my-service-plugin-menu"}>
                    <div className={type === 0 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                         onClick={() => {
                             this.getFavorDataByType(0)
                         }}
                    >
                        <div>
                            {"科技成果"}
                        </div>
                    </div>
                    <div className={type === 1 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                         onClick={() => {
                             this.getFavorDataByType(1);
                         }}
                    >
                        <div>
                            {"技术需求"}
                        </div>
                    </div>
                    <div className={type === 2 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                         onClick={() => {
                             this.getFavorDataByType(2);
                         }}
                    >
                        <div>
                            {"专家人才"}
                        </div>
                    </div>
                    <div className={type === 3 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                         onClick={() => {
                             this.getFavorDataByType(3);
                         }}
                    >
                        <div>
                            {"科技政策"}
                        </div>
                    </div>
                </div>
                <div className={"outcome-mgr-ct-table"}>
                    <div className={"table-wrap"}>
                        <div className={"table-header"}>
                            <div className={"table-header-cell index"}>
                                {"索引"}
                            </div>
                            <div className={"table-header-cell title"}>
                                {type === 2 ? "姓名" : "标题"}
                            </div>
                            <div className={"table-header-cell type"}>
                                {
                                    type === 2 ? "职称" :
                                        type === 3 ? "适用区域" : "类型"
                                }
                            </div>
                            <div className={"table-header-cell date"}>
                                {type === 3 ? "发布机构" : "行业"}
                            </div>
                            <div className={"table-header-cell operation"}>
                                {"操作"}
                            </div>
                        </div>
                        <div className={"table-ct"}>
                            {this.loadTable(data, type)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CollectionMgr;
