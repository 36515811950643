import React, {Component} from 'react';
import QueueAnim from "rc-queue-anim";
import "./../page.css";
import {RightOutlined} from "@ant-design/icons";
import {handleClickMonitor, OutcomeRoot, PageId, UserInfoStorage} from "../../tool/Const";
import {SrcHeader} from "../../tool/http/Request";

class Outcome extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // 加载push内容
    loadOutcome = (data, userInfo) => {
        if (data.length > 0) {
            let ui = [];
            data.forEach((item, index) => {
                // 在项目中不可避免要动态加载图片或者把图片路径写的灵活一点，便于维护代码，我们可以使用require.context()来实现。
                // let picDir = require.context("../pic/", true, /\.(png|jpg)$/);
                // let imgUrl = picDir("./" + item["outcomeBanner"]);
                let imgUrl = SrcHeader + item["outcomeId"] + "/" + item["outcomeBanner"];
                ui.push(
                    <div className={"outcome-item"} key={index} style={{margin: (index + 1)%3 === 2 ? '10px 2%' : '10px 0'}}>
                        <div className={"outcome-item-img"}
                             onClick={() => {
                                 handleClickMonitor(userInfo, item["outcomeId"], 2, 1, OutcomeRoot.path + "/" + item["outcomeId"])
                             }}
                        >
                            {/*<span>{"图片位置"}</span>*/}
                            <img className={"img-style contain"} src={imgUrl} alt={""}/>
                        </div>
                        <div className={"outcome-item-title"}>
                            <span>{item["outcomeName"]}</span>
                        </div>
                        <div className={"outcome-item-tags"}>
                            <div className={"outcome-tag"} style={{minWidth: '92px'}}>
                                {item["relDate"]}
                            </div>
                            <div className={"outcome-tag ellipsis"}>
                                {item["outcomeAreas"]}
                            </div>
                        </div>
                        <div className={"outcome-item-des"}>
                            <span>{item["outcomeDes"]}</span>
                        </div>
                        <div className={"outcome-item-more"}
                             onClick={() => {
                                 handleClickMonitor(userInfo, item["outcomeId"], 2, 1, OutcomeRoot.path + "/" + item["outcomeId"])
                             }}
                        >
                            <span>{"查看详情"}</span>
                            <RightOutlined/>
                        </div>
                    </div>
                )
            });
            return ui;
        }
    }

    render() {
        let userInfo = UserInfoStorage.get("userInfo");
        let outcome = this.props.outcome;
        let pushData = this.props.pushData;

        return (
            <div className={"out-content"}>
                {
                    outcome && pushData.length > 0 ?
                        <div className={"outcome-ot"}>
                            <div className={"page-ct"}>
                                <div className={"outcome-title"}>
                                    <div className={"title-hall"}
                                         onClick={() => {
                                             handleClickMonitor(userInfo, PageId.outcome, 1, 1, OutcomeRoot.path)
                                         }}
                                    >
                                        {"进入成果大厅"}
                                    </div>
                                    <QueueAnim animConfig={{opacity: [1, 0], translateX: [0, 200]}} duration={2000} delay={100}>
                                        <div className={"outcome-title-card"} key={"out"}>
                                            <div style={{width: "6px", height: "100%", background: "rgb(60, 128, 212)"}}/>
                                            <div className={"outcome-title-ct"}>
                                                <div className={"title-main"}>
                                                    {"最新前沿科技成果"}
                                                </div>
                                                <div className={"title-des"}>
                                                    {"整合行业先进科研成果 助力企业创新转型升级"}
                                                </div>
                                            </div>
                                        </div>
                                    </QueueAnim>
                                </div>
                                <div style={{height: "20px", width: "100%"}}/>

                                <QueueAnim animConfig={{opacity: [1, 0], translateY: [0, 100]}} duration={1500} delay={400}>
                                    <div className={"outcome-ct"} key={"otr"}>
                                        <div className={"outcome-line"}>
                                            {this.loadOutcome(pushData, userInfo)}
                                        </div>
                                    </div>
                                </QueueAnim>

                            </div>
                        </div>
                        : null
                }
            </div>
        )
    }
}

export default Outcome;