import React from 'react';
import "./center.css";
import "./../page.css";
import {CaretDownOutlined, CaretRightFilled, LeftOutlined, PoweroffOutlined, RightOutlined} from "@ant-design/icons";
import Overview from "./Overview";
import AccountMgr from "./AccountMgr";
import Certification from "./Certification";
import OutcomeMgr from "./OutcomeMgr";
import DemandMgr from "./DemandMgr";
import AppointmentMgr from "./AppointmentMgr";
import SubscriptionMgr from "./SubscriptionMgr";
import CollectionMgr from "./CollectionMgr";
import ScheduleCheck from "./ScheduleCheck";
import ScheduleSetting from "./ScheduleSetting";
import ExpertResume from "./ExpertResume";
import NoticesMgr from "./NoticesMgr";
import OrdersMgr from "./OrdersMgr";
import UnlockMgr from "./UnlockMgr";
import {handleLogout, LoginRoot, UserInfoStorage} from "../../tool/Const";
import {failReturn} from "../../tool/http/ErrorMgr";
import LOGO_BLUE from "../../assets/icon/logo-v.svg";

const menuList = [
    {
        index: 0,
        title: "总览",
        subTitles: [],
        ct: <Overview />,
        type: "overview",
    },
    {
        index: 1,
        title: "账号管理",
        subTitles: [],
        ct: <AccountMgr />,
        type: "account",
    },
    {
        index: 2,
        title: "资质认证",
        subTitles: [],
        ct: <Certification />,
        type: "certification",
    },
    {
        index: 3,
        title: "技术成果管理",
        subTitles: [],
        ct: <OutcomeMgr />,
        type: "outcomeMgr",
    },
    {
        index: 4,
        title: "需求管理",
        subTitles: [],
        ct: <DemandMgr />,
        type: "demandMgr",
    },
    {
        index: 5,
        title: "我的服务",
        ct: null,
        subTitles: [
            {
                mainIndex: 5,
                title: "预约管理",
                index: 1,
                ct: <AppointmentMgr />,
                type: "appointmentMgr",
            },
            {
                mainIndex: 5,
                title: "订阅管理",
                index: 2,
                ct: <SubscriptionMgr />,
                type: "subscriptionMgr",
            },
            {
                mainIndex: 5,
                title: "收藏管理",
                index: 3,
                ct: <CollectionMgr />,
                type: "collectionMgr",
            },
            {
                mainIndex: 5,
                title: "订单管理",
                index: 4,
                ct: <OrdersMgr />,
                type: "ordersMgr",
            },
            {
                mainIndex: 5,
                title: "解锁管理",
                index: 5,
                ct: <UnlockMgr />,
                type: "unlockMgr",
            },
        ],
    },
    // {
    //     index: 6,
    //     title: "专家功能",
    //     ct: null,
    //     subTitles: [
    //         {
    //             mainIndex: 6,
    //             title: "专家简历",
    //             index: 1,
    //             ct: <ExpertResume />,
    //             type: "expertResume",
    //         },
    //         {
    //             mainIndex: 6,
    //             title: "日程查看",
    //             index: 2,
    //             ct: <ScheduleCheck />,
    //             type: "scheduleCheck",
    //         },
    //         {
    //             mainIndex: 6,
    //             title: "日程设置",
    //             index: 3,
    //             ct: <ScheduleSetting />,
    //             type: "scheduleSetting",
    //         }],
    // },
    // {
    //     index: 7,
    //     title: "消息",
    //     subTitles: [],
    //     ct: <NoticesMgr />,
    //     type: "noticesMgr",
    // },
]

const menuListUser = menuList.concat(
    {
        index: 6,
        title: "消息",
        subTitles: [],
        ct: <NoticesMgr />,
        type: "noticesMgr",
    },
)

const menuListExpert = menuList.concat(
    {
        index: 6,
        title: "专家功能",
        ct: null,
        subTitles: [
            {
                mainIndex: 6,
                title: "专家简历",
                index: 1,
                ct: <ExpertResume />,
                type: "expertResume",
            },
            {
                mainIndex: 6,
                title: "日程查看",
                index: 2,
                ct: <ScheduleCheck />,
                type: "scheduleCheck",
            },
            {
                mainIndex: 6,
                title: "日程设置",
                index: 3,
                ct: <ScheduleSetting />,
                type: "scheduleSetting",
            }],
    },
    {
        index: 7,
        title: "消息",
        subTitles: [],
        ct: <NoticesMgr />,
        type: "noticesMgr",
    },
)

class CenterPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: false,
            hiddenMenu: false,
            ct: '',
            menuIndexGroup: '',
        }
    }

    loadingFunMenu = (menu) => {
        let menuIndexGroup = this.state.menuIndexGroup;
        let menuIndex = menuIndexGroup.index;
        let menuSubIndex = menuIndexGroup.subIndex;
        let select = menuIndexGroup.select;
        let close = menuIndexGroup.close;
        let tags = [];
        menu.forEach((item, index) => {
            let subMenu = item.subTitles;
            let subTitles = [];
            let subLen = subMenu.length;
            let hasSub = subLen > 0;
            let subHgt = parseInt((subLen * 46) + "") + "px"
            if (hasSub) {
                subMenu.forEach((itemSub, indexSub) => {
                    subTitles.push(
                        <div className={"center-menu-item-sub"} key={indexSub}
                             style={menuIndex === index ? menuSubIndex === itemSub.index ? {
                                 background: "rgba(97, 184, 251, 0.2)",
                                 fontWeight: "bold"
                             } : {} : {opacity: "0"}}
                             onClick={() => {
                                 this.handleClickSubMenu(itemSub.ct, itemSub.type, itemSub.index, menuSubIndex, menuIndexGroup)
                             }}>
                            <span>{itemSub.title}</span>
                        </div>
                    )
                })
            }
            tags.push(
                [<div className={"center-menu-item"} key={index}
                      style={select === index ? {
                          background: hasSub ? "" : "rgba(165, 206, 255, 0.3)",
                          fontWeight: "bold"
                      } : {}}
                      onClick={() => {
                          this.handleClickMenu(index, menuIndex, hasSub, item.ct, item.type, select, menuSubIndex, close)
                      }}>
                    <span>{item.title}</span>
                    {hasSub ? menuIndex === index && !close ?
                        <CaretDownOutlined style={{paddingLeft: "50px", marginRight: "20px"}}/> :
                        <CaretRightFilled style={{paddingLeft: "50px", marginRight: "20px"}}/> : null}
                </div>,
                    <div className={"center-sub-items"}
                         style={menuIndex === index && !close ? {height: subHgt} : {
                             height: "0",
                             width: "0",
                             overflow: "hidden",
                             opacity: "0"
                         }}
                    >
                        {subTitles}
                    </div>]
            )
        })
        return tags;
    }

    handleClickSubMenu = (ct, type, subIndex, menuSubIndex, group) => {
        if (menuSubIndex !== subIndex) {
            window.location.href = '?type=' + type
            // group.subIndex = subIndex;
            // group.select = group.index;
            // this.setState({
            //     menuIndexGroup: group,
            //     ct: ct,
            // },
            //     () => window.location.href = '?type=' + type)
        }
    }

    handleClickMenu = (index, menuIndex, hasSub, ct, type, select, menuSubIndex, close) => {
        if (!hasSub) {
            window.location.href = '?type=' + type
            // if (index !== menuIndex) {
            //     this.setState({
            //         menuIndexGroup: {index: index, subIndex: 0, select: index, close: true},
            //         ct: ct,
            //     },
            //         () => window.location.href = '?type=' + type)
            // }
            return;
        }
        if (index !== menuIndex) {
            this.setState({
                menuIndexGroup: {index: index, subIndex: 0, select: select, close: false},
            })
        } else {
            this.setState({
                menuIndexGroup: {index: index, subIndex: menuSubIndex, select: select, close: !close},
            })
        }
    }

    // 处理url传参
    handleUrlParams = (menu) => {
        let url = this.props.location.search;
        if (url) {
            let params = url.split("=")[1].split("&")[0];
            let target = menu.filter(it => it.type === params);
            let subMenuList = menu.filter(it => it.subTitles.length > 0);
            console.log(params);
            // console.log(target);
            // console.log(subMenuList);
            if (params) {
                if (target.length > 0) {
                    this.setState({
                        ct: target[0].ct,
                        menuIndexGroup: {index: target[0].index, subIndex: 0, select: target[0].index, close: false},
                    })
                } else {
                    let subTargetList = subMenuList.map(it => it.subTitles.filter(item => item.type === params));
                    let subTarget = subTargetList.filter(list => list.length > 0)[0];
                    console.log(subTarget);
                    this.setState({
                        ct: subTarget[0].ct,
                        menuIndexGroup: {index: subTarget[0].mainIndex, subIndex: subTarget[0].index, select: subTarget[0].mainIndex, close: false},
                    })
                }
            }
        } else {
            this.setState({
                ct: <Overview />,
                menuIndexGroup: {index: 0, subIndex: 0, select: 0, close: true},
            })
        }
    }

    componentDidMount() {
        let userInfo = UserInfoStorage.get("userInfo");
        if (userInfo) {
            let isExpert = userInfo['isExp'];
            let menu = isExpert ? menuListExpert : menuListUser;
            this.handleUrlParams(menu);
            this.setState({
                isLogin: true,
            })
        } else {
            failReturn("请先登录账号", LoginRoot)
        }
    }

    render() {
        let isLogin = this.state.isLogin;
        let hiddenMenu = this.state.hiddenMenu;
        let userInfo = UserInfoStorage.get("userInfo");
        let isExpert = userInfo ? userInfo['isExp'] : false;
        let menu = isExpert ? menuListExpert : menuListUser;

        console.log(this.state.menuIndexGroup)

        return (
            <div className={"home-ct"} style={{display: isLogin ? "" : "none"}}  id={"wrap"}>
                <div className={"center-header"}>
                    <div className={"center-header-logo"}
                        onClick={() => {
                            window.open("/", "_blank")
                        }}
                    >
                        {/*{"湃通长三角平台Logo"}*/}
                        <img className={"img-style contain"} src={LOGO_BLUE} alt={"logo"}/>
                    </div>
                    <div className={"center-header-right"}>
                        <div className={"center-header-item"}
                             onClick={() => {
                                 window.open("/", "_blank")
                             }}
                        >
                            {"返回平台首页"}
                        </div>
                        <div className={"center-header-item"} style={{width: 'auto', cursor: "pointer"}}
                             onClick={() => {
                                 handleLogout(userInfo)
                             }}
                        >
                            <div className={"logout-icon"}>
                                <PoweroffOutlined/>
                            </div>
                            <span>{"退出"}</span>
                        </div>
                    </div>
                </div>
                <div className={"center-container"}>
                    <div className={"center-left-menu"} style={{width: hiddenMenu ? "18px" : ""}}>
                        <div className={"center-menu-ct"}
                             style={hiddenMenu ? {opacity: "0"} : {transitionDelay: "150ms"}}>
                            <div className={"center-menu-title"}>
                                <span>{"功能控制台"}</span>
                            </div>
                            <div className={"center-menu-items-outer"}>
                                {this.loadingFunMenu(menu)}
                                <div style={{width: "100%", height: "40px"}}/>
                            </div>
                        </div>
                        <div className={"center-menu-bt-outer"}>
                            <div className={"center-menu-bt"} onClick={() => {
                                this.setState({hiddenMenu: !hiddenMenu})
                            }}>
                                {hiddenMenu ? <RightOutlined style={{transform: "translateX(-21px)"}}/> :
                                    <LeftOutlined style={{transform: "translateX(-21px)"}}/>}
                            </div>
                        </div>
                    </div>
                    <div className={"center-right-ct"} style={hiddenMenu ? {width:  "calc(100% - 18px)"} : {transitionDelay: "150ms"}}>
                        {this.state.ct}
                    </div>
                </div>
            </div>
        )
    }
}

export default CenterPage;
