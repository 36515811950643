import React, {Component} from "react";
import "./center.css";
import Calendar from "./Calendar";
import {getUserInfoCache, LoginRoot, UserInfoStorage, utcTimeFormat} from "../../tool/Const";
import moment from "moment";
import {CheckExpertAppointUrl, data, error, loginOutErrOr, sendGetRequest} from "../../tool/http/Request";
import {connectionError, fail, failReturn, findMsgByError} from "../../tool/http/ErrorMgr";
import {LoadingOutlined} from "@ant-design/icons";

class ScheduleCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: '',

            curDate: moment().utcOffset(8), // 当前日期和时间
            dateUpperLimit: '',  // 当前日期上限
            dateLowerLimit: '',  // 当前日期下限
            isLoadDate: false,

            appointMap: new Map(),  // 预约名单map
        }
    }

    componentDidMount() {
        getUserInfoCache(this);
        this.getCalOpenTime();
    }

    // 获取当前日期的上限（结束）和下限（开始）
    getCalOpenTime = () => {
        let curDate = this.state.curDate;
        this.setState({
                dateLowerLimit: moment(curDate).subtract(1, 'year').format('YYYY-MM-DD'),  // 当前日期下限（开始）
                dateUpperLimit: moment(curDate).add(1, 'months').format('YYYY-MM-DD'),  // 当前日期上限（结束）
                isLoadDate: true,
            },
            () => {
                this.getAppointSchMap(curDate, this.state.dateLowerLimit, this.state.dateUpperLimit);
            })
    }

    // 获取预约日程及对应名单pre
    getAppointSchMap = (curDate, dateLowerLimit, dateUpperLimit) => {
        // console.log("dateUpperLimit:", this.state.dateUpperLimit);
        // console.log("dateLowerLimit:", this.state.dateLowerLimit);
        // let yesterday = moment(curDate).subtract(1, 'day').format('YYYY-MM-DD');

        // 判断日历呈现的最早一天
        // let start = '';
        // let dateStart = moment(curDate).date(1);  // 当前月的1号
        // let countStart = dateStart.day();  // 1号是星期几
        // start = dateStart.subtract(countStart, "days").format('YYYY-MM-DD');

        // 判断日历呈现的最后一天
        // let end = '';
        // let dateEnd = moment(curDate).date(1).add(1, "months");
        // let countEnd = 7 - dateEnd.day(); // 7-星期几，就可知道要渲染几个下月数据
        // console.log(countEnd)
        // if (countEnd === 7) {
        //     end = dateEnd.format('YYYY-MM-DD');
        // } else {
        //     end = dateEnd.add(countEnd, "days").format('YYYY-MM-DD');
        // }
        if (dateLowerLimit && dateUpperLimit) {
            this.checkExpAppoint(dateLowerLimit, dateUpperLimit);
        }
    }

    // 获取预约日程及对应名单（调用api）
    checkExpAppoint = (start, end) => {
        console.log("start: ", start);
        console.log("end: ", end);
        let map = new Map();
        let userInfo = UserInfoStorage.get("userInfo");
        let params = {
            userId: userInfo.userId,  // 专家接口
            expertId: userInfo.userId,
            start: start,
            end: end,
            needDes: true,  // 专家端需要用户详情参数
        }
        // console.log("预约日程params: ", params);
        sendGetRequest(CheckExpertAppointUrl, params, true).then(res => {
            console.log("获取专家预约日程res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    let appointList = res[data];
                    if (appointList) {
                        // 处理api返回数据，根据appointDate
                        let list = [];
                        let tmpList = [];
                        let len = appointList.length;
                        for (let i = 0; i < len; i++) {
                            if (tmpList.indexOf(appointList[i].appointDate) === -1) {
                                list = [];
                                list.push(appointList[i]);
                                tmpList.push(appointList[i].appointDate);
                            } else {
                                list.push(appointList[i]);
                            }
                            list.sort(
                                // 排序升序
                                function (a, b) {
                                    return a.appointTime.split(';')[0] - b.appointTime.split(';')[0];
                                }
                            )
                            map.set(appointList[i].appointDate, list);
                            // console.log("list: ", list)
                            // console.log("tmpList: ", tmpList)
                            // console.log("map: ", map)
                            this.setState({
                                appointMap: map,
                            },
                                () => {
                                    console.log("appointMap: ", this.state.appointMap);
                                })
                        }
                    }
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    render() {
        let userInfo = this.state.userInfo;

        let curDate = this.state.curDate;
        let dateUpperLimit = this.state.dateUpperLimit;  // 当前日期上限
        let dateLowerLimit = this.state.dateLowerLimit;  // 当前日期下限
        let isLoadDate = this.state.isLoadDate;

        let appointMap = this.state.appointMap;

        return (
            <div className={"expert-fun-ct"}>
                {
                    isLoadDate ?
                        [
                            <div className={"center-ct-header"}>
                                <div className={"center-ct-header-wrap"}>
                                    <div className={"center-ct-header-icon"}/>
                                    <div className={"center-ct-header-title"}>
                                        {"查看日程"}
                                    </div>
                                    <div className={"center-ct-header-subtitle"}>
                                        {"查看每日日程"}
                                    </div>
                                </div>
                            </div>,
                            <div className={"schedule-calendar"}>
                                <Calendar parent={this}
                                          userInfo={userInfo}
                                          type={"expCal"}
                                          curDate={curDate} dateUpperLimit={dateUpperLimit} dateLowerLimit={dateLowerLimit}
                                          appointMap={appointMap}
                                />
                            </div>
                        ]
                        :
                        [
                            <div className={"center-ct-header"}>
                                <div className={"center-ct-header-wrap"}>
                                    <div className={"center-ct-header-icon"}/>
                                    <div className={"center-ct-header-title"}>
                                        {"查看日程"}
                                    </div>
                                    <div className={"center-ct-header-subtitle"}>
                                        {"查看每日日程"}
                                    </div>
                                </div>
                            </div>,
                            <div className={"load-plugin"}>
                                <LoadingOutlined className={"load-icon"}/>
                                <div className={"load-txt"}>
                                    {"日程加载中，请稍候..."}
                                </div>
                            </div>
                        ]
                }
            </div>
        )
    }
}

export default ScheduleCheck;
