import React, {Component} from 'react';
import "./policy.css";
import "../page.css";
import Header from "../Header";
import Footer from "../Footer";
import AdRight from "../../tool/AdRight";
import Contact from "../home/Contact";
import {
    advList,
    dataIndexE,
    ExpertRoot,
    findFavorDataByType,
    getAdvData,
    handleAdRightWinSizeChange, handleAdRightWinSizeInit,
    handleClickMonitor,
    handlePageScrollMove,
    LoadingExpertCopAds,
    LoadingOutcomeCopAds,
    OutcomeRoot,
    PolicyRoot,
    PriAbbrJson,
    SaveBt,
    UserInfoStorage
} from "../../tool/Const";
import PolicyFilter from "./PolicyFilter";
import Partitioner from "../../tool/Partitioner";
import EMPTY from "../../assets/img/record_empty.svg";

const adClasses = {
    adOuter: "policy-ct-ad",
    adOne: "policy-ct-ad-one",
    adTwo: "policy-ct-ad-two",
}

const loadSetting = {
    loadingBatch: 12,
    classes: {
        outer: "outcome-page-partition-ct",
    }
}

const initGeo = "中华人民共和国";

class PolicyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fixed: true,
            fixedAd: false,
            adLeft: "",
            adBt: false,
            showAd: true,
            gap: 0,
            cH: 0,
            hoverI: -1,
            adjustGap: 0,
            openSize: 0,
            partitioner: {
                totalSize: 0,
                showData: [],
                loadComplete: false,
            },
            options: {
                index: '3478',
                geo: initGeo,
                keyWords: "",
            },
            favorList: [],
            idList: [],
            advMap: new Map(),  // adv数据
        };
        this.timeout = null;
    }

    componentDidMount() {
        handleAdRightWinSizeInit(this);
        window.addEventListener("resize", this.handleResize)
        window.onscroll = () => {
            let cH = document.getElementById("expert-outer").clientHeight;
            let cHC = this.state.cH;

            let headerH = document.getElementById("header").clientHeight;
            let filterH = document.getElementById("filter").clientHeight;
            let paddingH = 0;
            let adH = document.getElementById("ad").clientHeight;
            let contactH = 42;
            let distanceAboveAd = headerH + filterH + paddingH + adH + contactH - 60;  // 1790
            let distanceBtAd = headerH + filterH + paddingH + contactH - 60;  // 530
            let distanceContact = headerH + filterH + paddingH + contactH - 90;  // 500

            handlePageScrollMove(this, "p-tag", distanceAboveAd, distanceBtAd + cH, distanceContact + cH, "expert-outer", 940)
            if (cHC !== cH) {
                this.setState({
                    cH: cH,
                })
            }
        };
        findFavorDataByType(this);
        getAdvData(advList.policy, this);
    }

    handleResize = () => {
        handleAdRightWinSizeChange("expert-outer", this);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize)
    }

    // 加载数据
    loadPolicyData = () => {
        let data = []
        let showData = this.state.partitioner.showData;
        let hoverI = this.state.hoverI;
        let userInfo = UserInfoStorage.get("userInfo");
        let favorList = this.state.favorList;
        let options = this.state.options;

        if (showData) {
            showData.forEach((item, index) => {
                data.push(
                    <div className={"policy-data-item"} key={index} style={{marginTop: index === 0 ? "0" : ""}}>
                        <div className={"policy-item-square"}>
                            {SaveBt(item, index, hoverI, this, userInfo, favorList, options)}
                            <div className={"save-policy"} style={{display: index === hoverI ? "" : "none"}}>
                                {favorList.indexOf(item["policyId"]) > -1 ? "取消收藏" : "点击收藏"}
                            </div>
                        </div>
                        <div className={"policy-item-square"} style={{background: "#d7e5f1", justifyContent: "center"}}>
                            <span style={{fontSize: "22px", fontWeight: "bold", color: "rgb(59,59,59)"}}>
                                {/*{"国"}*/}
                                {PriAbbrJson[item["policyPri"]]}
                            </span>
                        </div>
                        <div className={"policy-item-ct"}
                             onClick={() => {
                                 handleClickMonitor(userInfo, item["policyId"], 2, 1, PolicyRoot.path + "/" + item["policyId"])
                             }}
                        >
                            <div className={"policy-item-title"}>
                                {/*{"上海市可再生能源和新能源发展专项资金扶持办法，上海市可再生能源和新能源发展专项资金扶持办法"}*/}
                                {item["policyName"]}
                            </div>
                            <div className={"policy-item-info"}>
                                <div className={"policy-item-info-line"}>
                                    <span className={"policy-dp-word"}>
                                        {/*{"中华人民共和国科学技术部"}*/}
                                        {item["policyRoot"]}
                                    </span>
                                </div>
                                <div className={"policy-item-info-line"}>
                                    <span style={{color: "rgb(95,95,95)"}}>
                                        <span style={{paddingRight: "10px"}}>
                                             {"发布时间："}
                                        </span>
                                        <span>
                                            {item["policyDate"]}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            data.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }
        return data;
    }

    render() {
        let adLeft = this.state.adLeft;
        let right = "calc((100% - " + adLeft + " ) / 2)";
        let totalGap = this.state.gap - this.state.adjustGap;
        let gapR = totalGap === 0 ? "0" : parseInt(totalGap + "") + "px";
        let wH = parseInt((this.state.cH - 30) + "") + "px";
        let partitioner = this.state.partitioner;
        let options = this.state.options;
        let advMap = this.state.advMap;
        console.log(options);
        console.log("advMap: ", advMap);

        return (
            <div className={"home-ct"}>
                <Header/>
                <div className={"policy-page"}>
                    <PolicyFilter parent={this} total={this.state.partitioner.totalSize} options={options}/>
                    <div className={"policy-page-content"} id={"expert-outer"}>
                        <div className={"policy-ct-data"}>
                            {this.loadPolicyData()}
                            <div className={"outcome-page-partition"}
                                 style={{display: partitioner.showData ? "" : "none"}}
                            >
                                <Partitioner parent={this} loadSetting={loadSetting} options={options}
                                             // handleCheckMonitor={handleIdList}
                                />
                            </div>
                        </div>
                        {
                            advMap.size === advList.policy.length ?
                                <AdRight fixedAd={this.state.fixedAd} adBt={this.state.adBt} gapR={gapR} right={right}
                                         wholeHgt={wH} adClasses={adClasses}
                                         adTitleOne={"精选科技成果"} adOneCt={LoadingOutcomeCopAds(advMap.get(dataIndexE.outcome))} adOneUrl={OutcomeRoot.path}
                                         adTitleTwo={"推荐专家"} adTwoCt={LoadingExpertCopAds(advMap.get(dataIndexE.expert))} adTwoUrl={ExpertRoot.path}
                                />
                                : null
                        }
                    </div>
                </div>
                <Contact fixed={this.state.fixed}/>
                <div>
                    <Footer/>
                </div>
            </div>
        )
    }
}

export default PolicyPage;
