import React, {Component} from 'react';
import "./about.css";
import Bk from "../../assets/about/bk.jpg";
import IMG1 from "../../assets/about/company_info_1.jpg";
import IMG2 from "../../assets/about/company_info_2.jpg";
import IMG3 from "../../assets/about/company_info_3.jpg";

class CompanyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerImg: Bk,
        }
    }

    componentDidMount() {
        this.props.parent.loadData(this.state.bannerImg)
    }

    render() {
        return (
            <div className={"about-com-ct"}>
                <div className={"about-com-ct-level-one"}>
                    <div className={"about-com-ct-title"}>
                        {"湃通长三角一体化科创服务平台 —— 一站式科技创新服务平台"}
                    </div>
                    <div className={"about-com-ct-content"}>
                        <span>{"“长三角一体化科创服务平台”是由上海湃通科技有限责任公司联合长三角城市科技主管部门发起的，专门服务于长三角高校科研院所和全国科技型企业的综合型科创服务平台。"}</span>
                        <span>{"平台按照“1+4+X”的战略构想，搭建以科技成果转移转化为核心，以企业技术创新、政府协同创新、院校科研创新、科技人才创新为支撑，集地方产业规划、科技园区运营、科技项目孵化、产学研协同、投融资融合、科技峰会运营、科技项目评估、企业工商注册、知识产权布局、企业人才培训、企业财务辅导、产品检测认证、政府项目申报、法律咨询服务为一体的综合性业务体系。"}</span>
                        <span>{"打通创新链、产业链、资金链、人才链、服务链高效融合的通道，让创新要素快速转换为现实生产力。"}</span>
                    </div>
                </div>
                <div className={"about-com-ct-img"}>
                    <img className={"img-style contain"} src={IMG1} alt={"company"}/>
                </div>
                <div className={"about-com-ct-level-three"}>
                    <div className={"about-com-ct-content"}>
                        <span>{"上海湃通科技有限公司成立于2019年，是由“上海市经济和信息化委员会”、“上海市教育委员会”、“上海市科学技术委员会”、“上海市知识产权局”四家单位支持下成立的一家民营科技服务型企业。多年来，湃通科技致力于为商界、政界提供科学理性、富于前瞻性和可操作性的智力服务，完成了国内多个园区、企业集团、各级政府的相关技术服务和咨询课题。"}</span>
                        <span>{"湃通科技目前已经形成了“战略咨询、园区运营、成果转化、科技峰会、招商引资、产业投资”六位一体、相互促进的综合优势。 湃通科技将创新成果与商业机遇有效结合，并带来切实的收益，逐渐向业内的知名品牌迈进。"}</span>
                    </div>
                </div>
                <div className={"about-com-ct-img"}>
                    <img className={"img-style contain"} src={IMG2} alt={"company"}/>
                </div>
                <div className={"about-com-ct-img"}>
                    <img className={"img-style contain"} src={IMG3} alt={"company"}/>
                </div>
            </div>
        )
    }
}

export default CompanyInfo;
