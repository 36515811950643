import React, {Component} from "react";
import "./page.css";

class ScriptParent extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    loadInfoLayout = (title, value, loginModule) => {
        return (
            <div className={"script-item-info-tag"}>
                 <span className={"script-item-info-tag-title"}>
                    {title}
                </span>
                <span className={"script-item-info-tag-value"}>
                    {value}
                </span>
                {loginModule}
            </div>
        )
    }
}

export default ScriptParent;