import React, {Component, Fragment} from "react";
import "./center.css";
import {InfoCircleOutlined, LeftOutlined} from "@ant-design/icons";
import CERT_ICON from "../../assets/icon/cert_icon.png";
import CERT_COM_ICON from "../../assets/icon/cert_com_icon.png";
import ID_FRONT from "../../assets/img/idFront_sample.jpg";
import ID_BACK from "../../assets/img/idBack_sample.jpg";
import COMP_SRC_SAMPLE from "../../assets/img/comp_src_sample.jpg";
import EXP_SRC_SAMPLE from "../../assets/img/exp_src_sample.jpg";
import {handleImgUpload, picTypes} from "../../tool/Const";
import {
    error,
    genPojo,
    ProcessCompAuthUrl,
    ProcessExpAuthUrl,
    ProcessPersonAuthUrl,
    sendPostRequest
} from "../../tool/http/Request";
import {connectionError, fail, findMsgByError, success} from "../../tool/http/ErrorMgr";

// 资质认证要求
const certNoticeList = [
    {
        type: 1,
        notes: [
            "1.每个证件只能绑定一个账号",
            "2.证件照不清晰或与输入的信息不匹配,将导致实名认证被驳回",
            "3.您提供的证件信息将受到严格保护，仅用于身份验证,未经本人许可不会用于其他用途",
            "4.除原证件无效（如：改名、移民）等特殊情况外，实名认证审核通过后将不能修改",
        ],
        certRequire: [
            "1.需上传身份证正反面照片",
            "2.证件必须在有效期内，有效期需在一个月以上",
        ],
        picRequire: [
            "1.证件照上信息需完整且清晰可辨（无反光、遮挡、水印、证件套、logo等）",
            "2.申请人填写的“真实姓名”和“证件号码”需和提交证件照片信息一致",
            "3.证件必须真实拍摄，不能使用复印件",
            "4.确保照片完整（不缺边角），证件周围不允许加上边角框（如：加上红框等）",
        ]
    },
    {
        type: 2,
        notes: [
            "1.每个证件只能绑定一个账号",
            "2.证件照不清晰或与输入的信息不匹配,将导致实名认证被驳回",
            "3.您提供的证件信息将受到严格保护，仅用于身份验证,未经本人许可不会用于其他用途",
            "4.除原证件无效（如：改名、移民）等特殊情况外，实名认证审核通过后将不能修改",
        ],
        certRequire: [
            "1.需上传加盖公章的企业营业执照复印件",
            "2.证件必须在有效期内，有效期需在一个月以上",
        ],
        picRequire: [
            "1.企业营业执照复印件清晰可辨",
            "2.证件照上信息需完整且清晰可辨（无反光、遮挡、水印、证件套、logo等）",
            "3.申请人填写的“真实姓名”和“证件号码”需和提交证件照片信息一致",
            "4.证件必须真实拍摄，不能使用复印件",
            "5.确保照片完整（不缺边角），证件周围不允许加上边角框（如：加上红框等）",
        ]
    },
    {
        type: 3,
        notes: [
            "1.每个证件只能绑定一个账号",
            "2.证件照不清晰或与输入的信息不匹配,将导致实名认证被驳回",
            "3.您提供的证件信息将受到严格保护，仅用于身份验证,未经本人许可不会用于其他用途",
            "4.除原证件无效（如：改名、移民）等特殊情况外，实名认证审核通过后将不能修改",
        ],
        certRequire: [
            "1.需上传加盖公章的事业单位法人证书/机构登记证书复印件",
            "2.证件必须在有效期内，有效期需在一个月以上",
        ],
        picRequire: [
            "1.事业单位法人证书/机构登记证书复印件清晰可辨",
            "2.证件照上信息需完整且清晰可辨（无反光、遮挡、水印、证件套、logo等）",
            "3.申请人填写的“真实姓名”和“证件号码”需和提交证件照片信息一致",
            "4.证件必须真实拍摄，不能使用复印件",
            "5.确保照片完整（不缺边角），证件周围不允许加上边角框（如：加上红框等）",
        ]
    }
]

// 证件类型list
const certCardTypeList = [
    "身份证",
    "港澳居民来往内地通行证",
    "台湾居民来往大陆通行证",
    "护照(中国签发)",
    "外国人永久居留证",
    "其他国家或地区身份证明",
]

// 专家来源-机构类型list
const orgTypeList = ["高等院校", "科研院所", "企业"]

class CertificationComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            certInfo: {  // 认证信息
                // 个人认证
                userId: '',
                userReal: '',
                idType: 1,  // 身份证
                idNum: '',
                idFront: '',
                idBack: '',
                // 企业认证
                userComp: '',
                compCode: '',  // 统一社会信用代码
                compSrc: '',  // 企业营业执照
                compAdd: '',  // 营业执照注册地
                legalRep: '',  // 法定代表人

                // 机构认证
                orgType: '',  // 机构类型
                // userOrg: '',  // 专家所属机构
                // orgCode: '',  // 专家所属机构的统一社会信用代码
                expSrc: [],  // 专家认证照片

                userPerson: this.props.userInfo.per,  // 是否使用个人认证资料
                extraSource: [],
            },
            imgUrl: {
                idFront: '',
                idBack: '',
                compSrc: '',
                expSrc: [],
            },
        }
    }

    // 切换资质认证要求
    loadCertNotice = (certNoticeList, type) => {
        let data = certNoticeList.filter(it => it["type"] === type);
        if (data) {
            return (
                <Fragment>
                    <div className={"cert-process-notice-ele"}>
                        <div className={"cert-process-notice-ele-header"}>
                            <InfoCircleOutlined className={"notice-icon"}/>
                            {"注意事项"}
                        </div>
                        <div className={"cert-process-notice-ele-ct-wrap"}>
                            {this.loadCertCom(data[0].notes)}
                        </div>
                    </div>
                    <div className={"cert-process-notice-ele"} style={{margin: '20px 0'}}>
                        <div className={"cert-process-notice-ele-header"}>
                            <InfoCircleOutlined className={"notice-icon"}/>
                            {"证件要求"}
                        </div>
                        <div className={"cert-process-notice-ele-ct-wrap"}>
                            {this.loadCertCom(data[0].certRequire)}
                        </div>
                    </div>
                    <div className={"cert-process-notice-ele"}>
                        <div className={"cert-process-notice-ele-header"}>
                            <InfoCircleOutlined className={"notice-icon"}/>
                            {"照片要求"}
                        </div>
                        <div className={"cert-process-notice-ele-ct-wrap"}>
                            {this.loadCertCom(data[0].picRequire)}
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    // 加载资质认证组件
    loadCertCom = (com) => {
        let list = [];
        com.forEach((it, index)=>{
            list.push (
                <div className={"cert-process-notice-ele-ct"} key={index}>
                    {it}
                </div>
            )
        })
        return list;
    }

    // 加载资质认证具体填写信息
    loadCertCt = (userInfo, type, certInfo, imgUrl) => {
        let picHeader = "https://localhost:3000/";
        switch (type) {
            case 1: {
                return (
                    <Fragment>
                        <div className={"cert-process-ct-ele"}>
                            <div className={"cert-process-ct-ele-label"}>
                                {"真实姓名："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"realName"}
                                   type={"text"}
                                   placeholder={"请填写真实姓名"}
                                   onChange={(e) => {this.fillCertInfo(e, type, certInfo, "userReal")}}
                            />
                        </div>
                        {/*<div className={"cert-process-ct-ele"}>*/}
                        {/*    <div className={"cert-process-ct-ele-label"}>*/}
                        {/*        {"证件类型："}*/}
                        {/*    </div>*/}
                        {/*    <select className={"account-ct-user-setting-input"}*/}
                        {/*            style={{width: '422px', height: '38px'}}*/}
                        {/*            defaultValue={"身份证"}*/}
                        {/*            onChange={(e) => {this.fillCertInfo(e, type, certInfo, "idType")}}*/}
                        {/*    >*/}
                        {/*        {this.loadCertCardSelect(certCardTypeList)}*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        {/*<div className={"cert-process-ct-ele"}>*/}
                        {/*    <div className={"cert-process-ct-ele-label"}>*/}
                        {/*        {"证件号码："}*/}
                        {/*    </div>*/}
                        {/*    <input className={"account-ct-user-setting-input"}*/}
                        {/*           id={"id"}*/}
                        {/*           type={"text"}*/}
                        {/*           placeholder={"请填写身份证号码"}*/}
                        {/*           onChange={(e) => {this.fillCertInfo(e, type, certInfo, "idNum")}}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className={"cert-process-pic-upload"}>
                            <div className={"cert-process-pic-upload-title"}>
                                {"请上传您的证件照"}
                            </div>
                            <div className={"cert-process-pic-upload-ct"}>
                                <div className={"cert-process-pic-upload-ele"}>
                                    <div className={"cert-process-pic-upload-img"}>
                                        <img className={"img-style contain"}
                                             src={certInfo.idFront ? imgUrl.idFront : ID_FRONT} alt={"id_front"}/>
                                    </div>
                                    <div className={"cert-process-pic-upload-fun"}>
                                        <div className={"cert-process-pic-upload-txt"}>
                                            {"上传证件正面照（2M以内）"}
                                        </div>
                                        <label htmlFor={"idFront"} className={"cert-process-pic-upload-bt"}>
                                            {"点击上传"}
                                        </label>
                                        <input type={"file"} name={"file"} id={"idFront"}
                                               className={"input-file"}
                                               accept={picTypes}
                                               onChange={(e) => {
                                                   handleImgUpload(e,1400, 1400, certInfo, imgUrl, "idFront", this)
                                               }}
                                        />
                                    </div>
                                </div>
                                <div className={"cert-process-pic-upload-ele"}>
                                    <div className={"cert-process-pic-upload-img"}>
                                        <img className={"img-style contain"}
                                             src={certInfo.idBack ? imgUrl.idBack: ID_BACK} alt={"id_front"}/>
                                    </div>
                                    <div className={"cert-process-pic-upload-fun"}>
                                        <div className={"cert-process-pic-upload-txt"}>
                                            {"上传证件背面照（2M以内）"}
                                        </div>
                                        <label htmlFor={"idBack"} className={"cert-process-pic-upload-bt"}>
                                            {"点击上传"}
                                        </label>
                                        <input type={"file"} name={"file"} id={"idBack"}
                                               className={"input-file"}
                                               accept={picTypes}
                                               onChange={(e) => {
                                                   handleImgUpload(e,1400, 1400, certInfo, imgUrl, "idBack", this)
                                               }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }
            case 2: {
                return (
                    <Fragment>
                        <div className={"cert-process-ct-ele"}>
                            <div className={"cert-process-ct-ele-label auth-comp"}>
                                {"企业名称："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"realName"}
                                   type={"text"}
                                   placeholder={"请填写企业名称"}
                                   onChange={(e) => {this.fillCertInfo(e, type, certInfo, "userComp")}}
                            />
                        </div>
                        {/*<div className={"cert-process-ct-ele"}>*/}
                        {/*    <div className={"cert-process-ct-ele-label auth-comp"}>*/}
                        {/*        {"统一社会信用代码："}*/}
                        {/*    </div>*/}
                        {/*    <input className={"account-ct-user-setting-input"}*/}
                        {/*           id={"realName"}*/}
                        {/*           type={"text"}*/}
                        {/*           placeholder={"请填写统一社会信用代码"}*/}
                        {/*           onChange={(e) => {this.fillCertInfo(e, type, certInfo, "compCode")}}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className={"cert-process-ct-ele auth-comp-license"}>
                            <div className={"cert-process-ct-ele-label auth-comp"}>
                                {"企业营业执照："}
                            </div>
                            <div className={"cert-process-pic-upload-ele"} style={{width: 'auto'}}>
                                <div className={"cert-process-pic-upload-img"}>
                                    <img className={"img-style contain"}
                                         src={certInfo.compSrc ? imgUrl.compSrc: COMP_SRC_SAMPLE} alt={"comp_license"}/>
                                </div>
                                <div className={"cert-process-pic-upload-fun"}>
                                    <div className={"cert-process-pic-upload-txt"}>
                                        {"上传加盖公章的企业营业执照复印件（2M以内）"}
                                    </div>
                                    <label htmlFor={"compSrc"} className={"cert-process-pic-upload-bt"}>
                                        {"点击上传"}
                                    </label>
                                    <input type={"file"} name={"file"} id={"compSrc"}
                                           className={"input-file"}
                                           accept={picTypes}
                                           onChange={(e) => {
                                               handleImgUpload(e,1400, 1400, certInfo, imgUrl, "compSrc", this)
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"cert-process-ct-ele"}>
                            <div className={"cert-process-ct-ele-label auth-comp"}>
                                {"法定代表人："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"realName"}
                                   type={"text"}
                                   placeholder={"请填写法定代表人"}
                                   onChange={(e) => {this.fillCertInfo(e, type, certInfo, "legalRep")}}
                            />
                        </div>
                        <div className={"cert-process-ct-ele auth-comp-license"}>
                            <div className={"cert-process-ct-ele-label auth-comp"}>
                                {"使用个人认证资料："}
                            </div>
                            <div className={"cert-user-person-checkbox-wrap"}>
                                <div className={"cert-user-person-checkbox"}>
                                    <input className={""}
                                           type={"checkbox"}
                                           checked={certInfo.userPerson}
                                           onChange={() => {this.handleUserPerson(certInfo)}}
                                    />
                                    <div>
                                        {"使用个人认证资料作为法人代表人认证资料"}
                                    </div>
                                </div>
                                <div className={"cert-user-person-checkbox"}>
                                    <input className={""}
                                           type={"checkbox"}
                                           checked={!certInfo.userPerson}
                                           onChange={() => {this.handleUserPerson(certInfo)}}
                                    />
                                    <div>
                                        {"不使用个人认证资料作为法人代表人认证资料"}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            certInfo.userPerson ?
                                "" :
                                this.loadCertCt(userInfo, 1, certInfo, imgUrl)
                        }
                    </Fragment>
                )
            }
            case 3: {
                return (
                    <Fragment>
                        {
                            certInfo.per ?
                                "" :
                                this.loadCertCt(userInfo, 1, certInfo, imgUrl)
                        }
                        <div className={"cert-process-ct-ele"}>
                            <div className={"cert-process-ct-ele-label auth-comp"}>
                                {"机构类型："}
                            </div>
                            <div className={"cert-process-org-type-checkbox-wrap"}>
                                {this.loadOrgTypeSelect(orgTypeList, certInfo)}
                            </div>
                        </div>
                        <div className={"cert-process-ct-ele"}>
                            <div className={"cert-process-ct-ele-label auth-comp"}>
                                {"机构名称："}
                            </div>
                            <input className={"account-ct-user-setting-input"}
                                   id={"realName"}
                                   type={"text"}
                                   placeholder={"请填写机构全称"}
                                   onChange={(e) => {this.fillCertInfo(e, type, certInfo, "userComp")}}
                            />
                        </div>
                        {/*<div className={"cert-process-ct-ele"}>*/}
                        {/*    <div className={"cert-process-ct-ele-label auth-comp"}>*/}
                        {/*        {"统一社会信用代码："}*/}
                        {/*    </div>*/}
                        {/*    <input className={"account-ct-user-setting-input"}*/}
                        {/*           id={"realName"}*/}
                        {/*           type={"text"}*/}
                        {/*           placeholder={"请填写统一社会信用代码"}*/}
                        {/*           onChange={(e) => {this.fillCertInfo(e, type, certInfo, "compCode")}}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className={"cert-process-ct-ele auth-comp-license"}>
                            <div className={"cert-process-ct-ele-label auth-comp"}>
                                {"专家认证证书/对应机构登记证书："}
                            </div>
                            <div className={"cert-process-pic-upload-ele"} style={{width: 'auto'}}>
                                <div className={"cert-process-pic-upload-img-wrap"}>
                                    {this.loadExpSrc(certInfo.expSrc, imgUrl)}
                                </div>
                                <div className={"cert-process-pic-upload-fun"}>
                                    <div className={"cert-process-pic-upload-txt"}>
                                        {"上传加盖公章的事业单位法人证书/机构登记证书复印件（2M以内, 最多上传三张）"}
                                    </div>
                                    <label htmlFor={"expSrc"} className={"cert-process-pic-upload-bt"}
                                           style={{pointerEvents : certInfo.expSrc.length === 3 ? "none" : "",
                                               background: certInfo.expSrc.length === 3 ? "#888888" : "",
                                               color: certInfo.expSrc.length === 3 ? "#e1e1e1" : ""
                                           }}
                                    >
                                        {"点击上传"}
                                    </label>
                                    <input type={"file"} name={"file"} id={"expSrc"}
                                           className={"input-file"}
                                           accept={picTypes}
                                           onChange={(e) => {
                                               handleImgUpload(e,1400, 1400, certInfo, imgUrl, "expSrc", this)
                                           }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }
    }

    // 加载证件类型select
    loadCertCardSelect = (typeList) => {
        let list = [];
        if (typeList) {
            typeList.forEach((item, index) => {
                list.push(
                    <option value={index} key={index}>
                        {item}
                    </option>
                )
            })
            return list;
        }
    }

    // 加载专家认证图片
    loadExpSrc = (src, imgUrl) => {
        let list = [];
        if (src.length > 0) {
            imgUrl.expSrc.forEach((item, index) => {
                list.push(
                    <div className={"cert-process-pic-upload-img"}>
                        <img className={"img-style contain"}
                             src={item} alt={"exp_src"}/>
                    </div>
                )
            })
            return list;
        } else {
            list.push(
                <div className={"cert-process-pic-upload-img"}>
                    <img className={"img-style contain"}
                         src={EXP_SRC_SAMPLE} alt={"exp_src"}/>
                </div>
            )
            return list;
        }
    }

    // 填充资质认证信息
    fillCertInfo = (e, type, certInfo, tag) => {
        let value = e.target.value;
        console.log("输入信息：", tag, value);
        certInfo[tag] = value;
        console.log("certInfo: ", certInfo);
    }

    // 处理是否使用个人认证资料勾选select
    handleUserPerson = (certInfo) => {
        let value = certInfo.userPerson;
        console.log(value);
        certInfo.userPerson = !value;
        this.setState({
            certInfo: certInfo,
        })
    }

    // 加载机构类型select
    loadOrgTypeSelect = (orgTypeList, certInfo) => {
        let list = [];
        orgTypeList.forEach((item, index) => {
            list.push(
                <div className={"cert-process-org-type-checkbox"}>
                    <input className={""}
                           type={"checkbox"}
                           checked={certInfo.orgType === index + 1}
                           onChange={() => {this.handleOrgType(index + 1, certInfo)}}
                    />
                    <div>
                        {item}
                    </div>
                </div>
            )
        })
        return list;
    }

    // 处理机构类型勾选
    handleOrgType = (index, certInfo) => {
        console.log("index: ", index);
        certInfo.orgType = index;
        this.setState({
            certInfo: certInfo,
        })
    }

    // 处理资质认证
    handleProcessAuth = (userInfo, type, certInfo) => {
        certInfo.userId = userInfo.userId;
        console.log("提交认证：", type, certInfo);
        // 个人认证信息
        let userReal = certInfo.userReal;
        let idNum = certInfo.idNum;
        let idFront = certInfo.idFront;
        let idBack = certInfo.idBack;
        // 企业认证信息
        let userComp = certInfo.userComp;
        let compCode = certInfo.compCode;
        let compSrc = certInfo.compSrc;
        // let compAdd = certInfo.compAdd;
        let legalRep = certInfo.legalRep;
        // 专家认证信息
        let orgType = certInfo.orgType;
        let expSrc = certInfo.expSrc;

        let userPerson = certInfo.userPerson;

        let isAllFilledPerAuth = userReal && idFront && idBack;  // 个人认证必须填写的信息
        let isAllFilledCompAuth = userPerson ? userComp && compSrc && legalRep
            : userComp && compSrc && legalRep && isAllFilledPerAuth;  // 企业认证必须填写的信息（分使用个人认证资料和不使用个人认证资料）
        let isAllFilledExpAuth = userPerson ? orgType && userComp && (expSrc.length > 0)
            : orgType && userComp && (expSrc.length > 0) && isAllFilledPerAuth;

        // let isAllFilledPerAuth = userReal && idNum && idFront && idBack;  // 个人认证必须填写的信息
        // let isAllFilledCompAuth = userPerson ? userComp && compCode && compSrc && legalRep
        //     : userComp && compCode && compSrc && legalRep && isAllFilledPerAuth;  // 企业认证必须填写的信息（分使用个人认证资料和不使用个人认证资料）
        // let isAllFilledExpAuth = userPerson ? orgType && userComp && compCode && (expSrc.length > 0)
        //     : orgType && userComp && compCode && (expSrc.length > 0) && isAllFilledPerAuth;
        //
        // console.log("userPerson: ", userPerson)
        // console.log("orgType: ", orgType)
        // console.log("userComp: ", userComp)
        // console.log("compCode: ", compCode)
        // console.log("expSrc: ", expSrc.length > 0)
        // console.log("isAllFilledPerAuth: ", isAllFilledPerAuth)
        // console.log("isAllFilledExpAuth: ", isAllFilledExpAuth)

        switch (type) {
            case 1: {
                if (isAllFilledPerAuth) {
                    sendPostRequest(genPojo(ProcessPersonAuthUrl, certInfo), true).then(res => {
                        console.log("个人认证res", res);
                        if (res === connectionError) {
                            fail(findMsgByError(connectionError));
                        } else {
                            if (res && res.hasOwnProperty("success") && res.success) {
                                success("个人认证信息已提交，请等待审核");
                            } else {
                                fail(findMsgByError(res[error]))
                            }
                        }
                    })
                } else {
                    fail("个人认证资料请填写完整")
                }
                break;
            }
            case 2: {
                if (isAllFilledCompAuth) {
                    sendPostRequest(genPojo(ProcessCompAuthUrl, certInfo), true).then(res => {
                        console.log("企业认证res", res);
                        if (res === connectionError) {
                            fail(findMsgByError(connectionError));
                        } else {
                            if (res && res.hasOwnProperty("success") && res.success) {
                                success("企业认证信息已提交，请等待审核");
                            } else {
                                fail(findMsgByError(res[error]))
                            }
                        }
                    })
                } else {
                    fail("企业认证资料请填写完整")
                }
                break;
            }
            case 3: {
                if (isAllFilledExpAuth) {
                    sendPostRequest(genPojo(ProcessExpAuthUrl, certInfo), true).then(res => {
                        console.log("专家认证信息已提交认证res", res);
                        if (res === connectionError) {
                            fail(findMsgByError(connectionError));
                        } else {
                            if (res && res.hasOwnProperty("success") && res.success) {
                                success("专家认证信息已提交，请等待审核");
                            } else {
                                fail(findMsgByError(res[error]))
                            }
                        }
                    })
                } else {
                    fail("专家认证资料请填写完整")
                }
                break;
            }
        }
    }



    render() {
        let userInfo = this.props.userInfo;
        let type = this.props.type;
        let certInfo = this.state.certInfo;
        let imgUrl = this.state.imgUrl;
        // console.log("certInfo: ", certInfo);

        return (
            <div className={"cert-process-wrap"}>
                <div className={"cert-process-header"}>
                    <div className={"back-icon"} onClick={() => {this.props.parent.switchType(0)}}>
                        <LeftOutlined />
                    </div>
                    <div className={"cert-process-icon"}>
                        <img className={"img-style contain"} src={type === 1 ? CERT_ICON : CERT_COM_ICON} alt={"icon"}/>
                    </div>
                    <div className={"cert-process-type"}>
                        <div className={"cert-process-type-title"}>
                            {type === 1 ? "个人认证" : (type === 2 ? "企业认证" : "专家认证")}
                        </div>
                        <div className={"cert-process-type-subtitle"}>
                            {"请填写真实完善的信息，资质认证资料审核通过后不能修改。"}
                        </div>
                    </div>
                </div>
                <div className={"cert-process-notice"}>
                    {this.loadCertNotice(certNoticeList, type)}
                </div>
                <div className={"cert-process-ct"}>
                    <div className={"cert-process-ct-title"}>
                        {"填写信息"}
                    </div>
                    {this.loadCertCt(userInfo, type, certInfo, imgUrl)}
                </div>
                <div className={"cert-process-button-wrap"}>
                    <button className={"center-ct-button save-button"}
                            onClick={() => {this.handleProcessAuth(userInfo, type, certInfo)}}
                    >
                        {"提交认证"}
                    </button>
                </div>
            </div>
        )
    }
}

export default CertificationComponent;