import React, {Component} from 'react';
import "../page.css";
import {RightOutlined} from "@ant-design/icons";
import IMG1 from "../../assets/partner/复旦大学.jpg";
import IMG2 from "../../assets/partner/同济大学.jpg";
import IMG3 from "../../assets/partner/华东政法大学.jpg";
import IMG4 from "../../assets/partner/上海视觉艺术学院.jpg";
import IMG5 from "../../assets/partner/上海交通职业技术学院.jpg";
import IMG6 from "../../assets/partner/76.jpg";
import IMG7 from "../../assets/partner/125.jpg";
import IMG8 from "../../assets/partner/130.jpg";
import IMG9 from "../../assets/partner/131.jpg";
import IMG10 from "../../assets/partner/133.jpg";
import IMG11 from "../../assets/partner/135.jpg";
import IMG12 from "../../assets/partner/136.jpg";
import IMG13 from "../../assets/partner/307.jpg";
import IMG14 from "../../assets/partner/308.jpg";
import IMG15 from "../../assets/partner/309.jpg";
import IMG16 from "../../assets/partner/310.jpg";
import IMG17 from "../../assets/partner/311.jpg";
import IMG18 from "../../assets/partner/312.jpg";
import IMG19 from "../../assets/partner/313.jpg";
import IMG20 from "../../assets/partner/315.jpg";
import IMG21 from "../../assets/partner/317.jpg";
import IMG22 from "../../assets/partner/318.jpg";
import IMG23 from "../../assets/partner/320.jpg";
import IMG24 from "../../assets/partner/324.jpg";
import IMG25 from "../../assets/partner/327.jpg";
import IMG26 from "../../assets/partner/329.jpg";
import IMG27 from "../../assets/partner/655.jpg";
import IMG28 from "../../assets/partner/666.jpg";
import IMG29 from "../../assets/partner/671.jpg";
import IMG30 from "../../assets/partner/676.jpg";
import IMG31 from "../../assets/partner/682.jpg";
import IMG32 from "../../assets/partner/936.jpg";
import IMG33 from "../../assets/partner/1010.jpg";
import IMG34 from "../../assets/partner/1011.jpg";
import IMG35 from "../../assets/partner/3039.jpg";
import IMG36 from "../../assets/partner/3151.jpg";
import IMG37 from "../../assets/partner/3194.jpg";
import IMG38 from "../../assets/partner/3427.jpg";
import IMG39 from "../../assets/partner/上海科学院.jpg";
import IMG40 from "../../assets/partner/中国科学院.jpg";

class Partner extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    loadPartnerPart = (img, name, key, url) => {
        return (
            <div className={"partner-item"} key={key}
                 onClick={() => {
                     window.open(url, "_blank")
                 }}
            >
                <img className={"partner-img-style"} src={img} alt={""}/>
                <span className={"partner-name"}>{name}</span>
            </div>
        )
    }

    render() {
        return <div className={"out-content"}>
            <div className={"partner-ot"}>
                <div className={"page-ct"}>
                    <div className={"info-header-title"}>
                        <div className={"info-header-name"}>
                            {"合作伙伴"}
                        </div>
                        <div className={"info-header-des"}>
                            {"携手湃通共创科技新篇章"}
                        </div>
                    </div>
                    <div className={"partner-grid"}>
                        {this.loadPartnerPart(IMG1, "复旦大学", "fddx", "https://www.fudan.edu.cn/")}
                        {this.loadPartnerPart(IMG7, "上海交通大学", "jtdx", "https://www.sjtu.edu.cn/")}
                        {this.loadPartnerPart(IMG2, "同济大学", "tjdx", "https://www.tongji.edu.cn/")}
                        {this.loadPartnerPart(IMG9, "华东师范大学", "hdsfdx", "https://www.ecnu.edu.cn/")}
                        {this.loadPartnerPart(IMG10, "华东理工大学", "hdlgdx", "https://www.ecust.edu.cn/")}
                        {this.loadPartnerPart(IMG11, "东华大学", "dhdx", "https://www.dhu.edu.cn/")}
                        {this.loadPartnerPart(IMG8, "上海财经大学", "shcjdx", "https://www.sufe.edu.cn/")}
                        {this.loadPartnerPart(IMG6, "上海大学", "shdx", "https://www.shu.edu.cn/")}
                        {this.loadPartnerPart(IMG13, "上海理工大学", "shlgdx", "https://www.usst.edu.cn/main.htm")}
                        {this.loadPartnerPart(IMG16, "上海中医药大学", "shzyydx", "https://www.shutcm.edu.cn/")}
                        {this.loadPartnerPart(IMG17, "上海师范大学", "shsfdx", "https://www.shnu.edu.cn/")}
                        {this.loadPartnerPart(IMG3, "华东政法大学", "hdzfdx", "https://www.ecupl.edu.cn/")}
                        {this.loadPartnerPart(IMG19, "上海海事大学", "shhsdx", "https://www.shmtu.edu.cn/")}
                        {this.loadPartnerPart(IMG15, "上海海洋大学", "shhydx", "https://www.shou.edu.cn/")}
                        {this.loadPartnerPart(IMG14, "上海工程技术大学", "shgcjsdx", "https://www.sues.edu.cn/")}
                        {this.loadPartnerPart(IMG21, "上海电力大学", "shdldx", "https://www.shiep.edu.cn/")}
                        {this.loadPartnerPart(IMG20, "上海应用技术大学", "shyyjsdx", "https://www.sit.edu.cn/")}
                        {this.loadPartnerPart(IMG18, "上海第二工业大学", "shdegydx", "http://www.sspu.edu.cn/")}
                        {this.loadPartnerPart(IMG36, "上海科技大学", "shkjdx", "https://www.shanghaitech.edu.cn/")}
                        {this.loadPartnerPart(IMG24, "上海体育学院", "shtyxy", "https://www.sus.edu.cn/")}
                        {this.loadPartnerPart(IMG39, "上海科学院", "shkxy", "https://www.sast.org.cn/")}
                        {this.loadPartnerPart(IMG40, "中国科学院", "zgkxy", "https://www.cas.cn/")}
                    </div>
                    <div className={"page-more"} style={{marginTop: "50px"}}
                         onClick={() => {
                             window.location.href = "/about?type=4"
                         }}
                    >
                        <span className={"page-more-text"}>
                            查看更多
                        </span>
                        <RightOutlined className={"page-more-icon"}/>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Partner;
