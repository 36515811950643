import React, {Component} from 'react';
import "./news.css";
import {
    advList,
    dataIndexE,
    DemandRoot,
    getAdvData,
    handleAdRightWinSizeChange, handleAdRightWinSizeInit,
    handleClickMonitor,
    handlePageScrollMove,
    LoadingDemandCopAds,
    LoadingPolicyCopAds,
    NewsRoot,
    PolicyRoot,
    UserInfoStorage
} from "../../tool/Const";
import Header from "../Header";
import Partitioner from "../../tool/Partitioner";
import AdRight from "../../tool/AdRight";
import Contact from "../home/Contact";
import Footer from "../Footer";
import EMPTY from "../../assets/img/record_empty.svg";
import {SrcHeader, srcHeader} from "../../tool/http/Request";

const adClasses = {
    adOuter: "news-ct-ad",
    adOne: "news-ct-ad-one",
    adTwo: "news-ct-ad-two",
}

const loadSetting = {
    loadingBatch: 12,
    classes: {
        outer: "outcome-page-partition-ct",
    }
}

class NewsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fixed: true,
            fixedAd: false,
            adLeft: "",
            adBt: false,
            showAd: true,
            gap: 0,
            cH: 0,
            hoverI: -1,
            adjustGap: 0,
            openSize: 0,
            partitioner: {
                totalSize: 0,
                showData: [],
                loadComplete: false,
            },
            options: {
                index: '3566',
                from: 0,
            },
            idList: [],
            advMap: new Map(),  // adv数据
        };
    }

    componentDidMount() {
        handleAdRightWinSizeInit(this);
        window.addEventListener("resize", this.handleResize)
        window.onscroll = () => {
            let cH = document.getElementById("news-outer").clientHeight;
            let cHC = this.state.cH;

            let headerH = document.getElementById("header").clientHeight;
            let bannerH = document.getElementById("banner").clientHeight;
            let paddingH = 40;
            let adH = document.getElementById("ad").clientHeight;
            let contactH = 42;
            let distanceAboveAd = headerH + bannerH + paddingH + adH + contactH - 60;  // 1568
            let distanceBtAd = headerH + bannerH + paddingH + contactH - 60;  // 410
            let distanceContact = headerH + bannerH + paddingH + contactH - 90;  // 380

            handlePageScrollMove(this, "", distanceAboveAd, distanceBtAd + cH, distanceContact + cH, "news-outer", 940)
            if (cHC !== cH) {
                this.setState({
                    cH: cH,
                })
            }
        }
        getAdvData(advList.news, this);
    }

    MouseHover = (index, hoverI) => {
        if (index !== hoverI) {
            this.setState({
                hoverI: index,
            })
        }
    }

    handleResize = () => {
        handleAdRightWinSizeChange("news-outer", this);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize)
    }

    loadNewsData = () => {
        let data = []
        let showData = this.state.partitioner.showData;
        let hoverI = this.state.hoverI;
        let userInfo = UserInfoStorage.get("userInfo");

        if (showData) {
            showData.forEach((item, index) => {
                // let picDir = require.context("../pic/", true, /\.(png|jpg)$/);
                // let imgUrl = picDir("./" + item["newsBanner"]);
                let imgUrl = SrcHeader + item["newsId"] + "/" + item["newsBanner"];
                data.push(
                    <div className={"news-data-item"} key={index}
                         onMouseEnter={this.MouseHover.bind(this, index, hoverI)}
                         onMouseLeave={this.MouseHover.bind(this, -1, hoverI)}
                         onClick={() => {
                             handleClickMonitor(userInfo, item["newsId"], 2, 1, NewsRoot.path + "/" + item["newsId"])
                         }}
                    >
                        <div className={"news-img-outer"}>
                            <div className={"news-img-inner"}>
                                <img className={"news-item-img"}
                                     style={hoverI === index ? {transform: "scale(1.2, 1.2)"} : {}}
                                     src={imgUrl} alt={""}
                                />
                            </div>
                        </div>
                        <div className={"news-item-ct-outer"}>
                            <div className={"news-item-title"}>
                                <span className={"item-flow"}>
                                    {/*{"我国2021年航天发射大盘点！都有哪些精彩的发射任务？发射大盘点都有哪些精彩的发射任务？发射大盘都有哪些精彩的发射任务？发射大盘"}*/}
                                    {item.newsName}
                                </span>
                            </div>
                            <div className={"news-item-des"}>
                            <span className={"item-flow"}>
                                {/*{"当狡猾的新冠病毒抵达南极，对地球上最纯净的一片古老大陆会带来什么影响？自疫情发生至今，我国极地科学考察活" +*/}
                                {/*"动从未中断，截至目前一直保持“零感染”，极地医学保障是怎么做到的？截至目前一直保持“零感染”，极地医学保" +*/}
                                {/*"医学保障是怎么做到的？截至目前一直保持“零感染”，极地医学保障是怎么障是怎么做医学保障是怎么做到的？截至目前一直保持“零感染”，极地医学保障是怎么到的？"}*/}
                                {item.newsDes}
                            </span>
                            </div>
                            <div className={"news-item-tag"}>
                                <span style={{paddingRight: "10px"}}>
                                     {"发布时间："}
                                </span>
                                <span>
                                    {item.newsDate}
                                </span>
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            data.push(
                <div className={"data-item-empty"}>
                    <div className={"data-item-empty-img"}>
                        <img className={"img-style contain"} src={EMPTY} alt={""}/>
                    </div>
                    <div className={"data-item-empty-txt"}>
                        {"没有符合条件的数据"}
                    </div>
                </div>
            )
        }
        return data;
    }

    handleSelectMenu = (options, type) => {
        if (options.from !== type) {
            options.from = type;
            this.setState({
                options: Object.assign({}, options),
            })
        }
    }

    render() {
        let adLeft = this.state.adLeft;
        let right = "calc((100% - " + adLeft + " ) / 2)";
        let totalGap = this.state.gap - this.state.adjustGap;
        let gapR = totalGap === 0 ? "0" : parseInt(totalGap + "") + "px";
        let wH = parseInt((this.state.cH - 30) + "") + "px";
        let partitioner = this.state.partitioner;
        let options = this.state.options;
        let type = options.from;
        let advMap = this.state.advMap;
        console.log(options);
        console.log("advMap: ", advMap);

        return (
            <div className={"home-ct"}>
                <Header/>
                <div className={"news-page"}>
                    <div className={"news-banner"} id={"banner"}>
                        <div className={"news-banner-title"}>
                            {"了解实时科技新闻资讯"}
                        </div>
                    </div>
                    <div style={{width: "100%", height: "40px"}}/>
                    <div className={"news-page-content"} id={"news-outer"}>
                        <div className={"news-ct-data"}>
                            <div className={"news-ct-data-menu"}>
                                <div className={"news-menu-item"}
                                     onClick={() => {
                                         this.handleSelectMenu(options, 0)
                                     }}
                                     style={type === 0 ? {borderBottom: "3px solid #2576d9", color: "black"} : {}}>
                                    {"最新资讯"}
                                </div>
                                <div className={"news-menu-sp"}/>
                                <div className={"news-menu-item"}
                                     onClick={() => {
                                         this.handleSelectMenu(options, 1)
                                     }}
                                     style={type === 1 ? {borderBottom: "3px solid #2576d9", color: "black"} : {}}>
                                    {"精选新闻"}
                                </div>
                                <div className={"news-menu-sp"}/>
                                <div className={"news-menu-item"}
                                     onClick={() => {
                                         this.handleSelectMenu(options, 2)
                                     }}
                                     style={type === 2 ? {borderBottom: "3px solid #2576d9", color: "black"} : {}}>
                                    {"企业相关"}
                                </div>
                                <div className={"news-menu-sp"}/>
                                <div className={"news-menu-item"}
                                     onClick={() => {
                                         this.handleSelectMenu(options, 3)
                                     }}
                                     style={type === 3 ? {borderBottom: "3px solid #2576d9", color: "black"} : {}}>
                                    {"院校相关"}
                                </div>
                            </div>
                            {this.loadNewsData()}
                            <div className={"outcome-page-partition"}
                                 style={{display: partitioner.showData ? "" : "none"}}
                            >
                                <Partitioner parent={this} loadSetting={loadSetting} options={options}
                                             // handleCheckMonitor={handleIdList}
                                />
                            </div>
                        </div>
                        {
                            advMap.size === advList.news.length ?
                                <AdRight fixedAd={this.state.fixedAd} adBt={this.state.adBt} gapR={gapR} right={right}
                                         wholeHgt={wH} adClasses={adClasses}
                                         adTitleOne={"热门政策解读"} adOneCt={LoadingPolicyCopAds(advMap.get(dataIndexE.policy))} adOneUrl={PolicyRoot.path}
                                         adTitleTwo={"推荐需求"} adTwoCt={LoadingDemandCopAds(advMap.get(dataIndexE.demand))} adTwoUrl={DemandRoot.path}
                                />
                                : null
                        }
                    </div>
                </div>
                <Contact fixed={this.state.fixed}/>
                <div>
                    <Footer/>
                </div>
            </div>
        )
    }
}

export default NewsPage;
