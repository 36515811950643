import React, {Component} from 'react';
import {LoadAds} from "./Const";

class AdRight extends Component {
    render() {
        let fixedAd = this.props.fixedAd;
        let adBt = this.props.adBt;
        let gapR = this.props.gapR;
        let right = this.props.right;
        let wholeHgt = this.props.wholeHgt;
        let adClasses = this.props.adClasses;
        let style = fixedAd ? {
            position: "fixed",
            bottom: "60px",
            transform: "translateX(" + gapR + ")",
            right: right,
        } : adBt ? {height: wholeHgt} : {};
        let adList = [{adClass: adClasses.adOne, adTitle: this.props.adTitleOne, adCt: this.props.adOneCt, adUrl: this.props.adOneUrl},
            {adClass: adClasses.adTwo, adTitle: this.props.adTitleTwo, adCt: this.props.adTwoCt, adUrl: this.props.adTwoUrl}]

        return (
            LoadAds(style, adClasses.adOuter, adList)
        )
    }
}

export default AdRight;
