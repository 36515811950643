import React, {Component} from "react";
import "./center.css";
import {FileSearchOutlined} from "@ant-design/icons";
import NODATA from "../../assets/icon/record_empty.svg";
import {
    CheckAwaitAppointsUrl,
    CheckComingAppointsUrl,
    CheckHistoricalAppointUrl,
    data,
    error,
    loginOutErrOr,
    sendGetRequest
} from "../../tool/http/Request";
import {copyFun, LoginRoot, MouseHover, schTime, UserInfoStorage} from "../../tool/Const";
import {connectionError, fail, failReturn, findMsgByError} from "../../tool/http/ErrorMgr";
import moment from "moment";

class AppointmentMgr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 0,
            data: '',
            hoverI: -1,
        }
    }

    loadTable = (data, hoverI) => {
        let table = [];
        if (data.length > 0) {
            data.forEach((item, index) => {
                let startTime = schTime[item.appointTime.split(';')[0]];
                let endTime = schTime[item.appointTime.split(';')[2]];
                table.push(
                    <div className={"table-ct-wrap"} key={index}>
                        <div className={"table-ct-cell app-date"}>
                            {item["appointDate"]}
                        </div>
                        <div className={"table-ct-cell app-time"}>
                            {startTime + '-' + endTime}
                        </div>
                        <div className={"table-ct-cell app-expert"}>
                            <span>
                                 {item["expertInfo"].expertName}
                            </span>
                            <div className={"check-expert-id"}
                                onMouseEnter={() => {MouseHover(index, hoverI, this)}}
                                onMouseLeave={() => {MouseHover(-1, hoverI, this)}}
                            >
                                {"查看专家ID"}
                                <div className={"expert-id-tooltip"}
                                     style={{display: index === hoverI ? "" : "none"}}
                                     id={"expertId-txt"}
                                >
                                    <span>
                                        {item["expertInfo"].expertId}
                                    </span>
                                    <span className={"check-expert-id"}
                                          style={{color: '#ffffff'}}
                                          onClick={() => {
                                              copyFun("expertId-txt")
                                          }}
                                    >
                                        {"复制"}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={"table-ct-cell operation"}>
                            <div className={"operation-plugin add-fun"}
                                 style={{width: '90px'}}
                                 onClick={() => {
                                     window.open('/expert/' + item["expertInfo"].expertId, "_blank")
                                 }}
                            >
                                <FileSearchOutlined className={"operation-plugin-icon"}/>
                                <div className={"operation-plugin-txt"}>
                                    {"查看专家"}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            return (
                <div className={"table-ct-no-data"}>
                    <img className={"table-ct-no-data-icon"} src={NODATA} alt={"no-data"}/>
                    <div className={"table-ct-no-data-tip"}>
                        {"暂无数据"}
                    </div>
                </div>
            )
        }
        return table;
    }

    componentDidMount() {
        this.getAppoints(CheckComingAppointsUrl, 0, false);
    }

    // 用户中心查看预约日程
    getAppoints = (url, type, isHistorical) => {
        let userInfo = UserInfoStorage.get("userInfo");
        let params = {};
        if (isHistorical) {
            params = {
                userId: userInfo.userId,
                today: moment().utcOffset(8).format("YYYY-MM-DD").toString(),
            }
        } else {
            params = {
                userId: userInfo.userId
            };
        }
        sendGetRequest(url, params, true).then(res => {
            console.log("用户中心预约日程res: ", url, res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                   this.setState({
                       data: this.sortByDateAndTime(res[data]),
                       type: type,
                   })
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 获取的数据排序
    sortByDateAndTime = (data) => {
        if (data.length > 0) {
            data.sort(function (a, b) {
                if (moment(a["appointDate"]).isBefore(b["appointDate"])) {
                    return -1;
                }
                if (moment(a["appointDate"]).isAfter(b["appointDate"])) {
                    return 1;
                }
                if (moment(a["appointDate"]).isSame(b["appointDate"])) {
                    if (a["appointTime"].split(';')[0] > b["appointTime"].split(';')[0]) {
                        return 1;
                    }
                    if (a["appointTime"].split(';')[0] < b["appointTime"].split(';')[0]) {
                        return -1;
                    }
                }
            })
        }
        return data;
    }

    render() {
        let type = this.state.type;
        let data = this.state.data;
        let hoverI = this.state.hoverI;

        return (
            <div className={"appointment-mgr-ct"}>
                <div className={"center-ct-header"}>
                    <div className={"center-ct-header-wrap"}>
                        <div className={"center-ct-header-icon"}/>
                        <div className={"center-ct-header-title"}>
                            {"预约管理"}
                        </div>
                        <div className={"center-ct-header-subtitle"}>
                            {"领先一步获取先机，轻松助力成果转化"}
                        </div>
                    </div>
                </div>
                <div className={"my-service-plugin-menu"}>
                    <div className={type === 0 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                         onClick={() => {
                             this.getAppoints(CheckComingAppointsUrl, 0, false)
                         }}
                    >
                        <div>
                            {"进行中预约"}
                        </div>
                    </div>
                    <div className={type === 1 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                         onClick={() => {
                             this.getAppoints(CheckAwaitAppointsUrl, 1, false)
                         }}
                    >
                        <div>
                            {"待审核预约"}
                        </div>
                    </div>
                    <div className={type === 2 ? "my-service-plugin-menu-ele-selected" : "my-service-plugin-menu-ele"}
                         onClick={() => {
                             this.getAppoints(CheckHistoricalAppointUrl, 2, true)
                         }}
                    >
                        <div>
                            {"历史预约记录"}
                        </div>
                    </div>
                </div>
                <div className={"appointment-mgr-ct-table"}>
                    <div className={"table-wrap"}>
                        <div className={"table-header"}>
                            <div className={"table-header-cell app-date"}>
                                {"预约日期"}
                            </div>
                            <div className={"table-header-cell app-time"}>
                                {"预约时间"}
                            </div>
                            <div className={"table-header-cell app-expert"}>
                                {"专家"}
                            </div>
                            <div className={"table-header-cell operation"}>
                                {"操作"}
                            </div>
                        </div>
                        <div className={"table-ct"}>
                            {this.loadTable(data, hoverI)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AppointmentMgr;
