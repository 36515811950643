import React from 'react';
import "./../page.css";
import {
  CloseOutlined,
  CommentOutlined,
  EditFilled, LeftOutlined,
  QqCircleFilled,
  QuestionCircleFilled,
  WechatFilled
} from "@ant-design/icons";

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showWin: false,
            selectedWay: 0,
        };
    }

    loadChat = () => {
        return (
            <div className={"chart-service"}>
                <div className={"chart-sign"}>
                    <CommentOutlined/>
                </div>
                <span className={"chart-word"}>{"湃通咨询顾问"}</span>
            </div>
        )
    }

    loadingByWay = (way) => {
        if (way === 0) {
            return (
                <div className={"expend-ct-inter"}>
                    <div className={"expend-item"} onClick={() => {this.setState({selectedWay: 1})}}>
                        <QuestionCircleFilled style={{marginRight: "10px", fontSize: "18px"}}/>
                        {"常见问题解答"}
                    </div>
                    <div className={"expend-item"} onClick={() => {this.setState({selectedWay: 2})}}>
                        <WechatFilled style={{marginRight: "10px", fontSize: "18px"}}/>
                        {"资深顾问微信咨询"}
                    </div>
                    <div className={"expend-item"}>
                        <QqCircleFilled style={{marginRight: "10px", fontSize: "18px"}}/>
                        {"资深顾问QQ咨询"}
                    </div>
                    <div className={"expend-item"}>
                        <EditFilled style={{marginRight: "10px", fontSize: "18px"}}/>
                        {"线上提交留言"}
                    </div>
                </div>
            )
        } else if (way === 1) {
            return (
                <div className={"expend-ct-inter"}>
                    <ul className={"expend-item"} style={{fontSize: "12px", lineHeight: "20px", height: "30%"}}>
                        <li>{"如何认证成为湃通合作的专家？"}</li>
                    </ul>
                    <ul className={"expend-item"} style={{fontSize: "12px", lineHeight: "20px", height: "30%"}}>
                        <li>{"如何在平台发布企业技术需求？"}</li>
                    </ul>
                    <ul className={"expend-item"} style={{fontSize: "12px", lineHeight: "20px", height: "30%"}}>
                        <li>{"发布需求后由哪一方来跟踪和完成需求？"}</li>
                    </ul>
                </div>
            )
        } else {
            return (
                <div className={"expend-ct-inter"}>
                    <div className={"qr-title"}>
                        <span>{"描二维码添加顾问微信"}</span>
                    </div>
                    <div className={"qr-ct"}>
                        <div className={"qr-img"}>
                            {"二维码"}
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        let showWin = this.state.showWin;
        let selectedWay = this.state.selectedWay;
        return ([
                <div id={"expend"} className={"expand-contact"} style={{display: showWin ? "" : "none"}}>
                    <div className={"expend-header"}>
                        <div className={"contact-tool-h"}>
                            {this.loadChat()}
                        </div>
                        <div className={"close-contact"} onClick={() => {
                            this.setState({showWin: false, selectedWay: 0})
                        }}>
                            <CloseOutlined/>
                        </div>
                    </div>
                    <div className={"expend-ct"}>
                      {
                        this.state.selectedWay === 0 ?
                          null :
                          <div className={"expend-back"} onClick={() => {this.setState({selectedWay: 0})}}>
                            <LeftOutlined />
                            <span>{"返回"}</span>
                          </div>
                      }
                      {this.loadingByWay(selectedWay)}
                    </div>
                </div>,
                <div id={"simple"}>
                    {this.props.fixed ?
                        <div className={"contact-tool"}
                             style={{
                                 position: "fixed",
                                 boxShadow: "0 0 8px rgba(0, 0, 0, 0.4)",
                                 display: showWin ? "none" : ""
                             }} onClick={() => {
                            this.setState({showWin: true})
                        }}>
                            {this.loadChat()}
                        </div>
                        :
                        <div className={"contact-ct"}>
                            <div className={"contact-tool"} style={{display: showWin ? "none" : ""}} onClick={() => {
                                this.setState({showWin: true})
                            }}>
                                {this.loadChat()}
                            </div>
                        </div>
                    }
                </div>]
        )
    }

}

export default Contact;