import React, {Component, Fragment} from "react";
import {UserInfoStorage, switchLogin} from "../Const";
import {error, genPojo, sendPostRequest, SubmitItemUnlockUrl} from "../http/Request";
import {connectionError, fail, findMsgByError, success} from "../http/ErrorMgr";//导入withRoute
import {withRouter} from 'react-router-dom';

class PayPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // 用户提交解锁服务
    submitItemUnlock = (itemId, toPay) => {
        let userInfo = UserInfoStorage.get("userInfo");
        let par = {
            userId: userInfo.userId,
            itemId: itemId,
        }
        let pojo = {
            orderId: '12345678912',
            realPrice: toPay,
            serviceCt: JSON.stringify(par),
        };
        sendPostRequest(genPojo(SubmitItemUnlockUrl, pojo), false).then(res => {
            console.log("用户提交解锁服务res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    success("解锁成功");
                    window.location.reload();
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    switchType = (type, title, itemId) => {
        switch (type) {
            case 1: {
                return (
                    <Fragment>
                        <div className={"pay-panel-title"}>
                            {title}
                        </div>
                        <div className={"pay-wrap"}>
                            <div className={"pay-btn"}
                                 onClick={() => {
                                     switchLogin(window.location.href, this)
                                 }}
                            >
                                {"立即登录"}
                            </div>
                        </div>
                    </Fragment>
                )
            }
            case 2: {
                return (
                    <Fragment>
                        <div className={"pay-panel-title"}>
                            {title}
                            <a className={"rights"} target={"_blank"} >
                                {"全部特权>"}
                            </a>
                        </div>
                        <div className={"pay-wrap"}>
                            <div className={"goods-list"}>
                                <div className={"goods-item"}>
                                    <div className={"price"}>
                                <span className={"big-num color-mark"}>
                                    {"100元 / "}
                                </span>
                                        <span>
                                    {"月"}
                                </span>
                                    </div>
                                    <div className={"desc"}>
                                <span>
                                    {"每天仅需 "}
                                </span>
                                        <span className={"color-mark"}>
                                    {"3.33"}
                                </span>
                                        <span>
                                {" 元"}
                            </span>
                                    </div>
                                </div>
                                <div className={"goods-item"}>
                                    <div className={"price"}>
                                <span className={"big-num color-mark"}>
                                    {"3元 / "}
                                </span>
                                        <span>
                                    {"条"}
                                </span>
                                    </div>
                                    <div className={"desc"}>
                                <span>
                                    {"每天仅需 "}
                                </span>
                                        <span className={"color-mark"}>
                                    {"3.33"}
                                </span>
                                        <span>
                                    {" 元"}
                                </span>
                                    </div>
                                </div>
                            </div>
                            <div className={"pay-btn"}
                                 onClick={() => {
                                     this.submitItemUnlock(itemId, 10)
                                 }}
                            >
                                {"立即开通"}
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }
    }

    render() {
        let type = this.props.type;
        let classWrap = this.props.classWrap;
        let title = this.props.title;
        let itemId = this.props.itemId;

        return (
            <div className={classWrap}>
                {this.switchType(type, title, itemId)}
            </div>
        )
    }
}

export default withRouter(PayPanel);