import React, {Component} from "react";
import "./center.css";
import PERSON from "../../assets/icon/certification_person.png";
import COMPANY from "../../assets/icon/certification_company.png";
import EXPERT from "../../assets/icon/certification_expert.png";
import AUTH_CHECK from "../../assets/img/auth_check.png";
import {CheckOutlined} from "@ant-design/icons";
import CertificationComponent from "./CertificationComponent";
import {getUserInfoCache, UserInfoStorage} from "../../tool/Const";
import {authRepeatError, CheckCanSubmitAuthUrl, error, sendGetRequest} from "../../tool/http/Request";
import {connectionError, fail, findMsgByError} from "../../tool/http/ErrorMgr";

// 资质认证详情
const certList = [
    {
        type: 1,
        img: PERSON,
        title: "个人认证",
        introduce: "适用于发明人、专家、技术中介个人、投资创业者、个体户、个体工商业者等。",
        adv: [
            "发布个人向的技术成果和需求1",
            "发布个人向的技术成果和需求2",
            "发布个人向的技术成果和需求3",
        ]
    },
    {
        type: 2,
        img: COMPANY,
        title: "企业认证",
        introduce: "适用于个人独资企业、企业法人、企业非法人、企业分支机构等能提供营业执照的用户主体。",
        adv: [
            "发布个人向的技术成果和需求1",
            "发布个人向的技术成果和需求2",
            "发布个人向的技术成果和需求3",
        ]
    },
    {
        type: 3,
        img: EXPERT,
        title: "专家认证",
        introduce: "适用于院校、研究机构（实验室/研究院/新型研发机构）、与科技创新相关的专家等。",
        adv: [
            "发布个人向的技术成果和需求1",
            "发布个人向的技术成果和需求2",
            "发布个人向的技术成果和需求3",
        ]
    }
]

class Certification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: '',
            type: 0,
        }
    }

    // 加载资质认证（1 个人认证；2 企业认证；3 专家认证）
    loadCert = (data) => {
        let list = [];
        data.forEach((item, index) => {
            list.push(
                <div className={"certification-table"} key={index}>
                    <div className={"certification-table-icon"}>
                        <img className={"img-style contain"} src={item.img} alt={"logo"}/>
                    </div>
                    <div className={"certification-table-title"}>
                        {item.title}
                    </div>
                    <div className={"certification-table-introduce"}>
                        {item.introduce}
                    </div>
                    <div className={"certification-table-adv"}>
                        {this.loadCertAdv(item.adv)}
                    </div>
                    <div className={"center-ct-button"}
                         onClick={() => {
                             this.checkCanSubmitAuth(item.type)
                             // this.switchType(item.type)
                         }}>
                        {"立即认证"}
                    </div>
                </div>
            )
        })
        return list;
    }

    // 加载资质认证特点
    loadCertAdv = (adv) => {
        let advList = [];
        adv.forEach((it, it_index)=>{
            advList.push (
                <div className={"certification-table-adv-ele"} key={it_index}>
                    <CheckOutlined style={{marginRight: '10px'}}/>
                    {it}
                </div>
            )
        })
        return advList;
    }

    // 切换资质认证类型（1 个人认证；2 企业认证；3 专家认证）
    switchType = (type) => {
        this.setState({
            type: type,
        })
    }

    componentDidMount() {
        // 缓存获取用户信息
        getUserInfoCache(this);
    }

    // 查看是否可以提交认证，当有审核中的认证时需提示
    checkCanSubmitAuth = (type) => {
        console.log(type)

        let userInfoCache = UserInfoStorage.get("userInfo");
        let userId = userInfoCache.userId;
        let params = {
            userId: userId,
        }
        sendGetRequest(CheckCanSubmitAuthUrl, params, true).then(res => {
            console.log("查看认证情况res：", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.setState({
                        type: type,
                    })
                } else if (res[error] === authRepeatError) {
                    this.setState({
                        type: 9,
                    })
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    render() {
        let userInfo = this.state.userInfo;
        let type = this.state.type;

        return (
            <div className={"certification-ct"}>
                <div className={"center-ct-header"}>
                    <div className={"center-ct-header-wrap"}>
                        <div className={"center-ct-header-icon"}/>
                        <div className={"center-ct-header-title"}>
                            {"资质认证"}
                        </div>
                        <div className={"center-ct-header-subtitle"}>
                            {"为了您的账号安全，请根据实际情况进行资质认证"}
                        </div>
                    </div>
                </div>
                {/*<div className={"certification-tip"}>*/}
                {/*    <span className={"certification-tip-ele"}>*/}
                {/*        {"资质认证要求1：111111"}*/}
                {/*    </span>*/}
                {/*    <span className={"certification-tip-ele"}>*/}
                {/*        {"资质认证要求2：111111"}*/}
                {/*    </span>*/}
                {/*    <span className={"certification-tip-ele"}>*/}
                {/*        {"资质认证要求3：111111"}*/}
                {/*    </span>*/}
                {/*</div>*/}
                {
                    type === 0 ?
                        <div className={"certification-table-wrap"}>
                            {this.loadCert(certList)}
                        </div>
                        :
                        (
                            type === 9 ?
                                <div className={"cert-check-wrap"}>
                                    <div className={"cert-check-img"}>
                                        <img className={"img-style contain"} src={AUTH_CHECK} alt={""}/>
                                    </div>
                                    <div className={"cert-check-txt"}>
                                        {"尊敬的用户，您的资质认证正在审核流程中，此阶段工作会在一周内完成，若有问题，可咨询管理员。"}
                                    </div>
                                    <div className={"cert-button"}
                                         onClick={() => {this.switchType(0)}}
                                    >
                                        {"返回"}
                                    </div>
                                </div>
                                :
                                <CertificationComponent parent={this} userInfo={userInfo} type={type}/>
                        )
                }
            </div>
        )
    }
}

export default Certification;
