import React from 'react';
import "./../page.css";
import {RightOutlined} from "@ant-design/icons";
import {DemandRoot, handleClickMonitor, handleIdByIndex, PageId, PolicyRoot, UserInfoStorage} from "../../tool/Const";
import TRAFFIC_TWO from "../../assets/img/traffic_hall.jpg";

class Policy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // 加载push内容
    loadPolicy = (data, userInfo) => {
        if (data.length > 0) {
            let ui = [];
            data.forEach((item, index) => {
                ui.push(
                    <div className={"policy-line"} key={index} style={{marginBottom: index === 5 ? "0px" : "" }}>
                        <div className={"policy-time"}>
                            <div className={"policy-time-tag"}>
                                <span className={"policy-tag-ct"}>
                                    {item["policyRoot"]}
                                </span>
                            </div>
                            <div className={"policy-time-tag"}>
                                <div className={"policy-tag-time"}>
                                    <span>
                                        {"发布时间："}
                                    </span>
                                    <span>
                                        {item["policyDate"]}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={"policy-pos"}>
                            <div className={"policy-tag"}>
                                <span>{"沪"}</span>
                            </div>
                        </div>
                        <div className={"policy-name"}>
                            <span className={"policy-name-txt"}
                                  onClick={() => {
                                      handleClickMonitor(userInfo, item["policyId"], 2, 1, PolicyRoot.path + "/" + item["policyId"])
                                  }}
                            >
                                {item["policyName"]}
                            </span>
                        </div>
                    </div>
                )
            });
            return ui;
        }
    }

    render() {
        let userInfo = UserInfoStorage.get("userInfo");
        let policy = this.props.policy;
        let pushData = this.props.pushData;

        return (
            <div className={"out-content"}>
                <div className={"policy-ot-ad traffic-bg-two"}>
                    <div className={"traffic-ct"}>
                        {/*<div className={"outcome-expert"}>*/}
                        <img className={"img-style cover"} src={TRAFFIC_TWO} alt={"traffic"}/>
                        <div className={"traffic-txt-wrap"}>
                            <div className={"traffic-ct-txt"}>
                                <span className={"traffic-txt"}>{"寻找全球技术经纪合伙人  共塑全球科技创新新生态"}</span>
                            </div>
                        </div>

                        {/*</div>*/}
                    </div>

                    {/*<div className={"page-ct"}>*/}
                    {/*    <div className={"outcome-expert"}>*/}
                    {/*        <span>{"大厅综合性引流"}</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {
                    policy && pushData.length > 0 ?
                        <div className={"policy-ot"}>
                            <div className={"page-ct"}>
                                <div className={"info-header-title"}>
                                    <div className={"info-header-name"}>
                                        {"科技政策"}
                                    </div>
                                    <div className={"info-header-des"}>
                                        {"重要科技政策跟踪与向导"}
                                    </div>
                                </div>
                                <div className={"policy-info-ct"}>
                                    <div className={"policy-sp"}/>
                                    {this.loadPolicy(pushData, userInfo)}
                                </div>
                                <div className={"page-more"} style={{marginTop: "20px"}}
                                     onClick={() => {
                                         handleClickMonitor(userInfo, PageId.policy, 1, 1, PolicyRoot.path)
                                     }}
                                >
                            <span className={"page-more-text"}>
                                查看更多
                            </span>
                                    <RightOutlined className={"page-more-icon"}/>
                                </div>
                            </div>
                        </div>
                        : null
                }

            </div>
        )
    }
}

export default Policy;