import React, {Component} from "react";
import "./error.css";
import "../page.css";
import Footer from "../Footer";
import Header from "../Header";
import BG from "../../assets/img/404.png";
import {HomeRoot} from "../../tool/Const";

class ErrorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div className={"home-ct"}>
                <Header/>
                <div className={"error-page"} id={"pageHeader"}>
                    <img className={"img-style contain"} src={BG} alt={"404"}/>
                    <div className={"btn-wrap"}>
                        <a className={"btn back"} onClick={() => {window.history.back()}}>
                            {"返回上一页"}
                        </a>
                        <a className={"btn home"} href={HomeRoot}>
                            {"返回首页"}
                        </a>
                    </div>
                </div>
                <div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default ErrorPage;