import React, {Component, Fragment} from "react";
import "./center.css";
import Schedule from "./Schedule";
import {getUserInfoCache} from "../../tool/Const";

class ScheduleSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: '',
        }
    }

    // 禁止滚动条功能
    stopScroll = (isScroll) => {
        // 为元素添加事件监听
        let id = document.getElementById('schedule-wrap');
        if (!isScroll) {
            id.style['overflow'] = 'hidden';
        } else {
            id.style['overflow-y'] = 'auto';
        }
    }

    componentDidMount() {
        getUserInfoCache(this);
    }

    render() {
        let userInfo = this.state.userInfo;

        return (
            [
            <div className={"expert-fun-ct"} id={"schedule-wrap"}>
                <div className={"center-ct-header"}>
                    <div className={"center-ct-header-wrap"}>
                        <div className={"center-ct-header-icon"}/>
                        <div className={"center-ct-header-title"}>
                            {"日程设置"}
                        </div>
                        <div className={"center-ct-header-subtitle"}>
                            {"设置预约时间与日程安排"}
                        </div>
                    </div>
                </div>
                <Schedule parent={this} userInfo={userInfo}/>
            </div>]
        )
    }
}

export default ScheduleSetting;
