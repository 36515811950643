import {createMsg} from "../MsgWin";

const loginInputError = "输出错误！账号或密码长度需大于等于6位！"

const connectionError = "CT_ERROR";

const fail = (failInfo) => {
    return createMsg()(failInfo, 2, 2);
}

const failReturn = (failInfo, url) => {
    return createMsg()(failInfo, 2, 2, url);
}

const success = (successInfo) => {
    return createMsg()(successInfo, 2, 1);
}

const successReturn = (successInfo, url) => {
    return createMsg()(successInfo, 2, 1, url);
}

const findMsgByError = (error) => {
    console.log(error)
    switch (error) {
        case connectionError: {
            return "网络连接错误，请稍后重试！";
        }
        case "SYSTEM_ERROR": {
            return "网络连接错误，请稍后重试！";
        }
        case "INPUT_ERROR": {
            return "请求参数无效，请输入正确参数！";
        }
        case "USER_REPEAT": {
            return "用户ID重复，请联系客服！";
        }
        case "NOT_MATCH_ERROR": {
            return "无法找到匹配信息，请重新输入参数！";
        }
        case "WRONG_PASS_ERROR": {
            return "密码错误，请输入正确密码！";
        }
        case "TIME_OUT": {
            return "该操作已超时，请在有效时间内完成对应操作！";
        }
        case "LOGIN_FIRST": {
            return "请先登录您的账号后再执行该操作！";
        }
        case "SEND_VALID": {
            return "请先发送验证码，验证码有效时间10分钟！";
        }
        case "CODE_ERROR": {
            return "验证码错误，请重新输入！";
        }
        case "ALI_REPEAT": {
            return "当前支付宝账号已被使用！";
        }
        case "WECHAT_REPEAT": {
            return "当前微信账号已被使用！";
        }
        case "EMAIL_REPEAT": {
            return "该邮箱已被使用！";
        }
        case "PHONE_REPEAT": {
            return "该手机号码已被使用！";
        }
        case "NICK_REPEAT": {
            return "该昵称已被使用！";
        }
        case "LOGIN_OUT": {
            return "用户登录已过期，请重新登陆！";
        }
        case "DB_ERROR": {
            return "当前服务器正忙，请稍后重试！";
        }
        case "BALANCE_NOT_ENOUGH": {
            return "余额不足";
        }
        case "COIN_AMOUNT_INVALID": {
            return "湃通币余额不足，请充值";
        }
        case "WAIT_ERROR": {
            return "";
        }
        default: {
            return "系统错误，请稍后重试！";
        }
    }
}

export {
    loginInputError,
    connectionError,
    findMsgByError,
    fail,
    failReturn,
    success,
    successReturn,
}
