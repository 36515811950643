import React, {Component} from 'react';
import "./about.css";
import avatar from "../pic/wang.jpg";
import Bk from "../pic/aboutCp.png";

const teamList = [
    {
        img: avatar,
        name: "梁建军",
        title: "咨询委员会执委",
        des: "教授级高级工程师，国家知识产权局首批专利信息领军人才，曾任上海科学技术交流中心高级主管、上海市知识产权服务中心副主任。上海市科委、经信委项目以及上海市政府采购等评审专家。"
    },
    {
        img: avatar,
        name: "姜丽芳",
        title: "咨询委员会执委",
        des: "高级工程师，哈尔滨理工大学硕士。历任瑞章科技有限公司副总裁、大唐电信联芯科技有限公司政府事务总监等。16年半导体、通信、物联网产业开发和规划工作经验。长期负责战略合作、政府项目、标准、市场与品牌、产业咨询与服务相关工作。"
    },
    {
        img: avatar,
        name: "臧伟",
        title: "CEO",
        des: "高级技术转移经理人；长三角高校技术转移联盟理事；上海市长宁区优秀创业青年；上海市科技创新创业服务先进个人；上海工程技术大学创业特聘导师，现任上海湃通科技有限责任公司首席执行官。"
    },
    {
        img: avatar,
        name: "李玉",
        title: "CMO",
        des: "国际技术转移经理人（ITTJ）；注册科技评估师；基金经理人；中国人工智能学会（CAAI）会员；2022胡润U30创业领袖；曾任多家国家大学科技园项目负责人，上海煜唐实业有限公司总经理，现任上海湃通科技有限责任公司首席市场官。"
    },
    {
        img: avatar,
        name: "胡一舟",
        title: "COO",
        des: "科技服务专家。具有丰富的科技行业咨询及实战经验，曾负责多个大型产业园区和孵化器的运营管理工作。熟知技术转移和科技成果转化的运营管理，擅长企业需求的挖掘和制定定制化解决方案。现任上海湃通科技有限责任公司首席运营官。"
    },
    {
        img: avatar,
        name: "施博文",
        title: "CTO",
        des: "网络技术专家。美国亚利桑那州立大学硕士，前华为全栈技术专家，具有多个海内外技术项目研发经历。现任上海湃通科技有限责任公司首席技术官。"
    },
    {
        img: avatar,
        name: "王若成",
        title: "CIO",
        des: "数字化运营专家。具备多年企业数字化推广运营以及大规模自媒投放分析经验。现任上海湃通科技有限责任公司首席数据官。"
    },
]


class FoundingTeam extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bannerImg: Bk,
        }
    }

    componentDidMount() {
        this.props.parent.loadData(this.state.bannerImg)
    }

    loadTeam = (data) => {
        let list = [];
        data.forEach((item, index) => {
            list.push(
                <div className={"about-com-ct-team"}>
                    <div className={"about-com-ct-team-img"}>
                        <img className={"img-style cover"} src={item.img} alt={"icon"}/>
                    </div>
                    <div className={"about-com-ct-team-info"}>
                        <div className={"about-com-ct-team-info-wrap"}>
                                <span className={"about-com-ct-team-info-name"}>
                                    {item.name}
                                </span>
                            <span className={"about-com-ct-team-info-title"}>
                                    {item.title}
                                </span>
                        </div>
                        <div className={"about-com-ct-team-info-ct"}>
                            {item.des}
                        </div>
                    </div>
                </div>
            )
        })
        return list;
    }

    render() {
        return (
            <div className={"about-com-ct"}>
                <div className={"about-com-ct-team-wrap"}>
                    {this.loadTeam(teamList)}
                </div>
            </div>
        )
    }
}

export default FoundingTeam;
