import React, {Component} from "react";
import "./center.css";
import {data, error, loginOutErrOr, RefreshUserByIdUrl, sendGetRequest} from "../../tool/http/Request";
import {connectionError, fail, failReturn, findMsgByError} from "../../tool/http/ErrorMgr";
import {genProcessBack, LoginRoot, UserInfoStorage} from "../../tool/Const";
import PayOperator from "./PayOperator";

export default class PayParent extends Component<PayProps, PayState> {
    constructor() {
        super();
        this.state = {

        }
    }

    // 调用支付api
    // 1=EXP_APPOINT;2=VIP;3=UNLOCK_ITEM;4=PAITON_COIN;5=OTHER
    handlePayPre = (index, serviceNum, ct) => {
        let userInfo = UserInfoStorage.get("userInfo");
        let payStatus = this.state.payStatus;
        let orderPojo = {
            userId: userInfo.userId,
            serviceNum: serviceNum,
            realPrice: payStatus.realPrice,
            paiPrice: payStatus.paiPrice,
            rmbPrice: payStatus.rmbPrice,
            disPrice: payStatus.disPrice,
        };

        let operator = new PayOperator();

        let params = {
            order: orderPojo,
            index: index,
            ct: ct,
        };

        let _this = this;
        const myBack: WinBack = {
            callDataBack(data: any) {
                _this.setState({
                    orderPojo: data,
                }, () => {
                    _this.handlePay()
                })
            }
        }

        operator.initOrder(params, genProcessBack(myBack, true))
    }

    // 更新用户信息
    refreshUser = (userInfo) => {
        let params = {
            userId: userInfo.userId,
        };
        sendGetRequest(RefreshUserByIdUrl, params, true).then(res => {
            console.log("更新用户信息res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    UserInfoStorage.set("userInfo", res[data]);
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }
}