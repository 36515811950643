import React, {Component} from "react";
import './center.css';
import {CloseOutlined, DeleteOutlined, FolderOpenOutlined, MailOutlined, MinusOutlined} from "@ant-design/icons";
import {Checkbox} from "antd";
import {
    data,
    DeleteUserNoticesUrl,
    error,
    FindNoticesByPageUrl,
    genPojo,
    loginOutErrOr,
    ReadNoticeUrl,
    sendGetRequest,
    sendPostRequest
} from "../../tool/http/Request";
import {LoginRoot, UserInfoStorage} from "../../tool/Const";
import {connectionError, fail, failReturn, findMsgByError, success} from "../../tool/http/ErrorMgr";
import NODATA from "../../assets/icon/record_empty.svg";
import READ from "../../assets/icon/icon_notice_read.jpeg";
import moment from "moment";

const CheckboxGroup = Checkbox.Group;

const pageLimit = 50;

class NoticesMgr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: '',
            data: [],
            selectList: [],

            modalStatus: false,
            selData: '',
        }
    }

    // 加载消息
    loadNotices = (data, selectList, modalStatus) => {
        let ui = [];
        if (data.length > 0) {
            data.forEach((item, index) => {
                ui.push(
                    <div className={"notice-list"} key={index}>
                        <div className={"notice-cell notice-check"}>
                            <Checkbox
                                className={"notice-checkbox"}
                                checked={selectList.includes(item)}
                                onChange={() => {this.handleCheckBoxByItem(selectList, item)}}
                            />
                        </div>
                        <div className={"notice-cell-main-wrap"}
                             onClick={() => {
                                 item["isRead"] ? this.setModalAndData(modalStatus, item) : this.handleReadNotice(modalStatus, item, index)
                             }}
                        >
                            <div className={"notice-cell notice-status"}>
                                {
                                    item["isRead"] ?
                                        <FolderOpenOutlined />
                                        :
                                        <MailOutlined />
                                }
                            </div>
                            <div className={"notice-cell notice-date"}>
                                {item["noteDate"]}
                            </div>
                            <div className={"notice-cell notice-type"}>
                                {this.handleType(item["noteType"])}
                            </div>
                            <div className={"notice-cell notice-txt"}>
                                <div className={"notice-txt-style"}>
                                    {item["noteMsg"]}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            ui.push(
                <div className={"notice-plugin-no-data"}>
                    <img className={"table-ct-no-data-icon"} src={NODATA} alt={"no-data"}/>
                    <div className={"table-ct-no-data-tip"}>
                        {"暂无数据"}
                    </div>
                </div>
            )
        }
        return ui;
    }

    // 查看调用接口
    handleReadNotice = (modalStatus, selData, index) => {
        let userInfo = UserInfoStorage.get("userInfo");
        let params = {
            userId: userInfo.userId,
            type: selData["noteType"],
            noticeId: selData["noticeId"],
        };
        sendGetRequest(ReadNoticeUrl, params, false).then(res => {
            console.log("查看调用接口res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    let data = this.state.data;
                    data[index]["isRead"] = true;
                    this.setState({
                        data: data
                    });
                    this.setModalAndData(modalStatus, selData)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 详情弹窗开关
    setModalAndData = (modalStatus, selData) => {
        this.setState({
            modalStatus: !modalStatus,
            selData: selData,
        })
    }

    // 详情弹窗
    loadModal = (modalStatus, selData) => {
        if (modalStatus === true) {
            console.log(selData)
            return (
                <div className={"modal-plugin"}>
                    <div className={"notice-plugin-box"}>
                        <div className={"modal-header-wrap"}>
                            <div className={"modal-header-title"}>
                                {"信息详情"}
                            </div>
                            <div className={"modal-header-subtitle"}>
                                {"若信息与实际情况不符，请联系客服"}
                            </div>
                        </div>
                        <div className={"notice-plugin-info"}>
                            <div className={"notice-plugin-info-header"}>
                                <div className={"notice-plugin-info-header-left"}>
                                    <div>
                                        <img className={"img-style contain"} src={READ} alt={""}/>
                                    </div>
                                </div>
                                <div className={"notice-plugin-info-header-right"}>
                                    <div className={"notice-plugin-info-header-item"}>
                                        <div className={"notice-plugin-info-header-item-title"}>
                                            {"时间："}
                                        </div>
                                        <div className={"notice-plugin-info-header-item-txt"}>
                                            {selData["noteDate"]}
                                        </div>
                                    </div>
                                    <div className={"notice-plugin-info-header-item"}>
                                        <div className={"notice-plugin-info-header-item-title"}>
                                            {"消息主题："}
                                        </div>
                                        <div className={"notice-plugin-info-header-item-txt"}>
                                            {this.handleType(selData["noteType"])}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"notice-plugin-info-main"}>
                                <div className={"notice-plugin-info-main-msg"}>
                                    {selData["noteMsg"]}
                                </div>
                            </div>
                        </div>
                        <div className={"form-plugin-btn-wrap"}>
                            <button className={"center-ct-button save-button"}
                                    onClick={() => {
                                        this.setModalAndData(modalStatus, '')
                                    }}
                            >
                                {"确定"}
                            </button>
                        </div>
                        <div className={"modal-close-wrap"}>
                            <CloseOutlined onClick={() => {
                                this.setModalAndData(modalStatus, '')
                            }}/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 获取的数据排序
    sortByDate = (data) => {
        if (data.length > 0) {
            data.sort(function (a, b) {
                if (moment(a["noteDate"]).isBefore(b["noteDate"])) {
                    return 1;
                }
                if (moment(a["noteDate"]).isAfter(b["noteDate"])) {
                    return -1;
                }
                if (moment(a["noteDate"]).isSame(b["noteDate"])) {
                    if (a["noticeId"] > b["noticeId"]) {
                        return -1;
                    }
                    if (a["noticeId"] < b["noticeId"]) {
                        return 1;
                    }
                }
            })
        }
        return data;
    }

    // 全选
    handleCheckBox = (selectList, data) => {
        let len = selectList.length;
        if (len > 0 && len <= data.length) {
            selectList = [];
        } else {
            selectList = Object.assign([], data);
        };
        this.setState({
            selectList: this.sortByDate(selectList),
        })
    }

    // 添加删除的消息
    handleCheckBoxByItem = (selectList, selData) => {
        console.log(selData)
        if (selectList.includes(selData)) {
            let len = selectList.length;
            for (let i = 0; i < len; i++) {
                console.log(i, selectList[i]["noticeId"])
                console.log(i, 2, selData["noticeId"])
                if (selectList[i]["noticeId"] === selData["noticeId"]) {
                    selectList.splice(i, 1);
                    this.setState({
                        selectList: this.sortByDate(selectList),
                    })
                    break;
                }
            }
        } else {
            selectList.push(selData);
            this.setState({
                selectList: this.sortByDate(selectList),
            })
        }

    }

    // 删除消息接口
    handleDeleteNotice = (selectList) => {
        let pojo = selectList;
        sendPostRequest(genPojo(DeleteUserNoticesUrl, pojo), true).then(res => {
            console.log("删除消息接口res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    success("删除消息成功");
                    this.getNoticesByPage(pageLimit, 1);
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot);
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 根据type显示对应主题
    handleType = (type) => {
        switch (type) {
            case 1: {return "资质认证";}
            case 2: {return "专家预约";}
            case 3: {return "技术需求提交";}
            case 4: {return "科技成果提交";}
            case 5: {return "账户管理";}
            case 6: {return "项目解锁";}
            case 7: {return "系统通知";}
            case 8: {return "湃通币充值";}
            default: {return "系统通知";}
        }
    }

    componentDidMount() {
        this.getNoticesByPage(pageLimit, 1);
    }

    // 根据页数获取消息
    getNoticesByPage = (pageLimit, pageNo) => {
        let userInfo = UserInfoStorage.get("userInfo");
        let params = {
            userId: userInfo.userId,
            pageLimit: pageLimit,
            pageNo: pageNo,
        };
        sendGetRequest(FindNoticesByPageUrl, params,true).then(res => {
            console.log("根据页数获取消息res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.setState({
                        data: this.sortByDate(res[data]),
                    })
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    render() {
        let userInfo = this.state.userInfo;
        let data = this.state.data;
        console.log("消息：", data);

        let selectList = this.state.selectList;
        console.log("selectList：", selectList);

        let modalStatus = this.state.modalStatus;
        let selData = this.state.selData;

        return (
            <div className={"notices-mgr-ct"}>
                {modalStatus && this.loadModal(modalStatus, selData)}
                <div className={"center-ct-header"}>
                    <div className={"center-ct-header-wrap"}>
                        <div className={"center-ct-header-icon"}/>
                        <div className={"center-ct-header-title"}>
                            {"消息"}
                        </div>
                        <div className={"center-ct-header-subtitle"}>
                            {"领先一步获取先机，轻松助力成果转化"}
                        </div>
                    </div>
                </div>
                <div className={"notices-mgr-header"}>
                    <div className={"notices-mgr-header-cell notice-check"}>
                        <Checkbox
                            className={"notice-checkbox"}
                            checked={data.length !== 0 && selectList.length === data.length}
                            onChange={() => {this.handleCheckBox(selectList, data)}}
                        />
                        {
                            selectList.length > 0 && selectList.length < data.length ?
                                <MinusOutlined className={"checkbox-minus"}
                                               onClick={() => {this.handleCheckBox(selectList, data)}}
                                />
                                : null
                        }
                    </div>
                    <div className={"notice-cell-main-wrap"}>
                        <div className={"notices-mgr-header-cell notice-status"}>
                            <MailOutlined style={{color: "rgb(255,255,255)"}}/>
                        </div>
                        <div className={"notices-mgr-header-cell notice-date"}>
                            {"日期"}
                        </div>
                        <div className={"notices-mgr-header-cell notice-type"}>
                            {"主题"}
                        </div>
                        <div className={"notices-mgr-header-cell notice-ct"}>
                            {"内容"}
                        </div>
                    </div>
                    <div className={"notices-mgr-header-fun"}>
                        <div className={"operation-plugin delete-fun"}
                             onClick={() => {
                                 this.handleDeleteNotice(selectList)
                             }}
                        >
                            <DeleteOutlined className={"operation-plugin-icon"}/>
                            <div className={"operation-plugin-txt"}>
                                {"删除已选"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"notices-mgr-notices-list"}>
                    {this.loadNotices(data, selectList, modalStatus)}
                </div>
            </div>
        );
    }
}

export default NoticesMgr;