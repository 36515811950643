import React, {Component} from "react";
import "./page.css";
import {
    CenterRoot,
    genProcessBack,
    handleUrlState,
    LoginRoot,
    RegisterRoot,
    storage,
    UserInfoStorage
} from "../tool/Const";
import {LoginByAliUrl, RefreshUserByIdUrl, sendGetBack, UpdateAliUrl} from "../tool/http/Request";
import {withRouter} from 'react-router-dom';
import {successReturn} from "../tool/http/ErrorMgr";
import ALI_BG from "../assets/img/alipay_bg.jpg";

class AliPlugin extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.handleRedirectUrl();
    }

    // 处理支付宝重定向
    handleRedirectUrl = () => {
        let url = window.location.href;
        let params = url.split("?")[1];
        console.log(params);
        if (params) {
            let paramsList = params.split("&");
            console.log(paramsList);

            let authCodePart = paramsList.filter(it => it.includes("auth_code="));
            let authCode = authCodePart[0].split("auth_code=")[1];
            console.log(authCodePart, authCode);

            let statePart = paramsList.filter(it => it.includes("state="));
            if (statePart) {
                let stateList = handleUrlState(statePart);
                let index = Number.parseInt(stateList.index);
                let code = stateList.stateCode;
                let storageKey = (index === 1 ? "aliLoginState" : "aliBindState");
                console.log(storageKey);
                let stateSession = storage.get(storageKey);
                console.log("stateSession: ", stateSession)
                if (Number.parseInt(code) === Number.parseInt(stateSession)) {
                    // 校验通过
                    console.log("state校验通过");
                    let param = {
                        authCode: authCode,
                    }

                    let _this = this;
                    const loginBack: WinBack = {
                        callDataBack(data: any) {
                            console.log("支付宝登录：", data);
                            let userId = data.userId;
                            if (userId) {
                                // 直接登录
                                UserInfoStorage.set("userInfo", data);
                                let toFrom = storage.getF("historyFrom");
                                const urlTo = toFrom || CenterRoot;
                                successReturn("支付宝登录成功", urlTo);
                            } else {
                                // 未绑定过账号，需要填写信息
                                if (index === 1) {
                                    // 注册界面
                                    _this.goRegByAli(data);
                                } else {
                                    // 个人中心界面
                                    _this.handleBindAli(data, "支付宝账号已绑定");
                                }
                            }
                        }
                    }

                    sendGetBack(LoginByAliUrl, param, true, genProcessBack(loginBack, true));
                } else {
                    // 触发CSRF攻击
                    // 不可证明客户端和当前第三方网站的登录认证状态存在关联
                    console.log("触发CSRF攻击[state校验不通过]");
                    // window.location.href = LoginRoot;
                }
            } else {
                // 触发CSRF攻击
                // 不可证明客户端和当前第三方网站的登录认证状态存在关联
                console.log("触发CSRF攻击[没有state]");
                // window.location.href = LoginRoot;
            }
        }
    }

    /**
     * 执行注册流程
     * 传参userInfo
     */
    goRegByAli = (userInfo) => {
        //必须在使用withRouter的情况下，该组件在this.props中才有路由参数和方法
        //否则，会报错
        let aliId = userInfo.aliId;
        this.props.history.push({
            pathname: RegisterRoot,
            state: {
                aliId: aliId,
            }
        })
    }

    /**
     * 执行账号绑定流程
     */
    handleBindAli = (data, msg) => {
        let userInfo = UserInfoStorage.get("userInfo");
        let userId = userInfo.userId;
        let aliId = data.aliId;
        // this.props.history.push({
        //     pathname: CenterRoot + "?type=account&way=ali",
        //     state: {
        //         aliId: aliId,
        //     }
        // })
        let params = {
            userId: userId,
            aliId: aliId,
        };

        let _this = this;
        const updateAliBack: WinBack = {
            callBack() {
                _this.refreshUser(userId, msg);
            }
        }

        sendGetBack(UpdateAliUrl, params, true, genProcessBack(updateAliBack, false));
    }

    // 更新用户信息
    refreshUser = (userId, msg) => {
        let params = {
            userId: userId,
        };

        const refreshBack: WinBack = {
            callDataBack(data: any) {
                UserInfoStorage.set("userInfo", data);
                successReturn(msg, CenterRoot + "?type=account");
            }
        }

        sendGetBack(RefreshUserByIdUrl, params, true, genProcessBack(refreshBack, true));
    }

    render() {
        return (
            <div className={"ali-plugin-wrap"}>
                <img className={"img-style cover"} src={ALI_BG} alt={"bg"}/>
            </div>
        )
    }
}

export default withRouter(AliPlugin);