import React, {Component} from 'react';
import "./about.css";
import "../page.css";
import Header from "../Header";
import Footer from "../Footer";
import CompanyInfo from "./CompanyInfo";
import CompanyCulture from "./CompanyCulture";
import FoundingTeam from "./FoundingTeam";
import CoPartners from "./CoPartners";

const tagsList = [
    {
        key: 1,
        tag: "公司简介",
    },
    {
        key: 2,
        tag: "企业文化",
    },
    {
        key: 3,
        tag: "创始团队",
    },
    {
        key: 4,
        tag: "合作伙伴",
    },
    // {
    //     key: 5,
    //     tag: "加入我们",
    // }
]

class AboutPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tag: 1,
            bannerImg: "",
        }
    }

    loadTagsNav = (tagsList, tag) => {
        let list = [];
        tagsList.map((item, index) => {
            let isSel = tag === item.key;
            list.push(
                <div className={isSel ? "about-tag-ct-sel" : "about-tag-ct"} key={index}
                     onMouseEnter={() => {this.changeTag(item.key)}}
                     // onClick={() => {this.changeTag(item.key)}}
                >
                    {item.tag}
                </div>
            )
        })
        return list;
    }

    changeTag = (key) => {
        this.setState({
            tag: key,
        })
    }

    loadTagsConByTag = (tag) => {
        switch (tag) {
            case 1: {
                return <CompanyInfo parent={this}/>
            }
            case 2: {
                return <CompanyCulture parent={this}/>
            }
            case 3: {
                return <FoundingTeam parent={this}/>
            }
            case 4: {
                return <CoPartners parent={this}/>
            }
            // case 5: {
            //     return <JoinUs parent={this}/>
            // }
            default: {
                return <CompanyInfo parent={this}/>
            }
        }
    }

    loadData = (bannerImg) => {
        this.setState({
            bannerImg: bannerImg,
        })
    }

    componentDidMount() {
        this.handleUrlBySearch();
    }

    /**
     * 处理首页跳转过来的合作伙伴
     */
    handleUrlBySearch = () => {
        let url = this.props.location.search;
        console.log(url);
        if (url) {
            let params = url.split("?type=")[1];
            console.log(params);
            this.changeTag(Number.parseInt(params));
        }
    }

    render() {
        let tag = this.state.tag;
        let bannerImg = this.state.bannerImg;

        return (
            <div className={"home-ct"}>
                <Header/>
                <div className={"about-ct"}>
                    <div className={"about-page"}>
                        <div className={"about-banner-outer"}>
                            <div className={"about-banner-words-outer"}>
                                <div>{tagsList[tag - 1].tag}</div>
                                <div style={{height: "6px", width: "50px", background: "white", marginTop: "20px"}}/>
                            </div>
                            {/*<img className={"about-banner-img"} src={bannerImg} alt={""}/>*/}
                        </div>
                    </div>
                    <div className={"about-page-ct"}>
                        <div className={"about-page-tags"}>
                            <div className={"about-tags-wrap"}>
                                {this.loadTagsNav(tagsList, tag)}
                            </div>
                        </div>
                        <div className={"about-page-tags-ct"}>
                            <div className={"about-tags-ct-wrap"}>
                                {this.loadTagsConByTag(tag)}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default AboutPage;
