import React, {Component} from 'react'
import moment from 'moment'
import {AimOutlined, CloseOutlined, SafetyOutlined, UserOutlined} from "@ant-design/icons";
import "./center.css";
import {
    CheckExpertAppointUrl,
    data,
    error, genPojo,
    loginOutErrOr,
    sendGetRequest,
    sendPostRequest, SubmitExpAppointUrl
} from "../../tool/http/Request";
import {connectionError, fail, failReturn, findMsgByError, success} from "../../tool/http/ErrorMgr";
import {getWeek, LoginRoot, PolicyMap, scheduleTime, schTime, UserInfoStorage, utcTimeFormat} from "../../tool/Const";
import {Select} from "antd";
import GeoFilter from "../../tool/filter/GeoFilter";
import CommonSelect from "../../tool/filter/CommonSelect";
// import 'antd/dist/antd.css';

const {Option} = Select;

// 星期list
const week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // curDate: utcTimeFormat, // 当前日期和时间
            // dateUpperLimit: '',  // 当前日期上限
            // dateLowerLimit: '',  // 当前日期下限
            showModal: false,
            selDate: '',  // 选择的日期
            dateMap: new Map(),  // 年份与对应月份map
            selYear: moment().format('YYYY'),  // 选择的年份
            selMonth: moment().format('MM'),  // 选择的月份

            selTimePoint: '请选择时间点',  // 根据预约日程的开始时间点
            selTime: '',  // 根据预约日程的开始时间点显示的预约时间段
            selSch: [],  // 用户预约的时间节点
            selAppointTitle: '',  // 用户的预约主题

            openOption: false,  // option开关
        }
    }

    // 加载上个月显示日期
    lastMonth = (type, appointMap) => {
        let date = moment(this.props.curDate).date(1);  // 当前月的1号
        let count = date.day();  // 1号是星期几
        date.subtract(count, "day");  // 比如本月1号是星期3，那么上个月的数据就应该显示3个
        let ui = [];
        for (let i = 0; i < count; i++) {
            let todayMoment = moment(date);
            let dayProps = moment(todayMoment).format('YYYY-MM-DD');
            ui.push(
                <div className={"calendar-day-cell last-month"} key={'lastMonth_' + i}
                     style={{height: type === "schCal" ? "85px" : ""}}
                >
                    <div className={"calendar-day-cell day"}>
                        <div className={"calendar-day-cell-date"}>
                            {date.date()}
                        </div>
                        <div className={"calendar-day-cell-item"}>
                            {type === "schCal" ? "" : this.loadCalendarDetail(dayProps, appointMap)}
                        </div>
                    </div>
                </div>
            )
            date.add(1, 'day')
        }
        return ui;
    }

    // 加载下个月显示日期
    nextMonth = (type, appointMap) => {
        // 选中的时间改为1号,并且加上1个月
        let date = moment(this.props.curDate).date(1).add(1, "months");
        let count = 7 - date.day(); // 7-星期几，就可知道要渲染几个下月数据
        if (count === 7) {
            return null;
        }
        let ui = [];
        for (let i = 0; i < count; i++) {
            let todayMoment = moment(date);
            let dayProps = moment(todayMoment).format('YYYY-MM-DD');
            ui.push(
                <div className={"calendar-day-cell next-month"} key={'nextMonth_' + i}
                     style={{height: type === "schCal" ? "85px" : ""}}
                >
                    <div className={"calendar-day-cell day"}>
                        <div className={"calendar-day-cell-date"}>
                            {date.date()}
                        </div>
                        <div className={"calendar-day-cell-item"}>
                            {type === "schCal" ? "" : this.loadCalendarDetail(dayProps, appointMap)}
                        </div>
                    </div>
                </div>
            )
            date.add(1, 'day')
        }
        return ui;
    }

    // 加载当月日期js 默认触发onChange
    thisMonth = (type, expertSchMap, openTimeSchMap, showModal, dateUpperLimit, dateLowerLimit, appointMap) => {
        let date = moment(this.props.curDate).date(1); // 获取本月1号数据
        let count = date.daysInMonth(); // 当前选中月份的总条数
        // 最后一个工作日,本月1号,+1月-1天=本月最后一天
        let ui = [];
        for (let i = 0; i < count; i++) {
            let nowMoment = moment();
            let todayMoment = moment(date);
            let isNow = todayMoment.isSame(nowMoment, "day");

            // let isSame = this.state.activeDay && todayMoment.isSame(this.state.activeDay, "day"); // 判断哪一天是选中日期
            let dayProps = moment(todayMoment).format('YYYY-MM-DD');
            // 判断是否在范围日期之内
            let isBetween = moment(dayProps).isBetween(dateLowerLimit, dateUpperLimit, null, '[]');
            // console.log("todayMoment: ", moment(todayMoment).format('YYYY-MM-DD'))
            // console.log("isBetween: ", isBetween)

            let isOpen = false;
            let isAppoint = false;
            if (type === "schCal") {
                // console.log(expertSchMap)
                // 处理是否开放预约
                isOpen = JSON.stringify(expertSchMap.get(parseInt(moment(todayMoment).format('E')))) !== "[]";

                // 处理是否有开放预约时间段
                isAppoint = JSON.stringify(openTimeSchMap.get(moment(todayMoment).format('YYYY-MM-DD'))) !== "[]";
                // console.log(moment(todayMoment).format('YYYY-MM-DD'), isAppoint, isAppoint !== undefined);

                ui.push(
                    <div className={"calendar-day-cell this-month"} key={'thisMonth_' + i}
                         style={{
                             height: "85px",
                             color: !isBetween ? "#cecece" : "",
                             pointerEvents: !isBetween || !isOpen || !isAppoint ? 'none' : "",
                         }}
                    >
                        <div className={"calendar-day-cell day"}
                             onClick={() => {
                                 this.setModal(type, showModal, dayProps)
                             }}
                        >
                            <div className={isNow ? "calendar-day-cell-date today" : "calendar-day-cell-date"}>
                                {date.date()}
                            </div>
                            <div className={isNow ?
                                "calendar-day-cell-item today" :
                                isBetween ?  !isOpen ? "calendar-day-cell-item no-open" :
                                        isAppoint ? "calendar-day-cell-item open-appoint" :
                                            "calendar-day-cell-item no-appoint"
                                    : "calendar-day-cell-item no-between"}>
                            </div>
                        </div>
                    </div>
                )
                date.add(1, "day");
            } else {
                ui.push(
                    <div className={"calendar-day-cell this-month"} key={'thisMonth_' + i}
                         style={{
                             color: !isBetween ? "#cecece" : "",
                             pointerEvents: !isBetween ? 'none' : "",
                         }}
                    >
                        <div className={"calendar-day-cell day"}
                             onClick={() => {
                                 this.setModal(type, showModal, dayProps)
                             }}
                        >
                            <div className={isNow ? "calendar-day-cell-date today" : "calendar-day-cell-date"}>
                                {date.date()}
                            </div>
                            <div className={isNow ?
                                "calendar-day-cell-item today" :
                                isBetween ? "calendar-day-cell-item" :
                                    "calendar-day-cell-item no-between"}>
                                {this.loadCalendarDetail(dayProps, appointMap)}
                            </div>
                        </div>
                    </div>
                )
                date.add(1, "day");
            }
        }
        return ui;
    }

    // 加载预约详情
    loadCalendarDetail = (selDate, appointMap) => {
        let list = [];
        if (appointMap.size > 0) {
            let hasData = appointMap.has(selDate);
            if (hasData) {
                let data = appointMap.get(selDate);
                // console.log("mapData: ", data);
                data.forEach((item, index) => {
                    let startTime = schTime[item.appointTime.split(';')[0]];
                    let endTime = schTime[item.appointTime.split(';')[2]];
                    list.push(
                        <div className={"calendar-day-cell-item-wrap"}>
                            <div className={"calendar-day-cell-item-cell-icon"}/>
                            <div className={"calendar-day-cell-item-cell appoint-time"}>
                                {startTime + '-' + endTime}
                            </div>
                            <div className={"calendar-day-cell-item-cell appoint-user"}>
                                {item.userDes.userReal ? item.userDes.userReal : item.userDes.userNick}
                            </div>
                        </div>
                    )
                })
            }
        }
        return list;
    }

    // 加载预约详情弹窗
    loadModal = (type, showModal, userInfo, expertData, selDate, openTimeSchMap, curDate, dateUpperLimit, appointMap) => {
        if (showModal === true) {
            if (type === "schCal") {
                let selTimePoint = this.state.selTimePoint;
                let selSch = this.state.selSch;
                return (
                    <div className={"sch-cal-modal-bg"}>
                        <div className={"sch-cal-modal-ct"}>
                            <div className={"modal-header-wrap"}>
                                <div className={"modal-header-title"}>
                                    {selDate + "（" + getWeek(selDate) + "）" + " 预约日程"}
                                </div>
                            </div>
                            <div className={"sch-cal-appoint-wrap"}>
                                <div className={"sch-cal-appoint-ct"}>
                                    <div className={"sch-cal-appoint-txt"}>
                                        {"选择预约开始时间点"}
                                    </div>
                                    {/*<select className={"sch-cal-appoint-select"}*/}
                                    {/*        id={"select" + selDate}*/}
                                    {/*        onChange={this.handleSchSel.bind(this)}*/}
                                    {/*        onClick={this.handleSchSel.bind(this)}*/}
                                    {/*>*/}
                                    {/*    {this.loadSchSel(openTimeSchMap, selDate)}*/}
                                    {/*</select>*/}
                                    <CommonSelect parent={this} classWrap={"sch-cal-appoint-select"}
                                                  selTimePoint={selTimePoint}
                                                  data={openTimeSchMap} tag={selDate}
                                    />
                                </div>
                                <div className={"sch-cal-appoint-ct"}>
                                    <div className={"sch-cal-appoint-txt"}>
                                        {"预约时间段"}
                                    </div>
                                    <div className={"sch-cal-appoint-date"}>
                                        {this.state.selTime ? this.state.selTime : "未选择预约时间段"}
                                    </div>
                                </div>
                                <div className={"sch-cal-appoint-ct"}>
                                    <div className={"sch-cal-appoint-txt"}>
                                        {"预约主题"}
                                    </div>
                                    <input className={"sch-cal-appoint-input"}
                                           id={"appointTitle"}
                                           type={"text"}
                                           placeholder={"请输入预约主题，最多25个字"}
                                           maxLength={25}
                                           onChange={(e) => {this.fillAppointTitle(e)}}
                                    />
                                </div>
                                <div className={"sch-cal-appoint-tip"}>
                                    {"注意：专家预约请认真预约，每次预约时间为一小时，提交预约后客服会来电沟通，请稍候"}
                                </div>
                                <div className={"sch-cal-appoint-btn-wrap"}>
                                    <button className={"center-ct-button save-button"}
                                            onClick={() => {
                                                this.submitExpAppoint(userInfo, expertData, selDate, selSch)
                                            }}
                                    >
                                        {"预约"}
                                    </button>
                                    <button className={"center-ct-button cancel-button"} style={{marginLeft: '60px'}}
                                            onClick={() => {
                                                this.setModal(type, showModal)
                                            }}
                                    >
                                        {"取消"}
                                    </button>
                                </div>
                            </div>
                            <div className={"modal-close-wrap"}>
                                <CloseOutlined onClick={() => {this.setModal(type, showModal)}}/>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className={"modal-bg"}>
                        <div className={"calendar-modal-ct"}>
                            <div className={"modal-header-wrap"}>
                                <div className={"modal-header-title"}>
                                    {selDate + "（" + getWeek(selDate) + "）" + " 预约详情"}
                                </div>
                            </div>
                            <div className={"modal-timeline-wrap"}>
                                <div className={"modal-timeline-ct"}>
                                    {this.loadTimeLine(curDate, dateUpperLimit, selDate, appointMap)}
                                </div>
                            </div>
                            <div className={"modal-close-wrap"}>
                                <CloseOutlined onClick={() => {
                                    this.setModal(type, showModal)
                                }}/>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    // 填充预约主题
    fillAppointTitle = (e) => {
        let value = e.target.value;
        console.log("输入信息：", value);
        this.setState({
            selAppointTitle: value,
        })
    }

    // 预约详情弹窗开关
    setModal = (type, showModal, date) => {
        if (type === "schCal") {
            this.props.parent.stopScrollM(showModal);
            this.setState({
                showModal: !showModal,
                selDate: date,
                selTime: '',
                selTimePoint: '请选择时间点',
                selAppointTitle: '',
            })
        } else {
            this.setState({
                showModal: !showModal,
                selDate: date,
            })
        }
    }

    // 处理预约时间段切换
    handleSchSel = (value) => {
        console.log("选择为：", value);
        let list = [];
        let startSch = parseInt(value);
        let middleSch = parseInt(value) + parseInt(1);
        let endSch = parseInt(value) + parseInt(2);
        let startTime = schTime[startSch];
        let endTime = schTime[endSch]
        list.push(startSch, middleSch, endSch);
        console.log(list);
        let selTime = startTime + " - " + endTime;
        this.setState({
            selTimePoint: startTime,
            selTime: selTime,
            selSch: list,
        })
    }

    // 预约专家时间段
    submitExpAppoint = (userInfo, expertData, selDate, selSch) => {
        let par = {
            userId: userInfo.userId,
            expertId: expertData.refId,
            appointDate: selDate,
            appointTime: (selSch + "").replaceAll(',', ';'),
            appointTitle: this.state.selAppointTitle,
        }
        let pojo = {
            orderId: '202205050003',
            realPrice: expertData.expertPrice,
            serviceCt: JSON.stringify(par),
        };
        // console.log("提交预约pojo", pojo);
        sendPostRequest(genPojo(SubmitExpAppointUrl, pojo), false).then(res => {
            console.log("提交预约res", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    success("日程预约成功");
                    window.location.reload();
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 加载预约详情时间线
    loadTimeLine = (curDate, dateUpperLimit, selDate, appointMap) => {
        // 判断日期大小
        let curDateFormat = moment().utcOffset(8);  // 一定要去拿当前日期，并非对象
        let isBetween = moment(selDate).isBetween(curDateFormat, dateUpperLimit, null, '[]');

        let list = [];
        if (appointMap.size > 0) {
            let hasData = appointMap.has(selDate);
            if (hasData) {
                let data = appointMap.get(selDate);
                console.log("mapData: ", data)
                data.forEach((item, index) => {
                    // let timeLower = item.time.split("-")[0];
                    // let timeUpper = item.time.split("-")[1];
                    // 预约时间标准化
                    let startTime = schTime[item.appointTime.split(';')[0]];
                    let endTime = schTime[item.appointTime.split(';')[2]];
                    list.push(
                        <div className={"calendar-modal-timeline"} key={index}>
                            <div className={"calendar-modal-timeline-cell timeline-time"}>
                                <span>
                                    {startTime}
                                </span>
                                <span>
                                    {endTime}
                                </span>
                            </div>
                            <div className={"calendar-modal-timeline-block"}
                                 style={!isBetween ? {background: 'rgba(187,187,187,0.2)'} :
                                     index%2 === 1 ?{background: 'rgba(15,150,150,0.4)'} : {}}
                            />
                            <div className={"calendar-modal-timeline-line"}/>
                            <div className={"calendar-modal-timeline-cell timeline-object"}
                                 style={!isBetween ? {background: 'rgba(187,187,187,0.2)', color: 'rgb(112,112,112)'} :
                                     index%2 === 1 ?{background: 'rgba(15,150,150,0.4)'} : {}}
                            >
                                <div className={"calendar-modal-timeline-cell-object"}>
                                    <div className={"calendar-modal-timeline-cell-icon userDes-userName"}/>
                                    <span>
                                        {"预约用户："}
                                    </span>
                                    <span style={{marginLeft: '8px'}}>
                                        {item["userDes"].userReal ? item["userDes"].userReal : item["userDes"].userNick}
                                    </span>
                                </div>
                                <div className={"calendar-modal-timeline-cell-object"}>
                                    <div className={"calendar-modal-timeline-cell-icon userDes-userComp"} />
                                    <span>
                                        {"所属公司："}
                                    </span>
                                    <span style={{marginLeft: '8px'}}>
                                        {item["userDes"].userComp}
                                    </span>
                                </div>
                                <div className={"calendar-modal-timeline-cell-object"}>
                                    <div className={"calendar-modal-timeline-cell-icon userDes-userArea"}/>
                                    <span>
                                        {"所属行业："}
                                    </span>
                                    <span style={{marginLeft: '8px'}}>
                                        {item["userDes"].userArea}
                                    </span>
                                </div>
                                <div className={"calendar-modal-timeline-cell-object"}>
                                    <div className={"calendar-modal-timeline-cell-icon userDes-appointTitle"}/>
                                    <span>
                                        {"预约主题："}
                                    </span>
                                    <span style={{marginLeft: '8px'}}>
                                        {item.appointTitle}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        }
        return list;
    }

    // 初始化日期选择列表（年份,年份所包含的月份）
    initDateMap = () => {
        let curDate = this.props.curDate;
        let dateLowerLimit = this.props.dateLowerLimit;
        let dateUpperLimit = this.props.dateUpperLimit;
        let type = this.props.type;
        if (curDate && dateLowerLimit && dateUpperLimit) {
            if (type === "schCal") {
                this.getDataMapBySchCal(curDate, dateLowerLimit, dateUpperLimit);
            } else {
                this.getDataMapByExpCal(curDate, dateLowerLimit, dateUpperLimit);
            }
        }
    }

    // 预约专家组件获取年份,年份所包含的月份
    getDataMapBySchCal = (curDate, dateLowerLimit, dateUpperLimit) => {
        let map = new Map();
        // 获取每个的年份
        let curYear = curDate.format("YYYY");
        let lowerYear = moment(dateLowerLimit).format("YYYY");
        let upperYear = moment(dateUpperLimit).format("YYYY");
        // console.log(curYear, lowerYear, upperYear);
        // 获取每个的年份包含的月份
        let curMonth = curDate.format("MM");
        let lowerMonth = moment(dateLowerLimit).format("MM");
        let upperMonth = moment(dateUpperLimit).format("MM");
        // console.log(curMonth, lowerMonth, upperMonth);
        let yearList = this.getMonthArr(curYear, upperYear);
        // console.log(list);
        // let yearSet = new Set();
        // yearList.forEach((time) => {
        //     yearSet.add(time);
        // });
        map.set("yearList", yearList);
        // console.log(yearSet);
        if (yearList.length === 1) {
            map.set(curYear, this.getMonthArr(curMonth, upperMonth))
        } else {
            map.set(curYear, this.getMonthArr(curMonth, 12));
            map.set(upperMonth, this.getMonthArr(1, upperMonth));
        }
        this.setState({
            dateMap: map,
        });
        console.log("dataMap: ", map);
    }

    // 日程查看组件获取年份,年份所包含的月份
    getDataMapByExpCal = (curDate, dateLowerLimit, dateUpperLimit) => {
        let map = new Map();
        // 获取每个的年份
        let curYear = moment(curDate).format('YYYY');
        let lowerYear = moment(dateLowerLimit).format("YYYY");
        let upperYear = moment(dateUpperLimit).format("YYYY");
        let yearList = this.getMonthArr(lowerYear, upperYear);
        // 获取每年的月份
        let curMonth = moment(curDate).format('MM');
        let lowerMonth = moment(dateLowerLimit).format('MM');
        let upperMonth = moment(dateUpperLimit).format('MM');
        // console.log(yearList);
        // let yearSet = new Set();
        // yearSet.add(this.getMonthArr(lowerYear, upperYear));
        // console.log(Array.from(yearSet));
        map.set("yearList", yearList);
        map.set(lowerYear, this.getMonthArr(lowerMonth, 12));
        if (upperYear > curYear) {
            map.set(curYear, this.getMonthArr(1, 12));
            map.set(upperYear, this.getMonthArr(1, upperMonth));
        } else {
            map.set(curYear, this.getMonthArr(1, upperMonth));
        };
        this.setState({
            dateMap: map,
        });
        console.log("dataMap: ", map);
    }

    // 获取月份开始和结束中间的连续月份
    getMonthArr = (start, end) => {
        let length = end - start + 1;
        let step = start - 1;
        return Array.apply(null,{length:length}).map(function (v,i){step++; return step;});
    }

    // 加载年份或月份选择(公共组件)
    loadYearMonthSel = (data, tag) => {
        let list = [];
        if (data.size > 0) {
            let monthList = data.get(tag);
            // console.log("data: ", data)
            // console.log("monthList: ", monthList)
            monthList.forEach((item, index) => {
                list.push(
                    <option className={"calendar-date-select-cell"} value={item} key={index}>
                        {item}
                    </option>
                )
            })
            return list;
        }
    }

    // 处理年份选择(公共组件)
    handleYearSel = (e) => {
        let selYearNew = e.target.value;
        let selYearOld = this.state.selYear;
        let selYearGap = Math.abs(selYearNew - selYearOld);
        // console.log("selYearGap: ", selYearGap)

        let curDate = this.props.curDate;
        let selMonth = this.state.selMonth;
        let dateMap = this.state.dateMap;
        let monthListNew = dateMap.get(selYearNew);
        let monthListOld = dateMap.get(selYearOld);

        // console.log("第一个月： ", monthListNew[0])
        // console.log("最后一个月： ", monthListNew[monthListNew.length-1])
        if (monthListNew[0] > selMonth || selMonth > monthListNew[monthListNew.length-1]) {
            let curDateNew = selYearNew > selYearOld ?
                curDate.add(13 - selMonth, 'months') : curDate.subtract(13 - monthListNew[0], 'months');
            this.setState({
                selYear: selYearNew,
                selMonth: monthListNew[0],
                // curDate: curDateNew,
            })
        } else {
            curDate.add(selYearNew - selYearOld, 'year');
            this.setState({
                selYear: selYearNew,
                selMonth: selMonth,
                // curDate: curDate.add(selYearNew - selYearOld, 'year'),
            },
                () => {
                console.log("加载年份选择: ", this.props.curDate.format("YYYY-MM-DD"))
            })
        }
    }

    // 处理月份选择(公共组件)
    handleMonthSel = (e) => {
        let selMonthOld = this.state.selMonth;
        let selMonthNew = e.target.value;
        let curDateOld = this.props.curDate;
        // console.log("monthSel: ", e.target.value)
        // console.log("monthSelOld: ", selMonthOld);
        // console.log("curDateOld: ", curDateOld);
        curDateOld.add(selMonthNew - selMonthOld, 'months')
        this.setState({
            selMonth: selMonthNew,
            // curDate: curDateOld.add(selMonthNew - selMonthOld, 'months'),
        })
    }

    // 标准化月份格式
    formatMonth = (month) => {
        if (month.toString()[0] == 0) {
            return month.toString()[1];
        }
    }

    componentDidMount() {
        this.initDateMap();
    }

    render() {
        let userInfo = this.props.userInfo;
        let expertData = this.props.expertData;
        let type = this.props.type;

        let curDate = this.props.curDate;
        let dateLowerLimit = this.props.dateLowerLimit;
        let dateUpperLimit = this.props.dateUpperLimit;
        let isDateUpperLimit = curDate === dateUpperLimit;
        let isDateLowerLimit = curDate === dateLowerLimit;
        console.log("目前切换的时间：", curDate.format('YYYY-MM-DD'))
        // console.log("dateUpperLimit: ", dateUpperLimit)
        // console.log("dateLowerLimit: ", dateLowerLimit)

        let dateMap = this.state.dateMap;  // 年份及对应月份map

        // 用户预约日程组件
        let expertSchMap = this.props.expertSchMap;
        // let userSchMap = this.props.userSchMap;
        let openTimeSchMap = this.props.openTimeSchMap;

        // 专家查看日程组件
        let appointMap = this.props.appointMap;
        // console.log("appointMap: ", appointMap)

        let curMonth = curDate.format("MM");
        let selDate = this.state.selDate;
        let selYear = this.state.selYear;
        let selMonth = this.state.selMonth;

        let showModal = this.state.showModal;

        return (
            [
                showModal && this.loadModal(type, showModal, userInfo, expertData, selDate, openTimeSchMap, curDate, dateUpperLimit, appointMap),
                <div className={"calendar-ct"}
                     style={{margin: type === "schCal" ? "20px auto" : ""}}
                >
                    <div className={"calendar-header"}>
                        <div className={"calendar-date"}>
                            <select className={"calendar-date-cell"}
                                    value={selYear}
                                    onChange={this.handleYearSel.bind(this)}
                            >
                                {this.loadYearMonthSel(dateMap, "yearList")}
                            </select>
                            <select className={"calendar-date-cell"}
                                    value={this.formatMonth(curMonth)}
                                    onChange={this.handleMonthSel.bind(this)}
                            >
                                {this.loadYearMonthSel(dateMap, selYear)}
                            </select>
                        </div>
                        {curDate.format('YYYY-MM')}
                        <div className={"calendar-sample"}>
                            <div className={"calendar-sample-cell"}>
                                <div className={"calendar-sample-cell-color calendar-today"} />
                                <div className={"calendar-sample-cell-text"}>
                                    {"当日"}
                                </div>
                            </div>
                            <div className={"calendar-sample-cell"}>
                                <div className={"calendar-sample-cell-color calendar-empty"} />
                                <div className={"calendar-sample-cell-text"}>
                                    {"开放预约"}
                                </div>
                            </div>
                            <div className={"calendar-sample-cell"}>
                                <div className={"calendar-sample-cell-color calendar-close"} />
                                <div className={"calendar-sample-cell-text"}>
                                    {"未开放预约"}
                                </div>
                            </div>
                            <div className={"calendar-sample-cell"}>
                                <div className={"calendar-sample-cell-color calendar-full"} />
                                <div className={"calendar-sample-cell-text"}>
                                    {"预约已满"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"calendar-week"}>
                        {week.map((item, index) => {
                            return (
                                <div className={"calendar-week-cell"} key={'week_' + index}>
                                    {item}
                                </div>
                            )
                        })}
                    </div>
                    <div className={"calendar-day"}>
                        {this.lastMonth(type, appointMap)}
                        {this.thisMonth(type, expertSchMap, openTimeSchMap, showModal, dateUpperLimit, dateLowerLimit, appointMap)}
                        {this.nextMonth(type, appointMap)}
                    </div>
                    <div style={{height: '10px'}}/>
                </div>
            ]
        )
    }
}

export default Calendar;
