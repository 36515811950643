import React, {Component} from 'react';
import "./about.css";
import IMG1 from "../../assets/partner/复旦大学.jpg";
import IMG2 from "../../assets/partner/同济大学.jpg";
import IMG3 from "../../assets/partner/华东政法大学.jpg";
import IMG4 from "../../assets/partner/上海视觉艺术学院.jpg";
import IMG5 from "../../assets/partner/上海交通职业技术学院.jpg";
import IMG6 from "../../assets/partner/76.jpg";
import IMG7 from "../../assets/partner/125.jpg";
import IMG8 from "../../assets/partner/130.jpg";
import IMG9 from "../../assets/partner/131.jpg";
import IMG10 from "../../assets/partner/133.jpg";
import IMG11 from "../../assets/partner/135.jpg";
import IMG12 from "../../assets/partner/136.jpg";
import IMG13 from "../../assets/partner/307.jpg";
import IMG14 from "../../assets/partner/308.jpg";
import IMG15 from "../../assets/partner/309.jpg";
import IMG16 from "../../assets/partner/310.jpg";
import IMG17 from "../../assets/partner/311.jpg";
import IMG18 from "../../assets/partner/312.jpg";
import IMG19 from "../../assets/partner/313.jpg";
import IMG20 from "../../assets/partner/315.jpg";
import IMG21 from "../../assets/partner/317.jpg";
import IMG22 from "../../assets/partner/318.jpg";
import IMG23 from "../../assets/partner/320.jpg";
import IMG24 from "../../assets/partner/324.jpg";
import IMG25 from "../../assets/partner/327.jpg";
import IMG26 from "../../assets/partner/329.jpg";
import IMG27 from "../../assets/partner/655.jpg";
import IMG28 from "../../assets/partner/666.jpg";
import IMG29 from "../../assets/partner/671.jpg";
import IMG30 from "../../assets/partner/676.jpg";
import IMG31 from "../../assets/partner/682.jpg";
import IMG32 from "../../assets/partner/936.jpg";
import IMG33 from "../../assets/partner/1010.jpg";
import IMG34 from "../../assets/partner/1011.jpg";
import IMG35 from "../../assets/partner/3039.jpg";
import IMG36 from "../../assets/partner/3151.jpg";
import IMG37 from "../../assets/partner/3194.jpg";
import IMG38 from "../../assets/partner/3427.jpg";
import IMG39 from "../../assets/partner/上海科学院.jpg";
import IMG40 from "../../assets/partner/中国科学院.jpg";
import Bk from "../pic/aboutCp.png";
import BANNER1 from "../../assets/about/cp_government.jpg";
import COMP1 from "../../assets/company/bosh.png";
import COMP2 from "../../assets/company/foton.png";
import COMP3 from "../../assets/company/tian.png";
import COMP4 from "../../assets/company/reebok.png";
import COMP5 from "../../assets/company/hurun.png";
import COMP6 from "../../assets/company/nar.png";
import COMP7 from "../../assets/company/jahwa.png";
import COMP8 from "../../assets/company/crec.png";
import COMP9 from "../../assets/company/guangming.png";
import COMP10 from "../../assets/company/yongtay.png";
import COMP11 from "../../assets/company/ikang.png";
import COMP12 from "../../assets/company/saic.png";
import COMP13 from "../../assets/company/pingan.png";
import COMP14 from "../../assets/company/qiangsheng.png";
import COMP15 from "../../assets/company/dongfangguoji.png";
import COMP16 from "../../assets/company/sph.png";
import COMP17 from "../../assets/company/qinghuatongfang.png";
import COMP18 from "../../assets/company/fanuc.png";

const companysList = [
    {key: "1", img: COMP1, title: null,},
    {key: "2", img: COMP2, title: null,},
    {key: "3", img: COMP3, title: null,},
    {key: "4", img: COMP4, title: null,},
    {key: "5", img: COMP5, title: null,},
    {key: "6", img: COMP6, title: null,},
    {key: "7", img: COMP7, title: null,},
    {key: "8", img: COMP8, title: null,},
    {key: "9", img: COMP9, title: null,},
    {key: "10", img: COMP10, title: null,},
    {key: "11", img: COMP11, title: null,},
    {key: "12", img: COMP12, title: null,},
    {key: "13", img: COMP13, title: null,},
    {key: "14", img: COMP14, title: null,},
    {key: "15", img: COMP15, title: null,},
    {key: "16", img: COMP16, title: null,},
    {key: "17", img: COMP17, title: null,},
    {key: "18", img: COMP18, title: null,},
]

const partnersList = [
    {key: "fddx", img: IMG1, title: "复旦大学",},
    {key: "jtdx", img: IMG7, title: "交通大学",},
    {key: "tjdx", img: IMG2, title: "同济大学",},
    {key: "hdsfdx", img: IMG9, title: "华东师范大学",},
    {key: "hdlgdx", img: IMG10, title: "华东理工大学",},
    {key: "dhdx", img: IMG11, title: "东华大学",},
    {key: "shcjdx", img: IMG8, title: "上海财经大学",},
    {key: "shdx", img: IMG6, title: "上海大学",},
    {key: "shlgdx", img: IMG13, title: "上海理工大学",},
    {key: "shzyydx", img: IMG16, title: "上海中医药大学",},
    {key: "shsfdx", img: IMG17, title: "上海师范大学",},
    {key: "hdzfdx", img: IMG3, title: "华东政法大学",},
    {key: "shhsdx", img: IMG19, title: "上海海事大学",},
    {key: "shhydx", img: IMG15, title: "上海海洋大学",},
    {key: "shgcjsdx", img: IMG14, title: "上海工程技术大学",},
    {key: "shdldx", img: IMG21, title: "上海电力大学",},
    {key: "shyyjsdx", img: IMG20, title: "上海应用技术大学",},
    {key: "shdegydx", img: IMG18, title: "上海第二工业大学",},
    {key: "shkjdx", img: IMG36, title: "上海科技大学",},
    {key: "shtyxy", img: IMG24, title: "上海体育学院",},
    {key: "shkxy", img: IMG39, title: "上海科学院",},
    {key: "zgkxy", img: IMG40, title: "中国科学院",},
]

class CoPartners extends Component{
    constructor(props) {
        super(props);
        this.state = {
            bannerImg: Bk,
        }
    }

    componentDidMount() {
        this.props.parent.loadData(this.state.bannerImg)
    }


    loadPartners = (data) => {
        let list = [];
        data.forEach((item, index) => {
            list.push(
                <div className={"about-com-ct-layout-ele"} key={item.key}>
                    <div className={"partner-ele"}>
                        <div className={"partner-img-style"}>
                            <img className={"img-style contain"} src={item.img} alt={"logo"}/>
                        </div>
                        {
                            item.title ?
                              <div className={"partner-name-title"}>
                                  {item.title}
                              </div>
                              : null
                        }
                    </div>
                </div>
            )
        })
        return list;
    }

    render() {
        return (
            <div className={"about-com-ct"}>
                <div className={"about-com-ct-level-one"}>
                    <div className={"about-com-ct-title"}>
                        {"政府（区域）合作伙伴"}
                    </div>
                    <div className={"about-com-ct-content"}>
                        <span>{"基于政府科创发展需要，湃通科技搭建区域技术要素市场、成果转化示范基地、科技创新活动等平台，助力政府构建创新生态体系。"}</span>
                    </div>
                    <div className={"about-com-ct-img"}>
                        <img className={"img-style contain"} src={BANNER1} alt={"company"}/>
                    </div>
                </div>
                <div style={{width: "100%" , height: '50px', borderBottom: "1px solid gray"}}/>
                <div style={{height: '50px'}}/>
                <div className={"about-com-ct-level-one"}>
                    <div className={"about-com-ct-title"}>
                        {"企业合作伙伴"}
                    </div>
                    <div className={"about-com-ct-content"}>
                        <span>{"湃通科技作为数字化技术服务平台“长三角一体化科创服务平台”的搭建者，为科技型企业提供从研发情报提供、产学研合作、知识产权布局、法律政策咨询、营销策划推广、产业渠道搭建、金融投资到政府项目申报全流程服务。"}</span>
                    </div>
                    <div style={{height: '20px'}}/>
                    <div className={"about-com-ct-layout"}>
                        {this.loadPartners(companysList)}
                    </div>
                    {/*<div className={"about-com-ct-img"}>*/}
                    {/*    <img className={"img-style contain"} src={BANNER1} alt={"company"}/>*/}
                    {/*</div>*/}
                </div>
                <div style={{width: "100%" , height: '50px', borderBottom: "1px solid gray"}}/>
                <div style={{height: '50px'}}/>
                <div className={"about-com-ct-level-one"}>
                    <div className={"about-com-ct-title"}>
                        {"高校科研院所合作伙伴"}
                    </div>
                    <div className={"about-com-ct-content"}>
                        <span>{"整合科技企业技术需求，数据筛选匹配相关领域技术专家，深度撮合产学研合作和技术转移工作，协助高校输出高价值科技成果，协调建立联合实验室等科研机构。"}</span>
                    </div>
                    <div style={{height: '20px'}}/>
                    <div className={"about-com-ct-layout"}>
                        {this.loadPartners(partnersList)}
                    </div>
                </div>
                <div style={{width: "100%" , height: '50px', borderBottom: "1px solid gray"}}/>
                <div style={{height: '50px'}}/>
                <div className={"about-com-ct-level-one"}>
                    <div className={"about-com-ct-title"}>
                        {"城市（经纪人）合作伙伴"}
                    </div>
                    <div className={"about-com-ct-content"}>
                        <span>{"湃通科技以平台为载体，提供丰富的科技资源、成熟的产品体系、高额的合作收益、全面的后台支撑、高标准的业务培训、便捷的管理系统，面向地区招募城市合伙人，赋能各创新主体及科技人员，共同开展城市创新服务。"}</span>
                    </div>
                    <div className={"about-com-ct-img"}>
                        <img className={"img-style contain"} src={BANNER1} alt={"company"}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default CoPartners;
