import React, {Component, Fragment} from "react";
import "./center.css";
import {
    IdcardOutlined,
    LogoutOutlined,
    PayCircleOutlined,
    SafetyCertificateOutlined,
    SettingOutlined,
    VerifiedOutlined
} from "@ant-design/icons";
import {
    getUserInfoCache,
    handleLogout,
    LoginRoot,
    MouseHover,
    objectToList,
    UserInfoStorage,
    utcTimeFormat
} from "../../tool/Const";
import {
    CheckVipStatusUrl,
    data,
    error,
    FindRankingUrl,
    FindUserOpStatsUrl,
    loginOutErrOr,
    sendGetRequest
} from "../../tool/http/Request";
import {connectionError, fail, failReturn, findMsgByError} from "../../tool/http/ErrorMgr";
import RANK from "../../assets/icon/icon_rank.png";
import RANK_ONE from "../../assets/icon/rank_one.svg";
import RANK_TWO from "../../assets/icon/rank_two.svg";
import RANK_THREE from "../../assets/icon/rank_three.svg";
import moment from "moment";
import LunchWin from "../../tool/Tool";
import VipWin from "./VipWin";
import PtcWin from "./PtcWin";

const _ = require('loadsh')

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverI: -1,
            userInfo: '',  // 用户信息缓存
            userOp: '',  // 用户动态数据(统计的用户各项数字)
            vipStatus: {  // vip状态
                level: '',
                toTime: '',
            },
            rankMap: new Map(),  // 排行榜数据
            vipLoaded: false,
            opLoaded: false,
            rankLoaded: false,
        }
    }

    // 加载用户信息
    loadingUserInfo = (userInfo, hoverI, vipLoaded, vipStatus) => {
        if (userInfo && vipLoaded) {
            // 判断个人/专家/企业认证
            let isPer = userInfo['isPer'];
            let isExp = userInfo['isExp'];
            let isComp = userInfo['isComp'];
            let isVip = userInfo['userVip'] !== 0;
            return (
                <div className={"overview-info-base"}>
                    <div className={"overview-info-base-one"}>
                        <div className={"overview-info-username"}>
                            {this.datePeriod()}{userInfo.userNick}
                        </div>
                        <div className={"overview-info-certify"}
                             onMouseEnter={() => {
                                 MouseHover(2, hoverI, this)
                             }}
                             onMouseLeave={() => {
                                 MouseHover(-1, hoverI, this)
                             }}
                        >
                            <VerifiedOutlined style={{color: isPer ? "#148007" : 'gray'}}/>
                            <div className={"overview-tooltip"}
                                 style={{display: 2 === hoverI ? "" : "none"}}
                            >
                                {isPer ? "已个人认证" : "未个人认证"}
                            </div>
                        </div>
                        <div className={"overview-info-certify"}
                             onMouseEnter={() => {
                                 MouseHover(3, hoverI, this)
                             }}
                             onMouseLeave={() => {
                                 MouseHover(-1, hoverI, this)
                             }}
                        >
                            <VerifiedOutlined style={{color: isExp ? "#148007" : 'gray'}}/>
                            <div className={"overview-tooltip"} style={{display: 3 === hoverI ? "" : "none"}}>
                                {isExp ? "已专家认证" : "未专家认证"}
                            </div>
                        </div>
                        <div className={"overview-info-certify"}
                             onMouseEnter={() => {
                                 MouseHover(4, hoverI, this)
                             }}
                             onMouseLeave={() => {
                                 MouseHover(-1, hoverI, this)
                             }}
                        >
                            <VerifiedOutlined style={{color: isComp ? "#148007" : 'gray'}}/>
                            <div className={"overview-tooltip"} style={{display: 4 === hoverI ? "" : "none"}}>
                                {isComp ? "已企业认证" : "未企业认证"}
                            </div>
                        </div>
                        <div className={"overview-info-complete-info"}>
                            <IdcardOutlined/>
                        </div>
                    </div>
                    <div className={"overview-info-coin-com"}>
                        <div className={"overview-info-coin-com-icon"}>
                            <SafetyCertificateOutlined/>
                        </div>
                        <div className={"overview-info-coin"} style={{color: isVip ? "" : "#99a2aa"}}>
                            {"vip会员"}
                        </div>
                        {
                            isVip ?
                                [<div className={"overview-info-vip-date"}>
                                    {moment(vipStatus.toTime).format("YYYY-MM-DD")}
                                </div>,
                                    <div className={"overview-info-vip-date"}>
                                        {moment(utcTimeFormat).isBefore(moment(vipStatus.toTime).format("YYYY-MM-DD")) ? " 到期" : " 已逾期"}
                                    </div>]
                                :
                                null
                        }
                        <div className={"overview-info-coin-recharge"}
                             onClick={() => {
                                 this.handleVipPre(userInfo, vipStatus, "wrap", "vip")
                             }}
                        >
                            {isVip ? "续费" : "升级为会员"}
                        </div>
                    </div>
                    <div className={"overview-info-coin-com"}>
                        <div className={"overview-info-coin-com-icon"}>
                            <PayCircleOutlined/>
                        </div>
                        <div className={"overview-info-coin"}>
                            {"湃通币余额："}
                        </div>
                        <div className={"overview-info-coin-num"}>
                            {userInfo['userCoin']}
                        </div>
                        <div className={"overview-info-coin-recharge"}
                             onClick={() => {
                                 this.handleAddPtcPre(userInfo)
                             }}
                        >
                            {"充值"}
                        </div>
                    </div>
                    <div className={"overview-info-complete"}>
                        <div className={"overview-info-complete-icon"}>
                            <SettingOutlined/>
                        </div>
                        <div className={"overview-info-complete-title"}
                             onClick={() => {
                                 window.location.href = "/center?type=account"
                             }}
                        >
                            {"编辑个人资料"}
                        </div>
                    </div>
                    <div className={"overview-info-complete"}>
                        <div className={"overview-info-complete-icon"}>
                            <LogoutOutlined/>
                        </div>
                        <div className={"overview-info-complete-title"}
                             onClick={() => {
                                 handleLogout(userInfo)
                             }}
                        >
                            {"退出登录"}
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 充值湃通币
    handleAddPtcPre = (userInfo) => {
        let id = "wrap";
        let winId = "ptc";
        LunchWin(<PtcWin parentId={id} winId={winId} userInfo={userInfo} vipStatus={null}/>, id, winId);
    }

    // 判断当前时间的时间段（早中晚）
    datePeriod = () => {
        let date = new Date();
        let dateHour = date.getHours();
        if (dateHour >= 6 && dateHour < 12) {
            return "上午好，";
        } else if (dateHour >= 12 && dateHour < 18) {
            return "下午好，";
        } else {
            return "晚上好，";
        }
    }


    // 加载用户动态
    loadingMyOp = (opLoaded, userOp) => {
        if (opLoaded) {
            return (
                <Fragment>
                    <div className={"center-ct-header"}>
                        <div className={"center-ct-header-wrap"}>
                            <div className={"center-ct-header-icon"}/>
                            <div className={"center-ct-header-title"}>
                                {"我的动态"}
                            </div>
                            <div className={"center-ct-header-subtitle"}>
                                {"掌握最新动态"}
                            </div>
                        </div>
                    </div>
                    <div className={"overview-board-action"}>
                        <div className={"overview-board-action-ele"}>
                            <div className={"overview-board-action-num"}>
                                {userOp.outcomeView}
                            </div>
                            <div className={"overview-board-action-title"}>
                                {"科技成果浏览"}
                            </div>
                        </div>
                        <div className={"overview-board-action-ele"}>
                            <div className={"overview-board-action-num"}>
                                {userOp.demandView}
                            </div>
                            <div className={"overview-board-action-title"}>
                                {"科技需求浏览"}
                            </div>
                        </div>
                        <div className={"overview-board-action-ele"}>
                            <div className={"overview-board-action-num"}>
                                {userOp.expertView}
                            </div>
                            <div className={"overview-board-action-title"}>
                                {"专家人才浏览"}
                            </div>
                        </div>
                        <div className={"overview-board-action-ele"}>
                            <div className={"overview-board-action-num"}>
                                {userOp.policyView}
                            </div>
                            <div className={"overview-board-action-title"}>
                                {"科技政策浏览"}
                            </div>
                        </div>
                        <div className={"overview-board-action-ele"}>
                            <div className={"overview-board-action-num"}>
                                {userOp.outcomePush}
                            </div>
                            <div className={"overview-board-action-title"}>
                                {"科技成果提交"}
                            </div>
                        </div>
                        <div className={"overview-board-action-ele"}>
                            <div className={"overview-board-action-num"}>
                                {userOp.demandPush}
                            </div>
                            <div className={"overview-board-action-title"}>
                                {"科技需求提交"}
                            </div>
                        </div>
                        <div className={"overview-board-action-ele"}>
                            <div className={"overview-board-action-num"}>
                                {userOp.expertAppoint}
                            </div>
                            <div className={"overview-board-action-title"}>
                                {"我的预约"}
                            </div>
                        </div>
                        <div className={"overview-board-action-ele"}>
                            <div className={"overview-board-action-num"}>
                                {this.plusFun(userOp.outcomeSave, userOp.demandSave, userOp.expertSave, userOp.policySave)}
                            </div>
                            <div className={"overview-board-action-title"}>
                                {"我的收藏"}
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    // 数字相加
    plusFun = (a, b, c, d) => {
        return Number(a) + Number(b) + Number(c) + Number(d);
    }

    // 加载排行榜
    loadingRank = (rankLoaded, rankMap) => {
        if (rankLoaded) {
            return (
                <Fragment>
                    <div className={"center-ct-header"}>
                        <div className={"center-ct-header-wrap"}>
                            <div className={"center-ct-header-icon"}/>
                            <div className={"center-ct-header-title"}>
                                {"排行榜"}
                            </div>
                            <div className={"center-ct-header-subtitle"}>
                                {"最新科技点排名"}
                            </div>
                        </div>
                    </div>
                    <div className={"rank-plugin"}>
                        {this.loadRankLayout(rankMap)}
                    </div>
                </Fragment>
            )
        }
    }

    // 加载排行榜样式
    loadRankLayout = (rankMap) => {
        let ui = [];
        if (rankMap.size === 6) {
            for (let i = 0; i < 3; i++) {
                ui.push(
                    <div className={"rank-plugin-wrap"} style={{margin: i === 1 ? "0 20px" : ""}}>
                        <div className={"rank-plugin-header" + " " + "img_" + i}>
                            {this.handleRankHeader(i)}
                        </div>
                        <div className={"rank-shadow" + " " + "shadow_" + i}>
                            <div className={"rank-shadow-item" + " " + "shadow_item_" + i}/>
                        </div>
                        <div className={"rank-plugin-main" + " " + "main_" + i}>
                            {this.loadRankMain(rankMap.get(this.getTableNameByRankingType(i + 1)))}
                        </div>
                        <div className={"rank-plugin-self" + " " + "self_" + i}>
                            <div className={"rank-plugin-self-left"}>
                                <div className={"rank-plugin-self-icon"}>
                                    <img className={"img-style contain"} src={RANK} alt={"rank"}/>
                                </div>
                                <div className={"rank-plugin-self-title"}>
                                    {"我的排名"}
                                </div>
                            </div>
                            <div className={"rank-plugin-self-rank"}>
                                {
                                    rankMap.get(this.getTableNameByRankingType(i + 1) + "Rank") === 0 ?
                                        "未上榜" : "NO." + rankMap.get(this.getTableNameByRankingType(i + 1) + "Rank")
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return ui;
    }

    // 加载排行榜榜单
    loadRankMain = (list) => {
        let ui = [];
        let len = list.length;
        if (list && len >= 10) {
            // console.log(list);
            for (let i = 0; i < len; i++) {
                ui.push(
                    <div className={"rank-plugin-main-item"}>
                        {
                            i < 3 ?
                                <div className={"rank-plugin-main-item-rank-icon"}>
                                    <img className={"img-style contain"}
                                         src={i === 0 ? RANK_ONE : i === 1 ? RANK_TWO : RANK_THREE} alt={""}/>
                                </div>
                                :
                                <div className={"rank-plugin-main-item-rank"}>
                                    {list[i]["rankIndex"]}
                                </div>
                        }
                        <div className={"rank-plugin-main-item-name"}>
                            {list[i]["userNick"]}
                        </div>
                        <div className={"rank-plugin-main-item-points"}>
                            {list[i]["points"]}
                        </div>
                    </div>
                )
            }
        } else {
            ui.push(
                <div className={"rank-plugin-main-tip"}>
                    {"排行榜更新中，敬请期待"}
                </div>
            )
        }
        return ui;
    }

    // 排行榜标题
    handleRankHeader = (type) => {
        switch (type) {
            case 0: {
                return "科技资源榜"
            }
            case 1: {
                return "科技达人榜"
            }
            case 2: {
                return "科技贡献榜"
            }
        }
    }

    // 购买vip前置
    handleVipPre = (userInfo, vipStatus, id, winId) => {
        LunchWin(<VipWin parentId={id} winId={winId} userInfo={userInfo} vipStatus={vipStatus}/>, id, winId)
    }

    componentDidMount() {
        getUserInfoCache(this);  // 缓存获取用户信息
        this.getVipStatus();
        this.getUserOp();
        this.getRanking(11);
    }

    // 获取用户vip状态
    getVipStatus = () => {
        let userInfoCache = UserInfoStorage.get("userInfo");
        let userId = userInfoCache.userId;
        sendGetRequest(CheckVipStatusUrl, {userId: userId}, true).then(res => {
            // console.log("获取用户vip状态res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    let result = res[data];
                    let vipStatus = this.state.vipStatus;
                    if (!result) {
                        vipStatus.level = 0;
                    } else {
                        vipStatus.level = result['vipLevel'];
                        vipStatus.toTime = result['toTime'];
                    }
                    this.setState({
                        vipStatus: vipStatus,
                        vipLoaded: true,
                    })
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 获取用户动态
    getUserOp = () => {
        let userInfoCache = UserInfoStorage.get("userInfo");
        let userId = userInfoCache.userId;
        sendGetRequest(FindUserOpStatsUrl, {userId: userId}, true).then(res => {
            // console.log("获取用户动态res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.setState({
                        userOp: res[data],
                        opLoaded: true,
                    })
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // 获取用户排行榜
    // 科技资源榜（所有服务充值）
    // 科技达人榜（所有的消费）
    // 科技贡献榜（发布统计））
    // topIndex比榜单多1
    getRanking = (topIndex) => {
        let userInfo = UserInfoStorage.get("userInfo");
        let params = {
            userId: userInfo.userId,
            topIndex: topIndex,
        };
        sendGetRequest(FindRankingUrl, params, false).then(res => {
            // console.log("获取排行榜res: ", res);
            if (res === connectionError) {
                fail(findMsgByError(connectionError));
            } else {
                if (res && res.hasOwnProperty("success") && res.success) {
                    this.fillMap(res[data]);
                } else if (res[error] === loginOutErrOr) {
                    UserInfoStorage.remove("userInfo");
                    failReturn(findMsgByError(res[error]), LoginRoot)
                } else {
                    fail(findMsgByError(res[error]));
                }
            }
        })
    }

    // getTableNameByRankingType
    getTableNameByRankingType = (type) => {
        switch (type) {
            case 1: {
                return "techRsc"
            }
            case 2: {
                return "techMaster"
            }
            case 3: {
                return "techCtb"
            }
        }
    }

    // fillMap
    fillMap = (res) => {
        let list = [];
        let listAll = [1, 2, 3];
        let map = new Map;
        let resList = objectToList(res);
        let len = resList.length;
        for (let j = 0; j < len; j++) {
            let key = resList[j]["rankings"][0]['rankingType'];
            let value = this.sortByRankIndex(resList[j]["rankings"]);
            list.push(key)
            map.set(this.getTableNameByRankingType(key), value);
            map.set(this.getTableNameByRankingType(key) + "Rank", resList[j]["userRank"]);
        }
        // 处理空值
        let diff = _.difference(listAll, list);
        if (diff.length > 0) {
            for (let k in diff) {
                map.set(this.getTableNameByRankingType(diff[k]), []);
                map.set(this.getTableNameByRankingType(diff[k] + "Rank"), 0);
            }
        }
        this.setState({
            rankMap: map,
            rankLoaded: true,
        })
        // console.log(map);
    }

    // sort by rankIndex
    sortByRankIndex = (data) => {
        if (data.length > 0) {
            data.sort(function (a, b) {
                if (a["rankIndex"] < b["rankIndex"]) {
                    return -1;
                }
                if (a["rankIndex"] > b["rankIndex"]) {
                    return 1;
                }
            })
        }
        return data;
    }

    render() {
        let userInfo = this.state.userInfo;

        let vipStatus = this.state.vipStatus;
        let vipLoaded = this.state.vipLoaded;

        let userOp = this.state.userOp;
        let opLoaded = this.state.opLoaded;

        let rankMap = this.state.rankMap;
        let rankLoaded = this.state.rankLoaded;

        let hoverI = this.state.hoverI;


        return (
            <div className={"overview-ct-wrap"}>
                <div className={"overview-ct"}>
                    <div className={"overview-info-ct"}>
                        {this.loadingUserInfo(userInfo, hoverI, vipLoaded, vipStatus)}
                    </div>
                    <div className={"overview-board-ct"}>
                        {this.loadingMyOp(opLoaded, userOp)}
                    </div>
                    <div className={"overview-rank-ct"}>
                        {this.loadingRank(rankLoaded, rankMap)}
                    </div>
                </div>
            </div>
        )
    }
}

export default Overview;
