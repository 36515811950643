import axios from "axios";
import {connectionError, fail, findMsgByError} from "./ErrorMgr";

const WechatOAuthUrl = "https://open.weixin.qq.com/connect/qrconnect";

const AliOAuthUrl = "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm";

const AliAuthLoginOutUrl = "https://auth.alipay.com/login/logout.htm";

const AliId = "2021003140641053";

const Header = "https://www.paton-tech.com";

const SrcHeader = Header + "/src/";

// const RequestHeader = "http://localhost:8082";

const RequestHeader = Header + "/user/api";

// 微信通过code获取access_token url
const WxAuthUrl = "https://api.weixin.qq.com/sns/oauth2/access_token";

const Dir = "";

// 注册api
const RegisterUrl = RequestHeader + Dir + "/register";

// 登录api
const LoginUrl = RequestHeader + Dir + "/login";

/**
 * 支付宝登录
 * authCode
 * 返还userInfo
 */
const LoginByAliUrl = RequestHeader + Dir + "/LoginByAli";

/**
 * 更新支付宝账号
 * userId
 * aliId（解绑：null）
 */
const UpdateAliUrl = RequestHeader + Dir + "/updateAli";

/**
 * 微信登录
 * authCode
 * 返还userInfo
 */
const LoginByWxUrl = RequestHeader + Dir + "/LoginByWx";

/**
 * 更新微信账号
 * userId
 * wechatId（解绑：null）
 */
const UpdateWechatUrl = RequestHeader + Dir + "/updateWechat";

// 修改密码api
const ChangePassUrl = RequestHeader + Dir + "/changePass";

// 校验用户是否登录api
const CheckLoginValidUrl = RequestHeader + Dir + "/checkLoginValid";

// 用户登出api
const LogoutUrl = RequestHeader + Dir + "/logout";

// 更新用户信息
const RefreshUserByIdUrl = RequestHeader + Dir + "/refreshUserById";

// 发送短信验证码api
const SendPhoneValidUrl = RequestHeader + Dir + "/sendValidCode";

// 获取用户动态api
const FindUserOpStatsUrl = RequestHeader + Dir + "/findUserOpStats";

// 更新用户昵称
const UpdateNickUrl = RequestHeader + Dir + "/updateNick";

// 调用验证码发送至手机
const UpdatePhonePreUrl = RequestHeader + Dir + "/updatePhonePre";

// 更新用户手机
const UpdatePhonePostUrl = RequestHeader + Dir + "/updatePhonePost";

// 更新用户邮箱
const UpdateEmailUrl = RequestHeader + Dir + "/updateEmail";

// 更新用户基本数据api
const UpdateCareerUrl = RequestHeader + Dir + "/updateCareer";

// 获取专家日程设置api
const FindExpertScheduleInfoUrl = RequestHeader + Dir + "/findExpertScheduleInfo";

// 更新专家日程设置api
const UpdateExpertScheduleInfoUrl = RequestHeader + Dir + "/updateExpertScheduleInfo";

// 根据ref获取专家简历api
const FindExpertByRefUrl = RequestHeader + Dir + "/findExpertByRef";

// 获取专家预约日程api（专家日程查询(管理中心专家界面用，用户界面取非集用)）
const CheckExpertAppointUrl = RequestHeader + Dir + "/checkExpertAppoint";

// 用户提交预约api
const SubmitExpAppointUrl = RequestHeader + Dir + "/submitExpAppoint";

// 用户中心可查看提交了但是还未进行审核的预约api
const CheckAwaitAppointsUrl = RequestHeader + Dir + "/checkAwaitAppoints";

// 用户中心查看已经通过审核的未来预约日程api
const CheckComingAppointsUrl = RequestHeader + Dir + "/checkComingAppoints";

// 用户查询预约历史（200条）api
const CheckHistoricalAppointUrl = RequestHeader + Dir + "/checkHistoricalAppoint";

// 用户查询订单记录api
// 0 是待支付，1是待审核，2是历史（成功或失败）
const CheckStatusOrdersUrl = RequestHeader + Dir + "/checkStatusOrders";

// 需要对接支付服务api
const ProcessWaitPayOrderUrl = RequestHeader + Dir + "/processWaitPayOrder";

// 处理个人认证api
const ProcessPersonAuthUrl = RequestHeader + Dir + "/processPersonAuth";

// 处理企业认证api
const ProcessCompAuthUrl = RequestHeader + Dir + "/processCompAuth";

// 处理专家认证api
const ProcessExpAuthUrl = RequestHeader + Dir + "/processExpAuth";

// 查看是否可以提交认证，当有审核中的认证时需提示api
const CheckCanSubmitAuthUrl = RequestHeader + Dir + "/checkCanSubmitAuth";

// 获取资源数据api
const FindResourceDataUrl = RequestHeader + Dir + "/findResourceData";

// 获取资源数据数据量api
const FindResourceSizeUrl = RequestHeader + Dir + "/findResourceSize";

// 获取push数据api
const GetPushDataUrl = RequestHeader + Dir + "/getPushData";

// 获取adv数据api
const GetAdvDataUrl = RequestHeader + Dir + "/getAdvData";

// click监控（opType=1(click), 没有登录时userId传null, dataType为1代表页面2代表item）
const ClickMonitorUrl = RequestHeader + Dir + "/clickMonitor";

// 由资源ID获取资源收藏点击等数据
const CheckMonitorUrl = RequestHeader + Dir + "/checkMonitor";

// 做收藏的增加与减少，调整userId对应的itemIds，调整stats中item的收藏数量，调整userId对应的4大类op save数量
const FavorOpUrl = RequestHeader + Dir + "/favorOp";

// 通过userId和种类index拉取显示对应类型数据列表
const FindFavorDataByTypeUrl = RequestHeader + Dir + "/findFavorDataByType";

// 通过itemId获取详情，不是VIP的话锁定信息不返回
const FindItemDetailByIdUrl = RequestHeader + Dir + "/findItemDetailById";

// 查询vip状态
const CheckVipStatusUrl = RequestHeader + Dir + "/checkVipStatus";

// 用户提交VIP服务
const SubmitVipAppointUrl = RequestHeader + Dir + "/submitVipAppoint";

// 用户提交解锁服务api
const SubmitItemUnlockUrl = RequestHeader + Dir + "/submitItemUnlock";

// 查询用户所有解锁项目
const CheckUnlockItemByUserUrl = RequestHeader + Dir + "/checkUnlockItemByUser";

// 用户提交科技成果api
const SubmitOutcomeUrl = RequestHeader + Dir + "/submitOutcome";

// 用户提交技术需求api
const SubmitDemandUrl = RequestHeader + Dir + "/submitDemand";

// 用户获取只能是驳回或者等待的科研成果
const CheckOutcomeSubByIdAndStatusUrl = RequestHeader + Dir + "/checkOutcomeSubByIdAndStatus";

// 用户获取只能是驳回或者等待的技术需求
const CheckDemandsSubByIdAndStatusUrl = RequestHeader + Dir + "/checkDemandsSubByIdAndStatus";

// 用户获取已通过的科研成果/技术需求
const FindUserAcceptItemsUrl = RequestHeader + Dir + "/findUserAcceptItems";

// 用户下架已通过的科研成果/技术需求
const CloseUserItemByIdUrl = RequestHeader + Dir + "/closeUserItemById";

// 排行榜
const FindRankingUrl = RequestHeader + Dir + "/findRanking";

// 根据页数获取消息
const FindNoticesByPageUrl = RequestHeader + Dir + "/findNoticesByPage";

// 查看消息
const ReadNoticeUrl = RequestHeader + Dir + "/readNotice";

// 删除消息
const DeleteUserNoticesUrl = RequestHeader + Dir + "/deleteUserNotices";

// 获取订阅信息
const GetSubscriptSummaryUrl = RequestHeader + Dir + "/getSubscriptSummary";

// 更新订阅信息
const ProcessSubscriptUrl = RequestHeader + Dir + "/processSubscript";

// 删除订阅信息
const DeleteSubscriptUrl = RequestHeader + Dir + "/delSubscript";

// 切换订阅开关
const SwitchSubscriptUrl = RequestHeader + Dir + "/switchSubscript";

// 初始order
const InitOrderUrl = RequestHeader + Dir + "/initOrder";

// 支付接口(涉及湃通币)
const PayByCoinUrl = RequestHeader + Dir + "/payByCoin";

// 支付接口（支付宝）
const PayByAliUrl = RequestHeader + Dir + "/payByAli";

// 支付接口（微信）
const PayByWxUrl = RequestHeader + Dir + "/payByWx";

// 充值湃通币
const AddPtcUrl = RequestHeader + Dir + "/addPtc";

// 支付完调用的接口
const CheckOrderFinishUrl = RequestHeader + Dir + "/checkOrderFinish";

const data = "responseData";

const error = "errorType";

const TempId = "SMS_236640043";

const loginOutErrOr = "LOGIN_OUT";

const authRepeatError = "AUTH_REPEAT";

const sendPostBack = (pojo, cred, back: DataBack) => {
    console.log("post请求: ", pojo);
    sendPostRequest(pojo, cred).then(res => {
        console.log("post的res: ", res);
        if (res === connectionError) {
            fail(findMsgByError(connectionError));
        } else {
            if (res && res.hasOwnProperty("success") && res.success) {
                back.successBack(res[data])
            } else {
                back.failBack(res[error])
            }
        }
    })
}

const sendGetBack = (url, params, cred, back: DataBack) => {
    console.log("get请求: ", params);
    sendGetRequest(url, params, cred).then(res => {
        console.log("get的res: ", res);
        if (res === connectionError) {
            fail(findMsgByError(connectionError));
        } else {
            if (res && res.hasOwnProperty("success") && res.success) {
                back.successBack(res[data])
            } else {
                back.failBack(res[error])
            }
        }
    })
}

async function sendPostRequest(pojo, cred) {
    axios.defaults.withCredentials = cred;
    return await axios.post(pojo.url, JSON.stringify(pojo.data)).then(
        function (response) {
            return response.data;
        }
    ).catch(function (error) {
        console.log(error)
        return connectionError;
    })
}

async function sendGetRequest(url, params, cred) {
    axios.defaults.withCredentials = cred;
    return await axios.get(url, {
        params: params,
    }).then(
        function (response) {
            return response.data;
        }
    ).catch(function (error) {
        console.log(error)
        return connectionError;
    })
}

const genPojo = (url, data) => {
    return {
        url: url,
        data: data,
    }
}

export {
    RegisterUrl,
    LoginUrl,
    LoginByAliUrl,
    UpdateAliUrl,
    LoginByWxUrl,
    UpdateWechatUrl,
    LogoutUrl,
    RefreshUserByIdUrl,
    CheckLoginValidUrl,
    ChangePassUrl,
    SendPhoneValidUrl,
    FindUserOpStatsUrl,
    UpdateNickUrl,
    UpdatePhonePreUrl,
    UpdatePhonePostUrl,
    UpdateEmailUrl,
    UpdateCareerUrl,
    FindExpertScheduleInfoUrl,
    UpdateExpertScheduleInfoUrl,
    FindExpertByRefUrl,
    CheckExpertAppointUrl,
    SubmitExpAppointUrl,
    CheckAwaitAppointsUrl,
    CheckComingAppointsUrl,
    CheckHistoricalAppointUrl,
    CheckStatusOrdersUrl,
    ProcessWaitPayOrderUrl,
    ProcessPersonAuthUrl,
    ProcessCompAuthUrl,
    ProcessExpAuthUrl,
    CheckCanSubmitAuthUrl,
    FindResourceDataUrl,
    FindResourceSizeUrl,
    GetPushDataUrl,
    GetAdvDataUrl,
    ClickMonitorUrl,
    CheckMonitorUrl,
    FavorOpUrl,
    FindFavorDataByTypeUrl,
    FindItemDetailByIdUrl,
    CheckVipStatusUrl,
    SubmitVipAppointUrl,
    SubmitItemUnlockUrl,
    CheckUnlockItemByUserUrl,
    SubmitOutcomeUrl,
    SubmitDemandUrl,
    CheckOutcomeSubByIdAndStatusUrl,
    CheckDemandsSubByIdAndStatusUrl,
    FindUserAcceptItemsUrl,
    CloseUserItemByIdUrl,
    FindRankingUrl,
    FindNoticesByPageUrl,
    ReadNoticeUrl,
    DeleteUserNoticesUrl,
    GetSubscriptSummaryUrl,
    ProcessSubscriptUrl,
    DeleteSubscriptUrl,
    SwitchSubscriptUrl,
    InitOrderUrl,
    PayByCoinUrl,
    PayByAliUrl,
    PayByWxUrl,
    AddPtcUrl,
    CheckOrderFinishUrl,
    sendGetRequest,
    sendPostRequest,
    data,
    error,
    loginOutErrOr,
    authRepeatError,
    genPojo,
    TempId,
    sendPostBack,
    sendGetBack,
    Header,
    SrcHeader,
    WxAuthUrl,
    WechatOAuthUrl,
    AliOAuthUrl,
    AliId,
};
