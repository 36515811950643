import React from 'react';
import "./../page.css";
import {DownCircleOutlined} from "@ant-design/icons";

class TagsDown extends React.Component {
    genTagLine = () => {
        return (
            <div className={"tag-sp"}>
                <div className={"tag-sp-in"}/>
                <div className={"tag-sp-in"}/>
                <div className={"tag-sp-in"}/>
                <div className={"tag-sp-in"}/>
                <div className={"tag-sp-in"}/>
                <div className={"tag-sp-in"}/>
                <div className={"tag-sp-in"}/>
                <div className={"tag-sp-in"}/>
                <div className={"tag-sp-in"}/>
            </div>
        )
    }

    render() {
        let parent = this.props.parent;
        return <div className={"out-content"}>
            <div className={"tag-ot"}>
                <div className={"page-ct"} style={{background: "#e7e7e7"}}>
                    <div className={"tags-outer"}>
                        <div className={"tag-ct"} onClick={() => {parent.scrollToAnchor("solution-pos")}}>
                            <div className={"tag-title"}>
                                <span>{"科技创新解决方案"}</span>
                            </div>
                            <div className={"tag-des"}>
                                <span>{"全方位资源覆盖 全要素协调发展"}</span>
                                <span>{"全科技生态打造"}</span>
                            </div>
                            <div className={"tag-sign"}>
                                <DownCircleOutlined />
                            </div>
                        </div>
                        {this.genTagLine()}
                        <div className={"tag-ct"} onClick={() => {parent.scrollToAnchor("outcome-pos")}}>
                            <div className={"tag-title"}>
                                <span>{"最新前沿科技成果"}</span>
                            </div>
                            <div className={"tag-des"}>
                                <span>{"整合行业先进科研成果"}</span>
                                <span>{"助力企业创新转型升级"}</span>
                            </div>
                            <div className={"tag-sign"}>
                                <DownCircleOutlined />
                            </div>
                        </div>
                        {this.genTagLine()}
                        <div className={"tag-ct"} onClick={() => {parent.scrollToAnchor("expert-pos")}}>
                            <div className={"tag-title"}>
                                <span>{"权威科技专家团队"}</span>
                            </div>
                            <div className={"tag-des"}>
                                <span>{"汇聚顶尖科研人才 打造产业智慧大脑"}</span>
                                <span>{"破解企业人才困境"}</span>
                            </div>
                            <div className={"tag-sign"}>
                                <DownCircleOutlined />
                            </div>
                        </div>
                        {this.genTagLine()}
                        <div className={"tag-ct"} onClick={() => {parent.scrollToAnchor("demand-pos")}}>
                            <div className={"tag-title"}>
                                <span>{"精选热门技术需求"}</span>
                            </div>
                            <div className={"tag-des"}>
                                <span>{"多方位多渠道汇集需求"}</span>
                                <span>{"分程度分领域精准匹配"}</span>
                            </div>
                            <div className={"tag-sign"}>
                                <DownCircleOutlined />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default TagsDown;