import React, {Fragment, useState} from "react";
import {Swiper} from "swiper/react";
import {FreeMode, Navigation, Thumbs} from "swiper";

export function SwiperComponent(props) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const {itemId, picSrc, loadSwiperImg} = props;

    return (
        <Fragment>
            <Swiper className={"swiper-main-ct"}
                    loop={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
            >
                {loadSwiperImg(itemId, picSrc, "img-style contain")}
            </Swiper>
            <Swiper className={"swiper-sub-ct"}
                    key={"preView"}
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={3}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
            >
                {loadSwiperImg(itemId, picSrc, "img-style cover")}
            </Swiper>
        </Fragment>
    )
}
