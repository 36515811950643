import React, {Component} from 'react';
import "./policy.css";
import FilterHeader from "../../tool/filter/FilterHeader";
import GeoFilter from "../../tool/filter/GeoFilter";
import FilterTags from "../../tool/filter/FilterTags";
import Bk from "../pic/policyBk.png";
import {PolicyMap} from "../../tool/Const";

const filterMainStyle = {
    header: "policy-filter-header",
    title: "policy-filter-search-title",
    input: "policy-filter-input",
    search: "policy-filter-search-bt",
    outer: "policy-filter-search",
}

class PolicyFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectItem: -1,
            openGeo: false,
            showCity: -1,
        };
    }

    setOptions = (options) => {
        this.props.parent.setState({
            options: Object.assign({}, options)
        })
    }

    render() {
        let options = this.props.options;

        return (

            <div style={{minHeight: "400px", width: "100%", background: "white"}} id={"filter"}>
                <div className={"policy-bk"}>
                    <img className={"policy-banner-img"} src={Bk} alt={""}/>
                    <div className={"expert-filter"}>
                        <FilterHeader page={"policy"} parent={this} filterStyle={filterMainStyle} options={options}/>
                    </div>
                </div>
                <div className={"policy-filter-ct"}>
                    <div className={"policy-filter-outer"}>
                        <GeoFilter options={options} parent={this}
                                   areaClass={"policy-filter-area"} titleClass={"filter-area-geo-title"}
                                   map={PolicyMap} isIcon={true}
                                   mainType={"中华人民共和国"} header={"政策范围:"}/>
                        <div id={"p-tag"}>
                            <FilterTags options={options} parent={this} initTag={''} tags={[]} titleType={"科技政策"}
                                        total={this.props.total}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PolicyFilter;