import React from 'react';
import {
    EffectFade, Autoplay, Pagination
} from 'swiper';
import "./../page.css"
import B1 from "./../pic/banner1.jpg"
import B2 from "./../pic/banner2.jpg"
import {Swiper, SwiperSlide} from "swiper/react";
import Slo from "./../pic/slogan.png";
import Slo2 from "./../pic/slogan2.png"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/effect-fade";

class BannerHome extends React.Component {
    render() {
        return <div className={"out-content"}>
            <div className={"home-banner-ot"}>
                <Swiper spaceBetween={50} slidesPerView={1} loop={true}
                        effect={'fade'}
                        autoplay={{
                            "delay": 4000,
                            "disableOnInteraction": false
                        }}
                        modules={[Autoplay, EffectFade, Pagination]}
                        pagination={{
                            clickable: true
                        }}
                        className={"swiper-style-banner"}>
                    <SwiperSlide>
                        <div className={"banner-img-wrap"}>
                            <img src={Slo} alt={""} className={"banner-img"}/>
                        </div>
                        <img className={"home-banner-img"} src={B1} alt={""}/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={"banner-img-wrap"}>
                            <img src={Slo2} alt={""} className={"banner-img"}/>
                        </div>
                        <img className={"home-banner-img"} src={B2} alt={""}/>
                    </SwiperSlide>
                </Swiper>

                <div className={"page-ct"} style={{zIndex: "100", height: "90%"}}>
                    <div className={"banner-ct"}>
                        {/*<div className={"banner-up-ct"}>*/}
                            {/*<img src={Slo} alt={""} className={"banner-img"}/>*/}
                        {/*</div>*/}
                        <div className={"banner-dn-ct"}>
                            <div className={"banner-selection"}>
                                <div className={"banner-button"}
                                     style={{background: "rgba(8,101,177,0.7)", color: "white"}}>
                                    {"提交技术需求"}
                                </div>
                                <div className={"banner-button"}
                                     style={{background: "rgba(255,255,255,0.7)", color: "black"}}>
                                    {"企业技术咨询"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default BannerHome;